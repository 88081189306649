export const extensionDataSource = [
  {
    key: "1",
    name: "key",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Authorization key, required parameter to access Search Feed Report
          API. Contain only digits and letters. You may obtain your
          authorization key from your personal manager.
        </p>
        <p>
          Example: <b>UQlXSEHTd2kWbVY3n0ETLx3v</b>
        </p>
      </>
    ),
  },
  {
    key: "2",
    name: "resType",
    req_opt: "Required",
    type: "String",
    description: (
      <p>
        This parameter is used to specify the response type. Available values
        are <b>table</b> (json) and <b>csv</b>.
      </p>
    ),
  },
  {
    key: "3",
    name: "columns",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Value is a string that can take multiple values separated by a comma.
          In this field you should pass <b>labels</b> that you expect in
          response. Predefined labels that can be used are:
        </p>

        <ul>
          <li>- date,</li>
          <li>- provider,</li>
          <li>- search_channel,</li>
          <li>- tq,</li>
          <li>- filtered_traffic,</li>
          <li>- filtered_traffic_percentage,</li>
          <li>- coinis_redirects,</li>
          <li>- initial_searches_difference,</li>
          <li>- follow_on_searches,</li>
          <li>- country,</li>
          <li>- monetized_searches,</li>
          <li>- ad_coverage,</li>
          <li>- revenue,</li>
          <li>- monetized_rpm,</li>
          <li>- rpm,</li>
          <li>- rpc,</li>
          <li>- ctr,</li>
          <li>- clicks,</li>
          <li>- total_searches,</li>
          <li>- bing_initial_searches,</li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    name: "date_from",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "5",
    name: "date_to",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "6",
    name: "limit",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Limit shows you the number of rows which will be shown in the API response. By default limit is 30.",
  },
  {
    key: "7",
    name: "offset",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Offset determines how many rows will be skipped before the data is displayed. Default is 0.",
  },
  {
    key: "8",
    name: "ordering",
    req_opt: "Optional",
    type: "String",
    description: (
      <div>
        <p>
          For ordering you can use one of the labels from the columns list. For
          descending just use column name, e.g. revenue, and for ascending use -
          symbol, e.g. -revenue.
        </p>
      </div>
    ),
  },
];

export const extensionColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Req/Opt",
    dataIndex: "req_opt",
    key: "req_opt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
];

export const extensionResponse = `{
      "total": [
          {
              "date": 25,
              "country": 52,
              "revenue": 325.65,
              "monetized_searches": 83005
          }
      ],
      "table": [
          {
              "date": 20210306,
              "country": "US",
              "revenue": 19.842,
              "monetized_searches": 344
          },
          {
              "date": 20210309,
              "country": "US",
              "revenue": 15.936,
              "monetized_searches": 684
          },
          {
              "date": 20210304,
              "country": "US",
              "revenue": 12.78,
              "monetized_searches": 859
          },
          {
              "date": 20210319,
              "country": "US",
              "revenue": 10.956,
              "monetized_searches": 625
          },
          {
              "date": 20210304,
              "country": "GB",
              "revenue": 10.692,
              "monetized_searches": 107
          },
          {
              "date": 20210301,
              "country": "US",
              "revenue": 10.452,
              "monetized_searches": 932
          },
          {
              "date": 20210325,
              "country": "DE",
              "revenue": 9.792,
              "monetized_searches": 127
          },
          {
              "date": 20210323,
              "country": "US",
              "revenue": 9.324,
              "monetized_searches": 733
          },
          {
              "date": 20210303,
              "country": "US",
              "revenue": 8.766,
              "monetized_searches": 845
          },
          {
              "date": 20210302,
              "country": "US",
              "revenue": 7.956,
              "monetized_searches": 807
          },
          {
              "date": 20210310,
              "country": "US",
              "revenue": 7.68,
              "monetized_searches": 1094
          },
          {
              "date": 20210312,
              "country": "US",
              "revenue": 7.614,
              "monetized_searches": 532
          },
          {
              "date": 20210306,
              "country": "CA",
              "revenue": 7.458,
              "monetized_searches": 145
          },
          {
              "date": 20210325,
              "country": "US",
              "revenue": 6.042,
              "monetized_searches": 690
          },
          {
              "date": 20210305,
              "country": "US",
              "revenue": 5.628,
              "monetized_searches": 792
          },
          {
              "date": 20210316,
              "country": "DE",
              "revenue": 5.04,
              "monetized_searches": 107
          },
          {
              "date": 20210315,
              "country": "US",
              "revenue": 4.926,
              "monetized_searches": 606
          },
          {
              "date": 20210314,
              "country": "DE",
              "revenue": 4.836,
              "monetized_searches": 134
          },
          {
              "date": 20210321,
              "country": "US",
              "revenue": 4.74,
              "monetized_searches": 404
          },
          {
              "date": 20210308,
              "country": "US",
              "revenue": 4.308,
              "monetized_searches": 745
          }
      ],
      "rows": 897,
      "missing_dates": [
          "2021-03-26",
          "2021-03-27",
          "2021-03-28",
          "2021-03-29",
          "2021-03-30",
          "2021-03-31"
      ]
}`;
