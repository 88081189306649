import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { ColumnGraph } from "./ColumnGraph";
import styles from "./HeadInfo.module.css";
import classNames from "classnames";

export const HeadCardInfo = ({
  loading,
  name,
  color,
  dataDate,
  type,
  dataTotal,
  title,
}) => {
  return (
    <div
      className={classNames(
        styles.infoCard
        //   , {
        //   [styles.infoCardExtension]: module === "extension",
        //   [styles.infoCardN2S]: module === "n2s",
        // }
      )}
    >
      <div className={styles.dashboardBox}>
        <p>{title}</p>
        <div className={styles.headerStatsBox}>
          <p style={{ color, whiteSpace: "nowrap" }}>
            {loading ? <LoadingOutlined spin /> : dataTotal}
          </p>
          {dataDate && dataDate.length > 0 ? (
            <ColumnGraph
              data={dataDate}
              name={name}
              type={type}
              color={color}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
