import React, { useState, useEffect, useReducer } from "react";
import { filtersReducer } from "./utils/reducers";

import { Filters } from "./components/Filters";
import styles from "./AdSamples.module.css";
import { adSamplesService } from "../../services/searchAdSamples";
import { useFetch } from "../../utils/hooks/useFetch";
import { Grid } from "./components/Grid";

export const SearchAdSamples = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [filters, setFilters] = useState({});

  const [loadCountries, setLoadCountries] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      setLoadCountries(true);
      try {
        const res = await adSamplesService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadCountries(false);
      }
    };
    getCountries();
  }, []);

  const loadCopySamples = cancelToken =>
    adSamplesService.getSamplesPaged({
      selectedFilters,
      currentPage,
      pageSize,
      cancelToken,
    });

  const [
    {
      data: { results: samples, count: totalRows },
      isFetching,
    },
    getCopySamples,
  ] = useFetch(loadCopySamples);

  useEffect(() => {
    dispatchSelectedFilters({ type: "", payload: { filters: {} } });
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getCopySamples();
    }
  }, [currentPage, pageSize, getCopySamples, selectedFilters]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.pageTitle}>
        <h2>Ad Samples</h2>
      </div>
      <Filters
        filters={filters}
        setFilters={setFilters}
        loadCountries={loadCountries}
        countries={countries}
        submit={filters => {
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
      />
      <Grid
        currentPage={currentPage}
        pageSize={pageSize}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        samples={samples}
        totalRows={totalRows}
        isFetching={isFetching}
      />
    </div>
  );
};
