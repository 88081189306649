import { Button, Drawer, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { billingPaymentMethodsService } from "../../../services/billingPaymentMethods";
import { publisherReportsService } from "../../../services/publisherReports";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../utils/notifications";
import { generateCountries } from "../../PublisherReports/utils/filterOptions";
import styles from "../BillingPaymentMethods.module.css";

const { Option } = Select;
const { TextArea } = Input;

export const PaymentMethodForm = ({
  methodsCount,
  newMethodVisible,
  setNewMethodVisible,
  getPaymentMethods,
}) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await publisherReportsService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    getCountries();
  }, []);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const res = await billingPaymentMethodsService.submitPaymentMethod({
        ...values,
        method_type: "wire",
        is_default: !methodsCount,
      });
      if (res.success) {
        openSuccessNotification({ message: res.message });
        setNewMethodVisible(false);
        getPaymentMethods();
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({ message: e.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      title="New Wire Payment Method"
      placement="right"
      width={window.innerWidth < 700 ? "100%" : 700}
      className={styles.drawerPaymentMethod}
      onClose={() => {
        setNewMethodVisible(false);
      }}
      visible={newMethodVisible}
    >
      <p className={styles.highlightText}>
        Please add the following information:
      </p>
      <Form layout="vertical" onFinish={handleSubmit}>
        <div className={styles.dualFieldItem}>
          <Form.Item
            label="Bank name"
            name="bank_name"
            rules={[{ required: true, message: "Please enter bank name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bank country"
            name="bank_country"
            rules={[{ required: true, message: "Please enter bank country!" }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select country"
              filterOption={(inputValue, option) =>
                option.children.toLowerCase().includes(inputValue.toLowerCase())
              }
              allowClear
            >
              {generateCountries(countries || [])}
            </Select>
          </Form.Item>
        </div>
        <div className={styles.dualFieldItem}>
          <Form.Item
            label="Bank city"
            name="bank_city"
            rules={[{ required: true, message: "Please enter bank city!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bank address"
            name="bank_address"
            rules={[{ required: true, message: "Please enter bank address!" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className={styles.dualFieldItem}>
          <Form.Item
            label="Account number"
            name="account_number"
            rules={[
              { required: true, message: "Please enter account number!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="IBAN"
            name="iban"
            rules={[{ required: true, message: "Please enter IBAN!" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className={styles.dualFieldItem}>
          <Form.Item
            label="SWIFT"
            name="swift"
            rules={[{ required: true, message: "Please enter SWIFT!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Currency"
            name="currency"
            rules={[{ required: true, message: "Please enter currency!" }]}
          >
            <Select>
              <Option value="USD">USD ($)</Option>
              <Option value="EUR">EUR (€)</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item label="Note" name="note">
          <TextArea showCount maxLength={100} />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.paymentMethodBtn}
            type="primary"
            htmlType="submit"
            style={{ float: "right", marginTop: "0.6rem" }}
            loading={loading}
          >
            Add payment method
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
