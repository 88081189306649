import { Button, Tooltip, Alert } from "antd";
import { InfoCircleTwoTone, CopyOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styles from "../SearchWidgetBuilder.module.css";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { openSuccessNotification } from "../../../../utils/notifications";
import { ExampleTagContextual } from "../../components/ExampleTagContextual";
import { TutorialVideo } from "../../components/TutorialVideo";
import { WidgetPreviewWrapper } from "./WidgetPreviewWrapper";
import { AdPreview } from "./AdPreview";

export const GetTag = ({
  currentWidgetInfo,
  id,
  location,
  setStep,
  createdWidgets,
}) => {
  const [showExample, setShowExample] = useState(true);
  const [showContextualVideo, setShowContextualVideo] = useState(false);

  const history = useHistory();

  return (
    <>
      <h3 className={styles.titleTagWidget}>
        Get tag for {currentWidgetInfo?.title} (Contextual) zone
      </h3>
      <div>
        <div className={styles.tutorialContainer}>
          <img
            onClick={() => setShowContextualVideo(true)}
            alt="preview"
            className={styles.videoPreview}
            src="/images/video-preview.png"
          />
          <div className={styles.videoInstructions}>
            <p>
              Take a look at this video for step-by-step tutorial which will
              show you how to correctly add the code to your website. Make sure
              you properly input the code, or the ads won’t appear on your page
              at all. Click the preview or{" "}
              <span onClick={() => setShowContextualVideo(true)}>
                this link
              </span>{" "}
              to view it.
            </p>
            <p>You can see correct example of usage below.</p>
          </div>
        </div>
        <TutorialVideo
          source={"/media/video/contextual.mp4"}
          showVideo={showContextualVideo}
          setShowVideo={setShowContextualVideo}
        />
        <Alert
          className={styles.parHints}
          message={
            <p>
              Paste this code at the end of your body tag once the website is
              verified.{" "}
              <Tooltip title="If you are using Wordpress, you may insert this file via a Plugin. If not make sure this code is copied to all the website pages you wish to show ads.">
                <InfoCircleTwoTone />
              </Tooltip>
            </p>
          }
          type="info"
        />
        <div className={styles.scriptBox}>
          <CopyToClipboard
            text={
              currentWidgetInfo && currentWidgetInfo.script
                ? currentWidgetInfo.script
                : ""
            }
          >
            <Button
              icon={<CopyOutlined />}
              type="primary"
              className={styles.copyLinkBtn}
              onClick={() => openSuccessNotification({ message: "Copied!" })}
            >
              Copy
            </Button>
          </CopyToClipboard>
          {currentWidgetInfo && currentWidgetInfo.script
            ? currentWidgetInfo.script
            : ""}
        </div>
        <p className={styles.infoWidgetsP}>
          You can find HTML codes for individual wigdets below. Make sure to
          place the code for each of these widgets exactly where you want your
          widget to be displayed on your page. You can consult the video
          tutorial above for examples of placement, and you can have no more
          then 6 widgets per zone.
        </p>

        <div className={styles.widgetsGridTag}>
          {createdWidgets.map((widget, i) => (
            <WidgetPreviewWrapper key={i} widget={widget} id={i} getTag={true}>
              <AdPreview
                widgetStyle={widget.widgetStyle}
                widgetHeadline={widget.widgetHeadline}
              />{" "}
            </WidgetPreviewWrapper>
          ))}
        </div>

        <p className={styles.parRule}>
          Make sure you properly input the code, <b>or the ads won’t appear </b>
          on your page at all. You can see correct example of usage below.{" "}
          <span
            className={styles.showHideExamples}
            onClick={() => setShowExample(!showExample)}
          >
            {showExample ? "Hide examples." : "See examples."}{" "}
          </span>
        </p>
        {showExample && <ExampleTagContextual />}
      </div>
      {/* <Link to="/publisher/zones">
        <Button>{id && id !== "new" ? "Back" : "Done"}</Button>
      </Link> */}
      <div className={styles.actionBox}>
        <Button
          className={styles.backBtnPush}
          onClick={() =>
            !location.preview && id && id !== "new"
              ? setStep(0)
              : history.push("/publisher/zones")
          }
        >
          {id && id !== "new" ? "Back" : "Done"}
        </Button>
        {id && id !== "new" && !location.preview ? (
          <Button
            type="primary"
            onClick={() => history.push("/publisher/zones")}
          >
            Done
          </Button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
