import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useContext,
} from "react";

import { Table } from "./components/Table";
import styles from "./CoinisCampaigns.module.css";
import { useFetch } from "../../utils/hooks/useFetch";
import { Input } from "antd";
import { CampaignFilters } from "./components/CampaignsFilters";
import { TableTitle } from "../CoinisCampaigns/components/TableTitle";
import { TableTitleSort } from "../CoinisCampaigns/components/TableTitleSort";
import { allColumns, initialSelectedColumns } from "./utils/columns";
import { ActionsTableCol } from "./components/ActionsTableCol";
import { useDebounce } from "../../utils/hooks/useDebounce";
import { filtersReducer } from "./utils/reducers";
import { coinisCampaignsService } from "../../services/coinisCampaigns";
import { AccessModal } from "../../components/AccessModal";
import UserContext from "../../contexts/userContext";

export const CoinisCampaigns = () => {
  const { userInfo } = useContext(UserContext);
  const [columnsData] = useState(allColumns);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  // const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [filters, setFilters] = useState({});
  const [checkedColumns, setCheckedColumns] = useState(initialSelectedColumns);
  const [tableColumns, setTableColumns] = useState([]);
  //title debounce search
  const [searchTerm, setSearchTerm] = useState("");
  const debounceChannel = useDebounce(searchTerm, 300);
  //info modals
  const [modalPriceVisible, setModalPriceVisible] = useState(false);
  const [modalLinkVisible, setModalLinkVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isArchiveIncluded, setIsArchiveIncluded] = useState(false);

  const [programDetails, setProgramDetails] = useState({});

  const [sorting, setSorting] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({ campaign_id, channel, prices, links, status, offerwall }) => (
        <ActionsTableCol
          campaign_id={campaign_id}
          channel={channel}
          prices={prices}
          offerwall={offerwall}
          links={links}
          dispatchSelectedFilters={dispatchSelectedFilters}
          filters={filters}
          status={status}
          setModalPriceVisible={setModalPriceVisible}
          setModalLinkVisible={setModalLinkVisible}
          setIsEditModalVisible={setIsEditModalVisible}
        />
      ),
    };
  }, [filters]);

  const updateColumns = useCallback(
    value => {
      setTableColumns(() => {
        let newTableColumn = [];
        allColumns
          .filter(column => value.checkedColumns[column["value"]])
          .forEach(function ({ text, value, tooltip, render }) {
            if (text === "Program" && !userInfo.ow_tmp) {
              return;
            }
            newTableColumn.push({
              title: text === "Created" ?
                <TableTitleSort
                  text={text}
                  order={sorting[value] ? sorting[value] : false}
                  value={value}
                  onSortingClick={handleSortingClick}
                /> : <TableTitle text={text} />,
              dataIndex: value,
              render,
            });
          });
        newTableColumn.push(actionTableColumn);
        return newTableColumn;
      });
    },
    [actionTableColumn, userInfo.ow_tmp, sorting, handleSortingClick]
  );

  const loadMediaCampaigns = cancelToken =>
    coinisCampaignsService.getCampaignsPaged({
      selectedFilters,
      isArchiveIncluded,
      checkedColumns,
      currentPage,
      pageSize,
      cancelToken,
      sorting
    });

  useEffect(() => {
    // if (debounceChannel) {
    dispatchSelectedFilters({ type: "debounce", payload: debounceChannel });
    // }
  }, [debounceChannel]);

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  useEffect(() => setCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters, sorting]);

  // const exportCampaings = (filters, dateRange) => {
  //   mediaCampaignsService.exportMediaCampaigns({
  //     filters,
  //     dateRange,
  //     checkedColumns,
  //   });
  // };

  return (
    <div className={styles.campaignsTable}>
      <div className={styles.pageTitle}>
        <h2>Campaigns</h2>
        <Input
          style={{ maxWidth: 200 }}
          placeholder="Enter channel"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <CampaignFilters
        submit={filters => {
          updateColumns(filters);
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
        filters={filters}
        sorting={sorting}
        setFilters={setFilters}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        allColumns={columnsData}
        isArchiveIncluded={isArchiveIncluded}
        setIsArchiveIncluded={setIsArchiveIncluded}
      />
      <Table
        tableColumns={tableColumns}
        // setSelectedColumns={setSelectedColumns}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        searchValue={searchValue}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        onSearch={search => setSearchValue(search)}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        selectedFilters={selectedFilters}
        modalPriceVisible={modalPriceVisible}
        setModalPriceVisible={setModalPriceVisible}
        modalLinkVisible={modalLinkVisible}
        setModalLinkVisible={setModalLinkVisible}
      />
      <AccessModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        programDetails={programDetails}
        setProgramDetails={setProgramDetails}
      />
    </div>
  );
};
