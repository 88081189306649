import React, { useState, useEffect, useReducer, useMemo, useCallback } from "react";
import moment from "moment";

import { Table } from "./components/Table";
import styles from "./BillingPaymentHistory.module.css";
import { useFetch } from "../../utils/hooks/useFetch";
import { CampaignFilters } from "./components/CampaignsFilters";
import { filtersReducer } from "./utils/reducers";
import { billingPaymentHistoryService } from "../../services/billingPaymentHistory";
import { ActionsTableCol } from "./components/ActionsTableCol";
import { Button } from "antd";
import { TableTitle } from "./components/TableTitle"

export const BillingPaymentHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: moment(),
  });
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const tableColumns = [
    {
      title: (
        <TableTitle
          text={'Date'}
          order={sorting['date'] ? sorting['date'] : false}
          value={'date'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "date",
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: type => (
        <span className={`${styles.status} ${styles["status" + type]}`}>
          {type.replace("_", " ")}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: (
        <TableTitle
          text={'Amount'}
          order={sorting['amount'] ? sorting['amount'] : false}
          value={'amount'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "amount",
      render: amount => amount && "$ " + Number(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      render: item => {
        switch (item) {
          case "Offer Wall":
            return "Affiliate Programs";
          case "Website Owner":
            return "Webmasters";
          case "Extension":
            return "Search";
          case "N2S":
            return "N2S";
          default:
            return null;
        }
      },
    },
  ];

  const loadMediaCampaigns = cancelToken =>
    billingPaymentHistoryService.getPaymentsPaged({
      selectedFilters,
      tableColumns,
      currentPage,
      pageSize,
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({ invoice, id, allow_delete }) => (
        <ActionsTableCol
          id={id}
          invoice={invoice}
          dateRange={dateRange}
          dispatchSelectedFilters={dispatchSelectedFilters}
          filters={filters}
          allowDelete={allow_delete}
        />
      ),
    };
  }, [filters, dateRange]);

  useEffect(() => {
    dispatchSelectedFilters({
      payload: {
        filters: {},
        dateRange: { from: moment().subtract(30, "days"), to: moment() },
      },
    });
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters, sorting]);

  const handleDownloadInvoiceTemplate = () => {
    window.location = "/media/templates/invoice_template.pdf";
  };

  // const exportCampaings = (filters, dateRange) => {
  //   mediaCampaignsService.exportMediaCampaigns({
  //     filters,
  //     dateRange,
  //     checkedColumns,
  //   });
  // };

  return (
    <div className={styles.campaignsTable}>
      <h4>Payment History</h4>
      <p>You can access your payment history in the table below.</p>
      <CampaignFilters
        submit={filters => {
          dispatchSelectedFilters({
            type: "",
            payload: { filters, dateRange },
          });
        }}
        filters={filters}
        setFilters={setFilters}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <div>
        <Button
          className={styles.invoiceTemplateBtn}
          onClick={handleDownloadInvoiceTemplate}
        >
          Download Invoice Template
        </Button>
      </div>
      <Table
        tableColumns={[...tableColumns, actionTableColumn]}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        selectedFilters={selectedFilters}
        date
      />
    </div>
  );
};
