import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { HeadInfo } from "./components/HeadInfo";
import { Insights } from "./components/Insights";
import { StatisticsLineChart } from "./components/StatisticsLineChart";
import { TopChannelsTable } from "./components/TopChannelsTable";
import moment from "moment";
import { EarningsMap } from "./components/EarningsMap";
import { publisherDashboardService } from "../../services/publisherDashboard";
import { Spin } from "antd";
import { CustomLoader } from "./components/CustomLoader";
import { publisherReportsService } from "../../services/publisherReports";
import UserContext from "../../contexts/userContext";

const mapColors = [
  "#A3A1FB",
  "#50D8BB",
  "#FFA167",
  "#FF7CC3",
  "#81C9FF",
  "#FF8474",
];

export const PublisherDashboard = () => {
  const chartType = localStorage.getItem("isHourTime");
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(6, "days"),
    to: moment(),
  });
  const [hourTime, setHourTime] = useState(
    chartType ? chartType === "true" : false
  );
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [mapData, setMapData] = useState({});
  const [loading, setLoading] = useState(false);
  const [stonlyTrackSent, setStonlyTrackSent] = useState(false);
  const { userInfo } = useContext(UserContext);

  const parseTopCountries = useCallback(topCountries => {
    if (topCountries) {
      const formatedCountries = topCountries.map((country, index) => {
        return { [country.country]: mapColors[index] };
      });

      const mapData = Object.assign({}, ...formatedCountries);
      setMapData(mapData);
      setTopCountries(topCountries);
    }
  }, []);

  useEffect(() => {
    const fetchStatsChart = async () => {
      setLoading(true);
      try {
        const [chart, topChannels, countriesData, data] = await Promise.all([
          publisherDashboardService.getStatisticData(
            dateRange,
            ["impressions", "revenue", "clicks", "leads"],
            hourTime
          ),
          publisherDashboardService.getTopChannelsData(dateRange),
          publisherReportsService.getCountries(),
          publisherDashboardService.getEarningsMapData(dateRange),
        ]);
        setAllCountries(countriesData.data);
        parseTopCountries(data ? data.table : []);
        if (chart.values) {
          const chartParse = {
            values: chart.values.map(item => {
              item[2] = parseFloat(item[2].toFixed(2));
              return item;
            }),
          };
          setChartData(chartParse);
        } else {
          setChartData(chart);
        }
        setTableData(topChannels.table);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchStatsChart();
  }, [dateRange, parseTopCountries, hourTime]);

  useEffect(() => {
    if(userInfo.id && !stonlyTrackSent) {
      const referenceDate = new Date("2022-06-01");
      const joinedDate = new Date(userInfo.date_joined);
      if(joinedDate.getTime() >= referenceDate.getTime()) {
        window.stonlyTrack('track', 'new-user');
        window.stonlyTrack('identify', String(userInfo.id), {
          'user-age-key': 'new-user',
        });
      } else {
        window.stonlyTrack('identify', String(userInfo.id), {
          'user-age-key': 'old-user',
        });
      }
      setStonlyTrackSent(true);
    }
  }, [userInfo, stonlyTrackSent]);

  return (
    <div className={styles.mainContainer}>
      <h4>Dashboard</h4>
      <p style={{ paddingTop: "0.5rem" }} className={styles.statsTitle}>
        Last 10 days
      </p>
      <HeadInfo />
      <div className={styles.statsDivider} />
      <p className={styles.statsTitle}>Statistics for selected date range</p>
      <Spin spinning={loading} indicator={<CustomLoader />}>
        <StatisticsLineChart
          loading={false}
          chartData={chartData}
          dateRange={dateRange}
          setDateRange={setDateRange}
          hourTime={hourTime}
          setHourTime={setHourTime}
        />
        <div className={styles.dashBottomContent}>
          <TopChannelsTable
            dateRange={dateRange}
            tableData={tableData}
            loading={loading}
          />
          <Insights />
          <EarningsMap
            mapData={mapData}
            loading={false}
            topCountries={topCountries}
            allCountries={allCountries}
          />
        </div>
      </Spin>
    </div>
  );
};

// {/* <UsersDevicePie dateRange={dateRange} /> */}
