import { Button, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { billingPaymentMethodsService } from "../../services/billingPaymentMethods";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./BillingPaymentMethods.module.css";
import { PaymentMethod } from "./components/PaymentMethod";
import { PaymentMethodForm } from "./components/PaymentMethodForm";
import { PayPalForm } from "./components/PayPalForm";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../utils/notifications";
import UserContext from "../../contexts/userContext";
import { TipaltiForm } from "./components/Tipalti";
// import { TetherForm } from "./components/TetherForm";
// import { PaymentMethodTipalti } from "./components/PaymentMethodTipalti";

export const BillingPaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMethodVisible, setNewMethodVisible] = useState(false);
  const [dropdownMethodVisible, setDropdownMethodVisible] = useState(false);
  const [tipaltiVisible, setTipaltiVisible] = useState(false);
  const [paypalForm, setPaypalForm] = useState(false);
  // const [tetherForm, setTetherForm] = useState(false);

  const [tipaltiReload, setTipaltiReload] = useState(false);

  const [currentMethods, setCurrentMethods] = useState({});

  const [stonlyTrackSent, setStonlyTrackSent] = useState(false);
  const { userInfo } = useContext(UserContext);

  const [tipaltiLink, setTipaltiLink] = useState(null);

  // useEffect(() => {
  //   getTipaltiLink();
  // }, [tipaltiVisible, tipaltiReload]);

  const getPaymentMethods = async () => {
    setLoading(true);
    try {
      const res = await billingPaymentMethodsService.getPaymentMethods();
      if (res.success) {
        const payoneer = res.data.filter((method) => method.payoneer);
        const tipalti = res.data.filter((method) => method.tipalti);
        if (tipalti.length) {
          setTipaltiLink(tipalti[0].tipalti?.iframeUrl);
        }
        setCurrentMethods({
          payoneer: payoneer.length,
          tipalti: tipalti.length,
        });
        setPaymentMethods(res.data);
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePayoneerInit = async () => {
    setLoading(true);
    try {
      const res = await billingPaymentMethodsService.initializePayoneer();
      if (res.success) {
        openSuccessNotification({
          message: "Successfully initialized payoneer payment method.",
        });
        getPaymentMethods();
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTipaltiInit = async () => {
    setLoading(true);
    try {
      const res = await billingPaymentMethodsService.submitPaymentMethod({
        method_type: "tipalti",
        is_default: true,
      });
      if (res.success) {
        openSuccessNotification({ message: res.message });
        setTipaltiVisible(true);
        setTipaltiLink(res.data?.tipalti?.iframeUrl);
        getPaymentMethods();
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({ message: e.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, [tipaltiReload]);

  useEffect(() => {
    if (userInfo.id && !stonlyTrackSent) {
      const referenceDate = new Date("2022-06-01");
      const joinedDate = new Date(userInfo.date_joined);
      if (joinedDate.getTime() >= referenceDate.getTime()) {
        window.stonlyTrack("track", "new-user");
        window.stonlyTrack("identify", String(userInfo.id), {
          "user-age-key": "new-user",
        });
      } else {
        window.stonlyTrack("identify", String(userInfo.id), {
          "user-age-key": "old-user",
        });
      }
      setStonlyTrackSent(true);
    }
  }, [userInfo, stonlyTrackSent]);

  return (
    <div className={styles.mainContainer}>
      <h4>Payment Methods</h4>
      <p>
        Below you can see your chosen payment methods. You can add{" "}
        <b>one of each method</b>, but only one of them may be set as default.
      </p>
      {/* <p className={styles.paymentMethodsTitle}>Add a New Payment Method</p> */}
      <div className={styles.newPaymentMethodMain}>
        <Button
          loading={loading}
          className={styles.paymentMethodBtn}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setDropdownMethodVisible(!dropdownMethodVisible)}
          onBlur={() => setTimeout(() => setDropdownMethodVisible(false), 250)}
        >
          Add a New Payment Method
        </Button>
        <div
          className={styles.methodTypeContainer}
          style={{ height: dropdownMethodVisible ? 90 : 0 }}
        >
          <div className={styles.tipaltiSelectContainer}>
            <div
              className={styles.methodTypeSelectTipalti}
              onClick={() =>
                currentMethods.tipalti
                  ? openWarningNotification({
                      message: "You've already added tipalti account.",
                    })
                  : handleTipaltiInit()
              }
            >
              <img src="/images/tipalti_logo.png" alt="tipalti" />
            </div>
            <p>
              Recommended{" "}
              <img src="/images/trophy_highlight.svg" alt="trophy_highlight" />
            </p>
          </div>
          <div className={styles.orLine}>
            <div></div>
            <div className={styles.orLineTxt}>OR</div>
            <div></div>
          </div>
          <div
            className={styles.methodTypeSelect}
            onClick={() =>
              currentMethods.payoneer
                ? openWarningNotification({
                    message: "You've already added payoneer account.",
                  })
                : handlePayoneerInit()
            }
          >
            <img src="/images/payoneerFinance.png" alt="payoneer" />
          </div>
          <div
            className={styles.methodTypeSelect}
            onClick={() => setPaypalForm(true)}
          >
            <img src="/images/paypalFinance.svg" alt="paypal" />
          </div>
          <div
            className={styles.methodTypeSelect}
            onClick={() => setNewMethodVisible(true)}
          >
            <img src="/images/bankFinance.svg" alt="wire" />
          </div>
          {/* <div
            className={styles.methodTypeSelect}
            onClick={() => setTetherForm(true)}
          >
            <img src="/images/tetherFinance.png" alt="tether" />
          </div> */}
        </div>
      </div>
      <p className={styles.paymentMethodsTitle}>Existing Payment Methods</p>
      <Spin spinning={loading}>
        <div className={styles.methodsGrid}>
          {/* {tipaltiLink?.tipaltiPayable && (
            <PaymentMethodTipalti setTipaltiVisible={setTipaltiVisible} />
          )} */}
          {paymentMethods.map((methodDetails) => (
            <PaymentMethod
              // hasTipalti={tipaltiLink?.tipaltiPayable}
              getPaymentMethods={getPaymentMethods}
              methodDetails={methodDetails}
              key={methodDetails.id}
              setLoading={setLoading}
              setTipaltiVisible={setTipaltiVisible}
            />
          ))}

          {paypalForm && (
            <PayPalForm
              methodsCount={paymentMethods.length}
              setPaypalForm={setPaypalForm}
              getPaymentMethods={getPaymentMethods}
            />
          )}

          {/* {tetherForm && (
            <TetherForm
              methodsCount={paymentMethods.length}
              setTetherForm={setTetherForm}
              getPaymentMethods={getPaymentMethods}
            />
          )} */}
        </div>
      </Spin>
      <PaymentMethodForm
        methodsCount={paymentMethods.length}
        newMethodVisible={newMethodVisible}
        setNewMethodVisible={setNewMethodVisible}
        getPaymentMethods={getPaymentMethods}
      />
      <TipaltiForm
        tipaltiLink={tipaltiLink}
        tipaltiVisible={tipaltiVisible}
        setTipaltiVisible={setTipaltiVisible}
        loading={loading}
        setTipaltiReload={setTipaltiReload}
      />
    </div>
  );
};
