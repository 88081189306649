import "antd/dist/antd.min.css";

import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Spin } from "antd";

import styles from "./App.module.css";

import { auth } from "./services/auth";
import { AppLayout } from "./layout";
import { PageNotFound } from "./pages/page-not-found";
import { ResetPassword } from "./pages/ResetPassword";
import { ActivationPage } from "./pages/activation";
import { LockPage } from "./pages/LockPage";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA } from "./config";

const unauthenticatedRoutes = ["/reset-password", "/activation", "/lock"];

export const App = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        await auth.loadPermissions();
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    if (!unauthenticatedRoutes.includes(window.location.pathname)) {
      load();
    } else {
      setLoading(false);
    }
  }, []);

  return isLoading ? (
    <div className={styles.spinnerContainer}>
      <Spin size="large" />
    </div>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/page-not-found"
          render={(props) => {
            const { state } = props.location;
            const from = state && state.from;
            return <PageNotFound from={from} />;
          }}
        />
        <Route
          path="/reset-password"
          component={() => (
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA}>
              <ResetPassword />
            </GoogleReCaptchaProvider>
          )}
        />
        <Route
          path="/activation"
          component={() => (
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA}>
              <ActivationPage />
            </GoogleReCaptchaProvider>
          )}
        />
        <Route
          path="/lock"
          component={() => (
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA}>
              <LockPage />
            </GoogleReCaptchaProvider>
          )}
        />
        <Route path="/" component={AppLayout} />
      </Switch>
    </BrowserRouter>
  );
};
