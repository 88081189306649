import { callApi } from "./api";
const handleOrdering = (sorting, checkedColumns) => {
  const ordering = [];
  if (Object.keys(sorting).length === 0) {
    if (
      Object.keys(checkedColumns).includes("country") ||
      Object.keys(checkedColumns).includes("search_channel") ||
      Object.keys(checkedColumns).includes("tq")
    ) {
      ordering.push("revenue");
    } else {
      ordering.push("date");
    }
  } else {
    for (const key in sorting) {
      const val = sorting[key];
      if (val) {
        if (val === "ascend") {
          ordering.push("-" + key);
        } else {
          ordering.push(key);
        }
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class SearchReportsN2sService {
  async getCountries() {
    return await callApi({
      url: "/api/cns/countries/",
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: "/api/cns/os/",
    });
  }
  async getBrowsers() {
    return await callApi({
      url: "/api/cns/browsers/",
    });
  }

  async getStatsPaged({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    cancelToken,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    if (checkedColumns["creator_first_name"]) {
      colParameter += ",creator_last_name";
    }

    const ordering = handleOrdering(sorting, checkedColumns);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      country: filters.countries && filters.countries.join(","),
      // channel: filters.channel,
      search_channel:
        filters.search_channel && filters.search_channel.join(","),
      // campaign_id: filters.campaigns && filters.campaigns.join(","),
      ordering: ordering && ordering.join(","),
    });
    return await callApi({
      url: `/api/n2s/stats/${query}`,
      cancelToken,
    });
  }

  async exportReports({ selectedFilters, currentPage, pageSize, sorting }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    if (checkedColumns["creator_first_name"]) {
      colParameter += ",creator_last_name";
    }

    const ordering = handleOrdering(sorting, checkedColumns);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      country: filters.countries && filters.countries.join(","),
      // channel: filters.channel,
      search_channel:
        filters.search_channel && filters.search_channel.join(","),
      // campaign_id: filters.campaigns && filters.campaigns.join(","),
      ordering: ordering && ordering.join(","),
    });
    window.location = `/api/n2s/stats/?resType=csv&${query}`;
  }

  async changeTargetingStatus(data) {
    return await callApi({
      url: "/api/campaigns/targeting/",
      method: "POST",
      data,
    });
  }

  async getChannels() {
    return await callApi({
      url: `/api/n2s/autocomplete/`,
    });
  }
}

export const searchReportsN2sService = new SearchReportsN2sService();
