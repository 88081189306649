export const isValidDomain = (value) => {
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }
  return url.protocol === "https:" || url.protocol === "http:";
};

export const isDisabledZone = (status) => {
  if (status === "pending" || status === "rejected" || status === "suspended") {
    return true;
  }
  return false;
};
