import React from "react";
import { parsePositionStyles } from "../../utils";
import styles from "./AdThemes.module.css";

export const OldSchool = ({
  adQuantity,
  backgroundColor,
  contentColor,
  contentRadius,
  adPosition,
}) => {
  return (
    <div 
      className={styles.mainWrapper} style={{
      ...parsePositionStyles(adPosition)}}>
      <div
        className={styles.oldSchool}
        style={{
          backgroundColor,
          color: contentColor,
          borderRadius: contentRadius,
        }}
      >
        <img src="/images/coinis-icon.jpg" alt="coinis" />
        <div>
          <p>Visit Melbourne this summer</p>
          <p>Here's why you should consider visiting Melbourne</p>
        </div>
        <span className={styles.closeBtnOldSchool}>&#10005;</span>
      </div>
      {adQuantity === 2 ?
        <div
          className={styles.oldSchool}
          style={{
            backgroundColor,
            color: contentColor,
            borderRadius: contentRadius,
          }}
        >
          <img src="/images/coinis-icon.jpg" alt="coinis" />
          <div>
            <p>Visit Melbourne this summer</p>
            <p>Here's why you should consider visiting Melbourne</p>
          </div>
          <span className={styles.closeBtnOldSchool}>&#10005;</span>
        </div> : ""}
    </div>
  );
};
