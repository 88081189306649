import React, { useContext, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Divider,
  Tooltip,
  Button,
} from "antd";
import {
  MailOutlined,
  LockFilled,
  LoadingOutlined,
  DownOutlined,
  WalletFilled,
  CloseOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { auth } from "../../services/auth";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import styles from "../Layout.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { BarNotificationIcon } from "./BarNotificationIcon";
import { AgreementBox } from "./AgreementBox";
import { parseModuleName } from "../utils";

export const Header = ({
  collapsed,
  setCollapsed,
  isSmallScreen,
  setCurrentModule,
  currentModule,
  setAgreementModalVisible,
}) => {
  const { userInfo, loading, accManagerInfo, accManagerLoading } =
    useContext(UserContext);
  const history = useHistory();
  const [copyMessage, setMessage] = useState("Copy email");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const renderModuleTitle = (currentModule) => {
    switch (currentModule) {
      case "offerwall":
        return "Affiliate Programs";
      case "search":
        return "Search Feed";
      case "publisher":
        return "Webmasters";
      case "social_to_search":
        return "Agency";
      default:
        return "Finance & Payments";
    }
  };
  const formatBalance = (balance) => {
    if (balance === 0) {
      return "$ 0.00";
    }
    return (
      "$ " +
      Number(balance).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };
  const headerStyles = {
    background: "#fff",
    padding: 0,
    height: "70px",
    // position: "fixed",
    // zIndex: 3,
    // float: "right",
    // width: `calc(100% - ${collapsed ? 80 : 200}px)`,
    // boxShadow: "0px 2px 6px #EFF2F5",
  };
  const iconStyles = {
    background: "#1179BF",
    display: "flex",
    position: "absolute",
    zIndex: 100,
    top: 12,
    transition: ".2s",
    left: collapsed ? 0 : 200,
    cursor: "pointer",
    padding: "3px 5px",
    borderRadius: "0 3px 3px 0",
  };
  const permissionCount = userInfo.permissions
    ? Object.values(userInfo.permissions).filter((item) => item).length
    : 0;

  const isSearchSigned =
    userInfo.permissions &&
    userInfo.permissions.search &&
    userInfo.agreements_notification &&
    userInfo.agreements_notification.search &&
    ((userInfo.permissions.search.n2s &&
      userInfo.permissions.search.extension &&
      userInfo.agreements_notification.search.n2s &&
      userInfo.agreements_notification.search.extension &&
      userInfo.agreements_notification.search.n2s.status &&
      (userInfo.agreements_notification.search.n2s.status === "signed" ||
        userInfo.agreements_notification.search.n2s.status === "skip") &&
      userInfo.agreements_notification.search.extension.status &&
      (userInfo.agreements_notification.search.extension.status === "signed" ||
        userInfo.agreements_notification.search.extension.status === "skip")) ||
      (userInfo.permissions.search.n2s &&
        !userInfo.permissions.search.extension &&
        userInfo.agreements_notification.search.n2s &&
        userInfo.agreements_notification.search.n2s.status &&
        (userInfo.agreements_notification.search.n2s.status === "signed" ||
          userInfo.agreements_notification.search.n2s.status === "skip")) ||
      (!userInfo.permissions.search.n2s &&
        userInfo.permissions.search.extension &&
        userInfo.agreements_notification.search.extension &&
        userInfo.agreements_notification.search.extension.status &&
        (userInfo.agreements_notification.search.extension.status ===
          "signed" ||
          userInfo.agreements_notification.search.extension.status ===
            "skip")));
  const isPublisherSigned =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.publisher &&
    (userInfo.agreements_notification.publisher.status === "signed" ||
      userInfo.agreements_notification.publisher.status === "skip");
  const isOfferwallSigned =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.offerwall &&
    (userInfo.agreements_notification.offerwall.status === "signed" ||
      userInfo.agreements_notification.offerwall.status === "skip");
  const isAffiliateProgramsSigned =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.affiliate_programs &&
    (userInfo.agreements_notification.affiliate_programs.status === "signed" ||
      userInfo.agreements_notification.affiliate_programs.status === "skip");
  const isAgencySigned =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.social_to_search &&
    (userInfo.agreements_notification.social_to_search.status === "signed" ||
      userInfo.agreements_notification.social_to_search.status === "skip");
  const isBillingSigned =
    // Da je 1 od 4 potpisan i da ima permisiju;
    (auth.hasPermission("search") &&
      isSearchSigned &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("social_to_search") &&
      auth.hasPermission("publisher") &&
      isPublisherSigned) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    // Da su 2 od 4 potpisana i da ima permisije;
    (auth.hasPermission("search") &&
      isSearchSigned &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    (auth.hasPermission("search") &&
      isSearchSigned &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      !auth.hasPermission("social_to_search")) ||
    (auth.hasPermission("search") &&
      isSearchSigned &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    // Da su 3 od 4 potpisana i da ima permisije;
    (auth.hasPermission("search") &&
      isSearchSigned &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    (auth.hasPermission("search") &&
      isSearchSigned &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    (auth.hasPermission("search") &&
      isSearchSigned &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned) ||
    // Da su sva 4 potpisana i da ima permisije;
    (auth.hasPermission("search") &&
      isSearchSigned &&
      ((auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsSigned)) &&
      auth.hasPermission("publisher") &&
      isPublisherSigned &&
      auth.hasPermission("social_to_search") &&
      isAgencySigned);
  // Za search imamo slucaj n2s i extension, za OW i AP imamo slucaj izdvojen, publisher i agency idu dinamicki
  const isAgreementSigned =
    currentModule === "search"
      ? auth.hasPermission("search") && isSearchSigned
      : currentModule === "offerwall"
      ? (auth.hasPermission("offerwall") && isOfferwallSigned) ||
        (auth.hasPermission("affiliate_programs") && isAffiliateProgramsSigned)
      : currentModule === "billing"
      ? isBillingSigned
      : userInfo.agreements_notification &&
        userInfo.agreements_notification[currentModule] &&
        userInfo.agreements_notification[currentModule].status &&
        (userInfo.agreements_notification[currentModule].status === "signed" ||
          userInfo.agreements_notification[currentModule].status === "skip");

  const isSearchRequested =
    userInfo.permissions &&
    userInfo.permissions.search &&
    ((userInfo.permissions.search.n2s &&
      userInfo.permissions.search.extension &&
      userInfo.agreements_notification &&
      userInfo.agreements_notification.search &&
      userInfo.agreements_notification.search.n2s &&
      userInfo.agreements_notification.search.extension &&
      !!userInfo.agreements_notification.search.n2s.status &&
      !!userInfo.agreements_notification.search.extension.status) ||
      (userInfo.permissions.search.n2s &&
        !userInfo.permissions.search.extension &&
        userInfo.agreements_notification &&
        userInfo.agreements_notification.search &&
        userInfo.agreements_notification.search.n2s &&
        !!userInfo.agreements_notification.search.n2s.status) ||
      (!userInfo.permissions.search.n2s &&
        userInfo.permissions.search.extension &&
        userInfo.agreements_notification &&
        userInfo.agreements_notification.search &&
        userInfo.agreements_notification.search.extension &&
        !!userInfo.agreements_notification.search.extension.status));
  const isOfferwallRequested =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.offerwall &&
    !!userInfo.agreements_notification.offerwall.status;
  const isAffiliateProgramsRequested =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.affiliate_programs &&
    !!userInfo.agreements_notification.affiliate_programs.status;
  const isPublisherRequested =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.publisher &&
    !!userInfo.agreements_notification.publisher.status;
  const isAgencyRequested =
    userInfo.agreements_notification &&
    userInfo.agreements_notification.social_to_search &&
    !!userInfo.agreements_notification.social_to_search.status;
  const isBillingRequested =
    // Da je 1 od 4 requested i da ima permisiju;
    (auth.hasPermission("search") &&
      isSearchRequested &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    // Da su 2 od 4 requested i da ima permisije;
    (auth.hasPermission("search") &&
      isSearchRequested &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    (auth.hasPermission("search") &&
      isSearchRequested &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      !auth.hasPermission("social_to_search")) ||
    (auth.hasPermission("search") &&
      isSearchRequested &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    // Da su 3 od 4 requested i da ima permisije;
    (auth.hasPermission("search") &&
      isSearchRequested &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    (auth.hasPermission("search") &&
      isSearchRequested &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    (auth.hasPermission("search") &&
      isSearchRequested &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested) ||
    // Da su sva 4 requested i da ima permisije;
    (auth.hasPermission("search") &&
      isSearchRequested &&
      ((auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)) &&
      auth.hasPermission("publisher") &&
      isPublisherRequested &&
      auth.hasPermission("social_to_search") &&
      isAgencyRequested);

  const isAgreementRequested =
    currentModule === "search"
      ? auth.hasPermission("search") && isSearchRequested
      : currentModule === "offerwall"
      ? (auth.hasPermission("offerwall") && isOfferwallRequested) ||
        (auth.hasPermission("affiliate_programs") &&
          isAffiliateProgramsRequested)
      : currentModule === "billing"
      ? isBillingRequested
      : userInfo.agreements_notification &&
        userInfo.agreements_notification[currentModule] &&
        !!userInfo.agreements_notification[currentModule].status;

  // DMCC or not
  const isDocuSign = userInfo.docusign;
  const docuSignOfferwall =
    !isOfferwallRequested && isDocuSign && isDocuSign["offerwall"];
  const docuSignAffiliatePrograms =
    !isAffiliateProgramsRequested &&
    isDocuSign &&
    isDocuSign["affiliate_programs"];
  const docuSignPublisher =
    !isPublisherRequested && isDocuSign && isDocuSign["publisher"];
  const docuSignAgency =
    !isAgencyRequested && isDocuSign && isDocuSign["social_to_search"];
  const docuSignSearch =
    !isSearchRequested &&
    isDocuSign &&
    isDocuSign["search"] &&
    ((isDocuSign["search"].n2s && isDocuSign["search"].extension) ||
      (isDocuSign["search"].n2s && !isDocuSign["search"].extension) ||
      (!isDocuSign["search"].n2s && isDocuSign["search"].extension));
  const docuSignBilling =
    (!docuSignOfferwall || !docuSignAffiliatePrograms) &&
    !docuSignPublisher &&
    !docuSignSearch &&
    !docuSignAgency;
  const docuSignCondition =
    currentModule === "search"
      ? docuSignSearch
      : currentModule === "billing"
      ? !docuSignBilling
      : isDocuSign && isDocuSign[currentModule];

  // DocuSign button conditions start

  const agreementOfferwallDisabled = !(
    userInfo.docusign && userInfo.docusign["offerwall"]
  );
  const agreementAffiliateProgramsDisabled = !(
    userInfo.docusign && userInfo.docusign["affiliate_programs"]
  );
  const agreementPublisherDisabled = !(
    userInfo.docusign && userInfo.docusign["publisher"]
  );
  const agreementAgencyDisabled = !(
    userInfo.docusign && userInfo.docusign["social_to_search"]
  );
  const agreementSearchDisabled = !(
    userInfo.docusign &&
    userInfo.docusign["search"] &&
    ((userInfo.docusign["search"].n2s &&
      userInfo.docusign["search"].extension) ||
      (userInfo.docusign["search"].n2s &&
        !userInfo.docusign["search"].extension) ||
      (!userInfo.docusign["search"].n2s &&
        userInfo.docusign["search"].extension))
  );
  const agreementBillingDisabled = !(
    (agreementOfferwallDisabled || agreementAffiliateProgramsDisabled) &&
    agreementPublisherDisabled &&
    agreementSearchDisabled &&
    agreementAgencyDisabled
  );
  const agreementButtonDisabled =
    currentModule === "search"
      ? agreementSearchDisabled
      : currentModule === "offerwall"
      ? agreementOfferwallDisabled || agreementAffiliateProgramsDisabled
      : currentModule === "billing"
      ? agreementBillingDisabled
      : !(userInfo.docusign && userInfo.docusign[currentModule]);

  const agreementButtonSearch =
    userInfo.permissions &&
    userInfo.permissions.search &&
    ((userInfo.permissions.search.n2s &&
      userInfo.permissions.search.extension &&
      userInfo.agreements &&
      userInfo.agreements.search &&
      userInfo.agreements.search.n2s &&
      userInfo.agreements.search.extension) ||
      (userInfo.permissions.search.n2s &&
        !userInfo.permissions.search.extension &&
        userInfo.agreements &&
        userInfo.agreements.search &&
        userInfo.agreements.search.n2s) ||
      (!userInfo.permissions.search.n2s &&
        userInfo.permissions.search.extension &&
        userInfo.agreements &&
        userInfo.agreements.search &&
        userInfo.agreements.search.extension));

  const agreementButtonBilling =
    // 1/4
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    // 2/4
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      !auth.hasPermission("social_to_search")) ||
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    // 3/4
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      !auth.hasPermission("social_to_search")) ||
    (!auth.hasPermission("search") &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      !(
        auth.hasPermission("offerwall") ||
        auth.hasPermission("affiliate_programs")
      ) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      !auth.hasPermission("publisher") &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search) ||
    // 4/4
    (auth.hasPermission("search") &&
      agreementButtonSearch &&
      ((auth.hasPermission("offerwall") &&
        userInfo.agreements &&
        userInfo.agreements.offerwall) ||
        (auth.hasPermission("affiliate_programs") &&
          userInfo.agreements &&
          userInfo.agreements.affiliate_programs)) &&
      auth.hasPermission("publisher") &&
      userInfo.agreements &&
      userInfo.agreements.publisher &&
      auth.hasPermission("social_to_search") &&
      userInfo.agreements &&
      userInfo.agreements.social_to_search);

  const agreementButtonVisible =
    currentModule === "search"
      ? !(auth.hasPermission("search") && agreementButtonSearch)
      : currentModule === "offerwall"
      ? !(
          (auth.hasPermission("offerwall") &&
            userInfo.agreements &&
            userInfo.agreements["offerwall"]) ||
          (auth.hasPermission("affiliate_programs") &&
            userInfo.agreements &&
            userInfo.agreements["affiliate_programs"])
        )
      : currentModule === "billing"
      ? !agreementButtonBilling
      : !(userInfo.agreements && userInfo.agreements[currentModule]);

  // DocuSign button conditions end

  return (
    <>
      <Layout.Header style={{ ...headerStyles }}>
        {isSmallScreen ? (
          <span
            style={{ ...iconStyles }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <MenuOutlined style={{ fontSize: 32, color: "#fff" }} />
            ) : (
              <CloseOutlined style={{ fontSize: 32, color: "#fff" }} />
            )}
          </span>
        ) : null}
        <Row
          style={{
            flexWrap: "nowrap",
            maxHeight: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col style={{ flex: 1 }}></Col>
          <Col style={{ flex: 1 }} className={styles.headerRight}>
            {isAgreementSigned ? (
              ""
            ) : auth.hasOnlySearchBiPermission() ? (
              ""
            ) : (
              <>
                <AgreementBox
                  currentModule={currentModule}
                  setAgreementModalVisible={setAgreementModalVisible}
                  isAgreementRequested={isAgreementRequested}
                  docuSignCondition={docuSignCondition}
                  agreements={userInfo.agreements_notification}
                  isAgencyRequested={isAgencyRequested}
                  isOfferwallRequested={isOfferwallRequested}
                  isPublisherRequested={isPublisherRequested}
                  isSearchRequested={isSearchRequested}
                />
                <Divider
                  type="vertical"
                  style={{
                    height: "1.7rem",
                    backgroundColor: "#ededed",
                  }}
                />
              </>
            )}
            {!accManagerLoading &&
            accManagerInfo &&
            !(permissionCount === 1 && userInfo.permissions.publisher) &&
            accManagerInfo.first_name ? (
              <>
                <Dropdown
                  overlayStyle={{ width: "200px" }}
                  overlayClassName={styles.headerDropdown}
                  placement="bottomRight"
                  overlay={
                    <div className={styles.dropdownManager}>
                      <p className={styles.accManagerDesc}>
                        Hi, my name is {accManagerInfo.first_name} and I am your
                        account manager. Don't hesitate to contact me if you
                        need any help. Let's grow your income together!
                      </p>
                      <CopyToClipboard text={accManagerInfo.email}>
                        <Tooltip title={copyMessage} placement="right">
                          <p
                            className={styles.accManagerContact}
                            onClick={() => {
                              setMessage("Copied!");
                              setTimeout(() => {
                                setMessage("Copy email");
                              }, 2000);
                            }}
                          >
                            {accManagerInfo.email} <MailOutlined />
                          </p>
                        </Tooltip>
                      </CopyToClipboard>
                    </div>
                  }
                  trigger={["hover"]}
                >
                  <div className={styles.headerRightAccManager}>
                    <div className={styles.headerAccManager}>
                      <span>Account manager,</span>
                      <span>
                        {accManagerInfo.first_name} {accManagerInfo.last_name}
                      </span>
                    </div>
                    <img
                      className={styles.accManagerAvatar}
                      src={
                        accManagerInfo.image
                          ? accManagerInfo.image
                          : "/images/user.png"
                      }
                      alt="avatar"
                    />
                  </div>
                </Dropdown>
                <Divider
                  type="vertical"
                  style={{
                    height: "1.7rem",
                    backgroundColor: "#ededed",
                  }}
                />
              </>
            ) : null}
            {!(
              currentModule === "search" || auth.hasOnlySearchPermission()
            ) && (
              <>
                <div className={styles.balanceField}>
                  <img src="/images/wallet.svg" alt="wallet" />
                  <p>{formatBalance(userInfo.total_balance)}</p>
                </div>
                <Divider
                  type="vertical"
                  style={{
                    height: "1.7rem",
                    backgroundColor: "#ededed",
                  }}
                />
              </>
            )}
            <BarNotificationIcon
              currentModule={currentModule}
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}
            />
            <Dropdown
              overlayClassName={styles.headerDropdown}
              overlay={
                <Menu>
                  {userInfo ? (
                    <div className={styles.dropdownContentFix}>
                      <img
                        className={styles.profileDropdownAvatar}
                        src={
                          userInfo.image ? userInfo.image : "/images/user.png"
                        }
                        alt="avatar"
                      />
                      <p className={styles.profileDropdownName}>
                        {userInfo.first_name} {userInfo.last_name}
                      </p>
                      <span>{userInfo.email}</span>
                      <div className={styles.btnsDropdownContentWrapper}>
                        {agreementButtonVisible &&
                        !auth.hasOnlySearchBiPermission() ? (
                          <Tooltip
                            title={
                              agreementButtonDisabled
                                ? `Contact your Account Manager to sign the agreement for ${parseModuleName(
                                    userInfo.agreements,
                                    currentModule,
                                    isAgencyRequested,
                                    isOfferwallRequested,
                                    isPublisherRequested,
                                    isSearchRequested
                                  )}.`
                                : ""
                            }
                          >
                            <Menu.Item
                              onClick={() => setAgreementModalVisible(true)}
                            >
                              <Button
                                disabled={agreementButtonDisabled}
                                className={styles.btnDropdownContentOrange}
                                size="small"
                              >
                                Sign agreement
                              </Button>
                            </Menu.Item>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        <Link to={"/"}>
                          <Button
                            className={styles.btnDropdownContent}
                            size="small"
                          >
                            Edit your profile
                          </Button>
                        </Link>
                      </div>
                      <p className={styles.profileDropdownModuleChoice}>
                        Select your module
                      </p>
                    </div>
                  ) : null}
                  <Menu.Item
                    onClick={async () => {
                      if (
                        auth.hasPermission("offerwall") ||
                        auth.hasPermission("affiliate_programs")
                      ) {
                        history.push("/offerwall/dashboard");
                        setCurrentModule("offerwall");
                        setDropdownVisible(false);
                      } else {
                        history.push("/", {
                          fromModuleSelection: "offerwall",
                        });
                      }
                    }}
                  >
                    <div
                      className={
                        auth.hasPermission("offerwall") ||
                        auth.hasPermission("affiliate_programs")
                          ? styles.moduleNameDropdown
                          : styles.moduleNameDropdownLocked
                      }
                    >
                      <span className={styles.moduleInitials}>AP</span>
                      <div className={styles.moduleDetails}>
                        <div className={styles.moduleTitle}>
                          <span>
                            Affiliate Programs
                            {!(
                              auth.hasPermission("offerwall") ||
                              auth.hasPermission("affiliate_programs")
                            ) && (
                              <LockFilled style={{ marginLeft: "0.5rem" }} />
                            )}
                          </span>
                        </div>
                        <p className={styles.moduleDescription}>
                          Global, rewarding programs with high-quality products.
                        </p>
                      </div>
                    </div>
                  </Menu.Item>
                  {auth.hasPermission("publisher") && (
                    <Menu.Item
                      onClick={() => {
                        if (auth.hasPermission("publisher")) {
                          history.push("/publisher/dashboard");
                          setCurrentModule("publisher");
                          setDropdownVisible(false);
                        } else {
                          history.push("/", {
                            fromModuleSelection: "publisher",
                          });
                        }
                      }}
                    >
                      <div
                        className={
                          auth.hasPermission("publisher")
                            ? styles.moduleNameDropdown
                            : styles.moduleNameDropdownLocked
                        }
                      >
                        <span className={styles.moduleInitials}>W</span>
                        <div className={styles.moduleDetails}>
                          <div className={styles.moduleTitle}>
                            <span>
                              Webmasters
                              {!auth.hasPermission("publisher") && (
                                <LockFilled style={{ marginLeft: "0.5rem" }} />
                              )}
                            </span>
                          </div>
                          <p className={styles.moduleDescription}>
                            Skyrocket revenue stream for your websites.
                          </p>
                        </div>
                      </div>
                    </Menu.Item>
                  )}
                  {auth.hasPermission("search_feed") && (
                    <Menu.Item
                      onClick={() => {
                        if (auth.hasPermission("search_feed")) {
                          history.push("/search_feed/dashboard");
                          setCurrentModule("search_feed");
                          setDropdownVisible(false);
                        } else {
                          history.push("/", {
                            fromModuleSelection: "search_feed",
                          });
                        }
                      }}
                    >
                      <div
                        className={
                          auth.hasPermission("search_feed")
                            ? styles.moduleNameDropdown
                            : styles.moduleNameDropdownLocked
                        }
                      >
                        <span className={styles.moduleInitials}>W</span>
                        <div className={styles.moduleDetails}>
                          <div className={styles.moduleTitle}>
                            <span>
                              Search BI
                              {!auth.hasPermission("search_feed") && (
                                <LockFilled style={{ marginLeft: "0.5rem" }} />
                              )}
                            </span>
                          </div>
                          <p className={styles.moduleDescription}>
                            360 solution for search networks & premium feeds.
                          </p>
                        </div>
                      </div>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={() => {
                      if (auth.hasPermission("search")) {
                        history.push("/search/dashboard");
                        setCurrentModule("search");
                        setDropdownVisible(false);
                      } else {
                        history.push("/", {
                          fromModuleSelection: "search",
                        });
                      }
                    }}
                  >
                    <div
                      className={
                        auth.hasPermission("search")
                          ? styles.moduleNameDropdown
                          : styles.moduleNameDropdownLocked
                      }
                    >
                      <span className={styles.moduleInitials}>SF</span>
                      <div className={styles.moduleDetails}>
                        <div className={styles.moduleTitle}>
                          <span>
                            Search SDK
                            {!auth.hasPermission("search") && (
                              <LockFilled style={{ marginLeft: "0.5rem" }} />
                            )}
                          </span>
                        </div>
                        <p className={styles.moduleDescription}>
                          360 solution for search networks & premium feeds.
                        </p>
                      </div>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      if (auth.hasPermission("social_to_search")) {
                        history.push("/agency/dashboard");
                        setCurrentModule("social_to_search");
                        setDropdownVisible(false);
                      } else {
                        history.push("/", {
                          fromModuleSelection: "social_to_search",
                        });
                      }
                    }}
                  >
                    <div
                      className={
                        auth.hasPermission("social_to_search")
                          ? styles.moduleNameDropdown
                          : styles.moduleNameDropdownLocked
                      }
                    >
                      <span className={styles.moduleInitials}>AG</span>
                      <div className={styles.moduleDetails}>
                        <div className={styles.moduleTitle}>
                          <span>
                            Agency
                            {!auth.hasPermission("social_to_search") && (
                              <LockFilled style={{ marginLeft: "0.5rem" }} />
                            )}
                          </span>
                        </div>
                        <p className={styles.moduleDescription}>
                          Comprehensive reporting for agency-led media
                          strategies
                        </p>
                      </div>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      if (!auth.hasOnlySearchBiPermission()) {
                        history.push("/billing/payment-methods");
                        setCurrentModule("billing");
                        setDropdownVisible(false);
                      }
                    }}
                  >
                    <div
                      className={
                        !auth.hasOnlySearchBiPermission()
                          ? styles.moduleNameDropdown
                          : styles.moduleNameDropdownLocked
                      }
                    >
                      <span className={styles.moduleInitials}>
                        <WalletFilled style={{ fontSize: "1rem" }} />
                      </span>
                      <div className={styles.moduleDetails}>
                        <div className={styles.moduleTitle}>
                          <span>
                            Finance & Payments{" "}
                            {auth.hasOnlySearchBiPermission() && (
                              <LockFilled style={{ marginLeft: "0.5rem" }} />
                            )}
                          </span>
                        </div>
                        <p className={styles.moduleDescription}>
                          Platform finance moderation - all in one place.
                        </p>
                      </div>
                    </div>
                  </Menu.Item>
                  <div className={styles.dropdownContentFix}>
                    <Button
                      className={styles.btnDropdownLogout}
                      onClick={() => auth.logout()}
                      size="small"
                    >
                      Sign Out
                    </Button>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://coinis.com/publisher-policy"
                    >
                      Publisher Policy
                    </a>
                  </div>
                </Menu>
              }
            >
              <span className={styles.headerRightUser}>
                <div className={styles.headerUsername}>
                  {!loading ? (
                    userInfo.first_name ? (
                      <>
                        <span>
                          <span className={styles.headerHighligh}>
                            {userInfo.first_name} {userInfo.last_name}
                          </span>{" "}
                          using{" "}
                          <span className={styles.headerHighligh}>
                            {renderModuleTitle(currentModule)}
                          </span>
                        </span>
                        <span>{userInfo.email}</span>
                      </>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <LoadingOutlined spin />
                  )}
                </div>
                <DownOutlined className={styles.downArrow} />{" "}
                {userInfo ? (
                  <img
                    className={styles.profileAvatar}
                    src={userInfo.image ? userInfo.image : "/images/user.png"}
                    alt="avatar"
                  />
                ) : null}
              </span>
            </Dropdown>
          </Col>
          <div style={{ width: "20px" }}></div>
        </Row>
      </Layout.Header>
    </>
  );
};
