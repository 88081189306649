import Modal from "antd/lib/modal/Modal";
import React from "react";
import styles from "../FeedbackModal/FeedbackModal.module.css";
import { useState } from "react";
import { Radio, Input, Form, Button } from "antd";
import { feedbackService } from "../../../services/feedback";
import {
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";

const { TextArea } = Input;

export const FeedbackModal = ({ currentModule }) => {
  const [visible, setVisible] = useState(false);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [expectations, setExpectations] = useState(null);
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const options = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const [form] = Form.useForm();

  const checkRating = (_, value) => {
    if (value > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please select feedback rating."));
  };

  const ratingImageVisibility = i => {
    return hoverRating > i || (rating > i && !hoverRating);
  };

  const handleSubmit = async values => {
    if (rating > 0) {
      try {
        setSendingFeedback(true);
        let data = {
          rating,
          expectations: values.expectation,
          comment: values.comment,
          module: currentModule
        };
        const res = await feedbackService.sendFeedback(data);
        if (res.success) {
          openSuccessNotification({ message: res.message });
          setVisible(false);
          form.resetFields();
          setRating(0);
        } else {
          openWarningNotification({ message: res.message });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSendingFeedback(false);
      }
    }
  };

  return (
    <div className={styles.feedbackContainer}>
      <div className={styles.feedbackButton} onClick={() => setVisible(true)}>
        <span>Feedback</span>
      </div>
      <Modal
        getContainer={false}
        style={{ top: 40 }}
        className={styles.feedbackModal}
        title="Feedback Form for Publisher Platform"
        visible={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setRating(0);
        }}
        width={700}
        footer={null}
        forceRender={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="feedback_form"
          onFinish={handleSubmit}
          initialValues={{
            expectation: true,
          }}
        >
          <Form.Item
            name="feedback_rating"
            label="Please rate our platform:"
            required
            rules={[
              {
                validator: () => checkRating(null, rating),
              },
            ]}
          >
            <div className={styles.feedbackRating}>
              <img hidden src="/images/rocket-outline.png" alt="rocket"></img>
              {[...Array(5)].map((_, i) => (
                <div
                  style={{
                    display: "inline-block",
                    backgroundImage: "url(/images/rocket-outline.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  key={i + 1}
                  onClick={() => {
                    setRating(i + 1);
                  }}
                  onMouseEnter={() => {
                    setHoverRating(i + 1);
                  }}
                  onMouseLeave={() => {
                    setHoverRating(false);
                  }}
                >
                  <img
                    style={{
                      visibility: !ratingImageVisibility(i) ? "hidden" : "",
                      width: "50px",
                      height: "50px",
                      display: "inline-block",
                    }}
                    src="/images/rocket-hover.png"
                    alt="rocket"
                  ></img>
                </div>
              ))}
            </div>
          </Form.Item>
          <p style={{ marginTop: "10px" }}>Did we meet your expectations?</p>
          <Form.Item name="expectation">
            <Radio.Group
              options={options}
              optionType="button"
              buttonStyle="solid"
              value={expectations}
              onChange={value => setExpectations(value)}
            />
          </Form.Item>
          <p style={{ marginTop: "10px" }}>
            Please share your observation with us so that we can be better:
          </p>
          <Form.Item name="comment">
            <TextArea rows={4} placeholder="Enter your comments here" />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
              margin: 0,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              onClick={() => {
                setVisible(false);
                form.resetFields();
                setRating(0);
              }}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={sendingFeedback}>
              Forward Feedback
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
