import React from "react";
import styles from "./Examples.module.css";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

export const ExampleTag = ({ type }) => {
  return (
    <>
      <div className={styles.examplesContainer}>
        <div>
          <div className={styles.alignCenter}>
            <CheckCircleFilled style={{ fontSize: "30px", color: "#58A54F" }} />
            <p className={styles.correct}>Correct examples of implementation:</p>
          </div>
          <img src={type === 'ipp' ? "/images/ipp-correct.png" : "/images/wp-correct.png"} alt=""></img>
        </div>
        <div>
          <div className={styles.alignCenter}>
            <CloseCircleFilled style={{ fontSize: "30px", color: "#D85C5C" }} />
            <p className={styles.incorrect}>Incorrect examples of implementation:</p>
          </div>
          <img src={type === 'ipp' ? "/images/ipp-incorrect.png" : "/images/wp-incorrect.png"} alt=""></img>
        </div>
      </div>
    </>
  );
};
