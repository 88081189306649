import { Button } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./LockPage.module.css";
import { lockPageService } from "../../services/lockPage";
import { useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../utils/notifications";

export const LockPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const username = search.split("?username=")[1];
    if (!username) window.location.href = "/login";
  }, [search]);

  const handleResetLockedAccount = async () => {
    setLoading(true);
    if (!executeRecaptcha) {
      return;
    }
    const recaptcha_token = await executeRecaptcha("locked");
    try {
      const username = decodeURIComponent(search.split("?username=")[1]);
      const res = await lockPageService.resetLockedMail({
        username,
        recaptcha_token,
      });
      if (res.success) {
        openSuccessNotification({ message: res.message });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: "Error while trying to send reset instructions to your email!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: "url(images/background.png)" }}
    >
      <div className={styles.containerGroup}>
        <img src="/images/login_failed.png" alt="page-not-found" />
        <p className={styles.title}>Login Failed</p>
        <p>
          We noticed you had no success in your attempt to log in to our
          platform. Mistakes happen, but for your security we’ve temporarily
          limited login attempts to your account.
        </p>
        <p>
          To log back in, please reset your password or contact our support, and
          we will reach out as soon as possible.
        </p>
        <Button
          type="primary"
          loading={loading}
          onClick={handleResetLockedAccount}
          className={styles.goBack}
        >
          Email me reset instructions
        </Button>
      </div>
    </div>
  );
};
