import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../ExtensionPolicy.module.css";
import { searchDashboardService } from "../../../services/searchDashboard";

export const Blogs = () => {
  const [blogLoading, setBlogLoading] = useState(true);

  const [furderReadingExamples, setFurderReadingExamples] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await searchDashboardService.getSearchBlogs();
        setFurderReadingExamples(res);
      } catch (e) {
        console.log(e);
      } finally {
        setBlogLoading(false);
      }
    };
    fetchBlog();
  }, []);

  return (
    <Spin spinning={blogLoading}>
      <Row gutter={30}>
        {furderReadingExamples.map((furderReadingExample) => {
          return (
            <Col xs={24} sm={12} md={8} lg={8} xl={6}>
              {furderReadingExample?.title?.rendered ? (
                <div className={styles.readingExampleElement}>
                  <img
                    className={styles.readingExampleElementImage}
                    src={
                      furderReadingExample?.yoast_head_json?.og_image[0]?.url
                    }
                    alt=""
                  />
                  <div className={styles.statsTitleBlog}>
                    <div className={styles.statsTitleBlogTitle}>
                      {furderReadingExample?.title?.rendered}
                    </div>
                    <div className={styles.statsTitleBlogLinkWrapper}>
                      <div>
                        <a
                          href={
                            "https://coinis.com/blog/" +
                            furderReadingExample.slug
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read more
                        </a>
                        <img
                          style={{ marginLeft: "5px" }}
                          src="/images/arrow-right.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};
