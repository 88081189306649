import { callApi } from "./api";

class ActivationService {
  async getUserToken() {
    return await callApi({ url: "/api/accounts/" });
  }
  getTokenFromUrl() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get("token");
    return token;
  }
  async activateUserProfile(data) {
    delete data.confirm_password;
    data.token = this.getTokenFromUrl();
    return await callApi({
      url: "/api/publishers/activate/",
      method: "POST",
      data,
    });
  }
}

export const activationService = new ActivationService();
