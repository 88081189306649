import { Form, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./SearchWidgetBuilder.module.css";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";
import { WidgetForm } from "./components/WidgetForm";
import { GetTag } from "./components/GetTag";
import { publisherFormsService } from "../../../services/publisherFormsService";
import { useHistory } from "react-router-dom";
import { extractWidgets, parseWidgetsToPayload } from "./utils";
import QueryString from "qs";
import { isDisabledZone } from "../../../utils/validations";
import { toFormData } from "../utils";

export const ContextualAd = (props) => {
  const history = useHistory();

  const [zoneLoading, setZoneLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const isPreview =
    QueryString.parse(props.location.search, { ignoreQueryPrefix: true })
      .type === "preview";

  const [step, setStep] = useState(0);

  const [currentWidgetId, setCurrentWidgetId] = useState(
    props.match.params.id && props.match.params.id !== "new"
      ? props.match.params.id
      : null
  );
  const [currentWidgetInfo, setCurrentWidgetInfo] = useState({});
  const [questionnaireLoading, setQuestionnaireLoading] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const [ctxCategories, setCtxCategories] = useState([]);

  const [form] = Form.useForm();

  const [createdWidgets, setCreatedWidgets] = useState([]);

  const getWidgetInfo = useCallback(
    async (id) => {
      if (isPreview) {
        setStep(1);
      }
      setZoneLoading(true);
      try {
        const res = await publisherFormsService.getWidget(id);

        if (isDisabledZone(res.data?.status)) {
          setStep(0);
        }

        setCreatedWidgets(extractWidgets(res.data));

        form.setFieldsValue(res.data);

        setCurrentWidgetInfo(res.data);
      } catch (e) {
        openErrorNotification({
          message: "Error while trying to get details about zone!",
        });
      } finally {
        setZoneLoading(false);
      }
    },
    [form, isPreview]
  );

  useEffect(() => {
    if (currentWidgetId) getWidgetInfo(currentWidgetId);
  }, [currentWidgetId, getWidgetInfo]);

  useEffect(() => {
    const getCtxCategories = async () => {
      try {
        const res = await publisherFormsService.getCategories();
        if (res.success) {
          setCtxCategories(
            res.data.map((item) => ({
              value: item.key,
              label: item.name,
            }))
          );
        } else {
          openErrorNotification({
            message: res.message,
          });
        }
      } catch (e) {
        openErrorNotification({
          message: "Error while trying to get categories!",
        });
      } finally {
      }
    };
    getCtxCategories();
  }, []);

  const finishZoneForm = async (data) => {
    setBtnLoading(true);
    const dataObj = {
      ...data,
      ...parseWidgetsToPayload(createdWidgets),
      type: "contextual",
    };

    const formDataObj = !(
      props.match.params.id && props.match.params.id !== "new"
    )
      ? toFormData(dataObj)
      : dataObj;

    try {
      const res = !(props.match.params.id && props.match.params.id !== "new")
        ? await publisherFormsService.createZone(formDataObj)
        : await publisherFormsService.editWidget(
            formDataObj,
            props.match.params.id
          );

      if (res.success) {
        openSuccessNotification({ message: res.message });
        if (props.match.params.id && props.match.params.id !== "new") {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            history.push("/publisher/zones");
          } else {
            getWidgetInfo(props.match.params.id);
            setStep(1);
          }
        } else {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            openWarningNotification({
              message:
                "Looks like you have a new website! Allow us 24-48h to check your traffic quality. You will get an email once your zone is approved.",
              duration: 15,
            });
            history.push("/publisher/zones");
          } else {
            setCurrentWidgetId(res.data.id);
            setStep(1);
          }
        }
      } else {
        setQuestionnaireLoading(false);
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: "Error occurred while trying to create new zone!",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Spin spinning={zoneLoading}>
      <div className={styles.formContainer}>
        {step === 0 ? (
          <WidgetForm
            ctxCategories={ctxCategories}
            form={form}
            id={props.match.params.id}
            finishZoneForm={finishZoneForm}
            btnLoading={btnLoading}
            createdWidgets={createdWidgets}
            setCreatedWidgets={setCreatedWidgets}
            isShaking={isShaking}
            setIsShaking={setIsShaking}
            match={props.match}
            questionnaireLoading={questionnaireLoading}
            setQuestionnaireLoading={setQuestionnaireLoading}
          />
        ) : (
          <GetTag
            currentWidgetInfo={currentWidgetInfo}
            id={props.match.params.id}
            location={props.location}
            createdWidgets={createdWidgets}
            setStep={setStep}
          />
        )}
      </div>
    </Spin>
  );
};
