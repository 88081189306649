import { BASE_URL } from "../../../config";
import styles from "../Reports.module.css";

export const columnTemplate = [
  {
    value: "date",
    text: "Date",
    align: "center",
    tooltip: "Date frame period of observing your activity",
  },
  {
    value: "country",
    text: "Country",
    render: item => {
      return (
        <div className={styles.countryBox}>
          {item && item !== "--" && item.toLowerCase() !== "unknown" && (
            <img
              src={`${BASE_URL}media/countries/${item.toLowerCase()}.png`}
              alt="test"
            />
          )}
          {item}
        </div>
      );
    },
    align: "center",
    tooltip: "Country from which traffic is coming",
  },
  {
    value: "search_channel",
    text: "Search Channel",
    align: "center",
    tooltip:
      "Identifier of your campaigns. usually created by account managers to easily distinigush campaigns",
  },
  {
    value: "monetized_searches",
    text: "Monetized Searches",
    align: "center",
    tooltip:
      "Monetized searches represent amount of searches that showed actual ads on search pages",
  },
  {
    value: "clicks",
    text: "Clicks",
    align: "center",
    tooltip: "Clicks on search ads",
  },
  {
    value: "monetized_rpm",
    text: "Monetized RPM",
    align: "center",
    tooltip: "Monetized revenue per thousand monetized searches",
  },
  {
    value: "tq",
    text: "Traffic Quality",
    align: "center",
    tooltip:
      "Traffic quality represents an overall score of ad unit that is being run. it is a combination of many factors such as time on page, advertisers performance, ctr and more",
  },
  {
    value: "rpc",
    text: "RPC",
    align: "center",
    tooltip: "Revenue per click coming from clicks on search ads",
  },
  {
    value: "rpv",
    text: "RPV",
    align: "center",
    tooltip: "Revenue per visit  on search feed pages",
  },
  {
    value: "ctr",
    text: "CTR",
    align: "center",
    tooltip: "Click-through rate - clicks that occurred on monetized searches",
  },
  {
    value: "revenue",
    text: "Revenue",
    align: "center",
    tooltip: "Revenue from search feeds",
  },
];

export const columnPreset = {
  date: true,
  // search_channel: true,
  // clicks_in: true,
  monetized_searches: true,
  clicks: true,
  ctr: true,
  monetized_rpm: true,
  rpv: true,
  rpc: true,
  revenue: true,
};
