export const hourlyDataSource = [
  {
    key: "1",
    name: "key",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Authorization key, required parameter to access Agency Hourly
          Report API. Contain only digits and letters. You may obtain your
          authorization key from your personal manager.
        </p>
        <p>
          Example: <b>UQlXSEHTd2kWbVY3n0ETLx3v</b>
        </p>
      </>
    ),
  },
  {
    key: "2",
    name: "resType",
    req_opt: "Required",
    type: "String",
    description: (
      <p>
        This parameter is used to specify the response type. Available values
        are <b>table</b> (json) and <b>csv</b>.
      </p>
    ),
  },
  {
    key: "3",
    name: "columns",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Value is a string that can take multiple values separated by a comma.
          In this field you should pass <b>labels</b> that you expect in
          response. Predefined labels that can be used are:
        </p>
        <ul>
          <li>- date,</li>
          <li>- search_channel,</li>
          <li>- status,</li>
          <li>- spent,</li>
          <li>- revenue,</li>
          <li>- profit,</li>
          <li>- gross profit,</li>
          <li>- impressions,</li>
          <li>- clicks,</li>
          <li>- clicks feed,</li>
          <li>- roi,</li>
          <li>- cpc,</li>
          <li>- cpm,</li>
          <li>- ctr,</li>
          <li>- rpc,</li>
          <li>- search cr </li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    name: "timeframe",
    req_opt: "Required",
    type: "String",
    description: (
      <div>
        <p>
          This parameter is used to specify the interval or frequency of data. It is always set to <b>hourly</b>.
        </p>
      </div>
    ),
  },
  {
    key: "5",
    name: "date_from",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "6",
    name: "date_to",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "7",
    name: "limit",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Limit shows you the number of rows which will be shown in the API response. By default limit is 30.",
  },
  {
    key: "8",
    name: "offset",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Offset determines how many rows will be skipped before the data is displayed. Default is 0.",
  },
  {
    key: "9",
    name: "ordering",
    req_opt: "Optional",
    type: "String",
    description: (
      <div>
        <p>
          For ordering you can use one of the labels from the columns list. For
          descending just use column name, e.g. revenue, and for ascending use -
          symbol, e.g. -revenue.
        </p>
      </div>
    ),
  },
];

export const hourlyColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Req/Opt",
    dataIndex: "req_opt",
    key: "req_opt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
];

export const hourlyResponse = `{
  "total": [
      {
          "date": "",
          "hour": "",
          "search_channel": "",
          "revenue": 103017.08000000002,
          "clicks": 3442,
          "impressions": 1382,
          "spent": 455.28,
      }
  ],
  "table": [
      {
          "date": 20210510,
          "hour": 3,
          "search_channel": "src_ES_2504",
          "revenue": 7.46,
          "clicks": 55,
          "impressions": 21,
          "spent": 43.12,
      },
      {
          "date": 20210510,
          "hour": 6,
          "search_channel": "src_US_2605",
          "revenue": 0.71,
          "clicks": 213,
          "impressions": 123,
          "spent": 31.21,
      },
      {
          "date": 20210510,
          "hour": 14,
          "search_channel": "src_CA_2570",
          "revenue": 54.980000000000004,
          "clicks": 2342,
          "impressions": 342,
          "spent": 3.12,
      },
      {
          "date": 20210510,
          "hour": 14,
          "search_channel": "src_US_2525",
          "revenue": 27.34,
          "clicks": 465,
          "impressions": 12,
          "spent": 213.22,
      },
      {
          "date": 20210510,
          "hour": 6,
          "search_channel": "src_GB_2075",
          "revenue": 38.269999999999996,
          "clicks": 12,
          "impressions": 12,
          "spent": 11.12,
      },
      {
          "date": 20210510,
          "hour": 22,
          "search_channel": "src_RU_2525",
          "revenue": 0.43,
          "clicks": 2,
          "impressions": 12,
          "spent": 12.32,
      },
      {
          "date": 20210510,
          "hour": 18,
          "search_channel": "src_CA_2599",
          "revenue": 350.95,
          "clicks": 111,
          "impressions": 123,
          "spent": 54.23,
      },
      {
          "date": 20210510,
          "hour": 21,
          "search_channel": "src_ES_2572",
          "revenue": 0.0,
          "clicks": 31,
          "impressions": 132,
          "spent": 76.22,
      },
      {
          "date": 20210510,
          "hour": 12,
          "search_channel": "src_ES_2099",
          "revenue": 627.95,
          "clicks": 211,
          "impressions": 605,
          "spent": 10.72,
      },
      {
          "date": 20210510,
          "hour": 15,
          "search_channel": "src_ES_2098",
          "revenue": 0.0,
          "clicks": 0,
          "impressions": 0,
          "spent": 0.0,
      }
  ],
  "rows": 248
}`;
