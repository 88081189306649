import { callApi } from "./api";

class AuthService {
  userPermissions = {};
  user = null;
  accManager = null;

  async loadPermissions() {
    if (this.user) {
      this.callSmartlook(this.user[0]);
      return this.user;
    } else {
      const data = await callApi({ url: "/api/accounts/" });
      this.callSmartlook(data[0]);
      this.userPermissions = data[0].permissions;
      this.user = data;
      return this.user;
    }
  }

  callSmartlook(user) {
    window.smartlook && window.smartlook("identify", user.company_id);
  }

  async getAccManagerInfo() {
    if (this.accManager) {
      return this.accManager;
    } else {
      const res = await callApi({ url: "/api/publishers/account_manager/" });
      this.accManager = res.data;
      return this.accManager;
    }
  }

  async requestApproval(permission) {
    return await callApi({
      url: "/api/request_permission/",
      method: "POST",
      data: { permission },
    });
  }
  async updatePermissions(permissions) {
    return await callApi({
      url: "/api/add_permissions/",
      method: "POST",
      data: permissions,
    });
  }

  getPermissions() {
    return this.userPermissions;
  }
  getSearchPermissions() {
    return this.userPermissions.search;
  }

  hasPermission(permission) {
    return this.userPermissions[permission];
  }

  // added for passing list as argument
  hasMultiPermissions(permissions) {
    return permissions.some((permission) => this.userPermissions[permission]);
  }

  hasOnlySearchPermission() {
    return (
      (this.userPermissions?.search?.extension ||
        this.userPermissions?.search?.n2s) &&
      !this.userPermissions?.offerwall &&
      !this.userPermissions?.publisher
    );
  }

  hasOnlySearchBiPermission() {
    return (
      this.userPermissions?.search_feed &&
      !this.userPermissions?.offerwall &&
      !this.userPermissions?.social_to_search &&
      !this.userPermissions?.publisher &&
      !this.userPermissions?.search
    );
  }

  hasSearchPermission(permission) {
    return this.userPermissions.search[permission];
  }
  isTmpUser() {
    return this.user && this.user[0] && this.user[0].ow_tmp;
  }

  getFirstAvailableModule() {
    if (this.userPermissions.offerwall) {
      return "offerwall";
    } else if (this.userPermissions.publisher) {
      return "publisher";
    } else if (this.userPermissions.search) {
      return "search";
    } else if (this.userPermissions.search_feed) {
      return "search_feed";
    } else if (this.userPermissions.social_to_search) {
      return "social_to_search";
    } else {
      return "billing";
    }
  }

  logout() {
    window.location.href = "/logout";
  }
}

export const auth = new AuthService();
