import React from "react";
import { Popover, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import styles from "./Filters.module.css";
import { renderColumns } from "../../../utils/renderColumns";

export const ColumnsGroup = ({
  onChange,
  checkedColumns,
  columnsData,
  showUpdateButton,
  onUpdate,
}) => {
  const [columnsVisibility, setColumnsVisibility] = useState(false);
  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={columnsVisibility}
      onVisibleChange={() => setColumnsVisibility(prevState => !prevState)}
      content={renderColumns(
        onChange,
        checkedColumns,
        showUpdateButton,
        columnsData,
        onUpdate,
        setColumnsVisibility
      )}
    >
      <Button className={styles.columnsBtn} icon={<MenuOutlined rotate={90} />}>
        Columns
      </Button>
    </Popover>
  );
};
