import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import styles from "./Interstitial.module.css";
import { CloseOutlined } from "@ant-design/icons";

import NotificationContext from "../../../contexts/notificationContext";
import { userNotificationsService } from "../../../services/userNotifications";
import ReactMarkdown from "react-markdown";

export const InterstitialNotification = () => {

  const { interstitialNotification } = useContext(NotificationContext);
  const [interstitialVisible, setInterstitialVisible] = useState(true);

  useEffect(() => {
    if (interstitialNotification) {
      setInterstitialVisible(true);
    }
  }, [interstitialNotification])

  const readNotification = async notification_id => {
    setInterstitialVisible(false);
    window.smartlook('track', 'InterstitialNotificationRead');
    try {
      await userNotificationsService.readUserNotification(notification_id);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const closeNotification = () => {
    setInterstitialVisible(false);
    window.smartlook('track', 'InterstitialNotificationSkipped');
  }

  return <Modal
    width={700}
    centered
    className={styles.interstitialNotificationWrapper}
    open={interstitialNotification && interstitialVisible}
    closeIcon={<CloseOutlined onClick={closeNotification} />}
    title={<img alt="coinis-logo" src="/images/logo-blue.svg" />}
    footer={[
      <Button
        type="primary"
        ghost
        className={styles.understandBtn}
        onClick={() => readNotification(interstitialNotification?.id)}
      >
        {interstitialNotification?.interstitial_button_name}
      </Button>
    ]}
  >
    <div className={styles.notificationContent}>
      <ReactMarkdown
        className={styles.titleStyle}
      >
        {interstitialNotification?.title}
      </ReactMarkdown>
      <ReactMarkdown
        className={styles.descriptionStyle}
      >
        {interstitialNotification?.description}
      </ReactMarkdown>
      <div className={styles.bottomShadow} />
    </div>
  </Modal>

};
