import { Pagination, Table } from "antd";
import React, { useState, useCallback, useEffect, useMemo } from "react";

import { useFetch } from "../../utils/hooks/useFetch";
import { Filters } from "./components/Filters";
import { TableTitle } from "./components/TableTitle";
// import { NestedTable } from "./components/NestedTable";
import styles from "./Reports.module.css";
import moment from "moment";
import {
  columnTemplate,
  refactorTableData,
  sumOfDataRefactor,
} from "./utils/columnsList";
import { openWarningNotification } from "../../utils/notifications";
import { numberWithSpaces } from "../../utils/abbreviateNumber";
import { agencyService } from "../../services/agencyService";
// import { NestedTable } from "./components/NestedTable";

export const AgencyReportsDaily = ({ location }) => {
  const [columnsData] = useState(columnTemplate);
  const [tableColumns, setTableColumns] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const pageSizeOptions = [10, 20, 30, 50, 100];

  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(1, "days"),
    to: moment().subtract(1, "days"),
  });
  const [sorting, setSorting] = useState({
    // date: "descend",
  });

  // expanded rows represent rows that are added dynamically when user clicks on a row
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowLoading, setExpandedRowLoading] = useState(false);

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting]
  );
  const updateColumns = useCallback(
    (value) => {
      setTableColumns(() => {
        let newTableColumn = [];
        columnTemplate
          .filter((column) => value.checkedColumns[column["value"]])
          .forEach(function ({
            text,
            value,
            tooltip,
            render,
            align,
            fixed,
            bold,
          }) {
            newTableColumn.push({
              title: text ? (
                <TableTitle
                  text={text}
                  order={sorting[value] ? sorting[value] : false}
                  value={value}
                  align={align}
                  tooltip={tooltip}
                  onSortingClick={handleSortingClick}
                  bold={bold}
                />
              ) : null,
              dataIndex: value,
              align,
              fixed,
              text,
              render,
            });
          });
        return newTableColumn;
      });
    },
    [sorting, handleSortingClick]
  );

  const loadZoneData = (cancelToken) => {
    return agencyService.getStatsPagedDaily({
      selectedFilters,
      currentPage,
      pageSize,
      sorting,
      cancelToken,
    });
  };

  const [
    {
      data: { total: sumOfData, table: tableData, rows: totalItems },
      isFetching,
    },
    getZoneData,
  ] = useFetch(loadZoneData);
  const loading = isFetching;

  useEffect(() => setCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      setExpandedRows([]);
      getZoneData();
    }
  }, [sorting, pageSize, selectedFilters, getZoneData, currentPage]);

  const fetchExpandedRow = async (
    { expand_row, search_channel, country, date, og_index, is_inserted_row },
    filters
  ) => {
    if (!expand_row || is_inserted_row) return;
    try {
      setExpandedRowLoading(true);
      const selectedFilters = {
        ...filters,
        filters: {
          ...filters.filters,
          search_channel: [search_channel],
          country: [country],
        },
      };
      if (date) {
        selectedFilters.dateRange = {
          from: moment(date),
          to: moment(date),
        };
      }

      const data = await agencyService.getStatsPagedDaily({
        selectedFilters,
        currentPage: 1,
        pageSize: 10,
        sorting: {},
        isExpandedRow: true,
      });

      setExpandedRows((prevExpandedRows) => [
        ...prevExpandedRows,
        {
          og_index,
          rows: data?.table
            ? data.table.map((record) => ({ ...record, is_inserted_row: true }))
            : [],
        },
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setExpandedRowLoading(false);
    }
  };

  const collapseRow = (record) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.filter((row) => row.og_index !== record.og_index)
    );
  };

  const parsedTableData = useMemo(() => {
    const updatedTableData = tableData
      ? [...tableData.map((row, index) => ({ ...row, og_index: index }))]
      : [];
    if (!expandedRows) return updatedTableData;

    // expandedRows.key is the index where you want to insert new rows
    expandedRows.forEach((expandedRow) => {
      const indexToInsert = updatedTableData.findIndex(
        (row) => row.og_index === expandedRow.og_index
      );
      const dataToInsert = expandedRow.rows;

      updatedTableData[indexToInsert].is_expanded_row = true;

      // Insert expanded rows at the specified index
      updatedTableData.splice(indexToInsert + 1, 0, ...dataToInsert);
    });

    return updatedTableData;
  }, [tableData, expandedRows]);

  // sorting by date and revenue by default
  useEffect(() => {
    const selectedColumns = tableColumns.map((item) => item.dataIndex);
    if (
      selectedColumns.includes("date") &&
      selectedColumns.includes("revenue")
    ) {
      setSorting({ date: "descend", revenue: "descend" });
    } else {
      selectedColumns.includes("date")
        ? setSorting({ date: "descend" })
        : setSorting({ revenue: "descend" });
    }
  }, [tableColumns]);

  const handleExport = () => {
    if (selectedFilters.filters) {
      agencyService.exportReportsDaily({
        selectedFilters,
        currentPage,
        pageSize,
        sorting,
        action: false,
      });
    } else {
      openWarningNotification({
        message: "Please update filters before trying to export table.",
      });
    }
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <h2 className={styles.pageTitle}>Statistics</h2>
      </div>
      <Filters
        campaign_id={location.campaign_id}
        handleExport={handleExport}
        onSubmit={(value) => {
          updateColumns(value);
          setSelectedFilters(value);
        }}
        allColumns={columnsData.filter(
          (column) => column.value !== "expand_row"
        )}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        className={styles.mainTableContainer}
        style={{ marginTop: "2%" }}
        columns={tableColumns}
        rowKey={(record) => JSON.stringify(record)}
        onRow={(record) => {
          return {
            onClick: () =>
              record.is_expanded_row
                ? collapseRow(record)
                : fetchExpandedRow(record, selectedFilters),
          };
        }}
        rowClassName={(record) =>
          record.is_inserted_row ? styles.expandedRow : ""
        }
        dataSource={refactorTableData(parsedTableData)}
        loading={loading || expandedRowLoading}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
        pagination={false}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <NestedTable
        //       {...record}
        //       filters={selectedFilters}
        //       tableColumns={tableColumns}
        //     />
        //   ),
        //   rowExpandable: (record) => record.expand_row,
        // }}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={(value) => {
                setCurrentPage(value);
              }}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
              showTotal={(total) => {
                if (total)
                  return (
                    <div>
                      Showing {(currentPage - 1) * pageSize + 1} to{" "}
                      {total < currentPage * pageSize
                        ? total
                        : currentPage * pageSize}{" "}
                      of {total} entries.
                    </div>
                  );
              }}
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor(sumOfData);
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {/* <Table.Summary.Cell key={0} index={0}></Table.Summary.Cell> */}
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {numberWithSpaces(sumOfDataRefactored[el.dataIndex])}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
      />
    </div>
  );
};
