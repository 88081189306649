import { callApi } from "./api";
import moment from "moment";

// const tenDays = moment().subtract(30, "days").format("YYYY-MM-DD");
// const today = moment().subtract(21, "days").format("YYYY-MM-DD");
const tenDays = moment().subtract(9, "days").format("YYYY-MM-DD");
const today = moment().format("YYYY-MM-DD");

class SearchDashboard {
  async getStatisticData(chartType, stats) {
    return await callApi({
      url: `/api/${
        chartType === 2 ? "n2s" : "search"
      }/stats/?resType=chart&columns=date,${stats.join(
        ","
      )}&date_from=${tenDays}&date_to=${today}&ordering=date`,
      method: "GET",
    });
  }
  async getTopChannelsData(chartType) {
    return await callApi({
      url: `/api/${
        chartType === 1 ? "search" : "n2s"
      }/stats/?resType=table&columns=search_channel,monetized_searches,${
        chartType === 1 ? "rpm,ad_coverage,tq" : "monetized_rpm"
      },revenue&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=revenue`,
      method: "GET",
    });
  }
  async getEarningsMapData(chartType) {
    return await callApi({
      url: `/api/${
        chartType === 2 ? "n2s" : "search"
      }/stats/?resType=table&columns=country,revenue&date_from=${tenDays}&date_to=${today}&offset=0&limit=6&ordering=revenue`,
      method: "GET",
    });
  }

  async requestFeed(data) {
    return await callApi({
      url: "/api/request_feed/",
      method: "POST",
      data,
    });
  }

  async getFeedStatus() {
    return await callApi({
      url: "/api/feed/status/",
      method: "GET",
    });
  }

  async uploadFeedScreenshots(image) {
    const formData = new FormData();
    formData.append("file", image);

    return await callApi({
      url: "/api/upload/request_feed_screenshot/",
      method: "POST",
      data: formData,
    });
  }

  async getTopCardInfoChart(permission) {
    if (!permission) {
      return { values: [] };
    }
    return await callApi({
      url: `/api/search/stats/?resType=chart&columns=date,monetized_searches,revenue&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=date`,
      method: "GET",
    });
  }
  async getSearchBlogs() {
    return await callApi({
      url: `https://coinisheadless.com/wp-json/wp/v2/posts?page=1&per_page=4&acf_format=standard&categories=232`,
      method: "GET",
    });
  }
  async getTopCardInfoChartN2s(permission) {
    if (!permission) {
      return { values: [] };
    }
    return await callApi({
      url: `/api/n2s/stats/?resType=chart&columns=date,monetized_searches,revenue&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=date`,
      method: "GET",
    });
  }

  async getTopCardInfo(permission) {
    if (!permission) {
      return { values: [] };
    }
    return await callApi({
      url: `/api/search/stats/?resType=table&columns=date,monetized_searches,revenue&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=date`,
      method: "GET",
    });
  }
  async getTqOverview() {
    return await callApi({
      url: `/api/search/average_tq/stats/`,
      method: "GET",
    });
  }
  async getTopCardInfoN2s(permission) {
    if (!permission) {
      return { values: [] };
    }
    return await callApi({
      url: `/api/n2s/stats/?resType=table&columns=date,monetized_searches,revenue&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=date`,
      method: "GET",
    });
  }
}

export const searchDashboardService = new SearchDashboard();
