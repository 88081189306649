import { Spin, DatePicker, Form, Button, Radio } from "antd";
import React, { useCallback, useEffect } from "react";
import styles from "./StatisticsLineChart.module.css";
import Highcharts from "highcharts";
import moment from "moment";
import { openWarningNotification } from "../../../../utils/notifications";
const { RangePicker } = DatePicker;

const defaultOptions = {
  chart: {
    zoomType: "x",
  },
  title: {
    text: "",
  },
  xAxis: [
    {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: "Date",
      },
    },
    {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: "Date",
      },
      opposite: true,
    },
  ],
  yAxis: [],
  tooltip: {
    shared: true,
  },
  legend: {
    floating: false,
    layout: "vertical",
    navigation: {
      activeColor: "#3E576F",
      animation: true,
      arrowSize: 3,
      inactiveColor: "#CCC",
      style: {
        fontWeight: "bold",
        color: "#333",
        fontSize: "12px",
      },
    },
    margin: 0,
    labelFormatter: function () {
      let max = this.xData[this.xData.length - 1];
      let min = this.xData[0];
      return (
        Highcharts.dateFormat("%b, %e", new Date(min)) +
        " - " +
        Highcharts.dateFormat("%b, %e", new Date(max)) +
        " " +
        this.name
      );
    },
    backgroundColor:
      (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
  },
  series: [],
};

export const StatisticsLineChart = ({
  loading,
  chartData,
  setDateRange,
  dateRange,
  hourTime,
  setHourTime,
}) => {
  const addNewYAxis = useCallback((name, data, index, xAxis = 0, options) => {
    if (index > options.yAxis.length - 1) {
      let format = "{value}";
      if (name === "spent" || name === "earned") {
        format = "$ " + format;
      }

      const colorIndex = options.series.length;
      const color = colorIndex;
      options.yAxis.push({
        labels: {
          enabled: window.innerWidth > 800 ? true : false,
          format,
          style: {
            color,
          },
        },
        title: {
          enabled: window.innerWidth > 800 ? true : false,
          text: name,
          style: {
            color,
          },
        },
        opposite: true,
      });
      if (color === 0) {
        options.series.push({
          name: "Revenue",
          type: "spline",
          xAxis,
          color: "#f8ae3b",
          yAxis: index,
          data,
        });
      } else if (color === 1) {
        options.series.push({
          name: "Visits",
          type: "spline",
          xAxis,
          color: "#c2d791",
          yAxis: index,
          data,
        });
      } else {
        options.series.push({
          name: "Leads",
          type: "spline",
          xAxis,
          color: "#2d71b6",
          yAxis: index,
          data,
        });
      }

      // options.yAxis.push({
      //   labels: {
      //     format,
      //     style: {
      //       color,
      //     },
      //   },
      //   title: {
      //     text: text,
      //     style: {
      //       color,
      //     },
      //   },
      //   opposite: true,
      // });
    }

    // options.series.push({
    //   name: text,
    //   type: "spline",
    //   xAxis,
    //   yAxis: index,
    //   data,
    // });
  }, []);

  const parseChartData = useCallback(
    chartData => {
      // checking if response data is for the chart or the table
      if (chartData && chartData.values) {
        let options = { ...defaultOptions, series: [], yAxis: [] };
        let yAxisIndex = 0;
        const metrics = ["Revenue", "Visits", "Leads"];
        metrics.forEach((metric, metricIndex) => {
          const values = chartData.values.map(value => [
            value[0],
            value[metricIndex + 1],
          ]);
          addNewYAxis(metric, values, yAxisIndex, 0, options);
          yAxisIndex++;
          if (yAxisIndex > options.yAxis.length) {
            yAxisIndex = options.yAxis.length;
          }
        });
        if (options.series.length > 0) {
          Highcharts.chart("chart-wrapper", options);
        }
      } else {
        openWarningNotification({
          message: `There is no data for this filters`,
          duration: 8,
        });
      }
    },
    [addNewYAxis]
  );
  useEffect(() => {
    Highcharts.chart("chart-wrapper", defaultOptions);
    chartData !== null && parseChartData(chartData);
  }, [chartData, parseChartData]);

  // const handleDateRangeChange = value => {
  //   switch (value) {
  //     case "today":
  //       setDateRange({ from: moment(), to: moment() });
  //       break;
  //     case "yesterday":
  //       setDateRange({
  //         from: moment().subtract(1, "days"),
  //         to: moment().subtract(1, "days"),
  //       });
  //       break;
  //     case "7days":
  //       setDateRange({ from: moment().subtract(6, "days"), to: moment() });
  //       break;
  //     case "30days":
  //       setDateRange({ from: moment().subtract(30, "days"), to: moment() });
  //       break;
  //     case "thisMonth":
  //       setDateRange({
  //         from: moment().startOf("month"),
  //         to: moment().endOf("month"),
  //       });
  //       break;
  //     case "lastMonth":
  //       setDateRange({
  //         from: moment().subtract(1, "months").startOf("month"),
  //         to: moment().subtract(1, "months").endOf("month"),
  //       });
  //       break;
  //     default:
  //       setDateRange({ from: moment().subtract(6, "days"), to: moment() });
  //   }
  // };
  return (
    <Spin spinning={loading}>
      <div className={styles.statisticsLineHeader}>
        <Form
          style={{ width: "100%" }}
          onFinish={values => {
            if (values.rangeDate) {
              setDateRange({
                from: values.rangeDate[0],
                to: values.rangeDate[1],
              });
            } else {
              openWarningNotification({ message: "Please select date range!" });
            }
          }}
        >
          <div className={styles.dateRangeBox}>
            <div>
              <Form.Item
                name="rangeDate"
                initialValue={[moment().subtract(6, "days"), moment()]}
              >
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [
                      moment().subtract(1, "days"),
                      moment().subtract(1, "days"),
                    ],
                    "Last 2 Days": [moment().subtract(1, "days"), moment()],
                    "Last 7 Days": [moment().subtract(6, "days"), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment().subtract(1, "months").startOf("month"),
                      moment().subtract(1, "months").endOf("month"),
                    ],
                  }}
                  allowClear={false}
                  className={styles.dateRangeInput}
                />
              </Form.Item>
              <Radio.Group
                value={hourTime}
                style={{ marginBottom: 24, marginLeft: 24, color: "#626c8a" }}
                buttonStyle="solid"
                onChange={e => {
                  setHourTime(e.target.value);
                  localStorage.setItem("isHourTime", e.target.value);
                }}
              >
                <Radio.Button value={false}>Date</Radio.Button>
                <Radio.Button value={true}>Hour</Radio.Button>
              </Radio.Group>
            </div>
            <Form.Item>
              <Button
                className={styles.updateRangeBtn}
                type="primary"
                htmlType="submit"
              >
                Apply Filters
              </Button>
            </Form.Item>
          </div>
        </Form>

        {/* <Select
          defaultValue="7days"
          style={{ width: 150 }}
          onChange={handleDateRangeChange}
        >
          <Option value="today">Today</Option>
          <Option value="yesterday">Yesterday</Option>
          <Option value="7days">Last 7 Days</Option>
          <Option value="30days">Last 30 Days</Option>
          <Option value="thisMonth">This Month</Option>
          <Option value="lastMonth">Last Month</Option>
        </Select> */}
      </div>
      <div
        id="chart-wrapper"
        style={{ height: "500px" }}
        className={styles.lineChartContainer}
      />
    </Spin>
  );
};
