import { Select } from "antd";
const { Option } = Select;

export const generateCountries = arr => {
  return arr.map(country => (
    <Option key={country.code2} value={country.code2}>
      {country.name}
    </Option>
  ));
};

export const generateOptions = arr => {
  return arr.map(option => (
    <Option key={option.name} value={option.name}>
      {option.name}
    </Option>
  ));
};

export const generateChannels = arr => {
  return arr.map(option => (
    <Option key={option.campaign_id} value={option.campaign_id}>
      {option.channel}
    </Option>
  ));
};

export const generatePrograms = arr => {
  return arr.map(option => (
    <Option key={option.program_id} value={option.program_id}>
      {option.program_name}
    </Option>
  ));
};
