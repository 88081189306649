import { auth } from "../../services/auth";

export const getInitialModule = (pathname) => {
  // const permissions = auth.getPermissions();
  // const myPermissions = Object.values(permissions).filter(item => item);
  // if (myPermissions.length > 1) {
  if (pathname.match(/^\/offerwall/i)) {
    return "offerwall";
  } else if (pathname.match(/^\/publisher/i)) {
    return "publisher";
  } else if (pathname.match(/^\/search_feed/i)) {
    return "search_feed";
  } else if (pathname.match(/^\/search/i)) {
    return "search";
  } else if (pathname.match(/^\/agency/i)) {
    return "social_to_search";
  } else if (pathname.match(/^\/billing/i)) {
    return "billing";
  }
  // }
  //  else if (myPermissions.length === 1) {
  //   if (permissions.offerwall) {
  //     return "offerwall";
  //   } else if (permissions.publisher) {
  //     return "publisher";
  //   } else if (permissions.search) {
  //     return "search";
  //   }
  // }
};

export const getHomePath = (currentModule) => {
  switch (currentModule) {
    case "offerwall":
    case "affiliate_programs":
      return "/offerwall/dashboard";
    case "publisher":
      return "/publisher/dashboard";
    case "search":
      return "/search/dashboard";
    case "search_feed":
      return "/search_feed/dashboard";
    case "social_to_search":
      return "/agency/dashboard";
    case "billing":
      return "/billing/payment-methods";
    default:
      return "/";
  }
};

export const isModuleVisible = (pathname) => {
  return !(
    pathname.match(/^\/offerwall/i) ||
    pathname.match(/^\/publisher/i) ||
    pathname.match(/^\/search_feed/i) ||
    pathname.match(/^\/agency/i) ||
    pathname.match(/^\/search/i) ||
    pathname.match(/^\/billing/i)
  );
};

export const hasOnePermission = (pathname) => {
  if (pathname !== "/") {
    return false;
  }
  const myPermissions = Object.values(auth.getPermissions()).filter(
    (item) => item
  );
  return myPermissions.length > 1;
};

export const parseModuleName = (
  agreements,
  module,
  isAgencyRequested,
  isOfferwallRequested,
  isPublisherRequested,
  isSearchRequested
) => {
  if (module === "offerwall") {
    return "Affiliate Programs";
  } else if (module === "publisher") {
    return "Webmasters";
  } else if (module === "search_feed") {
    return "Search BI";
  } else if (module === "social_to_search") {
    return "Agency";
  } else if (module === "search" && agreements) {
    if (
      agreements.search &&
      agreements.search.extension === false &&
      agreements.search &&
      agreements.search.n2s === false
    ) {
      return "Search and N2S";
    }
    if (
      agreements.search &&
      agreements.search.extension === false &&
      !(agreements.search && agreements.search.n2s === false)
    ) {
      return "Search";
    } else if (
      !(agreements.search && agreements.search.extension === false) &&
      agreements.search &&
      agreements.search.n2s === false
    ) {
      return "N2S";
    }
  } else if (module === "billing") {
    let modules = [];
    if (!isOfferwallRequested) {
      modules.push("Affiliate Programs");
    }
    if (!isAgencyRequested) {
      modules.push("Agency");
    }
    if (!isPublisherRequested) {
      modules.push("Webmasters");
    }
    if (!isSearchRequested && agreements) {
      if (
        agreements.search &&
        agreements.search.extension === false &&
        agreements.search &&
        agreements.search.n2s === false
      ) {
        modules.push("Search");
        modules.push("N2S");
      }
      if (
        agreements.search &&
        agreements.search.extension === false &&
        !(agreements.search && agreements.search.n2s === false)
      ) {
        modules.push("Search");
      } else if (
        !(agreements.search && agreements.search.extension === false) &&
        agreements.search &&
        agreements.search.n2s === false
      ) {
        modules.push("N2S");
      }
    }
    var delimiter = ", ";
    var parsedModules = modules.join(delimiter);
    var index = parsedModules.lastIndexOf(delimiter);
    if (modules.length > 1) {
      return (
        parsedModules.substring(0, index) +
        " and " +
        parsedModules.substring(index + delimiter.length)
      );
    } else {
      return modules[0];
    }
  }
};
