import { Button, notification, Space, Tooltip, Popconfirm, Modal } from "antd";
import {
  DollarOutlined,
  PaperClipOutlined,
  LineChartOutlined,
  LoadingOutlined,
  EditOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { coinisCampaignsService } from "../../../services/coinisCampaigns";
import { coinisProgramsService } from "../../../services/coinisPrograms";
import UserContext from "../../../contexts/userContext";

export const ActionsTableCol = ({
  channel,
  campaign_id,
  prices,
  links,
  setModalPriceVisible,
  setModalLinkVisible,
  dispatchSelectedFilters,
  filters,
  status,
  offerwall,
  setIsEditModalVisible,
}) => {
  const { userInfo } = useContext(UserContext);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const requestReapproval = async () => {
    setApprovalLoading(true);
    notification.open({
      message: "Requesting approval...",
      key: "update",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });
    try {
      const res = await coinisCampaignsService.requestApproval(campaign_id);
      if (res.success) {
        notification["success"]({
          message: res.message,
          key: "update",
        });
        dispatchSelectedFilters({ type: "", payload: { filters } });
      } else {
        throw new Error();
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to send request.",
        key: "update",
      });
    } finally {
      setApprovalLoading(false);
    }
  };

  const updateCampaignStatus = async () => {
    notification.open({
      message: "Updating campaign...",
      key: "update",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });
    try {
      const res = await coinisProgramsService.editCampaign(campaign_id, {
        status: status === "Active" ? "Paused" : "Active",
      });
      if (res.success) {
        notification["success"]({
          message: "Campaign successfully updated!",
          key: "update",
        });
        dispatchSelectedFilters({ type: "", payload: { filters } });
      } else {
        throw new Error();
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to update campaign!",
        key: "update",
      });
    }
  };

  const handleArchiveCampaign = async () => {
    notification.open({
      message: "Archiving campaign...",
      key: "archive",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });
    try {
      const res = await coinisProgramsService.archiveCampaign(campaign_id);
      if (res.success) {
        notification["success"]({
          message: "Campaign successfully archived!",
          key: "archive",
        });
        setArchiveModalVisible(false);
        dispatchSelectedFilters({ type: "", payload: { filters } });
      } else {
        throw new Error();
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to archive campaign!",
        key: "archive",
      });
    }
  };

  return (
    <Space size={userInfo.ow_tmp ? "small" : 15}>
      {(status && status.toLowerCase() === "active") ||
      (status && status.toLowerCase() === "paused") || (status && status.toLowerCase() === "archived") ? (
        <>
          {userInfo.ow_tmp && (
            <Tooltip title="Edit">
              <Button
                style={{ color: "#737373" }}
                disabled={!offerwall}
                icon={<EditOutlined />}
                onClick={() =>
                  setIsEditModalVisible({
                    id: campaign_id,
                    type: null,
                  })
                }
              />
            </Tooltip>
          )}
          <Link
            to={{ pathname: "/offerwall/statistics", campaign_id, channel }}
          >
            <Tooltip title="Channel Statistics">
              <Button
                style={{ color: "#737373" }}
                icon={<LineChartOutlined />}
              />
            </Tooltip>
          </Link>
          <Tooltip title="Prices">
            <Button
              style={{ color: "#737373" }}
              // disabled={
              //   !(
              //     typeof prices === "string" ||
              //     (Array.isArray(prices) && prices.length > 0)
              //   )
              // }
              icon={<DollarOutlined />}
              onClick={() => setModalPriceVisible({ campaign_id, channel })}
            />
          </Tooltip>
          <Tooltip title="Link">
            <Button
              style={{ color: "#737373" }}
              icon={<PaperClipOutlined />}
              // disabled={links.length < 1}
              onClick={() => setModalLinkVisible({ campaign_id, channel })}
            />
          </Tooltip>
          {userInfo.ow_tmp && (
            <Popconfirm
              disabled={!offerwall} //<---------
              title="Are you sure you want to change status of this campaign?"
              onConfirm={updateCampaignStatus}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip
                title={
                  status === "Active" ? "Pause campaign" : "Activate campaign"
                }
              >
                <Button
                  disabled={!offerwall}
                  style={{ color: "#737373" }}
                  icon={
                    status === "Active" ? (
                      <PauseCircleOutlined />
                    ) : (
                      <PlayCircleOutlined />
                    )
                  }
                />
              </Tooltip>
            </Popconfirm>
          )}
          <Tooltip title="Archive">
            <Button
              style={{ color: "#737373" }}
              icon={<Icon
                component={() => (
                  <img
                    src="/images/icon-archive.png"
                    alt="Archive"
                  />
                )}
              />}
              onClick={() => setArchiveModalVisible({ campaign_id, channel })}
              disabled={(!offerwall || (status && status.toLowerCase() === "archived"))}
            />
          </Tooltip>
          <Modal
            title={
              <>
                <span style={{ marginLeft: "10px" }}>
                  Warning! You are about to archive campaign {channel}
                </span>
              </>
            }
            visible={archiveModalVisible}
            onOk={() => handleArchiveCampaign(campaign_id)}
            onCancel={() => setArchiveModalVisible(false)}
            okText="Archive"
            cancelText="Cancel"
          >
            <p>
              Archiving this campaign might change your statistics. Are you sure you want to proceed?
            </p>
          </Modal>
        </>
      ) : (
        <Tooltip
          title={
            status && status.toLowerCase() === "requested"
              ? "You’ve already requested this offer / product."
              : ""
          }
        >
          <Button
            style={{
              width: userInfo.ow_tmp ? 230 : 126,
              fontSize: "0.8rem",
              padding: "2px 2px",
              color: "#707070",
            }}
            onClick={requestReapproval}
            disabled={
              approvalLoading ||
              (status && status.toLowerCase() === "requested")
            }
            loading={approvalLoading}
          >
            Request Approval
          </Button>
        </Tooltip>
      )}
    </Space>
  );
};
