import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Radio, Spin, Tooltip } from "antd";
import {
  DeleteOutlined,
  InfoCircleTwoTone,
  EditOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import styles from "./AccessModal.module.css";
import { v4 as uuidv4 } from "uuid";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../utils/notifications";
import { coinisProgramsService } from "../../services/coinisPrograms";
import UserContext from "../../contexts/userContext";
import { isValidDomain } from "../../utils/validations";
import TextArea from "antd/lib/input/TextArea";
import { MACROS } from "../../utils/offerwallMacros";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { generateRandomString } from "./utils";

export const StartDDL = ({
  campaignEdit,
  campaignDetails,
  setLoadingModal,
  program_id,
  program_title,
  program_status,
  has_refferal_product_link,
  setIsModalVisible,
  setTriggerNewlyCreated,
  setActiveTab,
  isCampaignClone,
  welcomeProgramId,
}) => {
  const { userInfo } = useContext(UserContext);
  const [subIds, setSubIds] = useState([{ subid: "", gen_id: uuidv4() }]);
  const [defaultParams, setDefaultParams] = useState({
    cid: "{CLICK_ID}",
    sid: "{SUB_ID}",
  });
  const [isAdvancedSetup, setIsAdvancedSetup] = useState(false);

  const [campaignObject, setCampaignObject] = useState({});
  const [linkLoading, setLinkLoading] = useState(false);
  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);

  const [titleError, setTitleError] = useState({});

  useEffect(() => {
    if (campaignEdit && !isCampaignClone) {
      setCampaignObject({
        ...campaignDetails,
        title: campaignDetails.campaign_name,
        product_link:
          campaignDetails.additional_data &&
          campaignDetails.additional_data.product_link,
        redirect_utm_campaign:
          campaignDetails.additional_data &&
          campaignDetails.additional_data.redirect_utm_campaign,
        redirect_utm_source:
          campaignDetails.additional_data &&
          campaignDetails.additional_data.redirect_utm_source,
      });
      if (
        campaignDetails.additional_data &&
        campaignDetails.additional_data.params
      ) {
        const tempParams = campaignDetails.additional_data.params;
        const defaultParams = {
          cid: tempParams.find((item) => item.name === "cid")?.value || "",
          sid: tempParams.find((item) => item.name === "sid")?.value || "",
        };
        setDefaultParams(defaultParams);
        delete tempParams.cid;
        delete tempParams.sid;
        setSubIds(
          tempParams
            .filter((item) => item.name.includes("qs"))
            .map((item) => ({
              subid: item.value,
              gen_id: uuidv4(),
            }))
        );
      } else {
        setDefaultParams({ cid: "", sid: "" });
      }
    } else if (isCampaignClone) {
      setCampaignObject((prevState) => ({
        ...prevState,
        postback: campaignDetails.postback,
        title: campaignDetails.campaign_name,
        product_link:
          campaignDetails.additional_data &&
          campaignDetails.additional_data.product_link,
      }));
      if (
        campaignDetails.additional_data &&
        campaignDetails.additional_data.params
      ) {
        const tempParams = campaignDetails.additional_data.params;
        const defaultParams = {
          cid: tempParams.find((item) => item.name === "cid").value || "",
          sid: tempParams.find((item) => item.name === "sid").value || "",
        };
        setDefaultParams(defaultParams);
        setSubIds(
          tempParams
            .filter((item) => item.name.includes("qs"))
            .map((item) => ({
              subid: item.value,
              gen_id: uuidv4(),
            }))
        );
      } else {
        setDefaultParams({ cid: "", sid: "" });
      }
    }
  }, [
    isCampaignClone,
    setLoadingModal,
    campaignEdit,
    campaignDetails,
    userInfo,
  ]);

  useEffect(() => {
    const getCampaignLinks = async (isCampaignClone) => {
      setLinkLoading(true);
      try {
        const res = await coinisProgramsService.getCampaignLinks();

        const postbackDefault = isCampaignClone
          ? {}
          : { postback: userInfo.postback_url };

        const title = `${program_title.replace(
          /\s/g,
          "_"
        )}_${generateRandomString(5)}`;

        setCampaignObject((prevState) => ({
          ...prevState,
          ...res.data,
          ...postbackDefault,
          title,
        }));
      } catch (e) {
      } finally {
        setLinkLoading(false);
      }
    };
    if (!campaignEdit || isCampaignClone) {
      if (!isCampaignClone) {
        try {
          const default_params = JSON.parse(userInfo.affiliate_preset_params);
          if (default_params) {
            setDefaultParams(default_params);
          }
        } catch (e) {
          console.log(e);
        }
      }
      getCampaignLinks(isCampaignClone);
    }
    setIsAdvancedSetup(userInfo.is_affiliate_advanced_setup);
  }, [setLinkLoading, campaignEdit, program_title, isCampaignClone, userInfo]);

  const handleSubIdChange = (id, e) => {
    const subid = e.target.value;
    setSubIds((exAds) => {
      const newAds = [...exAds];
      newAds[id].subid = subid;
      return newAds;
    });
  };

  const handleSubIdDelete = (id) => {
    setSubIds((exAds) => {
      const newAds = [...exAds];
      newAds.splice(id, 1);
      return newAds;
    });
  };

  const handleStartDDLCampaign = async () => {
    if (!campaignObject.title) {
      setTitleError({
        status: "error",
        message: "Please fill in the link name. This is a required field.",
      });
      return;
    }
    if (
      has_refferal_product_link &&
      campaignDetails.product_link &&
      !isValidDomain(campaignObject.product_link)
    ) {
      openWarningNotification({
        message: "Please provide a valid product link.",
      });
      return;
    }
    const params = [
      ...Object.entries(defaultParams).map((item) => ({
        name: item[0],
        value: item[1],
      })),
      ...subIds.map((item, index) => ({
        name: "qs" + (index + 1),
        value: item.subid,
      })),
    ];
    const additional_data = {
      params,
      redirect_utm_campaign: campaignObject.redirect_utm_campaign,
      redirect_utm_source: campaignObject.redirect_utm_source,
    };

    if (has_refferal_product_link) {
      additional_data.product_link = campaignObject.product_link;
    }

    const requestObject = {
      program_id,
      ...campaignObject,
      additional_data,
    };
    setLoadingModal(true);
    try {
      const res =
        !campaignEdit || isCampaignClone
          ? await coinisProgramsService.startCampaign(requestObject)
          : await coinisProgramsService.editCampaign(
              campaignEdit,
              requestObject
            );
      if (res.success) {
        openSuccessNotification({
          message: "Successfully added program link!",
        });
        setIsModalVisible(false);
        setSubIds([{ subid: "", gen_id: uuidv4() }]);
        setCampaignObject({});
        if (setTriggerNewlyCreated) {
          setTriggerNewlyCreated((flag) => !flag);
          welcomeProgramId ? setActiveTab("1") : setActiveTab("2");
        }
      } else if (res.status === 409) {
        setTitleError({
          status: "error",
          message:
            "Oops! It seems that you already have a program link with the same name.",
        });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch {
    } finally {
      setLoadingModal(false);
    }
  };

  const parseUrlParameters = (defaultParams, parameters, campaign) => {
    let urlParameters = `?utm_campaign=${campaign.redirect_utm_campaign}&utm_source=${campaign.redirect_utm_source}`;
    Object.entries(defaultParams).forEach((item) => {
      if (item[1]) {
        urlParameters += `&${item[0]}=${item[1]}`;
      }
    });
    parameters.forEach((element, id) => {
      if (element.subid) {
        urlParameters += `&qs${id + 1}=${element.subid}`;
      }
    });
    return urlParameters;
  };

  const parseShortLinkUrlParameters = (defaultParams) => {
    let urlParameters = "";
    Object.entries(defaultParams)
      .filter((item) => item[1])
      .forEach((item, id) => {
        urlParameters += `${id === 0 ? "?" : "&"}${item[0]}=${item[1]}`;
      });
    return urlParameters;
  };

  const handleMacrosClick = (macroContent) => {
    setCampaignObject((prevObj) => {
      const postback = whereTheCursorWas
        ? prevObj.postback.substring(0, whereTheCursorWas) +
          macroContent +
          prevObj.postback.substring(whereTheCursorWas, prevObj.postback.length)
        : prevObj.postback + macroContent;
      if (whereTheCursorWas !== undefined) {
        setWhereTheCursorWas((prevState) => prevState + macroContent.length);
      }
      return { ...prevObj, postback };
    });
  };

  return (
    <div>
      <Spin spinning={linkLoading}>
        <div>
          <p className={styles.accessSectionTitle}>Get program link</p>

          <p className={styles.accessMinorTitle}>Link name</p>
          <div className={styles.accessSectionContainer}>
            <div className={styles.titleSection}>
              <Input
                className={styles.accessSectionInputLight}
                value={campaignObject?.title}
                suffix={<EditOutlined />}
                status={titleError.status}
                onChange={(e) => {
                  setTitleError({});
                  setCampaignObject((prevObj) => {
                    return { ...prevObj, title: e.target.value };
                  });
                }}
              />
              {titleError.message && (
                <span className={styles.titleError}>{titleError.message}</span>
              )}
            </div>
            <CopyToClipboard text={campaignObject?.title}>
              <Button
                icon={<CopyOutlined />}
                className={styles.accessSectionCopyBtn}
                onClick={() => openSuccessNotification({ message: "Copied" })}
                type="primary"
              />
            </CopyToClipboard>
          </div>

          <p className={styles.accessMinorTitle}>Program link</p>
          <div className={styles.accessSectionContainer}>
            <TextArea
              className={styles.accessSectionInput}
              disabled
              value={
                campaignObject.campaign_link +
                parseUrlParameters(defaultParams, subIds, campaignObject)
              }
            />
            <CopyToClipboard
              text={
                campaignObject.campaign_link +
                parseUrlParameters(defaultParams, subIds, campaignObject)
              }
            >
              <Button
                icon={<CopyOutlined />}
                className={styles.accessSectionCopyBtn}
                onClick={() => openSuccessNotification({ message: "Copied" })}
                type="primary"
              />
            </CopyToClipboard>
          </div>
          <p className={styles.accessMinorTitle}>Short link</p>
          <div className={styles.accessSectionContainer}>
            <Input
              className={styles.accessSectionInputLight}
              disabled
              value={
                campaignObject.short_link +
                parseShortLinkUrlParameters(defaultParams)
              }
            />
            <CopyToClipboard
              text={
                campaignObject.short_link +
                parseShortLinkUrlParameters(defaultParams)
              }
            >
              <Button
                icon={<CopyOutlined />}
                className={styles.accessSectionCopyBtn}
                onClick={() => openSuccessNotification({ message: "Copied" })}
                type="primary"
              />
            </CopyToClipboard>
          </div>
        </div>
        <p className={styles.accessSectionTitle}>Postback settings</p>
        <p className={styles.accessMinorTitle}>Postback link</p>
        <div className={styles.accessSectionContainer}>
          <Input
            className={styles.accessSectionInputLight}
            value={campaignObject.postback}
            suffix={<EditOutlined />}
            onChange={(e) =>
              setCampaignObject((prevObj) => {
                return { ...prevObj, postback: e.target.value };
              })
            }
          />
          <CopyToClipboard text={campaignObject.postback}>
            <Button
              icon={<CopyOutlined />}
              className={styles.accessSectionCopyBtn}
              onClick={() => openSuccessNotification({ message: "Copied" })}
              type="primary"
            />
          </CopyToClipboard>
        </div>
        <div className={styles.postbackMacros}>
          {/* <p>
            By editing postback URL, you can set custom postback for this
            campaign.
          </p> */}
          <p className={styles.postbackMacrosTitle}>
            Add parameters
            {/* <span>Add some of our macros to your postback URL</span> */}
          </p>
          <div>
            {MACROS.map((macro) => (
              <div
                key={macro.value}
                className={classNames(styles.macroItem, {
                  [styles.macroItemActive]: campaignObject?.postback?.includes(
                    macro.value
                  ),
                })}
                onClick={() => handleMacrosClick(macro.value)}
              >
                {macro.value}
              </div>
            ))}
          </div>
          <p className={styles.postbackMacrosWarning}>
            By default, this is your global postback. You can view your current
            settings on{" "}
            <Link target="_blank" to="/offerwall/global-settings">
              Global settings
            </Link>{" "}
            page. Trackers can be changed per each individual program link, as
            well as the parameters.{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://coinis.com/faq/postback-faq"
            >
              Postback FAQ
            </a>
            {/* <WarningFilled style={{ color: "#1890ff" }} />
            <span>Make sure macros are properly added.</span> If the
            implementation process is invalid, you won’t be able to view correct
            statistics in your tracker. */}
          </p>
        </div>
        <Radio.Group
          className={styles.radioGrupSetup}
          onChange={(e) => setIsAdvancedSetup(e.target.value)}
          value={isAdvancedSetup}
        >
          <Radio value={false}>Basic link set-up</Radio>
          <Radio value={true}>Advanced link set-up</Radio>
        </Radio.Group>

        {isAdvancedSetup && (
          <>
            {has_refferal_product_link && (
              <>
                <p className={styles.accessMinorTitle}>
                  Product link{" "}
                  <Tooltip title="Product link is a specific page where users will be transferred to. Please use the format https://your-product-link.com?some-specific-page">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </p>
                <div className={styles.productLinkInput}>
                  <Input
                    placeholder="https://your-product-link.com/some-specific-page"
                    style={{ borderRadius: "5px" }}
                    value={campaignObject.product_link}
                    onChange={(e) =>
                      setCampaignObject((prevObj) => {
                        return { ...prevObj, product_link: e.target.value };
                      })
                    }
                  />
                </div>
              </>
            )}
            <div className={styles.regularStartContainer}>
              <div>
                <p className={styles.accessMinorTitle}>Default Parameters</p>
                <div>
                  <div
                    className={classNames(
                      styles.inputVariant,
                      styles.inputVariantTop
                    )}
                  >
                    <Input
                      className={styles.inputFieldParameter}
                      disabled
                      value="cid"
                    />
                    <Input
                      className={styles.inputFieldSubid}
                      value={defaultParams.cid}
                      placeholder="+ Add Parameter"
                      onChange={(e) =>
                        setDefaultParams((prevParams) => ({
                          ...prevParams,
                          cid: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div
                    className={classNames(
                      styles.inputVariant,
                      styles.inputVariantTop
                    )}
                  >
                    <Input
                      className={styles.inputFieldParameter}
                      disabled
                      value="sid"
                    />
                    <Input
                      className={styles.inputFieldSubid}
                      value={defaultParams.sid}
                      placeholder="+ Add Parameter"
                      onChange={(e) =>
                        setDefaultParams((prevParams) => ({
                          ...prevParams,
                          sid: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className={styles.accessMinorTitle}>Additional Parameters</p>
                <div>
                  {subIds.map((item, id) => (
                    <div key={item.gen_id} className={styles.inputVariant}>
                      <Input
                        className={styles.inputFieldParameter}
                        disabled
                        value={"qs" + (id + 1)}
                      />
                      <Input
                        className={styles.inputFieldSubid}
                        value={item.subid}
                        placeholder="+ Add Parameter"
                        onChange={(e) => handleSubIdChange(id, e)}
                      />
                      <Button
                        disabled={subIds.length < 2}
                        className={styles.deleteSubidBtn}
                        onClick={() => handleSubIdDelete(id)}
                        icon={<DeleteOutlined />}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.addSubidContainer}>
              {subIds.length < 9 && (
                <Button
                  onClick={() =>
                    setSubIds((exAds) => [
                      ...exAds,
                      {
                        subid: "",
                        gen_id: uuidv4(),
                      },
                    ])
                  }
                  className={styles.addParamBtn}
                  type="outlined"
                >
                  Add Parameter
                </Button>
              )}
            </div>
          </>
        )}
        <div className={styles.startCampaignContainer}>
          <Button
            className={styles.buttonRad}
            htmlType="submit"
            disabled={program_status === "Paused"}
            type="primary"
            onClick={handleStartDDLCampaign}
          >
            {campaignEdit && !isCampaignClone
              ? "Save Program Link"
              : "Get Program Link"}
          </Button>
        </div>
      </Spin>
    </div>
  );
};
