import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
} from "antd";
import { InfoCircleTwoTone, CopyOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./SmartlinkForm.module.css";
import { Link, useHistory } from "react-router-dom";
import isValidDomain from "is-valid-domain";
import { publisherFormsService } from "../../../services/publisherFormsService";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";
import { ExampleTag } from "../components/ExampleTag";
import QueryString from "qs";
import { isDisabledZone } from "../../../utils/validations";

const { Option } = Select;
const { TabPane } = Tabs;

export const SmartlinkForm = ({ location, match }) => {
  const history = useHistory();
  const [zoneLoading, setZoneLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const isPreview =
    QueryString.parse(location.search, { ignoreQueryPrefix: true }).type ===
    "preview";

  // first || second step
  const [step, setStep] = useState(0);
  // tag or A2
  const [previewStep, setPreviewStep] = useState("1");
  const [isFrequency, setIsFrequency] = useState(true);
  const [currentZoneId, setCurrentZoneId] = useState(
    match.params.id && match.params.id !== "new" ? match.params.id : null
  );
  const [currentZoneInfo, setCurrentZoneInfo] = useState({});
  const [showExample, setShowExample] = useState(true);
  const [form] = Form.useForm();

  // const type = location.pathname.split("/")[
  //   location.pathname.split("/").length - 1
  // ];

  const getZoneInfo = useCallback(
    async (id) => {
      if (isPreview) {
        setStep(1);
      }
      setZoneLoading(true);
      try {
        const res = await publisherFormsService.getZone(id);

        if (isDisabledZone(res.data?.status)) {
          setStep(0);
        }

        if (match.params.id && match.params.id !== "new") {
          form.setFieldsValue(res.data);
          if (res.data.frequency_interval) {
            setIsFrequency(true);
          }
        }
        setCurrentZoneInfo(res.data);
      } catch (e) {
        openErrorNotification({
          message: "Error while trying to get details about zone!",
        });
      } finally {
        setZoneLoading(false);
      }
    },
    [form, isPreview, match.params.id]
  );

  useEffect(() => {
    if (currentZoneId) {
      getZoneInfo(currentZoneId);
    }
  }, [currentZoneId, getZoneInfo]);

  const finishZoneForm = async (data) => {
    setBtnLoading(true);
    try {
      if (!data.frequency_interval || !isFrequency) {
        data.frequency_interval = 0;
      }
      const res = !(match.params.id && match.params.id !== "new")
        ? await publisherFormsService.createZone({
            ...data,
            type: "smartlink",
          })
        : await publisherFormsService.editZone(
            {
              ...data,
              type: "smartlink",
            },
            match.params.id
          );
      if (res.success) {
        openSuccessNotification({ message: res.message });
        if (match.params.id && match.params.id !== "new") {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            history.push("/publisher/zones");
          } else {
            getZoneInfo(match.params.id);
            setStep(1);
          }
        } else {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            openWarningNotification({
              message:
                "Looks like you have a new website! Allow us 24-48h to check your traffic quality. You will get an email once your zone is approved.",
              duration: 15,
            });
            history.push("/publisher/zones");
          } else {
            setCurrentZoneId(res.data.id);
            setStep(1);
          }
        }
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: "Error occurred while trying to create new zone!",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Spin spinning={zoneLoading}>
      <div className={styles.formContainer}>
        {step === 0 ? (
          <>
            <h3>{match.params.id ? "Edit" : "New"} Smartlink Zone</h3>
            <div className={styles.createContent}>
              <Form form={form} onFinish={finishZoneForm}>
                <p className={styles.inputLabel}>
                  1. Zone Title{" "}
                  <Tooltip title="Enter the title for your zone. This will help you with identifying your performance in Statistics.">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </p>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value && value !== "" && value.length <= 250) {
                          callback();
                        } else {
                          callback("Zone title is not valid!");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    showCount
                    maxLength={512}
                    disabled={!!match.params.id && match.params.id !== "new"}
                    placeholder="Zone title"
                  />
                </Form.Item>
                <p className={styles.inputLabel}>
                  2. Web Site where JS will be added{"  "}
                  <Tooltip title="Please provide the name of the website where this .js file will be placed. You may put your entire website, or just one page of your website. Putting the .js file in just one page of your website, will only affect that page, not the entire website.">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </p>
                <Form.Item
                  name="website"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (
                          value !== "" &&
                          !value.startsWith("www.") &&
                          isValidDomain(value) &&
                          value.length <= 250
                        ) {
                          callback();
                        } else {
                          callback("Domain name is not valid!");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    showCount
                    maxLength={64}
                    onChange={(e) => {
                      form.setFieldsValue({
                        website: e.target.value.replace(
                          /^(https:\/\/)|(http:\/\/)/,
                          ""
                        ),
                      });
                    }}
                    disabled={!!match.params.id && match.params.id !== "new"}
                    addonBefore="www."
                    placeholder="new-example.com"
                  />
                </Form.Item>
                <p className={styles.inputLabel}>
                  3. Determine the frequency at which the ad would appear on
                  your page
                </p>
                <div className={styles.frequencyContent}>
                  <div className={styles.settingsBox}>
                    <Form.Item label="Repeat ad">
                      <Switch checked={isFrequency} onChange={setIsFrequency} />
                    </Form.Item>
                  </div>
                  <div className={styles.frequencyBox}>
                    <Form.Item
                      label="Time delay"
                      className={styles.firstFreq}
                      name="frequency_interval"
                      initialValue={1}
                    >
                      <InputNumber
                        style={{ width: 120 }}
                        min={0}
                        disabled={!isFrequency}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles.secondFreq}
                      name="frequency_unit"
                      initialValue="min"
                    >
                      <Select style={{ width: 120 }} disabled={!isFrequency}>
                        <Option value="sec">sec</Option>
                        <Option value="min">min</Option>
                        <Option value="hour">hour</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={styles.actionBox}>
                  <Link to="/publisher/zones">
                    <Button>Back</Button>
                  </Link>
                  <Button type="primary" htmlType="submit" loading={btnLoading}>
                    {match.params.id && match.params.id !== "new"
                      ? "Save"
                      : "Create"}{" "}
                    & Get Tag
                  </Button>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <>
            {previewStep === "1" ? (
              <h3>Get Tag for Movie Smart (Smartlink) Zone</h3>
            ) : (
              <h3>Here you can edit or copy code for A2 test</h3>
            )}
            <Tabs
              activeKey={previewStep}
              onChange={(key) => setPreviewStep(key)}
            >
              <TabPane tab="On Click" key="1">
                <div>
                  {/* <p className={styles.parHints}>
                    1. Frequency for this campaign is set automatically by
                    Coinis.
                  </p> */}
                  <p className={styles.parHintsOnClick}>
                    Paste this code at the end of your body tag.{" "}
                    {/* <Tooltip title="If you are using Wordpress, you may insert this file via a Plugin. If not make sure this code is copied to all the website pages you wish to show ads.">
                      <InfoCircleTwoTone />
                    </Tooltip> */}
                  </p>
                  <div className={styles.scriptBox}>
                    <CopyToClipboard
                      text={
                        currentZoneInfo.script ? currentZoneInfo.script : ""
                      }
                    >
                      <Button
                        icon={<CopyOutlined />}
                        type="primary"
                        className={styles.copyLinkBtn}
                        onClick={() =>
                          openSuccessNotification({ message: "Copied!" })
                        }
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    {currentZoneInfo.script ? currentZoneInfo.script : ""}
                  </div>
                  <p className={styles.parRule}>
                    Make sure you properly input the code,{" "}
                    <b>or the ads won’t appear</b> on your page at all. You can
                    see correct example of usage below.{" "}
                    <span
                      className={styles.showHideExamples}
                      onClick={() => setShowExample(!showExample)}
                    >
                      {showExample ? "Hide examples." : "See examples."}{" "}
                    </span>
                  </p>
                  {showExample && <ExampleTag />}
                </div>
                {/* <Link to={isPreview ? "/publisher/zones" : null}> */}
                <div className={styles.actionBox}>
                  <Button
                    className={styles.backBtnSmartlink}
                    onClick={() =>
                      !isPreview && match.params.id && match.params.id !== "new"
                        ? setStep(0)
                        : history.push("/publisher/zones")
                    }
                  >
                    Back
                  </Button>
                  {match.params.id &&
                  match.params.id !== "new" &&
                  !isPreview ? (
                    <Button
                      type="primary"
                      onClick={() => history.push("/publisher/zones")}
                    >
                      Done
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                {/* </Link> */}
              </TabPane>
              <TabPane tab="Direct Link" key="2">
                <div>
                  <p className={styles.parHints}>
                    Paste this link to a specific web page element you want
                    users to click on and display ad.
                  </p>
                  <div className={styles.scriptBox}>
                    <CopyToClipboard
                      text={currentZoneInfo.tag ? currentZoneInfo.tag : ""}
                    >
                      <Button
                        icon={<CopyOutlined />}
                        type="primary"
                        className={styles.copyLinkBtn}
                        onClick={() =>
                          openSuccessNotification({ message: "Copied!" })
                        }
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    {currentZoneInfo.tag ? currentZoneInfo.tag : ""}
                  </div>
                </div>
                {/* <Link to={isPreview ? "/publisher/zones" : null}> */}
                <div className={styles.actionBox}>
                  <Button
                    className={styles.backBtnSmartlink}
                    onClick={() =>
                      !isPreview ? setStep(0) : history.push("/publisher/zones")
                    }
                  >
                    {match.params.id && match.params.id !== "new"
                      ? "Back"
                      : "Done"}
                  </Button>
                  {match.params.id &&
                  match.params.id !== "new" &&
                  !isPreview ? (
                    <Button
                      type="primary"
                      onClick={() => history.push("/publisher/zones")}
                    >
                      Done
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                {/* </Link> */}
              </TabPane>
            </Tabs>
          </>
        )}
      </div>
    </Spin>
  );
};
