import { callApi } from "./api";

class BillingPaymentMethods {
  async getPaymentMethods() {
    return await callApi({
      url: "/api/billing/payment_methods/",
    });
  }

  async submitPaymentMethod(data) {
    return await callApi({
      url: "/api/billing/payment_methods/add/",
      method: "POST",
      data,
    });
  }

  async deletePaymentMethod(id) {
    return await callApi({
      url: `/api/billing/payment_methods/${id}/delete/`,
      method: "DELETE",
    });
  }

  async updatePaymentMethod(id, data) {
    return await callApi({
      url: `/api/billing/payment_methods/${id}/edit/`,
      method: "POST",
      data,
    });
  }

  async initializePayoneer() {
    return await callApi({
      url: "/api/billing/payoneer_init/",
      method: "POST",
    });
  }

  async getTipaltiLink() {
    return await callApi({
      url: "/api/billing/partners_tipalti_url/",
    });
  }
}

export const billingPaymentMethodsService = new BillingPaymentMethods();
