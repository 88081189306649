import React from "react";
import styles from "../Dashboard.module.css";

export const Insights = () => {
  return (
    <div className={styles.insightsContainer}>
      <h4>INSIGHTS</h4>
      <div className={styles.insightsList}>
        <div>
          <p className={styles.insightsDot}></p>
          <p>
            We have new campaigns at our inventory. Firefox, Chrome Extensions &
            Utilities. Please reach out your account manager.
          </p>
        </div>
        {/* <p>
          <span className={styles.insightsDot} />
          Increase your opportunities to reach the right audience by adding additional targeting.
        </p> */}
      </div>
    </div>
  );
};
