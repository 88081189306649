import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spin } from "antd";

import { TermsOfUse } from "./TermsOfUse";
import { StartDDL } from "./StartDDL";
import { StartRegular } from "./StartRegular";

import { openErrorNotification } from "../../utils/notifications";
import { coinisProgramsService } from "../../services/coinisPrograms";

import styles from "./AccessModal.module.css";

export const AccessModal = ({
  welcomeProgramId,
  setWelcomeProgramId,
  isRestricted,
  isModalVisible,
  setIsModalVisible,
  programDetails,
  setProgramDetails,
  fromTableId,
  setTriggerNewlyCreated,
  setTriggerGridRefresh,
  setActiveTab,
}) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState({});
  const [requestAccessForm] = Form.useForm();

  useEffect(() => {
    const getProgramDetails = async (fromTableId) => {
      setLoadingModal(true);
      try {
        const res = await coinisProgramsService.getProgramDetails(fromTableId);
        if (res.success) {
          setProgramDetails(res.data);
        } else {
          openErrorNotification({ message: res.message });
        }
      } catch (e) {
        openErrorNotification({
          message:
            "Error occured while trying to load details, please contact support.",
        });
      } finally {
        setLoadingModal(false);
      }
    };
    const getCampaignDetails = async (id) => {
      setLoadingModal(true);
      try {
        const res = await coinisProgramsService.getCampaignDetails(id);
        if (res.success) {
          setCampaignDetails(res.data);
        } else {
          openErrorNotification({ message: res.message });
        }
      } catch (e) {
        openErrorNotification({
          message:
            "Error occured while trying to load details, please contact support.",
        });
      } finally {
        setLoadingModal(false);
      }
    };
    if (isModalVisible.id) {
      getCampaignDetails(isModalVisible.id);
    } else if (fromTableId) {
      getProgramDetails(fromTableId);
    } else {
      // setProgramDetails({});
    }
    return () => {
      setCampaignDetails({});
    };
  }, [fromTableId, setProgramDetails, isModalVisible]);

  const handleConfirmModal = () => {
    if (welcomeProgramId) setWelcomeProgramId(null);
    programDetails.allowed_action === "request_access"
      ? requestAccessForm.submit()
      : setIsModalVisible({ type: programDetails.type, id: null });
  };

  const modalVisibleType = isModalVisible.type
    ? isModalVisible.type
    : campaignDetails.campaign_type;
  const isCampaignEdit = isModalVisible.id;
  const isCampaignClone = isModalVisible.clone;

  return (
    <Modal
      title={
        modalVisibleType === "terms_of_use"
          ? "Affiliate programs terms"
          : "Program Link Configuration"
      }
      visible={
        isModalVisible &&
        !(isModalVisible.type === null && isModalVisible.id === null)
      }
      width={850}
      style={!welcomeProgramId ? { top: 20 } : null}
      className={styles.accessModalWrapper}
      onCancel={() => setIsModalVisible(false)}
      centered={welcomeProgramId ? true : false}
      maskStyle={welcomeProgramId ? { zIndex: 1020 } : null}
      footer={
        modalVisibleType === "terms_of_use" ? (
          <Spin
            wrapperClassName={styles.modalFooterContainer}
            spinning={loadingModal}
            indicator={null}
          >
            <Button
              className={styles.buttonRad}
              onClick={() => setIsModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              className={styles.buttonRad}
              type="primary"
              onClick={handleConfirmModal}
            >
              {programDetails.allowed_action === "request_access"
                ? "Accept Terms of Use and Request Access"
                : "Accept Terms of Use"}
            </Button>
          </Spin>
        ) : (
          false
        )
      }
    >
      <Spin spinning={loadingModal} wrapperClassName={styles.spinningModal}>
        {modalVisibleType === "terms_of_use" ? (
          <TermsOfUse
            setLoadingModal={setLoadingModal}
            isRestricted={isRestricted}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            programDetails={programDetails}
            requestAccessForm={requestAccessForm}
            setTriggerGridRefresh={setTriggerGridRefresh}
            setTriggerNewlyCreated={setTriggerNewlyCreated}
            fromTableId={fromTableId}
          />
        ) : modalVisibleType === "Regular" ? (
          <StartRegular
            campaignEdit={isCampaignEdit}
            campaignDetails={campaignDetails}
            program_id={programDetails.id}
            program_title={programDetails.title}
            program_status={programDetails.status}
            landing_pages={programDetails.landing_pages}
            setIsModalVisible={setIsModalVisible}
            setLoadingModal={setLoadingModal}
            setTriggerNewlyCreated={setTriggerNewlyCreated}
            setActiveTab={setActiveTab}
            isCampaignClone={isCampaignClone}
            welcomeProgramId={welcomeProgramId}
          />
        ) : modalVisibleType === "DDL" ? (
          <StartDDL
            campaignEdit={isCampaignEdit}
            campaignDetails={campaignDetails}
            program_id={programDetails.id}
            program_title={programDetails.title}
            program_status={programDetails.status}
            has_refferal_product_link={programDetails.has_refferal_product_link}
            setIsModalVisible={setIsModalVisible}
            setLoadingModal={setLoadingModal}
            setTriggerNewlyCreated={setTriggerNewlyCreated}
            setActiveTab={setActiveTab}
            isCampaignClone={isCampaignClone}
            welcomeProgramId={welcomeProgramId}
          />
        ) : null}
      </Spin>
    </Modal>
  );
};
