import React, { useEffect } from "react";
import { Popover, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import styles from "./Filters.module.css";
import { renderColumns } from "../../../utils/renderColumns";

export const ColumnsGroup = ({
  onChange,
  checkedColumns,
  columnsData,
  showUpdateButton,
  onUpdate,
}) => {
  const [columnsVisibility, setColumnsVisibility] = useState(false);
  const [disabledColumns, setDisabledColumns] = useState({});

  useEffect(() => {
    if (
      checkedColumns?.filtered_traffic ||
      checkedColumns?.coinis_redirects ||
      checkedColumns?.filtered_traffic_percentage
    ) {
      setDisabledColumns({ country: true, provider: true, tq: true });
    } else if (
      checkedColumns?.country ||
      checkedColumns?.provider ||
      checkedColumns?.tq
    ) {
      setDisabledColumns({
        filtered_traffic: true,
        coinis_redirects: true,
        filtered_traffic_percentage: true,
      });
    } else {
      setDisabledColumns({});
    }
  }, [checkedColumns]);

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={columnsVisibility}
      onVisibleChange={() => setColumnsVisibility((prevState) => !prevState)}
      content={renderColumns(
        onChange,
        checkedColumns,
        showUpdateButton,
        columnsData,
        onUpdate,
        setColumnsVisibility,
        disabledColumns
      )}
    >
      <Button className={styles.columnsBtn} icon={<MenuOutlined rotate={90} />}>
        Columns
      </Button>
    </Popover>
  );
};
