import React, { useEffect, useState, useContext } from "react";

import Lottie from "react-lottie";
import NotificationContext from "../../contexts/notificationContext";
import NotificationBell from "../components/images/notifBell.json";

import { Badge, Divider, Dropdown } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BarNotification } from "./BarNotification";

import styles from "../Layout.module.css";

export const BarNotificationIcon = ({ currentModule, dropdownVisible, setDropdownVisible }) => {
  const {
    userNotifications,
    setUserNotifications,
    userNotificationsCount,
    unreadCount,
    loadingNotifications,
    userNotificationPage,
    setUserNotificationPage,
    userNotificationPageSize,
    setUserNotificationPageSize,
  } = useContext(NotificationContext);

  const count = userNotifications.filter(notification => !notification.read).length;

  const [animationStatus, setAnimationStopped] = useState({
    loaded: false,
    stopped: true,
  });

  const bell = {
    loop: false,
    autoplay: false,
    animationData: NotificationBell,
  };

  const INTERVAL_MS = 150000;

  useEffect(() => {
    if (count > 0) {
      const interval = setInterval(() => {
        if (!animationStatus.loaded) {
          setAnimationStopped(prevState => {
            return { ...prevState, loaded: !prevState.loaded };
          });
        } else if (animationStatus.loaded) {
          setAnimationStopped(prevState => {
            return { ...prevState, stopped: !prevState.stopped };
          });
        }
      }, INTERVAL_MS);
      return () => clearInterval(interval);
    }
  }, [userNotifications, animationStatus, setAnimationStopped, count]);

  return (
    <>
      <div className={styles.balanceField}>
        <Dropdown
          overlayClassName={styles.headerDropdown}
          placement="bottomRight"
          trigger={["click"]}
          visible={dropdownVisible}
          onVisibleChange={value => {
            !loadingNotifications && setDropdownVisible(value);
          }}
          overlay={
            <BarNotification
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}
              userNotifications={userNotifications}
              setUserNotifications={setUserNotifications}
              userNotificationsCount={userNotificationsCount}
              loadingNotifications={loadingNotifications}
              userNotificationPage={userNotificationPage}
              setUserNotificationPage={setUserNotificationPage}
              userNotificationPageSize={userNotificationPageSize}
              setUserNotificationPageSize={setUserNotificationPageSize}
              currentModule={currentModule}
            />
          }
        >
          <Badge count={loadingNotifications ? <span className={styles.notificationLoadingIcon}><LoadingOutlined spin style={{ fontSize: '5px', color: '#ccc' }} /></span> : unreadCount === 0 ? "" : unreadCount} size="small">
            <Lottie
              isStopped={animationStatus.stopped}
              options={bell}
              height={30}
              width={30}
              style={{ align: "center" }}
              isClickToPauseDisabled={true}
            />
          </Badge>
        </Dropdown>
      </div>
      <Divider
        type="vertical"
        style={{
          height: "1.7rem",
          backgroundColor: "#ededed",
        }}
      />
    </>
  );
};
