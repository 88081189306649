import React, { useState, useContext } from "react";
import { Popover, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import styles from "../CoinisCampaigns.module.css";
import { renderColumns } from "../../../utils/renderColumns";
import UserContext from "../../../contexts/userContext";

export const ColumnsGroup = ({
  onChange,
  checkedColumns,
  columnsData,
  showUpdateButton,
  onUpdate,
}) => {
  const [columnsVisibility, setColumnsVisibility] = useState(false);
  const { userInfo } = useContext(UserContext);

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={columnsVisibility}
      onVisibleChange={() => setColumnsVisibility(prevState => !prevState)}
      content={renderColumns(
        onChange,
        checkedColumns,
        showUpdateButton,
        !userInfo.ow_tmp ? columnsData.slice(0, 5) : columnsData,
        onUpdate,
        setColumnsVisibility
      )}
    >
      <Button icon={<MenuOutlined rotate={90} />} className={styles.columnsBtn}>
        Columns
      </Button>
    </Popover>
  );
};
