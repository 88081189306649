import { useEffect, useState } from "react";

const getNestedHeadings = headingElements => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H5") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H6" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

export const useHeadingsData = isLoaded => {
  const [nestedHeadings, setNestedHeadings] = useState([]);

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("h5, h6"));
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, [isLoaded]);

  return { nestedHeadings };
};
