export const dailyDataSource = [
  {
    key: "1",
    name: "key",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Authorization key, required parameter to access Agency Daily Report
          API. Contain only digits and letters. You may obtain your
          authorization key from your personal manager.
        </p>
        <p>
          Example: <b>UQlXSEHTd2kWbVY3n0ETLx3v</b>
        </p>
      </>
    ),
  },
  {
    key: "2",
    name: "resType",
    req_opt: "Required",
    type: "String",
    description: (
      <p>
        This parameter is used to specify the response type. Available values
        are <b>table</b> (json) and <b>csv</b>.
      </p>
    ),
  },
  {
    key: "3",
    name: "columns",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Value is a string that can take multiple values separated by a comma.
          In this field you should pass <b>labels</b> that you expect in
          response. Predefined labels that can be used are:
        </p>

        <ul>
          <li>- date,</li>
          <li>- search_channel,</li>
          <li>- country,</li>
          <li>- status,</li>
          <li>- spent,</li>
          <li>- revenue,</li>
          <li>- profit,</li>
          <li>- gross profit,</li>
          <li>- impressions,</li>
          <li>- clicks,</li>
          <li>- clicks feed,</li>
          <li>- roi,</li>
          <li>- cpc,</li>
          <li>- cpm,</li>
          <li>- ctr,</li>
          <li>- rpc,</li>
          <li>- search cr </li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    name: "timeframe",
    req_opt: "Required",
    type: "String",
    description: (
      <div>
        <p>
          This parameter is used to specify the interval or frequency of data.
          It is always set to <b>daily</b>.
        </p>
      </div>
    ),
  },
  {
    key: "5",
    name: "date_from",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "6",
    name: "date_to",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "7",
    name: "limit",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Limit shows you the number of rows which will be shown in the API response. By default limit is 30.",
  },
  {
    key: "8",
    name: "offset",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Offset determines how many rows will be skipped before the data is displayed. Default is 0.",
  },
  {
    key: "9",
    name: "ordering",
    req_opt: "Optional",
    type: "String",
    description: (
      <div>
        <p>
          For ordering you can use one of the labels from the columns list. For
          descending just use column name, e.g. revenue, and for ascending use -
          symbol, e.g. -revenue.
        </p>
      </div>
    ),
  },
];

export const dailyColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Req/Opt",
    dataIndex: "req_opt",
    key: "req_opt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
];

export const dailyResponse = `{
  "total": [
      {
          "date": 25,
          "search_channel": "",
          "revenue": 325.65,
          "clicks_feed": 83005
      }
  ],
  "table": [
      {
          "date":
          "search_channel": "src_ES_2504",
          "revenue": 19.842,
          "clicks_feed": 344
      },
      {
          "date": 20210309,
          "search_channel": "src_US_2605",
          "revenue": 15.936,
          "clicks_feed": 684
      },
      {
          "date": 20210304,
          "search_channel": "src_CA_2570",
          "revenue": 12.78,
          "clicks_feed": 859
      },
      {
          "date": 20210319,
          "search_channel": "src_US_2525",
          "revenue": 10.956,
          "clicks_feed": 625
      },
      {
          "date": 20210304,
          "search_channel": "src_GB_2075",
          "revenue": 10.692,
          "clicks_feed": 107
      },
      {
          "date": 20210301,
          "search_channel": "src_RU_2525",
          "revenue": 10.452,
          "clicks_feed": 932
      },
      {
          "date": 20210325,
          "search_channel": "src_CA_2599",
          "revenue": 9.792,
          "clicks_feed": 127
      },
      {
          "date": 20210323,
          "search_channel": "src_ES_2572",
          "revenue": 9.324,
          "clicks_feed": 733
      },
      {
          "date": 20210303,
          "search_channel": "src_ES_2099",
          "revenue": 8.766,
          "clicks_feed": 845
      },
      {
          "date": 20210302,
          "search_channel": "src_ES_2098",
          "revenue": 7.956,
          "clicks_feed": 807
      },
      {
          "date": 20210310,
          "search_channel": "src_RU_2525",
          "revenue": 7.68,
          "clicks_feed": 1094
      },
      {
          "date": 20210312,
          "search_channel": "src_CA_2570",
          "revenue": 7.614,
          "clicks_feed": 532
      }
  ],
  "rows": 897
}`;
