import React, { useEffect, useState } from "react";
import { Modal, Spin, Table, Tooltip } from "antd";
import styles from "../CoinisCampaigns.module.css";
import ReactMarkdown from "react-markdown";
import { coinisCampaignsService } from "../../../services/coinisCampaigns";
import { BASE_URL } from "../../../config";

export const ModalPrice = ({ modalPriceVisible, setModalPriceVisible }) => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPrices = async id => {
      setLoading(true);
      try {
        const res = await coinisCampaignsService.getPricesForCampaign(id);
        setPrices(res?.data?.prices);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (modalPriceVisible.campaign_id) {
      getPrices(modalPriceVisible.campaign_id);
    }
    return () => {
      setPrices([]);
      setLoading(false);
    };
  }, [modalPriceVisible.campaign_id]);

  const columns = [
    {
      title: "Country",
      dataIndex: "country_details",
      key: "country_details",
      render: item => (
        <Tooltip title={item.name}>
          <span className={styles.countryDesc}>
            <img
              width="24px"
              alt={item.regex}
              style={{ marginRight: "0.5rem" }}
              src={`${BASE_URL}media/countries/${
                item.regex.toLowerCase() === ".*"
                  ? "0"
                  : item.regex.toLowerCase()
              }.png`}
            />
            {item.regex === ".*" ? "Any" : item.regex}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Device",
      dataIndex: "device_type_details",
      key: "device_type_details",
      render: item => item.name,
    },
    {
      title: "OS",
      dataIndex: "os_details",
      key: "os_details",
      render: item => item.name,
    },
    {
      title: "Browser",
      dataIndex: "browser_details",
      key: "browser_details",
      render: item => item.name,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: price =>
        "$ " +
        Number(price).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];
  return (
    <Modal
      title={modalPriceVisible.channel}
      className={styles.modalContainer}
      visible={modalPriceVisible}
      style={{ top: 40 }}
      width={700}
      onCancel={() => setModalPriceVisible(false)}
      footer={null}
    >
      <Spin spinning={loading}>
        {Array.isArray(prices) ? (
          <Table
            rowKey={({
              country_details,
              device_type_details,
              os_details,
              browser_details,
              price,
            }) =>
              country_details.regex +
              device_type_details.regex +
              os_details.regex +
              browser_details.regex +
              price
            }
            dataSource={prices}
            columns={columns}
            pagination={false}
          />
        ) : (
          <div>{prices && <ReactMarkdown children={prices} />}</div>
        )}
      </Spin>
    </Modal>
  );
};
