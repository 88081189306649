import React, { useEffect, useState } from "react";
import { publisherDashboardService } from "../../../../services/publisherDashboard";
import { abbrNum } from "../../../../utils/abbreviateNumber";
import { HeadCardInfo } from "./HeadCardInfo";
import styles from "./HeadInfo.module.css";

export const HeadInfo = () => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  useEffect(() => {
    const fetchCardInfo = async () => {
      setLoading(true);
      try {
        const [dataChart, data] = await Promise.all([
          publisherDashboardService.getTopCardInfoChart(),
          publisherDashboardService.getTopCardInfo(),
        ]);
        setCardData(dataChart.values);
        setDataInfo(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchCardInfo();
  }, []);
  return (
    <div className={styles.headInfoContainer}>
      <HeadCardInfo
        chartData={cardData}
        dataInfo={dataInfo.total && abbrNum(dataInfo.total[0].impressions, 2)}
        loading={loading}
        type="1"
        title="Visits"
        color="#c2d791"
      />
      {/* <HeadCardInfo
        chartData={cardData}
        loading={loading}
        dataInfo={dataInfo.total && abbrNum(dataInfo.total[0].leads, 2)}
        title="Leads"
        type="2"
        color="#2e71b7"
      /> */}
      <HeadCardInfo
        chartData={cardData}
        loading={loading}
        dataInfo={dataInfo.total && abbrNum(dataInfo.total[0].clicks, 2)}
        title="Clicks"
        type="4"
        color="#f16f4d"
      />
      <HeadCardInfo
        chartData={cardData}
        loading={loading}
        dataInfo={dataInfo.total && "$ " + Number(dataInfo.total[0].revenue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        type="3"
        title="Revenue"
        color="#f7ae3d"
      />
    </div>
  );
};
