import { Pagination, Table } from "antd";
import React, { useState, useCallback, useEffect } from "react";

import { useFetch } from "../../utils/hooks/useFetch";
import { Filters } from "./components/Filters";
import { TableTitle } from "./components/TableTitle";
// import { NestedTable } from "./components/NestedTable";
import { publisherReportsService } from "../../services/publisherReports";
import styles from "./Reports.module.css";
import moment from "moment";
import { columnTemplate } from "./utils/columnsList";
import { openWarningNotification } from "../../utils/notifications";
import { numberWithSpaces } from "../../utils/abbreviateNumber";
import { useQuery } from "../../utils/hooks/useQuery";

export const PublisherReports = () => {
  const [columnsData] = useState(columnTemplate);
  const [tableColumns, setTableColumns] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const pageSizeOptions = [10, 20, 30, 50, 100];

  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [sorting, setSorting] = useState({
    revenue: "descend",
  });

  const query = useQuery();

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      if (sumOfData[0]) {
        let sumOfDataRefactored = { ...sumOfData[0] };
        if (sumOfData[0].revenue) {
          sumOfDataRefactored.revenue =
            "$ " + Number(sumOfData[0].revenue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        if (sumOfData[0].rpl) {
          sumOfDataRefactored.rpl =
            "$ " + Number(sumOfData[0].rpl).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        if (sumOfData[0].rpm) {
          sumOfDataRefactored.rpm =
            "$ " + Number(sumOfData[0].rpm).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        if (sumOfData[0].cr) {
          sumOfDataRefactored.cr =
            parseFloat(sumOfData[0].cr).toFixed(2) + " %";
        }
        if (sumOfData[0].cpm) {
          sumOfDataRefactored.cpm =
            "$ " + Number(sumOfData[0].cpm).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        if (sumOfData[0].ecpa) {
          sumOfDataRefactored.ecpa =
            "$ " + Number(sumOfData[0].ecpa).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return sumOfDataRefactored;
      }
    }
  };

  const refactorTableData = () => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          refactor.revenue =
            refactor.revenue && "$ " + Number(refactor.revenue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          refactor.cpm = refactor.cpm && "$ " + Number(refactor.cpm).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          refactor.fill_rate =
            refactor.fill_rate && refactor.fill_rate.toFixed(2);
          refactor.cr = refactor.cr && refactor.cr.toFixed(2) + " %";
          refactor.roi = refactor.roi && refactor.roi.toFixed(2) + " %";
          refactor.rpl = refactor.rpl && "$ " + Number(refactor.rpl).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          refactor.rpm = refactor.rpm && "$ " + Number(refactor.rpm).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          refactor.date = moment(refactor.date, "YYYYMMDD").format(
            "YYYY-MM-DD"
          );
          refactor.hour = moment(refactor.hour, "H").format("HH:mm");
          refactor.impressions = numberWithSpaces(refactor.impressions);
          refactor.fill = numberWithSpaces(refactor.fill);
          refactor.requests = numberWithSpaces(refactor.requests);
          refactor.clicks = numberWithSpaces(refactor.clicks);
          return {
            key: index,
            ...refactor,
          };
        })
      : "";
    return tableDataRefactored;
  };

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting]
  );
  const updateColumns = useCallback(
    value => {
      setTableColumns(() => {
        let newTableColumn = [];
        columnTemplate
          .filter(column => value.checkedColumns[column["value"]])
          .forEach(function ({ text, value, tooltip, render }) {
            newTableColumn.push({
              title: (
                <TableTitle
                  text={text}
                  order={sorting[value] ? sorting[value] : false}
                  value={value}
                  tooltip={tooltip}
                  onSortingClick={handleSortingClick}
                />
              ),
              dataIndex: value,
              render,
            });
          });
        return newTableColumn;
      });
    },
    [sorting, handleSortingClick]
  );

  const loadZoneData = cancelToken => {
    return publisherReportsService.getStatsPaged({
      selectedFilters,
      currentPage,
      pageSize,
      sorting,
      cancelToken,
      action: false,
    });
  };

  const [
    {
      data: { total: sumOfData, table: tableData, rows: totalItems },
      isFetching,
    },
    getZoneData,
  ] = useFetch(loadZoneData);
  const loading = isFetching;

  useEffect(() => setCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getZoneData();
    }
  }, [sorting, pageSize, selectedFilters, getZoneData, currentPage]);

  useEffect(() => {
    const selectedColumns = tableColumns.map(item => item.dataIndex);
    if (
      selectedColumns.includes("date") && 
      selectedColumns.includes("revenue")
    ) {
      setSorting({ date: "descend", revenue: "descend" });
    } else {
      (selectedColumns.includes("date")) ? setSorting({ date: "descend" }) : setSorting({ revenue: "descend" });
    }
  }, [tableColumns]);

  const handleExport = () => {
    if (selectedFilters.filters) {
      publisherReportsService.exportReports({
        selectedFilters,
        currentPage,
        pageSize,
        sorting,
        action: false,
      });
    } else {
      openWarningNotification({
        message: "Please update filters before trying to export table.",
      });
    }
  };
  return (
    <div>
      <h2 className={styles.pageTitle}>Statistics</h2>
      <Filters
        zone_id={Number(query.get("id"))}
        zone_title={query.get("title")}
        handleExport={handleExport}
        onSubmit={value => {
          updateColumns(value);
          setSelectedFilters(value);
        }}
        allColumns={columnsData}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setCurrentPage={setCurrentPage}
      />
      <Table
        className={styles.mainTableContainer}
        style={{ marginTop: "2%" }}
        columns={tableColumns}
        rowKey={({ key }) => key}
        dataSource={refactorTableData()}
        loading={loading}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
        pagination={false}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={value => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
              showTotal={(total) => {
                if(total) return <div>Showing {(currentPage-1)*pageSize + 1} to {(total < currentPage * pageSize) ? total : currentPage * pageSize} of {total} entries.</div>;
              }}  
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor();
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {numberWithSpaces(sumOfDataRefactored[el.dataIndex])}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
      />
    </div>
  );
};
