export const MACROS = [
  {
    value: "{cid}",
    text: "cid",
  },
  {
    value: "{payout}",
    text: "payout",
  },
];
