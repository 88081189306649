import React, { useState, useEffect, useContext } from "react";
import styles from "./BillingUploadInvoice.module.css";
import moment from "moment";
import momenttz from "moment-timezone";

import { billingUploadInvoiceService } from "../../services/billingUploadInvoice";
import UserContext from "../../contexts/userContext";

import { CustomLoadingSpinner } from "./components/CustomLoadingSpinner";
import {
  DatePicker,
  Radio,
  Form,
  Spin,
  Button,
  Row,
  Col,
  Input,
  Upload,
  Alert,
  message,
} from "antd";
import { openErrorNotification } from "../../utils/notifications";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

export const BillingUploadInvoice = () => {
  const { userInfo, loading } = useContext(UserContext);
  const [form] = Form.useForm();

  const [selectedModule, setSelectedModule] = useState("");

  const currentDate = momenttz();
  const userOffsetMinutes = momenttz().utcOffset();
  const enableTime =
    userOffsetMinutes > 0
      ? currentDate.clone().subtract(userOffsetMinutes, "minutes")
      : currentDate;

  // console.log('currentDate', currentDate)
  // console.log('userOffsetMinutes', userOffsetMinutes)
  // console.log('enableTime', enableTime)

  const [selectedMonthYear, setSelectedMonthYear] = useState(
    enableTime.isSame(currentDate, "month")
      ? moment().subtract(1, "months")
      : moment().subtract(2, "months")
  );

  const disabledDate = (current) => {
    return (
      current &&
      (enableTime.isSame(currentDate, "month")
        ? current > momenttz().subtract(1, "months")
        : current > enableTime.subtract(1, "months"))
    );
  };
  const customFormat = (value) => {
    return value && `${value.format("YYYY-MMM")}`;
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const [dropdownFormVisible, setDropdownFormVisible] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [filesList, setFilesList] = useState([]);
  const [fileSizeLimitExceedStatus, setFileSizeLimitExceedStatus] =
    useState(false);

  const [invoiceDate, setInvoiceDate] = useState(moment().format("YYYY-MM-DD"));
  const [invoiceFile, setInvoiceFile] = useState();
  const [currency, setCurrency] = useState("");

  const showUploadList = {
    showRemoveIcon: true,
  };

  const handleFileUpload = (infor) => {
    setLoadingForm(false);
    setInvoiceFile(infor.file);
    setFilesList([...infor.fileList]);
    if (infor.file.status === "removed" || infor.file.status === "uploading") {
      if (infor.file.status === "uploading") {
        setFilesList([]);
      }
      setInvoiceFile();
    } else {
      setFilesList([
        {
          uid: infor.file.uid,
          name: infor.file.name,
          status: "done",
          url: "",
        },
      ]);
      setInvoiceFile(infor.file);
    }
  };

  const handleSubmit = async (values) => {
    setLoadingForm(true);
    const formData = new FormData();
    formData.append("module", selectedModule);
    formData.append("invoice_number", values.invoiceNumber);
    formData.append("invoice_date", moment(invoiceDate).format("YYYY-MM-DD"));
    formData.append("month", selectedMonthYear.format("M"));
    formData.append("year", selectedMonthYear.format("YYYY"));
    formData.append("amount", values.amount);
    formData.append("currency", currency);
    formData.append("payment_method", values.paymentMethod.toLowerCase());
    formData.append("note", values.note ? values.note : "");
    formData.append("file", invoiceFile);
    if (!invoiceFile) {
      openErrorNotification({
        message: "You didn't upload any file. Please upload your invoice!",
      });
      setLoadingForm(false);
    } else {
      try {
        const res = await billingUploadInvoiceService.uploadInvoiceForm(
          formData
        );
        if (res.success) {
          setUserMessage(res.message);
          setDropdownFormVisible(false);
        } else {
          openErrorNotification({ message: res.message });
        }
      } catch (error) {
        openErrorNotification({
          message: "Failed to upload invoice, please contact support!",
        });
      } finally {
        setLoadingForm(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (selectedModule) {
        setFilesList([]);
        setInvoiceFile();
        form.resetFields();
        if (!selectedMonthYear) {
          openErrorNotification({
            message: "Please select month and year for uploading invoice!",
          });
          setFilesList([]);
          setInvoiceFile();
          form.resetFields();
          setUserMessage("");
          setDropdownFormVisible(false);
        } else {
          setLoadingForm(true);
          try {
            const res = await billingUploadInvoiceService.checkUserInvoice({
              module: selectedModule,
              month: selectedMonthYear.format("M"),
              year: selectedMonthYear.format("YYYY"),
            });
            res.success && setIsSuccessful(res.success);
            if (res.success) {
              form.setFieldsValue({
                ...res,
                invoiceDate: moment(res.invoiceDate),
                description: res.description,
                amount: res.amount,
                paymentMethod: res.paymentMethod,
                paymentMethodDetails: res.paymentMethodDetails,
              });
              setCurrency(res.currency);
              setDropdownFormVisible(true);
            } else {
              setDropdownFormVisible(false);
            }
            if (res.message) {
              setUserMessage(res.message);
            }
          } catch (e) {
            openErrorNotification({ message: e.message });
          } finally {
            setLoadingForm(false);
          }
        }
      }
    })();
  }, [selectedMonthYear, selectedModule, form]);

  useEffect(() => {
    if (fileSizeLimitExceedStatus) {
      setFilesList();
      setFileSizeLimitExceedStatus(false);
    }
  }, [fileSizeLimitExceedStatus, setFilesList, setFileSizeLimitExceedStatus]);

  return (
    <div className={styles.mainContainer}>
      <h4>Upload Invoice</h4>
      <p style={{ marginBottom: "16px" }}>
        Please select the month period of service and module you want to upload
        invoice for.
      </p>
      <div>
        <div className={styles.datePickerRow}>
          <DatePicker
            className={styles.datePicker}
            defaultValue={selectedMonthYear}
            disabledDate={disabledDate}
            format={customFormat}
            picker="month"
            onChange={(value) => {
              setSelectedMonthYear(value);
            }}
          />
          <p className={styles.italicText}>
            <ExclamationCircleOutlined
              style={{ marginLeft: 16, marginRight: 5 }}
            />
            Each month becomes selectable after 12:00 AM UTC+0 on the first day
            of the following month
          </p>
        </div>
        <div className={styles.modulesWrapper}>
          <Radio.Group
            size="large"
            disabled={loading}
            onChange={(e) => setSelectedModule(e.target.value)}
          >
            {userInfo.permissions && userInfo.permissions.offerwall && (
              <Radio.Button value="offerwall">Affiliate Programs</Radio.Button>
            )}
            {userInfo.permissions && userInfo.permissions.publisher && (
              <Radio.Button value="publisher">Webmasters</Radio.Button>
            )}
            {userInfo.permissions && userInfo.permissions.search.extension && (
              <Radio.Button value="search">
                Search Feed - Search Type-In
              </Radio.Button>
            )}
            {userInfo.permissions && userInfo.permissions.search.n2s && (
              <Radio.Button value="n2s">
                Search Feed - Native to Search
              </Radio.Button>
            )}
            {userInfo.permissions && userInfo.permissions.social_to_search && (
              <Radio.Button value="social_to_search">Agency</Radio.Button>
            )}
          </Radio.Group>
        </div>
        <Spin
          spinning={loadingForm}
          indicator={<CustomLoadingSpinner pausedAnim={loadingForm} />}
        >
          <div className={styles.uploadInvoiceFormContainer}>
            <Row
              hidden={!userMessage}
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              justify="start"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <Col span={12}>
                <Alert
                  message={userMessage}
                  type={
                    userMessage && !dropdownFormVisible && isSuccessful
                      ? "success"
                      : "info"
                  }
                />
              </Col>
            </Row>
            <Form
              form={form}
              layout="vertical"
              hidden={!dropdownFormVisible}
              onFinish={handleSubmit}
            >
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={6}>
                  <div style={{ width: "100%" }}>
                    <Form.Item name="invoiceDate" label="Invoice date">
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        initialValue={invoiceDate}
                        onChange={(value) => {
                          setInvoiceDate(value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <Input
                        maxLength={120}
                        min={0}
                        className={styles.uploadInvoiceInputField}
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={6}>
                  <div>
                    <Form.Item
                      name="invoiceNumber"
                      label="Invoice number"
                      rules={[
                        {
                          required: true,
                          message: "Please input invoice number!",
                        },
                      ]}
                    >
                      <Input
                        maxLength={120}
                        min={0}
                        className={styles.uploadInvoiceInputField}
                      />
                    </Form.Item>
                    <Form.Item name="amount" label="Amount">
                      <Input
                        maxLength={120}
                        min={0}
                        className={styles.uploadInvoiceInputField}
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={6}>
                  <Form.Item name="paymentMethod" label="Payment method">
                    <Input
                      maxLength={120}
                      min={0}
                      className={styles.uploadInvoiceInputField}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div>
                    <Form.Item
                      name="paymentMethodDetails"
                      label="Payment method details"
                    >
                      <Input.TextArea
                        disabled={true}
                        autoSize={{
                          minRows: 2,
                          maxRows: 6,
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    label="Upload File"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Dragger
                      accept=".pdf"
                      multiple={false}
                      maxCount={1}
                      beforeUpload={(file) => {
                        const isFileSizeOk = file.size / 1024 / 1024 < 5;
                        if (!isFileSizeOk) {
                          message.error(
                            "File size exceeds the limit. It must smaller than 5MB!"
                          );
                          setFileSizeLimitExceedStatus(true);
                        }
                        return !isFileSizeOk;
                      }}
                      onChange={(value) => handleFileUpload(value)}
                      customRequest={() => false}
                      fileList={filesList}
                      showUploadList={showUploadList}
                    >
                      <p
                        style={{
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        Drag invoice here or browse through your device.
                      </p>
                      <Button style={{ marginTop: "15px" }}>
                        Upload invoice
                      </Button>
                      <p style={{ marginTop: "10px" }}>
                        <i>
                          Maximum file size is <b>5 MB</b>.
                        </i>
                      </p>
                      <p style={{ marginTop: "5px" }}>
                        <i>
                          Supported file format is <b>.pdf</b>.
                        </i>
                      </p>
                    </Dragger>
                    <p
                      style={{
                        marginTop: "10px",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                      }}
                    >
                      In case you don’t have your own invoice, you can download
                      invoice template{" "}
                      <a href="/media/templates/invoice_template.pdf">here</a>.
                    </p>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                  <Form.Item label="Note">
                    <Form.Item name="note" noStyle>
                      <Input.TextArea placeholder="Enter your note here" />
                    </Form.Item>
                    <span>
                      <i style={{ color: "red" }}>Careful</i> Once you create an
                      invoice, you won’t be able to edit it again.
                    </span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} offset={9}>
                  <Button type="primary" htmlType="submit">
                    Save & Upload
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Spin>
      </div>
    </div>
  );
};
