import { Button, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { openWarningNotification } from "../../../utils/notifications";
import styles from "../Dashboard.module.css";

export const RequestFeed = ({ setFeedFormVisible, searchPermissions, feedStatus }) => {
  const [currentFeedType, setCurrentFeedType] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (!(searchPermissions.n2s && searchPermissions.extension)) {
      if (searchPermissions.n2s) {
        setCurrentFeedType("n2s");
        setButtonDisabled(feedStatus.n2s !== 'allow');
      } else {
        setCurrentFeedType("extension");
        setButtonDisabled(feedStatus.extension !== 'allow');
      }
    }
    else {
      setButtonDisabled(feedStatus.extension !== 'allow' && feedStatus.n2s !== 'allow')
    }
  }, [searchPermissions, feedStatus]);

  return (
    <div className={styles.requestFeedContainer}>
      {searchPermissions.n2s && searchPermissions.extension ? (
        <div className={styles.requestFeedRadioGroup}>
          <div
            className={
              `${styles.requestFeedRadioBox} 
              ${currentFeedType === "extension" && styles.requestFeedRadioBoxSelected} 
              ${(feedStatus.extension === 'approved' || feedStatus.extension === 'rejected') && styles.disabledBackground}
              ${(feedStatus.extension === 'pending') && styles.pendingBackground}`
            }
            onClick={() => feedStatus.extension === 'allow' ? setCurrentFeedType("extension") : undefined}
          >
            <Radio checked={currentFeedType === "extension" && feedStatus.extension === 'allow'} disabled={!(feedStatus.extension === 'allow')} />
            <div className={styles.accessFeedContent}>
              <p className={feedStatus.extension === 'pending' && styles.pendingColor}>Search</p>
              {
                feedStatus.extension === 'pending' ?
                  <p>Pending access for search feed</p> :
                  <p>Request access to search feed</p>
              }
            </div>
          </div>
          <div
            className={
              `${styles.requestFeedRadioBox} 
              ${currentFeedType === "n2s" && styles.requestFeedRadioBoxSelected} 
              ${(feedStatus.n2s === 'approved' || feedStatus.n2s === 'rejected') && styles.disabledBackground}
              ${(feedStatus.n2s === 'pending') && styles.pendingBackground}`
            }
            onClick={() => feedStatus.n2s === 'allow' ? setCurrentFeedType("n2s") : undefined}
          >
            <Radio checked={currentFeedType === "n2s" && feedStatus.n2s === 'allow'} disabled={!(feedStatus.n2s === 'allow')} />
            <div className={styles.accessFeedContent}>
              <p className={feedStatus.n2s === 'pending' && styles.pendingColor}>N2S</p>
              {
                feedStatus.n2s === 'pending' ?
                  <p>Pending access for N2S feed</p> :
                  <p>Request access to N2S feed</p>
              }
            </div>
          </div>
        </div>
      ) : null}
      <Button
        size="large"
        className={styles.buttonRequestFeed}
        type="primary"
        disabled={buttonDisabled}
        onClick={() =>
          currentFeedType
            ? setFeedFormVisible(currentFeedType)
            : openWarningNotification({ message: "Please select feed type." })
        }
      >
        <p>Request feed</p>
      </Button>
    </div>
  );
};
