import { callApi } from "./api";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ userNotificationPage, userNotificationPageSize }) => ({
  limit: userNotificationPageSize,
  offset: (userNotificationPage - 1) * userNotificationPageSize,
});

class UserNotificationsService {

  async getUserNotifications(modules, userNotificationPage, userNotificationPageSize, showUnread) {
    const query = getQueryString({
      module: modules && modules.join(","),
      ...getOffsetLimitFromPagination({ userNotificationPage, userNotificationPageSize }),
      unread: showUnread && showUnread,
    });
    return await callApi({
      url: `/api/notifications/${query}`,
    });
  }

  async readUserNotification(notificationId) {
    const formdata = new FormData();
    formdata.append("notification_id", notificationId);
    return await callApi({
      url: `/api/notifications/mark_read/`,
      method: "POST",
      data: formdata,
    });
  }

  async unreadUserNotification(notificationId) {
    const formdata = new FormData();
    formdata.append("notification_id", notificationId);
    return await callApi({
      url: `/api/notifications/mark_unread/`,
      method: "POST",
      data: formdata,
    });
  }
}

export const userNotificationsService = new UserNotificationsService();
