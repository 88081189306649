import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import styles from "../CoinisCampaigns.module.css";
import { openSuccessNotification } from "../../../utils/notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { coinisCampaignsService } from "../../../services/coinisCampaigns";

export const ModalLink = ({ modalLinkVisible, setModalLinkVisible }) => {
  const [links, setLinks] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPrices = async id => {
      setLoading(true);
      try {
        const res = await coinisCampaignsService.getLinksForCampaign(id);
        setLinks(res?.data?.links);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (modalLinkVisible.campaign_id) {
      getPrices(modalLinkVisible.campaign_id);
    }
    return () => {
      setLinks([]);
      setLoading(false);
    };
  }, [modalLinkVisible.campaign_id]);

  return (
    <Modal
      title={modalLinkVisible.channel}
      className={styles.modalContainer}
      visible={modalLinkVisible}
      style={{ top: 40 }}
      width={700}
      onCancel={() => setModalLinkVisible(false)}
      footer={null}
    >
      <Spin spinning={loading}>
        <Input value={links} />
        <div className={styles.actionGroupLink}>
          <CopyToClipboard text={links}>
            <Button
              icon={<CopyOutlined />}
              className={styles.copyLinkBtn}
              onClick={() => openSuccessNotification({ message: "Copied!" })}
            >
              Copy
            </Button>
          </CopyToClipboard>
        </div>
      </Spin>
    </Modal>
  );
};
