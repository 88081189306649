import { callApi } from "./api";
import moment from "moment";

class SearchGraphServiceBi {
  async getSearchStats(dateRange) {
    if (!dateRange) return null;
    return await callApi({
      url: `/api/search/advertiser-stats/?resType=chart&columns=date,monetized_searches,revenue,total_searches,clicks&date_from=${moment(
        dateRange.from
      ).format("YYYY-MM-DD")}&date_to=${moment(dateRange.to).format(
        "YYYY-MM-DD"
      )}&offset=0&limit=10&ordering=date`,
      method: "GET",
    });
  }
}

export const searchGraphServiceBi = new SearchGraphServiceBi();
