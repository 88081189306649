import React, { useState, useContext, useEffect } from "react";
import { List, Radio, Button, Modal } from "antd";

import NotificationContext from "../../../../contexts/notificationContext";
import ShowMoreText from "react-show-more-text";

import moment from "moment";
import ReactMarkdown from "react-markdown";

import { userNotificationsService } from "../../../../services/userNotifications";
import styles from "../BarNotification.module.css";
import { BASE_URL } from "../../../../config";

export const BarNotificationItem = React.forwardRef(
  ({ item, showUnread }, ref) => {
    const { unreadCount, setUnreadCount } = useContext(NotificationContext);

    const [wasModalOpen, setWasModalOpen] = useState(false);
    const [read, setRead] = useState(false);

    const LinkRenderer = props => {
      return (
        <a target="_blank" href={props.href} rel="noreferrer">
          {props.children}
        </a>
      );
    };

    useEffect(() => {
      setRead(!item.read);
    }, [item]);

    const readNotification = async notification_id => {
      setUnreadCount(unreadCount - 1);
      setRead(false);
      try {
        await userNotificationsService.readUserNotification(notification_id);
      } catch (e) {
        console.log(e);
      } finally {
      }
    };

    const unreadNotification = async notification_id => {
      setUnreadCount(unreadCount + 1);
      setRead(true);
      try {
        await userNotificationsService.unreadUserNotification(notification_id);
      } catch (e) {
        console.log(e);
      } finally {
      }
    };

    const handleSeeMore = e => {
      setWasModalOpen(wasModalOpen => !wasModalOpen);
    };

    const handleReadRadio = (e, id) => {
      e.stopPropagation();
      readNotification(id);
    };

    return (
      <>
        {showUnread === "True" ? (
          read && (
            <List.Item
              ref={ref}
              className={read ? styles.unReadlistItem : ""}
              onClick={() => {
                read && readNotification(item.id);
              }}
            >
              <div style={{ width: "500px" }}>
                <div className={styles.notificationTitle}>
                  <div>
                    {item.icon !== "" ? (
                      <img
                        className={styles.notificationIcon}
                        src={BASE_URL + item.icon}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <h3>{item.title}</h3>
                  </div>
                  <Radio
                    checked={read}
                    onClick={e => {
                      read
                        ? handleReadRadio(e, item.id)
                        : unreadNotification(item.id);
                    }}
                  />
                </div>
                <ShowMoreText
                  lines={3}
                  more="See more"
                  less={null}
                  style={{ maxHeight: 67 }}
                  className={styles.notificationDescription}
                  anchorClass="show-more-less-clickable"
                  onClick={() => handleSeeMore()}
                  expanded={false}
                  expandByClick={false}
                  truncatedEndingComponent={"..."}
                >
                  <ReactMarkdown
                    className={styles.barNotification}
                    components={{ a: LinkRenderer }}
                  >
                    {item.description}
                  </ReactMarkdown>
                </ShowMoreText>
                <div className={styles.notificationDate}>
                  {moment(item.date_created).format("D MMM YYYY, HH:MMA")}
                </div>
              </div>
            </List.Item>
          )
        ) : (
          <List.Item
            ref={ref}
            className={read ? styles.unReadlistItem : ""}
            onClick={() => {
              read && readNotification(item.id);
            }}
          >
            <div style={{ width: "500px" }}>
              <div className={styles.notificationTitle}>
                <div>
                  {item.icon !== "" ? (
                    <img
                      className={styles.notificationIcon}
                      src={BASE_URL + item.icon}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  <h3>{item.title}</h3>
                </div>
                <Radio
                  checked={read}
                  onClick={e => {
                    read
                      ? handleReadRadio(e, item.id)
                      : unreadNotification(item.id);
                  }}
                />
              </div>
              <ShowMoreText
                lines={3}
                more="See more"
                less={null}
                style={{ maxHeight: 67 }}
                className={styles.notificationDescription}
                anchorClass="show-more-less-clickable"
                onClick={() => handleSeeMore()}
                expanded={false}
                expandByClick={false}
                truncatedEndingComponent={"..."}
              >
                <ReactMarkdown
                  className={styles.barNotification}
                  components={{ a: LinkRenderer }}
                >
                  {item.description}
                </ReactMarkdown>
              </ShowMoreText>
              <div className={styles.notificationDate}>
                {moment(item.date_created).format("D MMM YYYY, HH:MMA")}
              </div>
            </div>
          </List.Item>
        )}
        <Modal
          className={styles.barNotificationItemModal}
          title={
            <div className={styles.barNotificationItemModalTitle}>
              {item.icon && (
                <img
                  className={styles.barNotificationItemModalIcon}
                  src={BASE_URL + item.icon}
                  alt=""
                />
              )}
              <h3>{item.title}</h3>
            </div>
          }
          closable={false}
          visible={wasModalOpen}
          onOk={false}
          onCancel={() => setWasModalOpen(wasModalOpen => !wasModalOpen)}
          zIndex={1200}
          footer={
            <Button
              style={{ width: "5.875rem" }}
              key={item.id}
              onClick={() => setWasModalOpen(wasModalOpen => !wasModalOpen)}
            >
              OK
            </Button>
          }
        >
          <ReactMarkdown
            className={styles.barNotification}
            components={{ a: LinkRenderer }}
          >
            {item.description}
          </ReactMarkdown>
        </Modal>
      </>
    );
  }
);
