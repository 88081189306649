import { Tag, Alert } from "antd";
import React from "react";
import { auth } from "../../../services/auth";
import styles from "../Profile.module.css";

export const MyModules = () => {
  return (
    <div className={styles.modulesContainer}>
      <Alert
        message="In order to add new modules to our platform, please contact support team for assistance. We look forward to helping you grow your business with us."
        type="info"
      ></Alert>
      <div className={styles.moduleContainer}>
        <div>
          <p className={styles.titleWithTag}>
            Affiliate Programs{" "}
            {auth.hasPermission("offerwall") ||
            auth.hasPermission("affiliate_programs") ? (
              <Tag color="green">ACTIVE MODULE</Tag>
            ) : (
              <Tag>CONTACT SUPPORT TO ADD THIS MODULE</Tag>
            )}
          </p>
          <p>
            Have own traffic or buy it from networks and need great offers with
            high rates to run? Be our guest! Start earning today with us. Top
            verticals with the highest margins with the secret sauce of our
            expertise in the market - a recipe for success!
          </p>
        </div>
      </div>
      {auth.hasPermission("publisher") && (
        <div className={styles.moduleContainer}>
          <div>
            <p className={styles.titleWithTag}>
              Webmasters{" "}
              {auth.hasPermission("publisher") ? (
                <Tag color="green">ACTIVE MODULE</Tag>
              ) : (
                <Tag>CONTACT SUPPORT TO ADD THIS MODULE</Tag>
              )}
            </p>
            <p>
              You have a website that you’d like to monetize? Excellent! Our
              Webmasters module offers the most traditional and reliable
              approach to advertising. Use the well-known and trendy formats to
              place the best ads for your audience and start earning in no time.
            </p>
          </div>
        </div>
      )}
      <div className={styles.searchModuleContainer}>
        <p>Search Feed</p>
        <div className={styles.moduleContainerSubmodule}>
          <div>
            <p className={styles.titleWithTag}>
              Search Type-In{" "}
              {auth.hasSearchPermission("extension") ? (
                <Tag color="green">ACTIVE MODULE</Tag>
              ) : (
                <Tag>CONTACT SUPPORT TO ADD THIS MODULE</Tag>
              )}
            </p>
            <p>
              Do you have an extension? Are you a publisher whose profit leans
              on search monetization? Either way, you’re a couple of clicks away
              from a module made for you! Track your progress with our accurate
              statistic dashboard, allowing you to monetize your traffic.
            </p>
          </div>
        </div>
        <div className={styles.moduleContainerSubmodule}>
          <div>
            <p className={styles.titleWithTag}>
              Native to Search{" "}
              {auth.hasSearchPermission("n2s") ? (
                <Tag color="green">ACTIVE MODULE</Tag>
              ) : (
                <Tag>CONTACT SUPPORT TO ADD THIS MODULE</Tag>
              )}
            </p>
            <p>
              Are you a publisher whose ad space accommodates seamless native
              ads? Proceed to our native to search module and don’t miss a thing
              that influences your campaign performance! Our all-in-one
              dashboard is seamlessly integrated with your statistics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
