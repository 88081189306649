import React, { useEffect, useState } from "react";
import { abbrNum } from "../../../../utils/abbreviateNumber";
import { HeadCardInfo } from "./HeadCardInfo";
import { HeadCardMissingDates } from "./HeadCardMissingDates";
import styles from "./HeadInfo.module.css";
import { searchDashboardServiceBi } from "../../../../services/searchDashboardBi";

export const HeadInfo = ({
  missingDates,
  missingDatesLoading,
  searchPermissions,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);

  useEffect(() => {
    const fetchCardInfo = async () => {
      setLoading(true);
      try {
        const [dataChart, data] = await Promise.all([
          searchDashboardServiceBi.getTopCardInfoChart(
            searchPermissions.extension
          ),
          searchDashboardServiceBi.getTopCardInfo(searchPermissions.extension),
        ]);
        setCardData(dataChart.values);
        setDataInfo(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchCardInfo();
  }, [searchPermissions]);
  return (
    <div className={styles.headInfoContainer}>
      {searchPermissions.extension && (
        <>
          <HeadCardInfo
            chartData={cardData}
            dataInfo={
              dataInfo.total && abbrNum(dataInfo.total[0].monetized_searches, 2)
            }
            loading={loading}
            type="1"
            title="Monetized Searches - Search Type-In"
            color="#c2d791"
          />
          <HeadCardInfo
            chartData={cardData}
            loading={loading}
            dataInfo={
              dataInfo.total && "$ " + abbrNum(dataInfo.total[0].revenue, 2)
            }
            type="2"
            title="Revenue - Search Type-In"
            color="#f7ae3d"
          />
        </>
      )}

      {!searchPermissions.extension && (
        <HeadCardMissingDates
          missingDates={missingDates}
          missingDatesLoading={missingDatesLoading}
        />
      )}
    </div>
  );
};
