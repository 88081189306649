import React from "react";
import styles from "./TableTitle.module.css";

export const TableTitle = ({ text }) => {

  return (
    <div className={styles.tableTitle}>
      <span className={styles.title}>{text}</span>
    </div>
  );
};
