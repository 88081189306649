import { Table } from "antd";
import React from "react";
import styles from "../Dashboard.module.css";

const columns = [
  {
    title: "CHANNEL",
    dataIndex: "channel",
  },
  {
    title: "Leads",
    dataIndex: "leads",
    align: "center",
  },
  {
    title: "VISITS",
    dataIndex: "im",
    align: "center",
  },
  {
    title: "REVENUE",
    dataIndex: "revenue",
    align: "center",
  },
];
const refactorTableData = tableData => {
  const tableDataRefactored = tableData
    ? tableData.map((el, index) => {
        const refactor = { ...el };
        refactor.revenue =
          refactor.revenue && "$ " + Number(refactor.revenue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return {
          key: index,
          ...refactor,
        };
      })
    : "";
  return tableDataRefactored;
};

export const TopChannelsTable = ({ loading, tableData }) => {
  return (
    <div className={styles.channelsTableContainer}>
      <h4>Top 10 Channels</h4>
      <Table
        loading={loading}
        columns={columns}
        dataSource={refactorTableData(tableData)}
        pagination={false}
        rowKey={({ campaign_id }) => campaign_id}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </div>
  );
};
