import React, { useEffect, useState } from "react";
import styles from "./AccessModal.module.css";
import { Form, Input, Radio } from "antd";
import ReactMarkdown from "react-markdown";
import { renderStatusIcon } from "../../pages/CoinisProgramDetails/utils";
import { coinisProgramsService } from "../../services/coinisPrograms";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../utils/notifications";
import { TableOfContents } from "./TableOfContents";
import { TermsOfUseImages } from "./TermsOfUseImages";

export const TermsOfUse = ({
  setLoadingModal,
  isModalVisible,
  isRestricted,
  programDetails,
  requestAccessForm,
  setIsModalVisible,
  setTriggerGridRefresh,
  setTriggerNewlyCreated,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [imagesObject, setImagesObject] = useState({
    type: "Images",
    images: [],
    question: "",
  });
  const handleRequestAccess = async (values) => {
    setLoadingModal(true);
    const answers = [
      ...Object.entries(values),
      [imagesObject.question, imagesObject.images.join(","), imagesObject.type],
    ];
    try {
      const res = await coinisProgramsService.requestApproval({
        answers,
        program_id: programDetails.id,
      });
      if (res.success) {
        if (setTriggerGridRefresh) {
          setTriggerGridRefresh((prevState) => !prevState);
        } else if (setTriggerNewlyCreated) {
          setTriggerNewlyCreated((prevState) => !prevState);
        }
        openSuccessNotification({ message: res.message });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch {
    } finally {
      setLoadingModal(false);
      setIsModalVisible({ type: null, id: null });
    }
  };

  useEffect(() => {
    setIsLoaded((prev) => !prev);
  }, [programDetails]);

  return (
    <div className={styles.accessModalContainer}>
      <div className={styles.accessModalSidemap}>
        <p>
          Make sure to read affiliate program rules carefully. In case of rule
          violation, your ad space may be disconnected from out affiliate
          program.
        </p>
        <TableOfContents isLoaded={isLoaded} />
      </div>
      <div className={styles.accessModalContent}>
        <div>
          <h5 id="terms-of-use">Terms of Use</h5>
          <div className={styles.accessModalContentDescription}>
            {programDetails.terms_of_use && (
              <ReactMarkdown children={programDetails.terms_of_use} />
            )}
          </div>
        </div>
        {programDetails.traffic_types && (
          <div>
            <h5 id="allowed-traffic-types">Allowed Traffic Types</h5>
            <div className={styles.accessModalContentBox}>
              {programDetails.traffic_types.map((item) => (
                <div key={item.name} className={styles.trafficTypeItem}>
                  <span>{item.name}</span>
                  <span>
                    {item.status} {renderStatusIcon(item.status)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {programDetails.program_info &&
          programDetails.program_info.Countries && (
            <div>
              <h5 id="countries">Countries</h5>
              <div className={styles.accessModalContentBox}>
                {programDetails.program_info.Countries.map((country) => (
                  <p key={country} className={styles.accessModalCountryItem}>
                    {country}
                  </p>
                ))}
              </div>
            </div>
          )}
        {programDetails?.allowed_action === "request_access" && (
          <Form
            form={requestAccessForm}
            onFinish={handleRequestAccess}
            layout="vertical"
            onFinishFailed={() =>
              document.querySelector("#request-approval").scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            <h5 id="request-approval">Request Approval</h5>
            {programDetails.approval_questions.map((item, index) => {
              if (index === 3 && !programDetails.banners_details) {
                return null;
              }
              if (item.type !== "Images" && item.type !== "Radio") {
                return (
                  <Form.Item
                    key={index}
                    label={item.question}
                    name={item.question}
                    rules={[
                      {
                        required: item.required,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }

              if (item.type === "Radio") {
                return (
                  <Form.Item
                    key={index}
                    label={item.question}
                    name={item.question}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="Yes">Yes</Radio.Button>
                      <Radio.Button value="No" style={{ marginLeft: "10px" }}>
                        No
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                );
              }

              if (item.type === "Images") {
                return (
                  <TermsOfUseImages
                    key={index}
                    question={item.question}
                    imagesObject={imagesObject}
                    setImagesObject={setImagesObject}
                  />
                );
              }
              return false;
            })}
          </Form>
        )}
        <div className={styles.bottomSpace}></div>
      </div>
    </div>
  );
};
