import { Button, Space, Tooltip, Modal } from "antd";
import {
  FileOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import styles from "../BillingPaymentHistory.module.css";
import { billingPaymentHistoryService } from "../../../services/billingPaymentHistory";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../utils/notifications";

export const ActionsTableCol = ({
  id,
  dispatchSelectedFilters,
  dateRange,
  filters,
  invoice,
  allowDelete,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDeleteInvoice = async (id) => {
    setIsModalVisible(false);
    try {
      const res = await billingPaymentHistoryService.deleteInvoice(id);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        dispatchSelectedFilters({
          payload: {
            filters, dateRange
          },
        });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message:
          "Error occured while trying to delete invoice, please contact support.",
      });
    } finally {

    }
  };

  return (
    <>
      <Space>
        <Tooltip title="See receipt">
          <a
            href={invoice}
            target="_blank"
            rel="noreferrer"
            className={styles.receiptButton}
          >
            <FileOutlined />
          </a>
        </Tooltip>
        <Tooltip title="Delete Invoice">
          <Button
            disabled={!allowDelete}
            icon={<DeleteOutlined />}
            onClick={() => setIsModalVisible(true)}
            className={styles.deleteButton}
          ></Button>
        </Tooltip>
      </Space>
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{ color: "#FAAD14" }} />
            <span style={{ marginLeft: "10px" }}>
              Are you sure you want to proceed?
            </span>
          </>
        }
        visible={isModalVisible}
        onOk={() => handleDeleteInvoice(id)}
        onCancel={() => setIsModalVisible(false)}
        okText="Delete invoice"
        cancelText="Cancel"
        className={styles.deleteInvoiceModal}
      >
        <p>
          You are about to <b>delete the invoice</b>. This action can’t be
          undone.
        </p>
      </Modal>
    </>
  );
};
