import React from 'react';

import { openErrorNotification } from './notifications';

const generateMessage = (content, level = 0) => {
  const message = [];

  Object.keys(content).forEach((key) => {
    if (Array.isArray(content[key])) {
      message.push(
        <div key={key} style={{ marginLeft: level * 10 }}>
          {key} - {content[key].join(',')}
        </div>
      );
    } else {
      message.push(
        <div key={key} style={{ marginLeft: level * 10 }}>
          {key}:{generateMessage(content[key], level + 1)}
        </div>
      );
    }
  });

  return message;
};

export const showApiErrors = (error) => {
  let message = '';

  if (error && error.response) {
    if (error.response.data) {
      if (error.response.data.code === 'validation_failed') {
        const details = error.response.data.detail;
        message = generateMessage(details);
      } else {
        message = error.response.data.message;
      }
    }
  }

  openErrorNotification({
    message: error.response ? (
      <>
        <div>Request failed with status code {error.response.status}</div> {message}
      </>
    ) : (
      <>
        <div>Something went wrong</div> {error.stack}
      </>
    ),
    duration: 8
  });
};
