import { Button, Form, Input, Radio, Spin, Switch, Tooltip } from "antd";
import {
  CopyOutlined,
  SafetyCertificateFilled,
  InfoCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./PushForm.module.css";
import { Link, useHistory } from "react-router-dom";
import isValidDomain from "is-valid-domain";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { publisherFormsService } from "../../../services/publisherFormsService";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";
import { PostbackModal } from "./components/PostbackModal";
import { PushSmartlinkModal } from "./components/PushSmartlinkModal";
import { ExampleTag } from "../components/ExampleTag";
import { TutorialVideo } from "../components/TutorialVideo";
import classNames from "classnames";
import QueryString from "qs";
import { isDisabledZone } from "../../../utils/validations";
import { Questionnaire } from "../components/Questionnaire";
import { toFormData } from "../utils";

export const PushForm = ({ location, type, match }) => {
  const history = useHistory();
  const [zoneLoading, setZoneLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const [questionnaireLoading, setQuestionnaireLoading] = useState(false);

  const isPreview =
    QueryString.parse(location.search, { ignoreQueryPrefix: true }).type ===
    "preview";

  // first || second step
  const [step, setStep] = useState(0);

  const [currentZoneInfo, setCurrentZoneInfo] = useState({});

  const [zoneProtocol, setZoneProtocol] = useState("https");
  const [frequencyCap, setFrequencyCap] = useState("low");

  const [postbackVisible, setPostbackVisible] = useState(false);
  const [postbackURL, setPostbackURL] = useState(null);
  const [smartlinkVisible, setSmartlinkVisible] = useState(false);
  const [smartlinkURL, setSmartlinkURL] = useState(null);
  const [showExample, setShowExample] = useState(true);
  const [showPushVideo, setShowPushVideo] = useState(false);

  const [form] = Form.useForm();

  const [currentZoneId, setCurrentZoneId] = useState(
    match.params.id && match.params.id !== "new" ? match.params.id : null
  );

  const getZoneInfo = useCallback(
    async (id) => {
      if (isPreview) {
        setStep(1);
      }
      setZoneLoading(true);
      try {
        const res = await publisherFormsService.getApp(id);

        if (isDisabledZone(res.data?.status)) {
          setStep(0);
        }

        if (match.params.id && match.params.id !== "new") {
          form.setFieldsValue(res.data);
          setPostbackURL(res.data ? res.data.postback : null);
          setSmartlinkURL(res.data ? res.data.smartlink : null);
          setZoneProtocol(res.data ? res.data.protocol : null);
          setFrequencyCap(res.data ? res.data.frequency_cap : null);
        }
        setCurrentZoneInfo(res.data);
      } catch (e) {
        openErrorNotification({
          message: "Error while trying to get details about zone!",
        });
      } finally {
        setZoneLoading(false);
      }
    },
    [form, match.params.id, isPreview]
  );
  useEffect(() => {
    if (currentZoneId) {
      getZoneInfo(currentZoneId);
    }
  }, [currentZoneId, getZoneInfo]);

  const finishZoneForm = async (data) => {
    setBtnLoading(true);
    const reqObject = {
      ...data,
      type: "push",
      postback: postbackURL ? postbackURL : "",
      smartlink: smartlinkURL ? smartlinkURL : "",
      protocol: zoneProtocol,
    };
    if (zoneProtocol === "https") {
      reqObject.frequency_cap = frequencyCap;
    }

    const formDataObj = !(match.params.id && match.params.id !== "new")
      ? toFormData(reqObject)
      : reqObject;

    try {
      const res = !(match.params.id && match.params.id !== "new")
        ? await publisherFormsService.createZone(formDataObj)
        : await publisherFormsService.editApp(formDataObj, match.params.id);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        if (match.params.id && match.params.id !== "new") {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            history.push("/publisher/zones");
          } else {
            getZoneInfo(match.params.id);
            setStep(1);
          }
        } else {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            openWarningNotification({
              message:
                "Looks like you have a new website! Allow us 24-48h to check your traffic quality. You will get an email once your zone is approved.",
              duration: 15,
            });
            history.push("/publisher/zones");
          } else {
            setCurrentZoneId(res.data.id);
            setStep(1);
          }
        }
      } else {
        setQuestionnaireLoading(false);
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: "Error occurred while trying to create new zone!",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDownloadSwFile = () => {
    publisherFormsService.downloadSwFile();
  };

  return (
    <Spin spinning={zoneLoading}>
      <div className={styles.formContainer}>
        {step === 0 ? (
          <>
            <h3>
              {match.params.id && match.params.id !== "new" ? "Edit" : "New"}{" "}
              Push Zone
            </h3>
            <div className={styles.createContent}>
              <Form
                form={form}
                initialValues={{ pricing_model: type || "cps" }}
              >
                <p className={styles.inputLabel}>
                  1. Zone Title{" "}
                  <Tooltip title="Enter the title for your zone. This will help you with identifying your performance in Statistics.">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </p>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value && value !== "" && value.length <= 250) {
                          callback();
                        } else {
                          callback("Zone title is not valid!");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    showCount
                    maxLength={512}
                    disabled={!!match.params.id && match.params.id !== "new"}
                    placeholder="Zone title"
                  />
                </Form.Item>
                <p className={styles.inputLabel}>2. Set up pricing model</p>
                <Form.Item
                  name="pricing_model"
                  rules={[
                    {
                      required: true,
                      message: "Please select pricing model!",
                    },
                  ]}
                >
                  <Radio.Group style={{ marginLeft: "1rem", marginBottom: 0 }}>
                    <Radio className={styles.radioStyle} value="cps">
                      CPS
                    </Radio>
                    <Radio className={styles.radioStyle} value="revshare">
                      RevShare
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <p className={styles.inputLabel}>
                  3. Web Site where JS will be added{"  "}
                  <Tooltip title="Please provide the name of the website where this .js file will be placed. You may put your entire website, or just one page of your website. Putting the .js file in just one page of your website, will only affect that page, not the entire website.">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </p>
                <Form.Item
                  name="website"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (
                          value !== "" &&
                          !value.startsWith("www.") &&
                          isValidDomain(value) &&
                          value.length <= 250
                        ) {
                          callback();
                        } else {
                          callback("Domain name is not valid!");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    showCount
                    maxLength={64}
                    onChange={(e) => {
                      form.setFieldsValue({
                        website: e.target.value.replace(
                          /^(https:\/\/)|(http:\/\/)/,
                          ""
                        ),
                      });
                      const currentProtocol = e.target.value.substring(
                        0,
                        e.target.value.indexOf(":")
                      );
                      if (
                        currentProtocol === "http" ||
                        currentProtocol === "https"
                      ) {
                        setZoneProtocol(currentProtocol);
                      }
                    }}
                    disabled={!!match.params.id && match.params.id !== "new"}
                    addonBefore="www."
                    placeholder="new-example.com"
                  />
                </Form.Item>
                <p className={styles.inputLabel}>
                  4. Set up additional settings for your website
                </p>

                <Radio.Group
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                  disabled={!!match.params.id && match.params.id !== "new"}
                  value={zoneProtocol}
                  onChange={(e) => setZoneProtocol(e.target.value)}
                >
                  <Radio className={styles.radioStyle} value="http">
                    HTTP
                  </Radio>
                  <Radio className={styles.radioStyle} value="https">
                    HTTPS
                  </Radio>
                </Radio.Group>
                <p className={styles.inputLabel}>
                  5. Set up additional settings for your website
                </p>
                <div className={styles.settingsBox}>
                  <div>
                    <Switch
                      checked={postbackVisible || postbackURL}
                      onChange={(e) => {
                        if (!e) {
                          setPostbackURL(null);
                        }
                        setPostbackVisible(e);
                      }}
                    />
                    <p>Set up Postback to track subscription</p>
                    <Tooltip title="Postback URL allows us to fire a server to server pixel on your conversions so that you can track them independently.">
                      <InfoCircleTwoTone />
                    </Tooltip>
                    {postbackURL && (
                      <Button
                        className={styles.postbackEdit}
                        icon={<EditOutlined />}
                        onClick={() => setPostbackVisible(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <div>
                    <Form.Item
                      initialValue={false}
                      name="content_locker"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <p>Add content locker</p>
                    <Tooltip title="Enabling content locker will prevent users from interacting with the site until they subscribe.">
                      <InfoCircleTwoTone />
                    </Tooltip>
                  </div>
                  <div>
                    <Switch
                      checked={smartlinkVisible || smartlinkURL}
                      onChange={(e) => {
                        if (!e) {
                          setSmartlinkURL(null);
                        }
                        setSmartlinkVisible(e);
                      }}
                    />
                    <p>Set up SmartLink to get extra-monetization</p>
                    <Tooltip title="If the user decides to block your notifications, they will be redirected to this link.">
                      <InfoCircleTwoTone />
                    </Tooltip>
                    {smartlinkURL && (
                      <Button
                        className={styles.postbackEdit}
                        icon={<EditOutlined />}
                        onClick={() => setSmartlinkVisible(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
                {zoneProtocol === "https" && (
                  <div>
                    <p className={styles.inputLabel}>
                      5. Choose frequency cap for this zone
                    </p>
                    <div
                      className={classNames(
                        styles.frequencyGroup
                        //   {
                        //   [styles.frequencyGroupDisabled]:
                        //     match.params.id && match.params.id !== "new",
                        // }
                      )}
                    >
                      <div
                        className={`${styles.frequencyRadioBox} ${frequencyCap === "low" &&
                          styles.frequencyRadioBoxSelected
                          }`}
                        onClick={() => setFrequencyCap("low")}
                      >
                        <span>Low frequency cap</span>
                        <span>
                          {currentZoneInfo?.ads_per_hour?.low || 3}{" "}
                          notifications per hour
                        </span>
                      </div>
                      <div
                        className={`${styles.frequencyRadioBox} ${frequencyCap === "medium" &&
                          styles.frequencyRadioBoxSelected
                          }`}
                        onClick={() => setFrequencyCap("medium")}
                      >
                        <span>Medium frequency cap</span>
                        <span>
                          {currentZoneInfo?.ads_per_hour?.medium || 6}{" "}
                          notifications per hour
                        </span>
                      </div>
                      <div
                        className={`${styles.frequencyRadioBox} ${frequencyCap === "high" &&
                          styles.frequencyRadioBoxSelected
                          }`}
                        onClick={() => setFrequencyCap("high")}
                      >
                        <span>High frequency cap</span>
                        <span>
                          {currentZoneInfo?.ads_per_hour?.high || 12}{" "}
                          notifications per hour
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.actionBox}>
                  <Link to="/publisher/zones">
                    <Button>Back</Button>
                  </Link>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (match.params.id && match.params.id !== "new") {
                        const values = form.getFieldsValue(true);
                        finishZoneForm(values);
                      } else {
                        form
                          .validateFields()
                          .then(() => {
                            setQuestionnaireOpen(true);
                          })
                          .catch(() => {
                            openWarningNotification({
                              message: "Please complete form input.",
                            });
                          });
                      }
                    }}
                    loading={btnLoading}
                  >
                    {match.params.id && match.params.id !== "new"
                      ? "Save"
                      : "Create"}{" "}
                    & Get Tag
                  </Button>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <>
            <h3>Get tag for Test Push CPC (Web Push) Zone</h3>
            <div className={styles.pushContent}>
              {currentZoneInfo.protocol === "https" && (
                <>
                  <p className={styles.parHints}>
                    1. Download and upload file to the root directory of your
                    website to start earning
                  </p>
                  <div className={styles.buttonBoxPush}>
                    <Button type="primary" onClick={handleDownloadSwFile}>
                      Download file sw.js
                    </Button>
                    <p>
                      <SafetyCertificateFilled /> This file is 100% safe!
                    </p>
                  </div>
                  <p className={styles.parHint}>
                    After clicking on “Download file sw.js”, same file “sw.js”
                    file must be added and hosted at root directory of your
                    site. It also must keep same file name:
                    <span> {currentZoneInfo.website}/sw.js </span>
                  </p>
                  <p className={styles.parHint}>
                    If you just copy the code below without adding it to root
                    directory of your site, the <b>ad won't appear</b> on your
                    page at all. Be sure to finish this step before proceeding
                    to step 2.
                  </p>
                </>
              )}
              <p className={styles.parHints}>
                {currentZoneInfo.protocol === "https" ? "2." : "1."} Get tag
              </p>
              <p className={styles.parHintsPlaceJS}>
                Paste this code at the end of your body tag.
              </p>
              <p>
                When users click anywhere on your page the ad will be displayed
                according to your frequency settings.
              </p>
              <div className={styles.scriptBox}>
                <CopyToClipboard
                  text={currentZoneInfo.script ? currentZoneInfo.script : ""}
                >
                  <Button
                    icon={<CopyOutlined />}
                    type="primary"
                    className={styles.copyLinkBtn}
                    onClick={() =>
                      openSuccessNotification({ message: "Copied!" })
                    }
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
                <div className={styles.scriptContent}>
                  {currentZoneInfo.script ? currentZoneInfo.script : ""}
                </div>
              </div>
              <div className={styles.tutorialContainer}>
                <img
                  onClick={() => setShowPushVideo(true)}
                  alt="preview"
                  className={styles.videoPreview}
                  src="/images/video-preview.png"
                />
                <div className={styles.videoInstructions}>
                  <p>
                    Take a look at this video for step-by-step tutorial which
                    will show you how to correctly add the code to your website.
                    Make sure you properly input the code, or the ads won’t
                    appear on your page at all. Click the preview or{" "}
                    <span onClick={() => setShowPushVideo(true)}>
                      this link
                    </span>{" "}
                    to view it.
                  </p>
                  <p>You can see correct example of usage below.</p>
                </div>
              </div>
              <TutorialVideo
                source={
                  zoneProtocol === "https"
                    ? "/media/video/push_https.mp4"
                    : "/media/video/push_http.mp4"
                }
                showVideo={showPushVideo}
                setShowVideo={setShowPushVideo}
              />
              <p className={styles.parRule}>
                Once again, make sure you properly input the code,{" "}
                <b>or the ads won’t appear </b>on your page at all. You can see
                correct example of usage below.{" "}
                <span
                  className={styles.showHideExamples}
                  onClick={() => setShowExample(!showExample)}
                >
                  {showExample ? "Hide examples." : "See examples."}{" "}
                </span>
              </p>
              {showExample && <ExampleTag type={'wp'} />}
            </div>
            <div className={styles.actionBox}>
              <Button
                className={styles.backBtnPush}
                onClick={() =>
                  !isPreview && match.params.id && match.params.id !== "new"
                    ? setStep(0)
                    : history.push("/publisher/zones")
                }
              >
                {match.params.id && match.params.id !== "new" ? "Back" : "Done"}
              </Button>
              {match.params.id && match.params.id !== "new" && !isPreview ? (
                <Button
                  type="primary"
                  onClick={() => history.push("/publisher/zones")}
                >
                  Done
                </Button>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
      <PostbackModal
        postbackVisible={postbackVisible}
        setPostbackVisible={setPostbackVisible}
        postbackURL={postbackURL}
        setPostbackURL={setPostbackURL}
      />
      <PushSmartlinkModal
        smartlinkVisible={smartlinkVisible}
        setSmartlinkVisible={setSmartlinkVisible}
        smartlinkURL={smartlinkURL}
        setSmartlinkURL={setSmartlinkURL}
      />
      <Questionnaire
        isOpen={questionnaireOpen}
        setIsOpen={setQuestionnaireOpen}
        loading={questionnaireLoading}
        setLoading={setQuestionnaireLoading}
        isNewZone={!currentZoneId}
        handleConfirm={(questionnaireValues) => {
          const values = form.getFieldsValue(true);
          finishZoneForm({ ...values, ...questionnaireValues });
        }}
      />
    </Spin>
  );
};
