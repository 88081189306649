import React, { useState, useContext, useEffect, useMemo } from "react";
import { Modal, Checkbox, Row, Col, Form, Input, Button } from "antd";
import UserContext from "../../../contexts/userContext";
import { auth } from "../../../services/auth";
import { agreementSigningService } from "../../../services/agreementSigning";
import {
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";

import styles from "./AgreementModal.module.css";

export const AgreementModal = ({
  agreementModalVisible,
  setAgreementModalVisible,
  currentModule,
}) => {
  const { userInfo, setUserInfo } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);

  const [form] = Form.useForm();

  const hasN2SUnsigned =
    auth.hasPermission("search") &&
    userInfo.permissions &&
    userInfo.permissions.search &&
    userInfo.permissions.search.n2s &&
    userInfo.agreements &&
    userInfo.agreements.search &&
    userInfo.agreements.search.n2s === false;
  const hasExtensionUnsigned =
    auth.hasPermission("search") &&
    userInfo.permissions &&
    userInfo.permissions.search &&
    userInfo.permissions.search.extension &&
    userInfo.agreements &&
    userInfo.agreements.search &&
    userInfo.agreements.search.extension === false;

  const returnPermissions = (permissions, user) => {
    let obj = { ...user.agreements };
    permissions.forEach((item) => {
      if (item === "n2s" || item === "extension") {
        obj.search = { ...obj.search, [item]: { status: "in_progress" } };
      } else {
        obj = { ...obj, [item]: { status: "in_progress" } };
      }
    });
    return obj;
  };

  const initialValues = useMemo(() => {
    return {
      first_name: userInfo.authorized_first_name,
      last_name: userInfo.authorized_last_name,
      email: userInfo.authorized_email,
    };
  }, [userInfo]);

  useEffect(() => {
    setModulesSelected([]);
    form.resetFields();
    if (
      currentModule === "publisher" ||
      currentModule === "offerwall" ||
      currentModule === "social_to_search"
    ) {
      setModulesSelected([currentModule]);
    } else if (
      (currentModule === "search" &&
        hasN2SUnsigned &&
        !hasExtensionUnsigned &&
        !userInfo.permissions.search.extension) ||
      (currentModule === "billing" &&
        hasN2SUnsigned &&
        !hasExtensionUnsigned &&
        !userInfo.permissions.search.extension &&
        !auth.hasPermission("publisher") &&
        !auth.hasPermission("offerwall") &&
        !auth.hasPermission("social_to_search"))
    ) {
      setModulesSelected(["n2s"]);
    } else if (
      (currentModule === "search" &&
        hasExtensionUnsigned &&
        !hasN2SUnsigned &&
        !userInfo.permissions.search.n2s) ||
      (currentModule === "billing" &&
        hasExtensionUnsigned &&
        !hasN2SUnsigned &&
        !userInfo.permissions.search.n2s &&
        !auth.hasPermission("publisher") &&
        !auth.hasPermission("offerwall") &&
        !auth.hasPermission("social_to_search"))
    ) {
      setModulesSelected(["extension"]);
    } else if (
      currentModule === "billing" &&
      auth.hasPermission("publisher") &&
      !auth.hasPermission("offerwall") &&
      !auth.hasPermission("search") &&
      !auth.hasPermission("social_to_search")
    ) {
      setModulesSelected(["publisher"]);
    } else if (
      currentModule === "billing" &&
      auth.hasPermission("offerwall") &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("search") &&
      !auth.hasPermission("social_to_search")
    ) {
      setModulesSelected(["offerwall"]);
    } else if (
      currentModule === "billing" &&
      auth.hasPermission("social_to_search") &&
      !auth.hasPermission("publisher") &&
      !auth.hasPermission("search") &&
      !auth.hasPermission("offerwall")
    ) {
      setModulesSelected(["social_to_search"]);
    }
  }, [
    currentModule,
    agreementModalVisible,
    hasExtensionUnsigned,
    hasN2SUnsigned,
    userInfo.permissions,
    form,
  ]);

  const handleSubmit = async (values) => {
    form.validateFields();
    let newAgreements = returnPermissions(modulesSelected, userInfo);
    if (
      !values.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      openWarningNotification({ message: "Email is not valid!" });
      return;
    }
    setLoading(true);
    try {
      const res = await agreementSigningService.forwardAgreement({
        modules: modulesSelected,
        ...values,
      });
      if (res.success) {
        openSuccessNotification({ message: res.message });
        setAgreementModalVisible(false);
        setUserInfo((prevUser) => ({ ...prevUser, agreements: newAgreements }));
      } else {
        openWarningNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const renderOptions = () => {
    return (
      <>
        {currentModule === "billing" ? (
          <>
            {auth.hasPermission("publisher") ? (
              <Col span={12}>
                <Checkbox
                  value="publisher"
                  disabled={
                    !(
                      userInfo.agreements &&
                      userInfo.agreements.publisher === false &&
                      userInfo.docusign.publisher === true
                    )
                  }
                >
                  Webmasters
                </Checkbox>
              </Col>
            ) : (
              ""
            )}
            {auth.hasPermission("offerwall") ? (
              <Col span={12}>
                <Checkbox
                  value="offerwall"
                  disabled={
                    !(
                      userInfo.agreements &&
                      userInfo.agreements.offerwall === false &&
                      userInfo.docusign.offerwall === true
                    )
                  }
                >
                  Affiliate programs
                </Checkbox>
              </Col>
            ) : (
              ""
            )}
            {auth.hasPermission("social_to_search") ? (
              <Col span={12}>
                <Checkbox
                  value="social_to_search"
                  disabled={
                    !(
                      userInfo.agreements &&
                      userInfo.agreements.social_to_search === false &&
                      userInfo.docusign.social_to_search === true
                    )
                  }
                >
                  Agency
                </Checkbox>
              </Col>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {userInfo.permissions &&
        userInfo.permissions.search &&
        userInfo.permissions.search.extension ? (
          <Col span={12}>
            <Checkbox
              value="extension"
              disabled={
                !(
                  userInfo.agreements &&
                  userInfo.agreements.search &&
                  userInfo.agreements.search.extension === false &&
                  userInfo.docusign.search.extension === true
                )
              }
            >
              Search — Search Type-In
            </Checkbox>
          </Col>
        ) : (
          ""
        )}
        {userInfo.permissions &&
        userInfo.permissions.search &&
        userInfo.permissions.search.n2s ? (
          <Col span={12}>
            <Checkbox
              value="n2s"
              disabled={
                !(
                  userInfo.agreements &&
                  userInfo.agreements.search &&
                  userInfo.agreements.search.n2s === false &&
                  userInfo.docusign.search.n2s === true
                )
              }
            >
              Search — Native 2 Search
            </Checkbox>
          </Col>
        ) : (
          ""
        )}
      </>
    );
  };

  const closeAgreementModal = () => {
    setAgreementModalVisible(false);
  };

  const onChange = (checkedValues) => {
    setModulesSelected([...checkedValues]);
  };

  const billingOnlyOne =
    currentModule === "billing" &&
    !(
      (hasN2SUnsigned &&
        userInfo.permissions &&
        userInfo.permissions.search &&
        !userInfo.permissions.search.extension &&
        !auth.hasPermission("publisher") &&
        !auth.hasPermission("offerwall") &&
        !auth.hasPermission("social_to_search")) ||
      (hasExtensionUnsigned &&
        userInfo.permissions &&
        userInfo.permissions.search &&
        !userInfo.permissions.search.n2s &&
        !auth.hasPermission("publisher") &&
        !auth.hasPermission("offerwall") &&
        !auth.hasPermission("social_to_search")) ||
      (auth.hasPermission("publisher") &&
        !auth.hasPermission("offerwall") &&
        !auth.hasPermission("search") &&
        !auth.hasPermission("social_to_search")) ||
      (auth.hasPermission("offerwall") &&
        !auth.hasPermission("publisher") &&
        !auth.hasPermission("search") &&
        !auth.hasPermission("social_to_search")) ||
      (auth.hasPermission("social_to_search") &&
        !auth.hasPermission("publisher") &&
        !auth.hasPermission("search") &&
        !auth.hasPermission("offerwall"))
    );

  const searchCondition =
    currentModule === "search" &&
    ((hasN2SUnsigned && hasExtensionUnsigned) ||
      (hasN2SUnsigned &&
        userInfo.permissions &&
        userInfo.permissions.search &&
        userInfo.permissions.search.extension) ||
      (hasExtensionUnsigned &&
        userInfo.permissions &&
        userInfo.permissions.search &&
        userInfo.permissions.search.n2s));

  return (
    <Modal
      title={
        currentModule === "billing"
          ? "Select module for agreement"
          : "Forwarding agreement to your e-mail"
      }
      visible={agreementModalVisible}
      onCancel={closeAgreementModal}
      footer={false}
      centered={true}
      width={billingOnlyOne || searchCondition ? 640 : 460}
      wrapClassName={styles.agreementModalWrapper}
    >
      {billingOnlyOne || searchCondition ? (
        <div>
          <p>
            Before we forward the agreement, please select a module and insert
            details of the authorized representative. If you’re the authorized
            representative, enter the information you’re using on the platform.
          </p>
          <div className={styles.optionsContainer}>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onChange}
              value={modulesSelected}
            >
              <Row gutter={12}>{renderOptions()}</Row>
            </Checkbox.Group>
          </div>
        </div>
      ) : (
        <p>
          Before we forward the agreement, please insert details of the
          authorized representative. If you’re the authorized representative,
          enter the information you’re using on the platform.
        </p>
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <Form.Item
          label="First name of authorized representative:"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please enter name of authorized representative!",
            },
          ]}
        >
          <Input placeholder="Enter name of authorized representative here" />
        </Form.Item>
        <Form.Item
          label="Last name of authorized representative:"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please enter name of authorized representative!",
            },
          ]}
        >
          <Input placeholder="Enter name of authorized representative here" />
        </Form.Item>
        <Form.Item
          label="E-mail address of authorized representative:"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter e-mail of authorized representative!",
            },
          ]}
        >
          <Input placeholder="Enter e-mail of authorized representative here" />
        </Form.Item>
        <Form.Item className={styles.actionButtons}>
          <Button onClick={closeAgreementModal}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={modulesSelected.length === 0}
          >
            Forward agreement
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
