import React from "react";
import { Button, Select, Row, Col } from "antd";
import { generateCountries } from "../utils/filterOptions";
import styles from "../AdSamples.module.css";

const { Option } = Select;

export const Filters = ({ filters, setFilters, loadCountries, countries, submit }) => {
    return (
        <>
            <div className={styles.filtersContainer}>
                <h3>Filter Ad Samples</h3>
                <div style={{ marginTop: "1%", paddingBottom: "0.5rem" }}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={24} md={8} lg={6} xxl={4}>
                            <span className={styles.spanMarginBottom}>Vertical</span>
                            <Select
                                size="medium"
                                mode="multiple"
                                className={styles.selectField}
                                placeholder="Select vertical"
                                allowClear
                                onChange={value => {
                                    setFilters({ ...filters, verticals: value });
                                }}
                            >
                                <Option value="Insurance">Insurance</Option>
                                <Option value="Warranty">Warranty</Option>
                                <Option value="Life Insurance">Life Insurance</Option>
                                <Option value="Credit Card">Credit Card</Option>
                                <Option value="Health Care Services">Health Care Services</Option>
                                <Option value="Computers & Customer Services">Computers & Customer Services</Option>
                                <Option value="Law & Government">Law & Government</Option>
                                <Option value="Games">Games</Option>
                                <Option value="Software">Software</Option>
                                <Option value="Education">Education</Option>
                                <Option value="Travel">Travel</Option>
                                <Option value="Finance">Finance</Option>
                                <Option value="Health">Health</Option>
                                <Option value="Cars">Cars</Option>
                                <Option value="Sale">Sale</Option>
                                <Option value="Work">Work</Option>
                                <Option value="Internet">Internet</Option>
                                <Option value="Food">Food</Option>
                                <Option value="Casino">Casino</Option>
                                <Option value="Business">Business</Option>
                                <Option value="Security">Security</Option>
                                <Option value="Fashion">Fashion</Option>
                                <Option value="Jobs">Jobs</Option>
                                <Option value="Home">Home</Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={6} xxl={4}>
                            <span className={styles.spanMarginBottom}>Geo</span>
                            <Select
                                size="medium"
                                mode="multiple"
                                className={styles.selectField}
                                placeholder="Select country"
                                allowClear
                                onChange={value => {
                                    setFilters({ ...filters, countries: value });
                                }}
                                filterOption={(inputValue, option) =>
                                    option.children.toLowerCase().includes(inputValue.toLowerCase())
                                }
                                loading={loadCountries}
                            >
                                {generateCountries(countries || [])}
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={6} xxl={4}>
                            <span className={styles.spanMarginBottom}>Device type</span>
                            <Select
                                size="medium"
                                mode="multiple"
                                className={styles.selectField}
                                placeholder="Select device type"
                                allowClear
                                onChange={value => {
                                    setFilters({ ...filters, device_types: value });
                                }}
                            >
                                <Option value="Desktop">Desktop</Option>
                                <Option value="Mobile">Mobile</Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={6} xxl={4}>
                            <span className={styles.spanMarginBottom}>Traffic type</span>
                            <Select
                                size="medium"
                                mode="multiple"
                                className={styles.selectField}
                                placeholder="Select traffic type"
                                allowClear
                                onChange={value => {
                                    setFilters({ ...filters, traffic_types: value });
                                }}
                            >
                                <Option value="facebook">Facebook</Option>
                                <Option value="native">Native</Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={6} xxl={4}>
                            <Button
                                className={styles.applyFiltersBtn}
                                onClick={() => submit(filters)}
                                type="primary"
                                size="medium"
                            >
                                Apply filters
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
