import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Select } from "antd";
import { userProfileService } from "../../../services/userProfile";
import styles from "../Profile.module.css";
import { generateCountries } from "../utils/Options";

export const PersonalDetails = ({ userDetails }) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await userProfileService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch {
      } finally {
      }
    };
    getCountries();
  }, []);

  useEffect(() => {
    form.setFieldsValue(userDetails);
  }, [form, userDetails]);

  return (
    <div className={styles.personalDetailsContent}>
      <Spin spinning={false} wrapperClassName={styles.personalDetailsContainer}>
        <Form
          form={form}
          name="userForm"
          layout="vertical"
          // onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
        >
          <div className={styles.personalDetailsTop}>
            <div className={styles.imageUpload}>
              {userDetails && userDetails.image ? (
                <div className={styles.imagePreviewModal}>
                  <img src={userDetails.image} alt="Preview" />
                </div>
              ) : (
                <div className={styles.imagePreviewModal}>
                  <img
                    src="https://i.pinimg.com/originals/45/d9/8a/45d98aa922bef6b5213b488dc36a8764.png"
                    alt="Preview"
                  />
                </div>
              )}
            </div>
            <div className={styles.userInfo}>
              <div>
                <p>User Information</p>
                <Form.Item name="first_name" label="Name">
                  <Input disabled placeholder="name" />
                </Form.Item>
                {/* <Form.Item name="last_name" label="Last Name">
                  <Input disabled placeholder="Last name" />
                </Form.Item> */}
                <Form.Item name="email" label="E-mail address">
                  <Input disabled placeholder="Email" />
                </Form.Item>
              </div>
              <div className={styles.userInfoLeft}>
                <p>Contact Details</p>
                <Form.Item name="phone" label="Contact phone">
                  <Input disabled placeholder="Phone" />
                </Form.Item>
                <Form.Item name="country" label="Country">
                  <Select disabled placeholder="Country" allowClear>
                    {generateCountries(countries || [])}
                  </Select>
                </Form.Item>
                <Form.Item name="city" label="City">
                  <Input disabled placeholder="City" />
                </Form.Item>
                <Form.Item name="address" label="Address">
                  <Input disabled placeholder="Address" />
                </Form.Item>
                <Form.Item name="zip_code" label="ZIP Code">
                  <Input disabled placeholder="ZIP Code" />
                </Form.Item>
                <Form.Item name="website" label="Website">
                  <Input disabled placeholder="Website" />
                </Form.Item>
              </div>
              {/* <Form.Item>
                <Button
                  className={styles.confirmInfoChangesBtn}
                  type="primary"
                  htmlType="submit"
                  disabled
                >
                  Save Changes
                </Button>
              </Form.Item> */}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
