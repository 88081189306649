import { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { HeadInfo } from "./components/HeadInfo";
// import { Insights } from "./components/Insights";
import { StatisticsLineChart } from "./components/StatisticsLineChart";
import { TopChannelsTable } from "./components/TopChannelsTable";
import { Spin } from "antd";
import { CustomLoader } from "./components/CustomLoader";
import { agencyService } from "../../services/agencyService";
import { EarningsMap } from "../SearchDashboard/components/EarningsMap";

const mapColors = [
  "#A3A1FB",
  "#50D8BB",
  "#FFA167",
  "#FF7CC3",
  "#81C9FF",
  "#FF8474",
];

export const AgencyDashboard = () => {
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [mapData, setMapData] = useState({});

  useEffect(() => {
    const fetchStatsChart = async () => {
      setLoading(true);
      try {
        const [chart, topChannels, countriesData, topCountries] =
          await Promise.all([
            agencyService.getStatisticData(["revenue", "rpc", "gross_profit"]),
            agencyService.getTopChannelsData(),
            agencyService.getCountries(),
            agencyService.getEarningsMapData(),
          ]);
        setAllCountries(countriesData.data);
        parseTopCountries(topCountries ? topCountries.table : []);
        if (chart.values) {
          const chartParse = {
            values: chart.values.map((item) => {
              item[2] = parseFloat(item[2].toFixed(2));
              return item;
            }),
          };
          setChartData(chartParse);
        } else {
          setChartData(chart);
        }
        if (topChannels.table) {
          setTableData(topChannels.table);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchStatsChart();
  }, []);

  const parseTopCountries = (topCountries) => {
    if (topCountries) {
      const formatedCountries = topCountries.map((country, index) => {
        return { [country.country]: mapColors[index] };
      });
      const mapData = Object.assign({}, ...formatedCountries);
      setMapData(mapData);
      setTopCountries(topCountries);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <h4 className={styles.titleDashboard}>
        <span>Dashboard</span>
      </h4>
      <p style={{ paddingTop: "0.5rem" }} className={styles.statsTitle}>
        Last 7 days
      </p>
      <HeadInfo />
      <div className={styles.statsDivider} />
      <Spin spinning={loading} indicator={<CustomLoader />}>
        <h4 className={styles.statsTitle}>Statistics</h4>
        <StatisticsLineChart loading={false} chartData={chartData} />
        <div className={styles.dashBottomContent}>
          <TopChannelsTable tableData={tableData} loading={loading} />
          <EarningsMap
            mapData={mapData}
            loading={false}
            topCountries={topCountries}
            allCountries={allCountries}
          />
        </div>
      </Spin>
    </div>
  );
};
