import { BASE_URL } from "../../../config";
import styles from "../Reports.module.css";

export const columnTemplate = [
  {
    value: "date",
    text: "Date",
    tooltip: "Date frame period of obeserving your activity",
  },
  {
    value: "hour",
    text: "Hour",
    tooltip: "Checking performance of your activity on hourly basis",
  },
  {
    value: "zone_name",
    text: "Zone name",
    tooltip: "Custom zone or unit name of your campaigns",
  },
  {
    value: "country",
    text: "Country",
    tooltip: "Country from which traffic is coming",
    render: item => {
      return (
        <div className={styles.countryBox}>
          {item && item !== "XX" && (
            <img
              src={`${BASE_URL}media/countries/${item.toLowerCase()}.png`}
              alt="test"
            />
          )}
          {item}
        </div>
      );
    },
  },
  {
    value: "impressions",
    text: "Visits/Impressions",
    tooltip: "Total traffic that came to our links after filtering",
  },
  {
    value: "leads",
    text: "Leads",
    tooltip:
      "Amount of leads - it can be installs, trials, sales, or other custom event",
  },
  {
    value: "clicks",
    text: "Clicks",
    tooltip: "Clicks on actual ads that appeared to users",
  },
  {
    value: "os",
    text: "OS",
    tooltip: "Operating systems from which traffic is coming",
  },
  {
    value: "browser",
    text: "Browser",
    tooltip: "Browser from which traffic is coming",
  },
  { value: "rpm", text: "RPM", tooltip: "Revenue per thousand impressions" },
  { value: "rpl", text: "RPL", tooltip: "Revenue per lead" },
  {
    value: "fill",
    text: "Fill",
    tooltip: "Number of returned ads compared with the total requested",
  },
  {
    value: "fill_rate",
    text: "Fill Rate",
    tooltip:
      "The ad fill rate is the ratio between the number of ads delivered to the number of ads requested by the ad server",
  },
  { value: "cr", text: "CR", tooltip: "Conversion rate of your campaigns" },
  { value: "revenue", text: "Revenue", tooltip: "Revenue or money earned" },
];

export const columnPreset = {
  date: true,
  zone_name: true,
  impressions: true,
  clicks: true,
  leads: true,
  ecpa: true,
  cpm: true,
  cr: true,
  revenue: true,
};
export const zoneColumnPreset = {
  revenue: true,
  cpm: true,
  impressions: true,
  clicks: true,
};
export const dateColumnPreset = {
  revenue: true,
  date: true,
  cpm: true,
  impressions: true,
  clicks: true,
};
export const countryColumnPreset = {
  revenue: true,
  country: true,
  cpm: true,
  impressions: true,
  clicks: true,
};
