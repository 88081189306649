import { Tooltip } from 'antd';
import { DesktopOutlined, MobileOutlined, FacebookOutlined, NotificationOutlined } from '@ant-design/icons';
import styles from "../AdSamples.module.css";

export const formatCountries = (data) => {
  let numOfWords = 0;
  let visibleElements = [];
  let other = 0;

  for (let i = 0; i < data.length; i++) {
    numOfWords = numOfWords + data[i].split(' ').length;
    if (!(numOfWords > 3)) {
      other = data.length - (i + 1);
      visibleElements = data.slice(0, i + 1).join(", ");
    }
  }

  return (
    <>
      <div>{visibleElements}</div>
      <Tooltip placement="topLeft" title={data.join(", ")}>
        <span className={styles.otherData}>{other !== 0 ? "+ " + other + " more" : ""}</span>
      </Tooltip>
    </>
  );
}

export const formatDevices = (data) => {
  const devices = data.map((item, index) => {
    if (item === "Desktop") {
      return <span key={index}><DesktopOutlined />Desktop</span>
    }
    else {
      return <span key={index}><MobileOutlined />Mobile</span>
    }
  })
  return (
    <div className={styles.devicesContainer}>
      {devices}
    </div>
  )
}

export const formatType = (type) => {
  if (type === "facebook") {
    return <div className={styles.devicesContainer}><span><FacebookOutlined />Facebook</span></div>
  }
  else {
    return <div className={styles.devicesContainer}><span><NotificationOutlined />Native</span></div>
  }
}

export const renderVerticals = (verticals) => {
  const parsedCountries = verticals.map((vertical, index) => (
    <span key={index} className={styles.spanStyle}>
      {vertical}
    </span>
  ));
  return (
    <div>
      {parsedCountries}
    </div>
  );
};