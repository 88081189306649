import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  Space,
  notification,
  Checkbox,
  Radio,
  Upload,
} from "antd";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { searchDashboardService } from "../../../services/searchDashboard";
import { openErrorNotification, openWarningNotification } from "../../../utils/notifications";
import styles from "../Dashboard.module.css";

const { Dragger } = Upload;

export const FeedForm = ({ feedFormVisible, setFeedFormVisible, onRequest }) => {
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [form] = Form.useForm();
  const [feedLoading, setFeedLoading] = useState(false);

  const [addProviderVisible, setAddProviderVisible] = useState(true);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const [uploadLoading, setUploadLoading] = useState(false);
  const [statsScreenshots, setStatsScreenshots] = useState([]);
  const [liveCampaignScreenshots, setLiveCampaignScreenshots] = useState([]);
  const [campaignImages, setCampaignImages] = useState([]);

  const [firstStepData, setFirstStepData] = useState({});

  const n2sForm = feedFormVisible === "n2s";

  const formatProviderInfo = (data) => {
    var result = '';
    for (var i = 0; i < data.length; i++) {
      var item = data[i];
      result += item.company + ', ' + item.name + ', ' + item.email + ". ";
    }
    result = additionalInfo === "" ? result : result + "Additional info: " + additionalInfo;
    return result;
  }

  const handleSubmit = (values) => {
    if (isFirstStep) {
      setFirstStepData(values)
      setIsFirstStep(!isFirstStep);
    } else {
      const data = !n2sForm ? {
        ...firstStepData,
        ...values,
        s2_a2: statsScreenshots,
        module: feedFormVisible
      } : {
        ...firstStepData,
        ...values,
        s2_a1: liveCampaignScreenshots,
        s2_a2: campaignImages,
        s2_a3: values.s2_a3.join(','),
        s2_a10: formatProviderInfo(values.s2_a10),
        module: feedFormVisible,
      }
      handleRequestFeedForm(data);
    };
  }

  const updateImageList = (value, type) => {
    if (type === 'search') {
      setStatsScreenshots((state) => {
        if (state.length > 4) {
          openWarningNotification({
            message: "You can't add more than 5 screenshots!",
          });
          return state;
        }
        return [...state, value];
      });
    } else if (type === 'n2s_1') {
      setLiveCampaignScreenshots((state) => {
        if (state.length > 4) {
          openWarningNotification({
            message: "You can't add more than 5 screenshots!",
          });
          return state;
        }
        return [...state, value];
      });
    } else if (type === 'n2s_2') {
      setCampaignImages((state) => {
        if (state.length > 4) {
          openWarningNotification({
            message: "You can't add more than 5 screenshots!",
          });
          return state;
        }
        return [...state, value];
      });
    }
  };

  const handleImageUpload = async (image, type) => {
    setUploadLoading(true);
    try {
      const res = await searchDashboardService.uploadFeedScreenshots(image);
      if (res.success) {
        updateImageList(res.data.url, type);
      } else {
        openErrorNotification({ message: res.message });
      }
    } finally {
      setUploadLoading(false);
    }
  }

  const handleRequestFeedForm = async (data) => {
    if ((!n2sForm && statsScreenshots.length === 0) || (n2sForm && (liveCampaignScreenshots.length === 0 || campaignImages.length === 0))) {
      openWarningNotification({
        message: "Screenshots missing!",
      });
    } else {
      setFeedLoading(true);
      notification.open({
        message: "Requesting feed...",
        key: "reqfeed",
        icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
        duration: 0,
      });
      try {
        const res = await searchDashboardService.requestFeed(data);
        if (res.success) {
          notification["success"]({
            message: "Waitlist Joined",
            key: "reqfeed",
          });
          setFeedFormVisible(false);
          setIsFirstStep(true);
          form.resetFields();
          setStatsScreenshots([]);
          setLiveCampaignScreenshots([]);
          setCampaignImages([]);
          onRequest();
        } else {
          throw new Error();
        }
      } catch (e) {
        notification["error"]({
          message: "Error, failed to send request!",
          key: "reqfeed",
        });
      } finally {
        setFeedLoading(false);
      }
    }
  }

  const validateTextarea = (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value || value.trim().length === 0) {
        reject('Please fill out this field!');
      }
      else if (value && value.length > 256) {
        reject('Character length limit exceded.');
      }
      else {
        resolve();
      }
    });
  };

  const validateKeywords = (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value || value.join("").trim().length === 0) {
        reject('Please fill out this field!');
      }
      else if (value && value.join("").length > 256) {
        reject('Character length limit exceded.');
      }
      else {
        resolve();
      }
    });
  };

  return (
    <Modal
      style={{ top: 50 }}
      wrapClassName={styles.feedFormModal}
      title={`Request Feed`}
      visible={feedFormVisible}
      onCancel={() => {
        if (feedLoading) {
          openWarningNotification("Requesting feed...");
          return;
        }
        form.resetFields();
        setIsFirstStep(true);
        setFeedFormVisible(false);
      }}
      footer={null}
      width={600}
    >
      <div className={styles.formFeedContainer}>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{ links: [""], s2_a10: [{ company: '', name: '', email: '' }] }}
        >
          {isFirstStep ?
            <>
              {!n2sForm ? (
                <>
                  <p className={styles.feedFormTitle}>
                    Please provide link(s) to your product(s)
                  </p>
                  <Form.List
                    name="links"
                    label="Please provide link(s) to your product(s)"
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Form.Item
                            key={key}
                            {...restField}
                            name={name}
                            style={{ marginBottom: 7 }}
                            rules={[{ required: true, message: "Missing Link!" }]}
                          >
                            <Input
                              placeholder="Link"
                              suffix={
                                restField.fieldKey !== 0 ? (
                                  <DeleteOutlined onClick={() => remove(name)} />
                                ) : null
                              }
                            />
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Link
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item
                    label="What sort of extension do you have?"
                    name="extensions"
                    rules={[
                      { required: true, message: "Please select extension type!" },
                    ]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "Google Chrome Extension",
                          value: "Google Chrome Extension",
                        },
                        {
                          label: "Mozilla Firefox Add-On",
                          value: "Mozilla Firefox Add-On",
                        },
                        {
                          label: "Other type of extension",
                          value: "Other type of extension",
                        },
                      ]}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label="Which feed are you looking for?"
                    name="feeds"
                    rules={[
                      {
                        required: true,
                        message: "Please select feeds you are looking for!",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "Bing",
                          value: "Bing",
                        },
                        {
                          label: "Yahoo",
                          value: "Yahoo",
                        },
                        {
                          label: "Yandex",
                          value: "Yandex",
                        },
                        {
                          label: "Custom XML",
                          value: "Custom XML",
                        },
                        {
                          label: "Other",
                          value: "Other",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="For what type of product or traffic would you plan to use it?"
                    name="types"
                    rules={[
                      {
                        required: true,
                        message: "Please select products/traffic!",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "Native/Display to Search",
                          value: "Native/Display to Search",
                        },
                        {
                          label: "Social to Search",
                          value: "Social to Search",
                        },
                        {
                          label: "Search to Search",
                          value: "Search to Search",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="How many active daily impressions do you have?"
                    name="impressions"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your daily impressions!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="10k+">10k+</Radio>
                      <Radio value="50k+">50k+</Radio>
                      <Radio value="200k+">200k+</Radio>
                      <Radio value="1mil+">1mil+</Radio>
                      <br />
                      <Radio value="20k+">20k+</Radio>
                      <Radio value="100k+">100k+</Radio>
                      <Radio value="500k+">500k+</Radio>
                      <Radio value="Other">Other</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
              <Form.Item
                label="How many active daily searches do you have?"
                name="searches"
                rules={[
                  { required: true, message: "Please enter your daily searches!" },
                ]}
              >
                <Radio.Group>
                  <Radio value="10k+">10k+</Radio>
                  <Radio value="50k+">50k+</Radio>
                  <Radio value="200k+">200k+</Radio>
                  <Radio value="1mil+">1mil+</Radio>
                  <br />
                  <Radio value="20k+">20k+</Radio>
                  <Radio value="100k+">100k+</Radio>
                  <Radio value="500k+">500k+</Radio>
                  <Radio value="Other">Other</Radio>
                </Radio.Group>
              </Form.Item>
              {!n2sForm ? (
                <>
                  <Form.Item
                    label="What verticals do you work with?"
                    name="verticals"
                    rules={[
                      {
                        required: true,
                        message: "Please select verticals you work with!",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "Blog/Magazine",
                          value: "Blog/Magazine",
                        },
                        {
                          label: "Streaming/Download",
                          value: "Streaming/Download",
                        },
                        {
                          label: "E-Commerce",
                          value: "E-Commerce",
                        },
                        {
                          label: "Finance/Economy",
                          value: "Finance/Economy",
                        },
                        {
                          label: "Sport",
                          value: "Sport",
                        },
                        {
                          label: "Gaming",
                          value: "Gaming",
                        },
                        // {
                        //   label: "Gambling",
                        //   value: "Gambling",
                        // },
                        // {
                        //   label: "Adult Websites",
                        //   value: "Adult Websites",
                        // },
                        {
                          label: "Other",
                          value: "Other",
                        },
                      ]}
                    />
                  </Form.Item>
                </>
              ) : null}
              <Form.Item
                label="What is the list of GEOs you cover?"
                name="geos"
                rules={[
                  {
                    required: true,
                    message: "Please enter geos you cover!",
                  },
                ]}
              >
                <Input placeholder="Enter your GEOs, e.g. US, UK, CA etc." />
              </Form.Item>
              <Form.Item
                label="Please provide more info about your activities. This information is highly important for us since we want to understand your business model and interests."
                name="info"
              >
                <Input placeholder="Enter info about your business here" />
              </Form.Item>
            </>
            :
            <>
              {!n2sForm ? (
                <>
                  <Form.Item
                    label="Which feed are you looking for?"
                    name="s2_a1"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="Please provide a screenshot of your stats in the developer dashboard"
                    // name="s2_a2"
                    rules={[
                      {
                        required: true,
                        message: "Missing screenshot!",
                      },
                    ]}
                  >
                    <Dragger
                      name="statsScreenshots"
                      multiple={true}
                      maxCount={5}
                      beforeUpload={(image) => {
                        handleImageUpload(image, 'search');
                        return false;
                      }}
                      showUploadList={{
                        showRemoveIcon: false
                      }}
                    >
                      <div className={styles.buttonUpload}>
                        <UploadOutlined style={{ fontSize: "1.4rem" }} />{" "}
                        <span>Upload or drag image</span>
                      </div>
                    </Dragger>
                  </Form.Item>
                  <Form.Item
                    label="Please tell us more about how you aquire users"
                    name="s2_a3"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="How much is daily revenue that we can expect from you?"
                    name="s2_a4"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="Have you tried search monetization before?"
                    name="s2_a5"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="How did you hear about Coinis?"
                    name="s2_a6"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label="Please provide a screenshot of a live example of a campaign you are currently running"
                    // name="s2_a1"
                    rules={[
                      {
                        required: true,
                        message: "Missing screenshot!",
                      },
                    ]}
                  >
                    <Dragger
                      name="liveCampaignScreenshots"
                      multiple={true}
                      maxCount={5}
                      beforeUpload={(image) => {
                        handleImageUpload(image, 'n2s_1');
                        return false;
                      }}
                      showUploadList={{
                        showRemoveIcon: false
                      }}
                    >
                      <div className={styles.buttonUpload}>
                        <UploadOutlined style={{ fontSize: "1.4rem" }} />{" "}
                        <span>Upload or drag image</span>
                      </div>
                    </Dragger>
                  </Form.Item>
                  <Form.Item
                    label="Please provide examples of images and titles of campaigns you are currently running"
                    // name="s2_a2"
                    rules={[
                      {
                        required: true,
                        message: "Missing images!",
                      },
                    ]}
                  >
                    <Dragger
                      name="campaignImages"
                      multiple={true}
                      maxCount={5}
                      beforeUpload={(image) => {
                        handleImageUpload(image, 'n2s_2');
                        return false;
                      }}
                      showUploadList={{
                        showRemoveIcon: false
                      }}
                    >
                      <div className={styles.buttonUpload}>
                        <UploadOutlined style={{ fontSize: "1.4rem" }} />{" "}
                        <span>Upload or drag image</span>
                      </div>
                    </Dragger>
                  </Form.Item>
                  <Form.Item
                    label="Please provide a list of keywords you are focused on"
                    name="s2_a3"
                    rules={[{ validator: validateKeywords }]}
                  >
                    <Select
                      mode="tags"
                      onChange={(values) => {
                        form.setFieldsValue({
                          s2_a3: values,
                        });
                      }}
                      tokenSeparators={[",", "/n"]}
                      placeholder="Enter or paste your keywords here"
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label="If you have a website, how do you plan to implement a search feed on your website?"
                    name="s2_a4"
                    extra="Note that every website needs to be approved prior to use."
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="Tell us more about how you aquire users"
                    name="s2_a5"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="How much is daily revenue that we can expect from you?"
                    name="s2_a6"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="What % of traffic is Native, Display, Search, Social?"
                    name="s2_a7"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="Do you do your own media buying or do you also give feeds to others?"
                    name="s2_a8"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="How many media buyers do you have in your team?"
                    name="s2_a9"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                  <p className={styles.feedFormTitle}>
                    With which feed providers have you previously worked with? Please write down the names of the companies and the representatives of the company.
                  </p>
                  <Form.List
                    name="s2_a10"
                    label="With which feed providers have you previously worked with? Please write down the names of the companies and the representatives of the company."
                    rules={[
                      {
                        validator: async (_, names) => {
                          names.length >= 3 ? setAddProviderVisible(false) : setAddProviderVisible(true)
                        },
                      },
                      {
                        validator: (_, value) => {
                          return new Promise((resolve, reject) => {
                            if (value && formatProviderInfo(value).length > 256) {
                              openWarningNotification({
                                message: "Character length limit exceded for feed providers information.",
                              });
                            }
                            else {
                              resolve();
                            }
                          })
                        }
                      }
                    ]}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} {...restField} name={name} className={styles.providerInfo} align="baseline">
                            <Form.Item label={restField.fieldKey === 0 ? "Company Name" : null} {...restField} name={[name, "company"]} rules={[{ required: true, message: "Missing company name" }]}>
                              <Input placeholder="Company Name" />
                            </Form.Item>
                            <Form.Item label={restField.fieldKey === 0 ? "Name" : null} {...restField} name={[name, "name"]} rules={[{ required: true, message: "Missing provider name" }]}>
                              <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label={restField.fieldKey === 0 ? "Email" : null} {...restField} name={[name, "email"]} rules={[{ required: true, type: "email", message: "Missing provider email" }]}>
                              <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label={restField.fieldKey === 0 ? " " : null}>
                              <Button disabled={restField.fieldKey === 0} icon={<MinusCircleOutlined />} onClick={() => remove(name)} />
                            </Form.Item>
                          </Space>
                        ))}
                        {addProviderVisible && <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Feed Provider
                          </Button>
                        </Form.Item>}
                        <Form.Item
                          label="Additional info (optional)"
                          extra="Compliance team might get in touch with the representatives of the companies you have provided, so make sure you include the correct name and email of the representative."
                        >
                          <Input.TextArea onChange={(e) => setAdditionalInfo(e.target.value)} placeholder="Enter your answer here" rows={1} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <p className={styles.feedFormExtra}>
                  </p>
                  <Form.Item
                    label="How did you hear about Coinis?"
                    name="s2_a11"
                    rules={[{ validator: validateTextarea }]}
                  >
                    <Input.TextArea placeholder="Enter your answer here" rows={1} />
                  </Form.Item>
                </>
              )}
            </>
          }
          <div className={styles.alignRight}>
            {!isFirstStep &&
              <Form.Item>
                <Button ghost type="primary" onClick={() => setIsFirstStep(true)}>
                  Back
                </Button>
              </Form.Item>
            }
            <Form.Item>
              <Button loading={feedLoading || uploadLoading} type="primary" htmlType="submit">
                {isFirstStep ? 'Continue' : 'Join Waitlist'}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal >
  );
};
