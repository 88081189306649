import React, { useEffect } from "react";
import { Button, Select, Row, Col, DatePicker, Checkbox } from "antd";
import styles from "../CoinisCampaigns.module.css";
import { ColumnsGroup } from "./ColumnsGroup";
// import { generatePrograms } from "../utils/filterOptions";
// import { useDebounce } from "../../../utils/hooks/useDebounce";
// import UserContext from "../../../contexts/userContext";
// import { coinisCampaignsService } from "../../../services/coinisCampaigns";

const { Option } = Select;

export const CampaignFilters = ({
  filters,
  setFilters,
  submit,
  checkedColumns,
  setCheckedColumns,
  allColumns,
  isArchiveIncluded,
  setIsArchiveIncluded,
  sorting
}) => {

  // const { userInfo } = useContext(UserContext);
  // const [autocompleteProgramLoading, setAutocompleteProgramLoading] =
  // useState(false);
  // const [searchProgramTerm, setSearchProgramTerm] = useState("");
  // const debounceProgram = useDebounce(searchProgramTerm, 300);
  // const [programs, setPrograms] = useState([]);

  // useEffect(() => {
  //   const getPrograms = async searchProgramTerm => {
  //     setAutocompleteProgramLoading(true);
  //     try {
  //       const res = await coinisCampaignsService.getProgramsAutocomplete(
  //         searchProgramTerm
  //       );
  //       let newPrograms = [...res.data];
  //       setPrograms(newPrograms);
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setAutocompleteProgramLoading(false);
  //     }
  //   };
  //   getPrograms(debounceProgram);
  // }, [debounceProgram]);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    submit({
      // filters: urlFilters || filters,
      filters,
      checkedColumns: urlCheckedColumns || checkedColumns,
    });
  };

  // fix warnings
  useEffect(() => {
    handleUpdate();
    // eslint-disable-next-line
  }, [sorting]);
  //fix warnings ^^

  return (
    <>
      <div className={styles.filtersContainer}>
        <div style={{ marginTop: "1%", paddingBottom: "0.5rem" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={5} xxl={4}>
              <span className={styles.spanMarginBottom}>Status</span>
              <Select
                size="medium"
                className={styles.selectStatuses}
                placeholder="All Statuses"
                allowClear
                onChange={value => {
                  setFilters({ ...filters, status: value });
                }}
              >
                <Option value="Active">Active</Option>
                <Option value="Paused">Paused</Option>
                <Option value="Pending">Pending</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={5} xxl={4}>
              <span className={styles.spanMarginBottom}>Type</span>
              <Select
                size="medium"
                className={styles.selectFormats}
                placeholder="All Formats"
                allowClear
                onChange={value => {
                  setFilters({ ...filters, type: value });
                }}
              >
                <Option value="CPC">CPC</Option>
                <Option value="RS">REVSHARE</Option>
                <Option value="CPA">CPA</Option>
                <Option value="CPM">CPM</Option>
              </Select>
            </Col>{" "}
            <Col xs={24} sm={12} md={8} lg={5} xxl={4}>
              <span className={styles.spanMarginBottom}>Date Created</span>
              <DatePicker
                style={{ width: "100%" }}
                onChange={value => {
                  setFilters({ ...filters, dateCreated: value });
                }}
              />
            </Col>{" "}
            {/* {userInfo.ow_tmp && (
              <Col xs={24} sm={12} md={8} lg={5} xxl={4}>
                <span className={styles.spanMarginBottom}>Program</span>
                <Select
                  mode="multiple"
                  onChange={value => setFilters({ ...filters, program: value })}
                  loading={autocompleteProgramLoading}
                  filterOption={false}
                  style={{ width: "100%" }}
                  placeholder="Select programs"
                  onSearch={setSearchProgramTerm}
                  value={filters.program}
                  allowClear
                >
                  {generatePrograms(programs || [])}
                </Select>
              </Col>
            )} */}
            <Col xs={24} sm={12} md={8} lg={4} xxl={4} className={styles.isArchivedCheckbox}>
              <Checkbox checked={isArchiveIncluded}
                onChange={e =>
                  setIsArchiveIncluded(e.target.checked)
                }>
                Include archived
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} md={8} lg={5} xxl={4}>
              <Button
                className={styles.applyFiltersBtn}
                style={{ marginTop: 22 }}
                onClick={handleUpdate}
                type="primary"
                size="medium"
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.bottomActions}>
        <div className={styles.rightActionBtns}>
          <div>
            <ColumnsGroup
              columnsData={allColumns}
              onChange={value => {
                setCheckedColumns(value);
              }}
              showUpdateButton={true}
              onUpdate={handleUpdate}
              checkedColumns={checkedColumns}
            />
          </div>
        </div>
      </div>
    </>
  );
};
