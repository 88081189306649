import React from "react";
import styles from "./../Layout.module.css";
import { RightCircleFilled, LeftCircleFilled } from "@ant-design/icons";

export const ModuleName = ({ currentModule, collapsed, onCollapsed }) => {
  const renderTitle = (currentModule, collapsed) => {
    switch (currentModule + collapsed) {
      case "offerwallfalse":
        return "Affiliate Programs";
      case "publisherfalse":
        return "Webmasters";
      case "searchfalse":
        return "Search Feed";
      default:
        return "\xa0\xa0\xa0";
    }
  };

  return (
    <div className={styles.sidebarModuleDisp}>
      <div className={styles.titleModuleContainer}>
        <span onClick={() => onCollapsed(!collapsed)}>
          {collapsed ? (
            <RightCircleFilled style={{ fontSize: 34 }} />
          ) : (
            <LeftCircleFilled style={{ fontSize: 34 }} />
          )}
        </span>
        <span style={{ marginBottom: ".5rem" }}>
          {renderTitle(currentModule, collapsed)}
        </span>
      </div>
      <div className={styles.mobileAppCointainer}>
        {!collapsed && (
          <>
            <p className={styles.blackLabel}>Download</p>
            <p className={styles.blueLabel}>Publisher App</p>
            <a href="https://play.google.com/store/apps/details?id=publisher.coinis.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
            <a href="https://apps.apple.com/us/app/coinis-publisher/id1620132880?itsct=apps_box_promote_link&itscg=30200">
              <img
                alt="Get it on App Store"
                src="/images/app-store-updated.png"
              />
            </a>
          </>
        )}
      </div>
    </div>
  );
};
