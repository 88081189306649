import React, { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import styles from "./WidgetPreviewWrapper.module.css";
import { Button, Popconfirm } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { openSuccessNotification } from "../../../../utils/notifications";
import classNames from "classnames";

export const WidgetPreviewWrapper = ({
  children,
  id,
  widget,
  handleOpenEdit,
  handleDelete,
  getTag,
}) => {
  const [codeVisible, setCodeVisible] = useState(false);
  return (
    <div className={styles.widgetWrapper}>
      <div className={styles.widgetHeader}>Widget {id + 1}</div>
      <div className={styles.widgetContent}>{children}</div>
      <div
        className={classNames(styles.widgetScriptDiv, {
          [styles.widgetScriptVisible]: codeVisible,
        })}
      >
        {widget.div}
      </div>
      <div
        className={classNames(styles.widgetFooter, {
          [styles.getTagFooter]: getTag,
        })}
      >
        {getTag ? (
          <>
            <Button
              icon={<CodeOutlined />}
              type="link"
              onClick={() => setCodeVisible((prev) => !prev)}
            >
              {codeVisible ? "See preview" : "See code"}
            </Button>
            {/* <Tooltip title="Copy code"> */}
            <CopyToClipboard text={widget && widget.div ? widget.div : ""}>
              <Button
                icon={<CopyOutlined />}
                type="primary"
                className={styles.copyLinkBtn}
                onClick={() => openSuccessNotification({ message: "Copied!" })}
              >
                COPY
              </Button>
              {/* <Button
                  icon={<CopyOutlined />}
                  type="link"
                  onClick={() =>
                    openSuccessNotification({ message: "Copied!" })
                  }
                /> */}
            </CopyToClipboard>
            {/* </Tooltip> */}
          </>
        ) : (
          <>
            <Button
              icon={<EditOutlined />}
              onClick={() => handleOpenEdit(widget, id)}
              type="link"
            />
            <Popconfirm
              title="Are you sure you want to delete this widget?"
              onConfirm={() => handleDelete(id)}
              okText="Yes"
              cancelText="No"
              placement="top"
            >
              <Button icon={<DeleteOutlined />} type="link" />
            </Popconfirm>
          </>
        )}
      </div>
    </div>
  );
};
