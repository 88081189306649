import React from "react";

import { Button, Tooltip } from "antd";
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { openSuccessNotification } from "../../../utils/notifications";
import { formatCountries, formatDevices, formatType, renderVerticals } from "../utils/index";

import styles from "../AdSamples.module.css";
import CopyToClipboard from "react-copy-to-clipboard";

export const Card = ({ details }) => {

  const download = async () => {
    const originalImage = details.image;
    const image = await fetch(originalImage + "?not-from-cache-please");

    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  };

  return (
    <div className={styles.sampleCard}>
      <div className={styles.detailsWrapper}>
        <div
          style={{ backgroundImage: "url('" + details.image + "')" }}
          className={styles.sampleImage}
        >
          <div
            className={styles.imageButtons}
          >
            <Tooltip title="Download image">
              <Button onClick={() => download()} icon={<DownloadOutlined />} />
            </Tooltip>
            <Tooltip title="Copy image URL">
              <CopyToClipboard text={details.image}>
                <Button onClick={() => openSuccessNotification({ message: "Copied!" })} icon={<CopyOutlined />} />
              </CopyToClipboard>
            </Tooltip>
          </div>
        </div>
        <div className={styles.cardContent}>
          <div>
            <div className={styles.rowContainer}>
              <h2>
                <span>{details.headline}</span>
                <CopyToClipboard text={details.headline}>
                  <Tooltip placement="topLeft" title="Copy headline">
                    <Button onClick={() => openSuccessNotification({ message: "Copied!" })} icon={<CopyOutlined />} />
                  </Tooltip>
                </CopyToClipboard>
              </h2>
            </div>
            <div className={styles.rowContainer}>
              <h3>
                <span>{details.description}</span>
                <CopyToClipboard text={details.description}>
                  <Tooltip placement="topLeft" title="Copy description">
                    <Button onClick={() => openSuccessNotification({ message: "Copied!" })} icon={<CopyOutlined />} />
                  </Tooltip>
                </CopyToClipboard>
              </h3>
            </div>
            <div className={styles.rowContainer}>
              <h4>Geos</h4>
              <div className={styles.cardSection}>
                {formatCountries(details.geos)}
              </div>
            </div>
            <div className={styles.rowContainer}>
              <h4>Device type</h4>
              <div className={styles.cardSection}>
                {formatDevices(details.device_types)}
              </div>
            </div>
            <div className={styles.rowContainer}>
              <h4>Traffic type</h4>
              <div className={styles.cardSection}>
                {formatType(details.traffic_type)}
              </div>
            </div>
          </div>
          <div className={styles.rowContainer}>
            {renderVerticals(details.verticals)}
          </div>
        </div>
      </div>
    </div >
  );
};
