import React, { useState } from "react";
import { Button } from "antd";
import styles from "./ZoneModal.module.css";
import { Link } from "react-router-dom";

import InPagePushImg from "../cardImages/inpagepush.json";
import PushRevShareImg from "../cardImages/pushrevshare.json";
import PushCPSImg from "../cardImages/pushcps.json";
import NativeAdsImg from "../cardImages/nativeads.json";
// import SmartlinkImg from "../cardImages/smartlink.json";
import ContextualAdsImg from "../cardImages/contextualads.json";
import Star from "../cardImages/star.json";

import Lottie from "react-lottie";

export const ZoneCard = ({
  title,
  paragraph,
  desc,
  path,
  disabled,
  select,
  img,
}) => {
  const [pausedAnim, setPausedAnim] = useState(true);
  const getDefaultOptions = img => {
    switch (img) {
      case "inpagepush":
        return {
          loop: false,
          autoplay: false,
          animationData: InPagePushImg,
        };
      case "pushrevshare":
        return {
          loop: false,
          autoplay: false,
          animationData: PushRevShareImg,
        };
      case "pushcps":
        return {
          loop: false,
          autoplay: false,
          animationData: PushCPSImg,
        };
      case "nativeads":
        return {
          loop: false,
          autoplay: false,
          animationData: NativeAdsImg,
        };
      // case "smartlink":
      //   return {
      //     loop: false,
      //     autoplay: false,
      //     animationData: SmartlinkImg,
      //   };
      case "contextualads":
        return {
          loop: false,
          autoplay: false,
          animationData: ContextualAdsImg,
        };
      default:
        return {
          loop: false,
          autoplay: false,
          animationData: InPagePushImg,
        };
    }
  };
  
  const starMark = {
    loop: true,
    autoplay: true,
    animationData: Star,
  }

  let arr = desc.split("/");
  return (
    <div
      className={styles.modalContent}
      onMouseEnter={() => setPausedAnim(false)}
      onMouseLeave={() => setPausedAnim(true)}
    >
      <h1>{title}</h1>
      <div className={styles.textWrapper}>
        <h3>{paragraph}</h3>
        <Lottie
          options={getDefaultOptions(img)}
          isPaused={pausedAnim}
          height={200}
          width={200}
        />
        {arr.map((paragraph, id) => (
          <div key={id} className={styles.mainTextWrapper}>
            <span>●</span>
            <div className={styles.mainText}>{paragraph}</div>
          </div>
        ))}
      </div>

      <Link
        to={{
          pathname: `/publisher/zones/${path}`,
        }}
      >
        <Button
          type="primary"
          className={styles.modalContentButton}
          disabled={disabled}
        >
          {select}
        </Button>
      </Link>

      {title === "Contextual Ads" ? (
        <div className={styles.starImage}>
          <Lottie
            options={starMark}
            height={90}
            width={90}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
