import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { parseModuleName } from "../utils";
import styles from "../Layout.module.css";

export const AgreementBox = ({
  currentModule,
  setAgreementModalVisible,
  isAgreementRequested,
  docuSignCondition,
  agreements,
  isAgencyRequested,
  isOfferwallRequested,
  isPublisherRequested,
  isSearchRequested,
}) => {
  const openModal = () => {
    setAgreementModalVisible(true);
  };

  const agreementTooltipContent = !docuSignCondition ? (
    <p style={{ margin: "0" }}>
      Please contact your Account Manager in order to sign the agreement. You
      must sign the agreement before your cumulative revenue reaches $5,000.
      Otherwise, you won't be able to withdraw the money from your account.
    </p>
  ) : isAgreementRequested ? (
    <p style={{ margin: "0" }}>
      Signing in progress. After both sides sign the agreement, you’ll receive
      an e-mail.
    </p>
  ) : (
    <p style={{ margin: "0" }}>
      You must sign the agreement before your cumulative revenue reaches $5,000.
      Otherwise, you won't be able to withdraw the money from your account.
    </p>
  );

  return (
    <Tooltip placement="bottom" title={agreementTooltipContent}>
      <div
        className={
          currentModule === "billing"
            ? styles.agreementContainerBilling
            : styles.agreementContainer
        }
        onClick={
          !isAgreementRequested && docuSignCondition ? openModal : undefined
        }
      >
        <div>
          <p>Missing agreement!</p>
          {isAgreementRequested ? (
            <p>Signing in progress.</p>
          ) : (
            <>
              <p>
                {docuSignCondition
                  ? "Click here to sign the agreement for"
                  : "Contact your Account Manager to sign the agreement for"}
              </p>
              <p>
                {parseModuleName(
                  agreements,
                  currentModule,
                  isAgencyRequested,
                  isOfferwallRequested,
                  isPublisherRequested,
                  isSearchRequested
                )}
                .
              </p>
            </>
          )}
        </div>
        <ExclamationCircleOutlined />
      </div>
    </Tooltip>
  );
};
