import React, { useState, useEffect, useReducer, useCallback } from "react";
import moment from "moment";

import { Table } from "./components/Table";
import styles from "./BillingAccountStatements.module.css";
import { useFetch } from "../../utils/hooks/useFetch";
import { CampaignFilters } from "./components/CampaignsFilters";
import { filtersReducer } from "./utils/reducers";
import { billingAccountStatementsService } from "../../services/billingAccountStatements";
import { TableTitle } from "./components/TableTitle";

export const BillingAccountStatements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: moment(),
  });
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const tableColumns = [
    {
      title: (
        <TableTitle
          text={"Date"}
          order={sorting["date"] ? sorting["date"] : false}
          value={"date"}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "date",
    },
    {
      title: (
        <TableTitle
          text={"Debit"}
          order={sorting["debit"] ? sorting["debit"] : false}
          value={"debit"}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "debit",
    },
    {
      title: (
        <TableTitle
          text={"Credit"}
          order={sorting["credit"] ? sorting["credit"] : false}
          value={"credit"}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "credit",
    },
    {
      title: (
        <TableTitle
          text={"Balance"}
          order={sorting["balance"] ? sorting["balance"] : false}
          value={"balance"}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "balance",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      render: (item) => {
        switch (item) {
          case "Offer Wall":
            return "Affiliate Programs";
          case "Website Owner":
            return "Webmasters";
          case "Extension":
            return "Search";
          case "N2S":
            return "N2S";
          default:
            return null;
        }
      },
    },
    {
      title: "Note",
      dataIndex: "note",
    },
  ];

  const dolarSignRefactor = (str) => {
    if (str.startsWith("-")) {
      return (
        "- $ " +
        Number(str.slice(1)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    } else {
      return (
        "$ " +
        Number(str).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  };

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      let sumOfDataRefactored = { ...sumOfData };
      if (sumOfData.debit) {
        sumOfDataRefactored.debit = dolarSignRefactor(sumOfData.debit);
      }
      if (sumOfData.credit) {
        sumOfDataRefactored.credit = dolarSignRefactor(sumOfData.credit);
      }
      return sumOfDataRefactored;
    }
  };

  const refactorTableData = (tableData) => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          refactor.debit = refactor.debit && dolarSignRefactor(refactor.debit);
          refactor.credit =
            refactor.credit && dolarSignRefactor(refactor.credit);
          refactor.balance =
            refactor.balance && dolarSignRefactor(refactor.balance);
          return {
            key: index,
            ...refactor,
          };
        })
      : "";
    return tableDataRefactored;
  };

  const loadMediaCampaigns = (cancelToken) =>
    billingAccountStatementsService.getStatementsPaged({
      selectedFilters,
      tableColumns,
      currentPage,
      pageSize,
      cancelToken,
      sorting,
    });

  const [
    {
      data: { totals: sumOfData, results: campaigns, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  useEffect(() => {
    dispatchSelectedFilters({
      payload: {
        filters: {},
        dateRange: { from: moment().subtract(30, "days"), to: moment() },
      },
    });
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters, sorting]);

  // const exportCampaings = (filters, dateRange) => {
  //   mediaCampaignsService.exportMediaCampaigns({
  //     filters,
  //     dateRange,
  //     checkedColumns,
  //   });
  // };

  return (
    <div className={styles.campaignsTable}>
      <h4>Account Statements</h4>
      <p>Follow your daily revenue by each platform</p>
      {/* <div className={styles.billingHeadInfo}>
        <div>
          <span>Balance</span>
          <span>$ 00,00</span>
        </div>
        <div>
          <span>Minimum payout</span>
          <span>5 USD</span>
        </div>
      </div> */}
      <CampaignFilters
        submit={(filters) => {
          dispatchSelectedFilters({
            type: "",
            payload: { filters, dateRange },
          });
        }}
        filters={filters}
        setFilters={setFilters}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        tableColumns={tableColumns}
        campaigns={refactorTableData(campaigns)}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        selectedFilters={selectedFilters}
        sumOfData={sumOfData}
        sumOfDataRefactor={sumOfDataRefactor}
      />
    </div>
  );
};
