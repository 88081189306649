import { Spin } from "antd";
import React, { useCallback, useEffect } from "react";
import styles from "./StatisticsLineChart.module.css";
import Highcharts from "highcharts";
// import { openWarningNotification } from "../../../../utils/notifications";

const defaultOptions = {
  chart: {
    zoomType: "x",
  },
  title: {
    text: "",
  },
  xAxis: [
    {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: "Date",
      },
    },
    {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: "Date",
      },
      opposite: true,
    },
  ],
  yAxis: [],
  tooltip: {
    shared: true,
  },
  legend: {
    floating: false,
    layout: "vertical",
    navigation: {
      activeColor: "#3E576F",
      animation: true,
      arrowSize: 3,
      inactiveColor: "#CCC",
      style: {
        fontWeight: "bold",
        color: "#333",
        fontSize: "12px",
      },
    },
    margin: 0,
    labelFormatter: function () {
      let max = this.xData[this.xData.length - 1];
      let min = this.xData[0];
      return (
        Highcharts.dateFormat("%b, %e", new Date(min)) +
        " - " +
        Highcharts.dateFormat("%b, %e", new Date(max)) +
        " " +
        this.name
      );
    },
    backgroundColor:
      (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
  },
  series: [],
};

export const StatisticsLineChart = ({
  loading,
  chartData,
  compareChartData,
  selectedMetrics,
}) => {
  const addNewYAxis = useCallback((name, data, index, xAxis = 0, options) => {
    var text;
    switch (name) {
      case "monetized_searches":
        text = "Monetized Searches";
        break;
      case "total_searches":
        text = "Total Searches";
        break;
      case "clicks":
        text = "Clicks";
        break;
      case "revenue":
        text = "Revenue";
        break;
      default:
        text = name;
    }
    if (index > options.yAxis.length - 1) {
      let format = "{value}";
      if (name === "spent" || name === "earned") {
        format = "$ " + format;
      }

      const colorIndex = options.series.length;
      const color = Highcharts.getOptions().colors[colorIndex];

      options.yAxis.push({
        labels: {
          format,
          style: {
            color,
          },
        },
        title: {
          text: text,
          style: {
            color,
          },
        },
        opposite: true,
      });
    }

    options.series.push({
      name: text,
      type: "spline",
      xAxis,
      yAxis: index,
      data,
    });
  }, []);

  const parseChartData = useCallback(
    chartData => {
      // checking if response data is for the chart or the table
      if (chartData && chartData.values) {
        let options = { ...defaultOptions, series: [], yAxis: [] };
        let yAxisIndex = 0;
        const metrics = selectedMetrics;
        metrics.forEach((metric, metricIndex) => {
          if (metric) {
            const values = chartData.values.map(value => [
              value[0],
              value[metricIndex + 1],
            ]);
            addNewYAxis(metric, values, yAxisIndex, 0, options);
            if (compareChartData && compareChartData.values) {
              const values = compareChartData.values.map(value => [
                value[0],
                value[metricIndex + 1],
              ]);
              addNewYAxis(metric, values, yAxisIndex, 1, options);
            }
          }
          yAxisIndex++;
          if (yAxisIndex > options.yAxis.length) {
            yAxisIndex = options.yAxis.length;
          }
        });
        if (options.series.length > 0) {
          Highcharts.chart("chart-wrapper", options);
        }
      } else {
        // openWarningNotification({
        //   message: `There is no data for this filters`,
        //   duration: 8,
        // });
      }
    },
    [addNewYAxis, compareChartData, selectedMetrics]
  );

  // const getChartData = async filters => {
  //   setLoading(true);
  //   let options = { ...defaultOptions, series: [], yAxis: [] };
  //   let yAxisIndex = 0;
  //   Highcharts.chart("chart-wrapper", options);

  //   const promises = filters.lines.map(async (line, index) => {
  //     const chartData = await statisticServices.channelChart(
  //       getChartQuery(line, filters.perHour, filters.dateRange)
  //     );
  //     let compareResults = "";
  //     if (filters.compareDateRange.from && filters.compareDateRange.to) {
  //       compareResults = await statisticServices.channelChart(
  //         getChartQuery(line, filters.perHour, filters.compareDateRange)
  //       );
  //     }
  //     if (chartData && chartData.values) {
  //       if (line.metrics.length > 0) {
  //         const metrics = line.metrics;
  //         metrics.forEach((metric, metricIndex) => {
  //           const values = chartData.values.map(value => [
  //             value[0],
  //             value[metricIndex + 1],
  //           ]);
  //           addNewYAxis(metric, values, yAxisIndex, 0, options);
  //           if (compareResults && compareResults.values) {
  //             const values = compareResults.values.map(value => [
  //               value[0],
  //               value[metricIndex + 1],
  //             ]);
  //             addNewYAxis(metric, values, yAxisIndex, 1, options);
  //           }
  //           yAxisIndex++;
  //           if (yAxisIndex > options.yAxis.length) {
  //             yAxisIndex = options.yAxis.length;
  //           }
  //         });
  //       }
  //     } else {
  //       openWarningNotification({
  //         message: `There are no data for this filters on line ${index + 1}`,
  //         duration: 8,
  //       });
  //     }
  //   });
  //   await Promise.all(promises);
  //   if (options.series.length > 0) {
  //     Highcharts.chart("chart-wrapper", options);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    Highcharts.chart("chart-wrapper", defaultOptions);
    chartData !== null && parseChartData(chartData);
  }, [chartData, parseChartData]);

  return (
    <Spin spinning={loading}>
      <div
        id="chart-wrapper"
        style={{ height: "500px" }}
        className={styles.lineChartContainer}
      />
    </Spin>
  );
};
