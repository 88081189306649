import {
  Button,
  Radio,
  DatePicker,
  Row,
  Col,
  Select,
  Modal,
  Tooltip,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import styles from "./Filters.module.css";
import {
  ExportOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ColumnsGroup } from "./ColumnsGroup";
import { reportsService } from "../../../services/reports";
import { generateChannels, generateCountries } from "../utils/filterOptions";
import moment from "moment";
import {
  MERGE_TABLE_OPTIONS,
  columnFtPreset,
  columnTqPreset,
} from "../utils/columnsList";
import { searchReportsExtensionService } from "../../../services/searchReportsExtension";
import { ColumnInfo } from "../../../components/ColumnInfo";
import { FilterPresetModal } from "../../../components/FilterPresetModal";
import UserFiltersContext from "../../../contexts/userFiltersContext";
import { showApiErrors } from "../../../utils/showApiErrors";
import { userFiltersService } from "../../../services/userFilters";
import { openSuccessNotification } from "../../../utils/notifications";
// import { TqBriefModal } from "./TqBriefModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { confirm } = Modal;

export const Filters = ({
  onSubmit,
  allColumns,
  dateRange,
  setDateRange,
  handleExport,
  additionalInfo,
  mergedTable,
  setMergedTable,
}) => {
  const [checkedColumns, setCheckedColumns] = useState(columnTqPreset);
  const [countries, setCountries] = useState([]);
  const [filters, setFilters] = useState({});
  const [channels, setChannels] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const [columnInfoVisible, setColumnInfoVisible] = useState(false);
  // const [tqBriefVisible, setTqBriefVisible] = useState(false);

  const {
    userFilters,
    setUserFilters,
    userSavedFilter,
    setUserSavedFilter,
    userSavedFilterLoading,
    module,
  } = useContext(UserFiltersContext);

  useEffect(() => {
    if (mergedTable === "tq") {
      setCheckedColumns(columnTqPreset);
      handleUpdate(null, columnTqPreset);
    } else {
      setCheckedColumns(columnFtPreset);
      handleUpdate(null, columnFtPreset);
    }
    // eslint-disable-next-line
  }, [mergedTable]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await reportsService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getChannels = async () => {
      setAutocompleteLoading(true);
      try {
        const res = await searchReportsExtensionService.getChannels();
        if (res) {
          setChannels(res);
        }
      } catch (e) {
        console.log(e);
        // TODO: handle error?
      } finally {
        setAutocompleteLoading(false);
      }
    };
    getCountries();
    getChannels();
  }, []);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      // filters: urlFilters || filters,
      filters,
      dateRange,
      checkedColumns: urlCheckedColumns || checkedColumns,
    });
  };

  useEffect(() => {
    handleUpdate();
    // eslint-disable-next-line
  }, []);

  const setSavedFilter = (name) => {
    let selectedSavedFilter = userFilters.find((el) => el.name === name);
    setFilters(JSON.parse(selectedSavedFilter.filter));
  };

  const handleDeleteFilter = async (id, name) => {
    const newFilters = userFilters.filter(
      (filter) => filter.name.toLowerCase() !== name
    );
    try {
      await userFiltersService.deleteFilter(id);
      openSuccessNotification({
        message: "Filter preset successfully deleted!",
      });
      if (userSavedFilter === name) {
        setUserSavedFilter(null);
        setFilters({});
      }
      setUserFilters(newFilters);
    } catch (e) {
      showApiErrors(e);
    }
  };

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: `Are you sure that you want to delete ${name} filter?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteFilter(id, name);
      },
    });
  };

  const handleOnChangeGroupColumns = (e) => {
    if (e.target.value === "filtered_traffic") {
      setFilters({ ...filters, countries: [] });
    }
    setMergedTable(e.target.value);
  };

  // function generateTqOptions() {
  //   const options = [];
  //   for (let i = -1; i <= 10; i += 0.5) {
  //     if (i !== -0.5) {
  //       options.push({ value: i, label: i.toString() });
  //     }
  //   }
  //   return options;
  // }

  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="last_report"
          size="large"
        >
          <Radio.Button
            value="last_report"
            onClick={() =>
              setDateRange({
                from: moment().subtract(2, "days"),
                to: moment(),
              })
            }
          >
            Last Report
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({ from: moment().subtract(6, "days"), to: moment() })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div className={styles.advancedFilters}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col md={24} lg={6} xxl={8}>
              <span className={styles.spanMarginBottom}>Date range</span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={false}
                // className={styles.rangeDatePicker}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Filter presets</span>
              <Select
                className={styles.filtersSelect}
                allowClear
                value={userSavedFilter}
                onChange={(value) => {
                  setUserSavedFilter(value);
                  if (value) {
                    setSavedFilter(value);
                  }
                }}
                style={{ width: "100%" }}
                loading={userSavedFilterLoading}
                placeholder="Select filter preset"
              >
                {userFilters.map(({ id, name }) => (
                  <Option className={styles.optionFilter} key={id} value={name}>
                    {name}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirm(id, name);
                      }}
                      className={styles.deleteBtn}
                    >
                      <DeleteOutlined
                        style={{
                          float: "right",
                          color: "#ff5600",
                          cursor: "pointer",
                          marginTop: "3px",
                        }}
                      />
                    </span>
                  </Option>
                ))}
              </Select>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xxl={4}
              className={styles.limitTooltip}
            >
              <FilterPresetModal
                filters={filters}
                userFilters={userFilters}
                setUserSavedFilter={setUserSavedFilter}
                currentModule={module}
                onUpdate={setUserFilters}
              />
            </Col>
          </Row>
          <div style={{ marginTop: "1%" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Country</span>
                <Select
                  disabled={mergedTable === "filtered_traffic"}
                  mode="multiple"
                  onChange={(value) =>
                    setFilters({ ...filters, countries: value })
                  }
                  style={{ width: "100%" }}
                  placeholder="Select country"
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  value={filters.countries}
                  allowClear
                >
                  {generateCountries(countries || [])}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Search Channel</span>
                <Select
                  mode="multiple"
                  onChange={(value) =>
                    setFilters({ ...filters, search_channel: value })
                  }
                  loading={autocompleteLoading}
                  // filterOption={false}
                  style={{ width: "100%" }}
                  placeholder="Select channels"
                  value={filters.search_channel}
                  allowClear
                >
                  {generateChannels(channels)}
                </Select>
              </Col>
              {/* <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>TQ</span>
                <Select
                  mode="multiple"
                  onChange={(value) => setFilters({ ...filters, tq: value })}
                  style={{ width: "100%" }}
                  placeholder="Select TQ value"
                  value={filters.tq}
                  allowClear
                  options={generateTqOptions()}
                />
              </Col> */}
              <Col xs={24} sm={12} md={12} lg={6} xxl={4}>
                <Button
                  style={{ marginTop: "22px", height: "32px", width: "100%" }}
                  className={styles.applyFiltersBtn}
                  type="primary"
                  size="medium"
                  onClick={handleUpdate}
                >
                  Apply Filters
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={styles.bottomActions}>
        <div className={styles.rightActionBtns}>
          <div>
            <ColumnsGroup
              columnsData={allColumns}
              onChange={(value) => {
                setCheckedColumns(value);
              }}
              showUpdateButton={true}
              onUpdate={handleUpdate}
              checkedColumns={checkedColumns}
            />
            <Button
              className={styles.columnsBtn}
              onClick={() => setColumnInfoVisible(true)}
            >
              Column Info
            </Button>
            <Tooltip title="Column preset">
              <Radio.Group
                className={styles.columnPresetRadio}
                options={MERGE_TABLE_OPTIONS}
                onChange={handleOnChangeGroupColumns}
                value={mergedTable}
                optionType="button"
                buttonStyle="solid"
              />
            </Tooltip>
          </div>
          <div className={styles.lastImportExport}>
            {additionalInfo?.complete_flag === 0 && (
              <p className={styles.incompleteData}>
                <ExclamationCircleOutlined /> Data not completed.
              </p>
            )}
            <p>{additionalInfo?.last_import}</p>
            <div>
              {/* <Button
                onClick={() => setTqBriefVisible(true)}
                className={styles.columnsBtn}
              >
                TQ Brief
              </Button> */}
              <Button
                onClick={handleExport}
                className={styles.columnsBtn}
                icon={<ExportOutlined />}
              >
                Export to CSV
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ColumnInfo
        visible={columnInfoVisible}
        setVisible={setColumnInfoVisible}
        allColumns={allColumns}
      />
      {/* <TqBriefModal
        visible={tqBriefVisible}
        setVisible={setTqBriefVisible}
        onFilter={(value) => {
          setFilters({ ...filters, search_channel: value });
          onSubmit({
            filters: { ...filters, search_channel: value },
            dateRange,
            checkedColumns: checkedColumns,
          });
        }}
      /> */}
    </>
  );
};
