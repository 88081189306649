import { callApi } from "./api";

class AgreementSigningService {
  async forwardAgreement(data) {
    return await callApi({
      url: "/api/billing/agreements/initiate/",
      method: "POST",
      data
    });
  }
}

export const agreementSigningService = new AgreementSigningService();