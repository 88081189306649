import { callApi } from "./api";
import moment from "moment";

const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        ordering.push("-" + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class BillingPaymentHistory {
  async getPaymentsPaged({
    selectedFilters,
    currentPage,
    tableColumns,
    pageSize,
    cancelToken,
    sorting
  }) {
    const { filters, dateRange } = selectedFilters;
    let colParameter = "platform,id";

    Object.keys(tableColumns).forEach(function (key) {
      if (tableColumns[key]) {
        colParameter += "," + key;
      }
    });
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: moment(dateRange.from).format("YYYY-MM-DD"),
      date_to: moment(dateRange.to).format("YYYY-MM-DD"),
      platform: filters.platform && filters.platform,
      ordering: ordering && ordering.join(",")
    });
    const response = await callApi({
      url: `/api/billing/invoice_history/${query}`,
      cancelToken,
    });
    return { results: response.data.results, count: response.data.count };
  }

  async uploadInvoice(id, data) {
    const response = await callApi({
      url: `/api/billing/invoice/${id}/upload/`,
      method: "POST",
      data,
    });
    return response;
  }

  async deleteInvoice(id) {
    const response = await callApi({
      url: `/api/billing/invoice/${id}/delete/`,
      method: `DELETE`
    });
    return response;
  }
}

export const billingPaymentHistoryService = new BillingPaymentHistory();
