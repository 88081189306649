import React from "react";
import { Tooltip } from "antd";
import moment from "moment";
import styles from "./HeadInfo.module.css";

export const ColumnGraph = props => {
  //remove when clicks are fixed vvv
  if (props.type === "4") {
    props = {
      ...props,
      data: props.data.map(item => [...item, 0]),
    };
  }
  //remove when clicks are fixed ^^^

  let sum = 0;
  for (let i in props.data) {
    sum += props.data[i][props.type];
  }
  sum = sum !== 0 ? sum : 1;
  // const tempDolar = props.info === "$" ? props.info : "";
  return (
    <div className={styles.mainDiv}>
      {props.data.map((el, index) => {
        const dateString = moment.unix(el[0] / 1000).format("DD-MM-YYYY");
        const columnValue =
          props.type === "3"
            ? "$ " + Number(el[props.type]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : el[props.type];
        return (
          <Tooltip
            placement="top"
            title={dateString + ": " + columnValue}
            key={index}
          >
            <div
              style={{
                height:
                  300 * (el[props.type] / sum) + 4 > 86
                    ? "86px"
                    : 300 * (el[props.type] / sum) + 4 + "px",
                background: props.color,
              }}
              className={`${styles["col-graph" + index]} ${
                styles["col-graph"]
              }`}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};
