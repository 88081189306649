import styles from "../CoinisCampaigns.module.css";
import moment from "moment";
import { StatusTableCol } from "../components/StatusTableCol";
import { ZoneTypeCol } from "../components/ZoneTypeCol";
import { Link } from "react-router-dom";
import { PricingModelColumn } from "../components/PricingModelColumn";
import { TableTitleSort } from "../components/TableTitleSort";

export const initialSelectedColumns = {
  title: true,
  website: true,
  conversions: true,
  pricing_model: true,
  format: true,
  date_created: true,
  status: true,
  type: true,
};

export const staticColumns = [
  {
    title: "Title",
    dataIndex: "title",
    render: (title, item) => {
      const pathEdit =
        item.format === "push" ? `${item.format}-${item.type}` : item.format;
      return (
        <Link
          to={{ pathname: `/publisher/zones/${pathEdit}/${item.id}` }}
          target="_blank"
          style={item.status === "suspended" ? { color: "#d1d1d1" } : {}}
          onClick={(e) => item.status === "suspended" && e.preventDefault()}
        >
          {title}
        </Link>
      );
    },
    // align: "center",
  },
  {
    title: "Website",
    dataIndex: "website",
    // align: "center",
  },
];

export const allColumns = (sorting, handleSortingClick) => {
  return [
    {
      title: (
        <TableTitleSort
          text={'Created'}
          order={sorting['date_created'] ? sorting['date_created'] : false}
          value={'date_created'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: "date_created",
      render: (date_created) =>
        date_created && moment(date_created).format("DD MMM YYYY hh:mm:ss"),
    },
    {
      title: "Pricing Model",
      dataIndex: "type",
      align: "center",
      render: (type, item) => <PricingModelColumn type={type} item={item} />,
    },
    {
      title: "Type",
      dataIndex: "format",
      align: "center",
      render: (zoneType) => {
        return <ZoneTypeCol zoneType={zoneType} />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      className: styles.statusColumn,
      render: (status) => {
        return <StatusTableCol status={status} />;
      },
    },
  ]
};
