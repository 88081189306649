import React from "react";
import styles from "../Layout.module.css";
import { Link } from "react-router-dom";
import { getHomePath } from "../utils";

export const Logo = ({ collapsed, currentModule }) => {
  return (
    <Link to={getHomePath(currentModule)}>
      <div className={styles.logo}>
        <img
          src={collapsed ? "/images/logo-small.png" : "/images/logo.svg"}
          alt="Logo"
        />
        <span>
          {currentModule === "search_feed" ? "Search BI" : "Publisher"}
        </span>
      </div>
    </Link>
  );
};
