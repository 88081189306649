export const filtersReducer = (state, action) => {
  switch (action.type) {
    case "debounce":
      return {
        ...state,
        filters: { ...state.filters, name: action.payload },
      };
    default:
      return action.payload;
  }
};
