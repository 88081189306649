import React from "react";
import { Table as AntTable, Pagination } from "antd";
import styles from "../CoinisCampaigns.module.css";
import { ModalPrice } from "./ModalPrice";
import { ModalLink } from "./ModalLink";

export const Table = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  tableColumns,
  modalPriceVisible,
  setModalPriceVisible,
  modalLinkVisible,
  setModalLinkVisible,
}) => {
  return (
    <>
      <AntTable
        className={styles.antTableContent}
        columns={tableColumns}
        // dataSource={campaigns}
        dataSource={campaigns}
        loading={loadingCampaigns}
        pagination={false}
        rowKey={({ channel }) => channel}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={["10", "20", "30", "50", "100"]}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
              showTotal={(total) => {
                if(total) return <div>Showing {(currentPage-1)*pageSize + 1} to {(total < currentPage * pageSize) ? total : currentPage * pageSize} of {total} entries.</div>;
              }}  
            />
          );
        }}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
      <ModalPrice
        modalPriceVisible={modalPriceVisible}
        setModalPriceVisible={setModalPriceVisible}
      />
      <ModalLink
        modalLinkVisible={modalLinkVisible}
        setModalLinkVisible={setModalLinkVisible}
      />
    </>
  );
};
