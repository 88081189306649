// import { WidgetOne } from "../adThemes/WidgetOne";
// import { WidgetThree } from "../adThemes/WidgetThree";

// export const renderWidget = (
//   widgetStyle,
//   backgroundColor,
//   contentColor,
//   contentRadius,
//   adPosition
// ) => {
//   switch (widgetStyle) {
//     case "widget1":
//       return (
//         <WidgetOne
//           backgroundColor={backgroundColor}
//           contentColor={contentColor}
//           contentRadius={contentRadius}
//           adPosition={adPosition}
//         />
//       );
//     case "widget3":
//       return (
//         <WidgetThree
//           backgroundColor={backgroundColor}
//           contentColor={contentColor}
//           contentRadius={contentRadius}
//           adPosition={adPosition}
//         />
//       );
//     default:
//       return null;
//   }
// };

export const extractWidgets = widgetObj => {
  const temp = Object.entries(widgetObj);
  const widgets = [];
  temp.forEach(item => {
    if (item[0].includes("widget_headline_") && item[1]) {
      const widgetId = Number(item[0].slice(16));
      widgets[widgetId] = { ...widgets[widgetId], widgetHeadline: item[1] };
    } else if (item[0].includes("widget_style_") && item[1]) {
      const widgetId = Number(item[0].slice(13));
      widgets[widgetId] = { ...widgets[widgetId], widgetStyle: item[1] };
    } else if (item[0].includes("div_") && item[1]) {
      const widgetId = Number(item[0].slice(4));
      widgets[widgetId] = { ...widgets[widgetId], div: item[1] };
    }
  });
  return widgets.filter(item => item);
};

export const parseWidgetsToPayload = widgets => {
  const temp = {};
  widgets.forEach((item, i) => {
    temp[`widget_headline_${i + 1}`] = item.widgetHeadline;
    temp[`widget_style_${i + 1}`] = item.widgetStyle;
  });
  for (let y = widgets.length + 1; y <= 6; y++) {
    temp[`widget_headline_${y}`] = null;
    temp[`widget_style_${y}`] = null;
  }
  return temp;
};
