import React from "react";
import { Modal } from "antd";
import { Player } from "video-react";

import styles from "./Tutorial.module.css";

export const TutorialVideo = ({ source, showVideo, setShowVideo }) => {
  return (
    <Modal
      className={styles.videoWrapper}
      centered
      title=""
      width={1100}
      visible={showVideo}
      footer={null}
      onCancel={() => setShowVideo(false)}
    >
      <Player
        autoPlay
      >
        <source
          src={source}
          type="video/mp4"
        />
      </Player>
    </Modal>
  );
};
