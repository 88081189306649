import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React from "react";
import styles from "./HeadInfo.module.css";
import classNames from "classnames";

export const HeadCardMissingDates = ({ missingDates, missingDatesLoading }) => {
  const generateMissingDates = (dates, loading) => {
    return loading ? (
      <LoadingOutlined spin />
    ) : dates.length > 0 && dates.length < 9 ? (
      dates.join(", ")
    ) : dates.length > 0 ? (
      <>
        {dates.slice(0, 7).join(", ")}
        <Popover
          placement="topLeft"
          title={<p style={{ color: "#555", margin: 0 }}>Missing dates</p>}
          content={
            <p style={{ maxWidth: 300, color: "#999" }}>{dates.join(", ")}</p>
          }
          trigger="click"
        >
          <button className={styles.seeMoreBtn}>see more</button>
        </Popover>
      </>
    ) : (
      "Looks like you are all up to date!"
    );
  };
  return (
    <div className={classNames(styles.infoCard, styles.infoCardMissing)}>
      <div className={styles.dashboardBoxMissing}>
        <p className={styles.missingDatesTitle}>Missing dates</p>
        <p className={styles.missingDatesInfo}>
          Statistics are updated each day until 12:30 UTC and the data might be
          late.*
        </p>
        <div className={styles.missingDatesList}>
          <p>{generateMissingDates(missingDates, missingDatesLoading)}</p>
        </div>
      </div>
    </div>
  );
};
