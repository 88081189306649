import { Pagination, Table } from "antd";
import React, { useState, useCallback, useEffect } from "react";

import { useFetch } from "../../utils/hooks/useFetch";
import { Filters } from "./components/Filters";
import { TableTitle } from "./components/TableTitle";
// import { NestedTable } from "./components/NestedTable";
import styles from "./Reports.module.css";
import moment from "moment";
import {
  columnTemplate,
  refactorTableData,
  sumOfDataRefactor,
} from "./utils/columnsList";
import { openWarningNotification } from "../../utils/notifications";
import { numberWithSpaces } from "../../utils/abbreviateNumber";
import { agencyService } from "../../services/agencyService";

export const AgencyReportsHourly = ({ location }) => {
  const [columnsData] = useState(columnTemplate);
  const [tableColumns, setTableColumns] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const pageSizeOptions = [10, 20, 30, 50, 100];

  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(1, "days"),
    to: moment().subtract(1, "days"),
  });
  const [sorting, setSorting] = useState({
    // date: "descend",
  });

  // last import & table color flag

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting]
  );
  const updateColumns = useCallback(
    (value) => {
      setTableColumns(() => {
        let newTableColumn = [];
        columnTemplate
          .filter((column) => value.checkedColumns[column["value"]])
          .forEach(function ({
            text,
            value,
            tooltip,
            render,
            align,
            fixed,
            bold,
          }) {
            newTableColumn.push({
              title: (
                <TableTitle
                  text={text}
                  order={sorting[value] ? sorting[value] : false}
                  value={value}
                  align={align}
                  tooltip={tooltip}
                  onSortingClick={handleSortingClick}
                  bold={bold}
                />
              ),
              dataIndex: value,
              align,
              fixed,
              render,
            });
          });
        return newTableColumn;
      });
    },
    [sorting, handleSortingClick]
  );

  const loadZoneData = (cancelToken) => {
    return agencyService.getStatsPagedHourly({
      selectedFilters,
      currentPage,
      pageSize,
      sorting,
      cancelToken,
    });
  };

  const [
    {
      data: { total: sumOfData, table: tableData, rows: totalItems },
      isFetching,
    },
    getZoneData,
  ] = useFetch(loadZoneData);
  const loading = isFetching;

  useEffect(() => setCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getZoneData();
    }
  }, [sorting, pageSize, selectedFilters, getZoneData, currentPage]);

  // sorting by date and revenue by default
  useEffect(() => {
    const selectedColumns = tableColumns.map((item) => item.dataIndex);
    if (
      selectedColumns.includes("date") &&
      selectedColumns.includes("hour") &&
      selectedColumns.includes("revenue")
    ) {
      setSorting({ date: "descend", hour: "descend", revenue: "descend" });
    } else if (
      selectedColumns.includes("date") &&
      selectedColumns.includes("revenue")
    ) {
      setSorting({ date: "descend", revenue: "descend" });
    } else {
      selectedColumns.includes("date")
        ? setSorting({ date: "descend" })
        : setSorting({ revenue: "descend" });
    }
  }, [tableColumns]);

  const handleExport = () => {
    if (selectedFilters.filters) {
      agencyService.exportReportsHourly({
        selectedFilters,
        currentPage,
        pageSize,
        sorting,
        action: false,
      });
    } else {
      openWarningNotification({
        message: "Please update filters before trying to export table.",
      });
    }
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <h2 className={styles.pageTitle}>Statistics</h2>
      </div>
      <Filters
        campaign_id={location.campaign_id}
        handleExport={handleExport}
        onSubmit={(value) => {
          updateColumns(value);
          setSelectedFilters(value);
        }}
        allColumns={columnsData}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        className={styles.mainTableContainer}
        style={{ marginTop: "2%" }}
        columns={tableColumns}
        rowKey={({ key }) => key}
        dataSource={refactorTableData(tableData)}
        loading={loading}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
        pagination={false}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={(value) => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
              showTotal={(total) => {
                if (total)
                  return (
                    <div>
                      Showing {(currentPage - 1) * pageSize + 1} to{" "}
                      {total < currentPage * pageSize
                        ? total
                        : currentPage * pageSize}{" "}
                      of {total} entries.
                    </div>
                  );
              }}
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor(sumOfData);
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {numberWithSpaces(sumOfDataRefactored[el.dataIndex])}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
      />
    </div>
  );
};
