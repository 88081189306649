export const abbrNum = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces);
  let abbrev = ["K", "M", "B", "T"];
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      number += abbrev[i];
      break;
    }
  }
  if (typeof number !== "string") {
    number = parseFloat(number).toFixed(2);
  }
  return number;
};

export const numberWithSpaces = (x) => {
  if (!x) {
    return x;
  }
  let isPercentage = false;
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (parts[1]) {
    if (parts[1].includes(" %")) isPercentage = true;
    let decimalOne = parts[1][0] ? parts[1][0] : 0;
    let decimalTwo = parts[1][1] ? parts[1][1] : 0;
    parts[1] = decimalOne + decimalTwo;
  }
  return isPercentage ? parts.join(".") + " %" : parts.join(".");
};
