import { callApi } from "./api";

class PublisherForms {
  async createZone(data) {
    return await callApi({
      url: "/api/publishers/zone_app/create/",
      method: "POST",
      data,
    });
  }

  // ZONE
  async getZone(id) {
    return await callApi({
      url: `/api/publishers/zones/${id}/`,
      method: "GET",
    });
  }
  async editZone(data, id) {
    return await callApi({
      url: `/api/publishers/zones/${id}/edit/`,
      method: "POST",
      data,
    });
  }
  async deleteZone(id) {
    return await callApi({
      url: `/api/publishers/zones/${id}/delete/`,
      method: "DELETE",
    });
  }

  // APP
  async getApp(id) {
    return await callApi({
      url: `/api/publishers/apps/${id}/`,
      method: "GET",
    });
  }
  async editApp(data, id) {
    return await callApi({
      url: `/api/publishers/apps/${id}/edit/`,
      method: "POST",
      data,
    });
  }
  async deleteApp(id) {
    return await callApi({
      url: `/api/publishers/apps/${id}/delete/`,
      method: "DELETE",
    });
  }

  // WIDGET
  async getWidget(id) {
    return await callApi({
      url: `/api/publishers/contextual/${id}/`,
      method: "GET",
    });
  }
  async editWidget(data, id) {
    return await callApi({
      url: `/api/publishers/contextual/${id}/edit/`,
      method: "POST",
      data,
    });
  }
  async deleteWidget(id) {
    return await callApi({
      url: `/api/publishers/contextual/${id}/delete/`,
      method: "DELETE",
    });
  }

  async verifyDomain(domain, id) {
    return await callApi({
      url: `/api/publishers/apps/check_file?domain=${domain}&id=${id}`,
    });
  }

  async downloadSwFile() {
    window.location = `/api/publishers/apps/get_sw`;
  }

  async requestReapproval(data) {
    return await callApi({
      url: `/api/publishers/reapprove/`,
      method: "POST",
      data,
    });
  }

  async getCategories() {
    return await callApi({
      url: "/api/omb/iab/",
    });
  }
}

export const publisherFormsService = new PublisherForms();
