import React from "react";
import {
  DashboardOutlined,
  LineChartOutlined,
  NotificationOutlined,
  UserOutlined,
  CodeOutlined,
  FileTextFilled,
  TagOutlined,
  // AppstoreOutlined,
  CreditCardFilled,
  FileFilled,
  FileProtectOutlined,
  // AppstoreOutlined,
  // KeyOutlined,
  // ControlOutlined,
  UploadOutlined,
  // HistoryOutlined
} from "@ant-design/icons";

import { auth } from "../services/auth";
import { MyProfile } from "../pages/MyProfile";
import { CoinisReports } from "../pages/CoinisReports";
// import { CoinisRequestHistory } from "../pages/CoinisRequestHistory";
import { CoinisCampaigns } from "../pages/CoinisCampaigns";
import { CoinisDashboard } from "../pages/CoinisDashboard";
import { CoinisAPIIntegration } from "../pages/CoinisAPIIntegration";
// import { CoinisGlobalSettings } from "../pages/CoinisGlobalSettings";

import { PublisherDashboard } from "../pages/PublisherDashboard";
import { PublisherZones } from "../pages/PublisherZones";
import { PublisherReports } from "../pages/PublisherReports";
import { PublisherAPIIntegration } from "../pages/PublisherAPIIntegration";

import { SmartlinkForm } from "../pages/PublisherForms/Smartlink";
import { InPagePushForm } from "../pages/PublisherForms/InPagePush";
import { PushForm } from "../pages/PublisherForms/Push";

// import { PublisherNotification } from "../pages/PublisherNotification";
// import { PublisherNotificationTable } from "../pages/PublisherNotificationTable";
import { SearchDashboard } from "../pages/SearchDashboard";
import { SearchReportsExtension } from "../pages/SearchReportsExtension";
import { SearchReportsN2S } from "../pages/SearchReportsN2S";
import { SearchAPIIntegration } from "../pages/SearchAPIIntegration";
import { SearchAdSamples } from "../pages/SearchAdSamples";
// import { SearchBestPractices } from "../pages/SearchBestPractices";
// import { SearchWidgetBuilder } from "../pages/SearchWidgetBuilder";
import { BillingPaymentMethods } from "../pages/BillingPaymentMethods";
import { BillingAccountStatements } from "../pages/BillingAccountStatements";
import { BillingPaymentHistory } from "../pages/BillingPaymentHistory";
import { BillingUploadInvoice } from "../pages/BillingUploadInvoice";
// import { SearchWidgetBuilder } from "../pages/SearchWidgetBuilder";
// import { SearchWidgetReports } from "../pages/SearchWidgetReports";
// import { SearchWidgets } from "../pages/SearchWidgets";
import { ContextualAd } from "../pages/PublisherForms/ContextualAd";
// import { CoinisPrograms } from "../pages/CoinisPrograms";
// import { CoinisProgramDetails } from "../pages/CoinisProgramDetails";
import { ExtensionPolicy } from "../pages/SearchExtensionPolicy";
import { SearchGraphView } from "../pages/SearchGraphView";
import { SearchDashboardBi } from "../pages/SearchDashboardBi";
import { SearchReportsBi } from "../pages/SearchReportsBi";
import { SearchGraphViewBi } from "../pages/SearchGraphViewBi";
// import { SearchTqOverview } from "../pages/SearchTqOverview";
import { AgencyDashboard } from "../pages/AgencyDashboard";
import { AgencyReportsDaily } from "../pages/AgencyReportsDaily";
import { AgencyReportsHourly } from "../pages/AgencyReportsHourly";
import { AgencyApiIntegration } from "../pages/AgencyAPIIntegration";
// import { SearchKeywordsN2S } from "../pages/SearchKeywordsN2S";

export const appRoutes = [
  //AFFILIATE PROGRAMS
  {
    title: "Dashboard",
    path: "/offerwall/dashboard",
    component: CoinisDashboard,
    icon: <DashboardOutlined />,
    permission_name: ["offerwall", "affiliate_programs"],
  },
  // {
  //   title: "All Programs",
  //   path: "/offerwall/programs",
  //   component: CoinisPrograms,
  //   icon: <TagOutlined />,
  //   permission_name: ["offerwall", "affiliate_programs"],
  //   ow_tmp: true,
  // },
  {
    title: "Campaigns",
    path: "/offerwall/campaigns",
    component: CoinisCampaigns,
    icon: <NotificationOutlined />,
    permission_name: ["offerwall"],
  },
  {
    title: "Statistics",
    path: "/offerwall/statistics",
    component: CoinisReports,
    icon: <LineChartOutlined />,
    permission_name: ["offerwall", "affiliate_programs"],
  },
  // {
  //   title: "Request History",
  //   path: "/offerwall/request-history",
  //   component: CoinisRequestHistory,
  //   icon: <HistoryOutlined />,
  //   permission_name: ["offerwall", "affiliate_programs"],
  // },
  // {
  //   title: "Global Settings",
  //   path: "/offerwall/global-settings",
  //   component: CoinisGlobalSettings,
  //   icon: <ControlOutlined />,
  //   permission_name: ["offerwall", "affiliate_programs"],
  //   ow_tmp: true,
  // },
  {
    title: "API Integration",
    path: "/offerwall/api",
    component: CoinisAPIIntegration,
    icon: <CodeOutlined />,
    permission_name: ["offerwall", "affiliate_programs"],
  },

  // {
  //   title: "",
  //   path: "/offerwall/program/:id",
  //   component: CoinisProgramDetails,
  //   permission_name: ["offerwall", "affiliate_programs"],
  //   skipRender: true,
  //   ow_tmp: true,
  // },

  //PUBLISHER
  {
    title: "Dashboard",
    path: "/publisher/dashboard",
    component: PublisherDashboard,
    icon: <DashboardOutlined />,
    permission_name: ["publisher"],
  },
  {
    title: "Zones",
    path: "/publisher/zones",
    component: PublisherZones,
    icon: <NotificationOutlined />,
    permission_name: ["publisher"],
  },
  {
    title: "Statistics",
    path: "/publisher/statistics",
    component: PublisherReports,
    icon: <LineChartOutlined />,
    permission_name: ["publisher"],
  },
  {
    title: "",
    path: "/publisher/zones/inpage/:id",
    component: InPagePushForm,
    icon: null,
    permission_name: ["publisher"],
    skipRender: true,
  },
  {
    title: "",
    path: "/publisher/zones/contextual/:id",
    component: ContextualAd,
    icon: null,
    permission_name: ["publisher"],
    skipRender: true,
  },
  {
    title: "",
    path: "/publisher/zones/push-revshare/:id",
    component: (props) => <PushForm {...props} type="revshare" />,
    icon: null,
    permission_name: ["publisher"],
    skipRender: true,
  },
  {
    title: "",
    path: "/publisher/zones/push-cps/:id",
    component: (props) => <PushForm {...props} type="cps" />,
    icon: null,
    permission_name: ["publisher"],
    skipRender: true,
  },
  {
    title: "",
    path: "/publisher/zones/native",
    component: () => <div>NATIVE</div>,
    icon: null,
    permission_name: ["publisher"],
    skipRender: true,
  },
  {
    title: "",
    path: "/publisher/zones/smartlink/:id",
    component: SmartlinkForm,
    icon: null,
    permission_name: ["publisher"],
    skipRender: true,
  },
  // {
  //   title: "Notifications",
  //   path: "",
  //   icon: <NotificationOutlined />,
  //   permission_name: ["publisher"],
  //   children: [
  //     {
  //       title: "New Notification",
  //       path: "/publisher/send-notification",
  //       icon: null,
  //       component: PublisherNotification,
  //       permission_name: ["publisher"],
  //     },
  //     {
  //       title: "Edit Notification",
  //       path: "/publisher/send-notification/:id",
  //       icon: null,
  //       component: PublisherNotification,
  //       permission_name: ["publisher"],
  //       skipRender: true,
  //     },
  //     {
  //       title: "Scheduled",
  //       path: "/publisher/notification-table",
  //       icon: null,
  //       component: PublisherNotificationTable,
  //       permission_name: ["publisher"],
  //     },
  //   ],
  // },
  {
    title: "API Integration",
    path: "/publisher/api",
    component: PublisherAPIIntegration,
    icon: <CodeOutlined />,
    permission_name: ["publisher"],
  },

  //SEARCH

  {
    title: "Dashboard",
    path: "/search/dashboard",
    component: SearchDashboard,
    icon: <DashboardOutlined />,
    permission_name: ["search"],
  },
  {
    title: "Statistics",
    path: "",
    icon: <LineChartOutlined />,
    permission_name: ["search"],
    children: [
      {
        title: "Search Type-In",
        path: "/search/statistics-extension",
        component: SearchReportsExtension,
        permission_name: ["search"],
        search_permission: "extension",
      },
      // {
      //   title: "Search TQ Overview",
      //   path: "/search/statistics-tq-overview-extension",
      //   component: SearchTqOverview,
      //   permission_name: ["search"],
      //   search_permission: "extension",
      // },
      {
        title: "Native To Search",
        path: "/search/statistics-n2s",
        component: SearchReportsN2S,
        permission_name: ["search"],
        search_permission: "n2s",
      },
    ],
  },
  {
    title: "Graph View",
    path: "/search/statistics-graph-view",
    component: SearchGraphView,
    icon: <LineChartOutlined />,
    permission_name: ["search"],
  },
  {
    title: "Ad Samples",
    path: "/search/ad-samples",
    component: SearchAdSamples,
    icon: <TagOutlined />,
    permission_name: ["search"],
    search_permission: "n2s",
  },
  {
    title: "Extension policy",
    path: "/search/extension-policy",
    component: ExtensionPolicy,
    icon: <FileProtectOutlined />,
    permission_name: ["search"],
    search_permission: "extension",
  },
  {
    title: "API Integration",
    path: "/search",
    icon: <CodeOutlined />,
    permission_name: ["search"],
    children: [
      {
        title: "Search Type-In",
        path: "/api-extension",
        component: () => <SearchAPIIntegration type="extension" />,
        icon: null,
        permission_name: ["search"],
        search_permission: "extension",
      },
      {
        title: "Native To Search",
        path: "/api-n2s",
        component: () => <SearchAPIIntegration type="n2s" />,
        icon: null,
        permission_name: ["search"],
        search_permission: "n2s",
      },
    ],
  },
  // SEARCH FEED - SERACH BI
  {
    title: "Dashboard",
    path: "/search_feed/dashboard",
    component: SearchDashboardBi,
    icon: <DashboardOutlined />,
    permission_name: ["search_feed"],
  },
  {
    title: "Statistics",
    path: "/search_feed/statistics",
    component: SearchReportsBi,
    icon: <LineChartOutlined />,
    permission_name: ["search_feed"],
  },
  {
    title: "Graph View",
    path: "/search_feed/statistics-graph-view",
    component: SearchGraphViewBi,
    icon: <LineChartOutlined />,
    permission_name: ["search_feed"],
  },
  {
    title: "Extension policy",
    path: "/search_feed/extension-policy",
    component: ExtensionPolicy,
    icon: <FileProtectOutlined />,
    permission_name: ["search_feed"],
  },
  {
    title: "API Integration",
    path: "/search_feed/api-integration",
    icon: <CodeOutlined />,
    permission_name: ["search_feed"],
    component: () => <SearchAPIIntegration type="extension" />,
  },

  // {
  //   title: "Suggested Keywords",
  //   path: "/keywords-suggestions",
  //   component: SearchKeywordsN2S,
  //   icon: <KeyOutlined />,
  //   permission_name: ["search"],
  //   search_permission: "n2s",
  // },
  // {
  //   title: "Ad Widgets",
  //   path: "/search/widgets",
  //   component: SearchWidgets,
  //   icon: <AppstoreOutlined />,
  //   permission_name: ["search"],
  //   search_permission: "n2s",
  // },
  // {
  //   title: "Edit Widget",
  //   path: "/search/ad-widget/:id",
  //   component: SearchWidgetBuilder,
  //   icon: null,
  //   permission_name: ["search"],
  //   search_permission: "n2s",
  //   skipRender: true,
  // },
  // {
  //   title: "New Widget",
  //   path: "/search/ad-widget",
  //   component: SearchWidgetBuilder,
  //   icon: null,
  //   permission_name: ["search"],
  //   search_permission: "n2s",
  //   skipRender: true,
  // },
  // {
  //   title: "Best Practices",
  //   path: "/search/best-practices",
  //   component: SearchBestPractices,
  //   icon: <FileDoneOutlined />,
  //   permission_name: ["search"],
  // },

  // AGENCY MODULE

  {
    title: "Dashboard",
    path: "/agency/dashboard",
    component: AgencyDashboard,
    icon: <DashboardOutlined />,
    permission_name: ["social_to_search"],
  },
  {
    title: "Statistics",
    path: "",
    icon: <LineChartOutlined />,
    permission_name: ["social_to_search"],
    children: [
      {
        title: "Daily",
        path: "/agency/stats/daily",
        component: AgencyReportsDaily,
        permission_name: ["social_to_search"],
      },
      {
        title: "Hourly",
        path: "/agency/stats/hourly",
        component: AgencyReportsHourly,
        permission_name: ["social_to_search"],
      },
    ],
  },
  {
    title: "API Integration",
    path: "/agency",
    icon: <CodeOutlined />,
    permission_name: ["social_to_search"],
    children: [
      {
        title: "Daily",
        path: "/api-daily",
        component: () => <AgencyApiIntegration type="daily" />,
        icon: null,
        permission_name: ["social_to_search"],
      },
      {
        title: "Hourly",
        path: "/api-hourly",
        component: () => <AgencyApiIntegration type="hourly" />,
        icon: null,
        permission_name: ["social_to_search"],
      },
    ],
  },

  //BILLING

  {
    title: "Payment Methods",
    path: "/billing/payment-methods",
    component: BillingPaymentMethods,
    icon: <CreditCardFilled />,
    permission_name: ["billing"],
  },
  {
    title: "Account Statements",
    path: "/billing/account-statements",
    component: BillingAccountStatements,
    icon: <FileTextFilled />,
    permission_name: ["billing"],
  },
  {
    title: "Payment History",
    path: "/billing/payment-history",
    component: BillingPaymentHistory,
    icon: <FileFilled />,
    permission_name: ["billing"],
  },
  {
    title: "Upload Invoice",
    path: "/billing/upload-invoice",
    component: BillingUploadInvoice,
    icon: <UploadOutlined />,
    permission_name: ["billing"],
  },
  {
    title: "My Profile",
    path: "/",
    component: MyProfile,
    icon: <UserOutlined />,
    permission_name: ["default"],
  },
];

export const getAllowedRoutes = () => {
  const filterByPermission = (routes) =>
    routes.filter((route) => {
      if (route.ow_tmp) {
        return auth.isTmpUser();
      }
      if (route.search_permission) {
        return auth.hasSearchPermission(route.search_permission);
      }
      if (auth.hasOnlySearchBiPermission()) {
        return (
          auth.hasMultiPermissions(route.permission_name) ||
          route.permission_name.includes("default")
        );
      }
      return (
        auth.hasMultiPermissions(route.permission_name) ||
        route.permission_name.includes("default") ||
        route.permission_name.includes("billing")
      );
    });
  const filteredRoutes = filterByPermission(appRoutes);
  return filteredRoutes.map((route) => {
    if (route.children) {
      return { ...route, children: filterByPermission(route.children) };
    }
    return route;
  });
};
