import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

const generateRoutes = routes => {
  const routeElements = routes.reduce((acc, curr) => {
    if (curr.children) {
      const list = curr.children.map(child => {
        const fullPath = curr.path + child.path;
        return (
          <Route
            exact
            key={fullPath}
            path={fullPath}
            component={child.component}
          />
        );
      });

      return acc.concat(list);
    } else {
      const fullPath = curr.path;
      return acc.concat(
        <Route
          exact
          key={fullPath}
          path={fullPath}
          component={curr.component}
        />
      );
    }
  }, []);
  routeElements.push(
    <Route
      key="page-not-found"
      render={props => {
        const { pathname, search, hash } = props.location;
        const from = pathname + search + hash;
        return (
          <Redirect to={{ pathname: "/page-not-found", state: { from } }} />
        );
      }}
    />
  );
  return routeElements;
};

export const SwitchRoutes = ({ routes }) => (
  <Switch>{generateRoutes(routes)}</Switch>
);
