import { Button, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { AdPreview } from "./AdPreview";
import { FormWidgetWrapper } from "./FormWidgetWrapper";
import styles from "./NewWidgetForm.module.css";

const { Option } = Select;

export const NewWidgetForm = ({ visible, setVisible, setCreatedWidgets }) => {
  const [selectedWidgetStyle, setSelectedWidgetStyle] =
    useState("widget-style-1");
  const [widgetHeadline, setWidgetHeadline] = useState("Related Topics (Ads)");
  useEffect(() => {
    if (visible.widget) {
      setSelectedWidgetStyle(visible.widget.widgetStyle);
      setWidgetHeadline(visible.widget.widgetHeadline);
    } else if (visible) {
      setSelectedWidgetStyle("widget-style-1");
    }
  }, [visible]);

  const handleFormSubmit = () => {
    if (visible.widget) {
      setCreatedWidgets((prevWidgets) => {
        const tempWidgets = [...prevWidgets];
        tempWidgets[visible.id] = {
          widgetStyle: selectedWidgetStyle,
          widgetHeadline,
        };
        return tempWidgets;
      });
      setVisible(false);
    } else {
      setCreatedWidgets((prevWidgets) => [
        ...prevWidgets,
        { widgetStyle: selectedWidgetStyle, widgetHeadline },
      ]);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setSelectedWidgetStyle();
    setWidgetHeadline("Related Topics (Ads)");
    setVisible(false);
  };

  return (
    <Modal
      title="Widget"
      open={visible}
      footer={false}
      width="70%"
      onCancel={handleCancel}
      style={{ top: 20 }}
    >
      <p className={styles.inputLabel}>Widget Headline</p>
      <Select
        placeholder="Select Widget Headline"
        style={{ width: "49%", marginBottom: "20px" }}
        value={widgetHeadline}
        onChange={(value) => setWidgetHeadline(value)}
      >
        <Option value="Related Topics (Ads)">Related Topics (Ads)</Option>
        <Option value="Promoted Content">Promoted Content</Option>
        <Option value="More Like This (Ads)">More Like This (Ads)</Option>
      </Select>
      <div className={styles.widgetsGrid}>
        {[...Array(12)].map((_, i) => (
          <FormWidgetWrapper
            id={i}
            selectedWidgetStyle={selectedWidgetStyle}
            setSelectedWidgetStyle={setSelectedWidgetStyle}
            key={i}
          >
            <AdPreview
              widgetStyle={`widget-style-${i + 1}`}
              widgetHeadline={widgetHeadline}
            />
          </FormWidgetWrapper>
        ))}
      </div>
      <div className={styles.bottomActions}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleFormSubmit} type="primary">
          Save Widget
        </Button>
      </div>
    </Modal>
  );
};
