import { callApi } from "./api";
import moment from "moment";

const tenDays = moment().subtract(9, "days").format("YYYY-MM-DD");
const today = moment().format("YYYY-MM-DD");

class PublisherDashboard {
  async getStatisticData(dateRange, stats, hourTime) {
    return await callApi({
      url: `/api/publishers/stats/?resType=chart&columns=date,${
        hourTime ? "hour," : ""
      }${stats.join(",")}&date_from=${dateRange.from.format(
        "YYYY-MM-DD"
      )}&date_to=${dateRange.to.format("YYYY-MM-DD")}&ordering=date`,
      method: "GET",
    });
  }
  async getTopChannelsData(dateRange) {
    return await callApi({
      url: `/api/publishers/stats/?resType=table&columns=zone_name,impressions,clicks,revenue&date_from=${dateRange.from.format(
        "YYYY-MM-DD"
      )}&date_to=${dateRange.to.format(
        "YYYY-MM-DD"
      )}&offset=0&limit=10&ordering=revenue`,
      method: "GET",
    });
  }
  async getEarningsMapData(dateRange) {
    return await callApi({
      url: `/api/publishers/stats/?resType=table&columns=country,revenue&date_from=${dateRange.from.format(
        "YYYY-MM-DD"
      )}&date_to=${dateRange.to.format(
        "YYYY-MM-DD"
      )}&offset=0&limit=6&ordering=revenue`,
      method: "GET",
    });
  }

  async getTopCardInfoChart() {
    return await callApi({
      url: `/api/publishers/stats/?resType=chart&columns=date,impressions,leads,revenue,clicks&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=date`,
      method: "GET",
    });
  }
  async getTopCardInfo() {
    return await callApi({
      url: `/api/publishers/stats/?resType=table&columns=date,impressions,leads,revenue,clicks&date_from=${tenDays}&date_to=${today}&offset=0&limit=10&ordering=data`,
      method: "GET",
    });
  }
}

export const publisherDashboardService = new PublisherDashboard();
