import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import styles from "./FilterPresetModal.module.css";
import { userFiltersService } from '../../services/userFilters';
import { openSuccessNotification } from '../../utils/notifications';
import { showApiErrors } from '../../utils/showApiErrors';

const confirm = Modal.confirm;

export const FilterPresetModal = ({
    filters,
    userFilters,
    setUserSavedFilter,
    currentModule,
    onUpdate
}) => {
    const [form] = Form.useForm();
    const [userFiltersModalIsOpen, setUserFiltersModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const showOverwriteConfirm = (name) => {
        confirm({
            maskClosable: true,
            title: `Are you sure you want to change existing (${name}) filter ?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleSaveFilter(name);
            },
            onCancel() { }
        });
    };

    const handleSubmit = (values) => {
        let checkNames = userFilters.filter((filter) => filter.name === values.name.toLowerCase());
        if (checkNames.length > 0) {
            showOverwriteConfirm(values.name.toLowerCase());
        } else {
            handleSaveFilter(values.name);
        }
    };

    const handleSaveFilter = async (filterName) => {
        setModalLoading(true);

        let myFilters = { filter: JSON.stringify({ ...filters }) };
        filterName = filterName.toLowerCase();
        myFilters.name = filterName.toLowerCase();
        myFilters.module = currentModule;

        const newFilters = userFilters.filter((filter) => filter.name.toLowerCase() !== filterName);

        try {
            const res = await userFiltersService.saveFilter(myFilters);
            newFilters.push(res);
            openSuccessNotification({ message: "Filter preset successfully created!" })
            onUpdate(newFilters);
            setUserSavedFilter(filterName.toLowerCase());
            setUserFiltersModal(false);
        } catch (e) {
            showApiErrors(e);
        } finally {
            setModalLoading(false);
        }
    };

    return (
        <div>
            <Tooltip className={styles.limitTooltip} placement="top" title={userFilters.length > 9 ? "You've reached the maximum number of filter presets." : ""}>
                <Button
                    style={{ marginTop: "22px", height: "32px", width: '100%' }}
                    type="primary"
                    ghost
                    disabled={Object.keys(filters).length === 0 || userFilters.length > 9}
                    onClick={() => setUserFiltersModal(true)} icon={<SaveOutlined />}
                >
                    Save Filter Preset
                </Button>
            </Tooltip>

            <Modal
                className={styles.userFiltersModal}
                visible={userFiltersModalIsOpen}
                closable={false}
                onCancel={() => setUserFiltersModal(false)}
                footer={null}
            >
                <Spin spinning={modalLoading}>
                    <Form
                        onFinish={handleSubmit}
                        onFinishFailed={({ errorFields }) => {
                            form.scrollToField(errorFields[0].name);
                        }}
                    >
                        <Row gutter={32}>
                            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                <Form.Item
                                    label="Filter name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please type filter name!'
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={32} style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 16px' }}>
                            <Button style={{ marginRight: '16px' }} onClick={() => setUserFiltersModal(false)}>
                                Cancel
                            </Button>
                            <Form.Item style={{ margin: 0 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Save Filter
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};
