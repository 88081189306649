import React from "react";
import { Modal } from "antd";
import styles from "./ZoneModal.module.css";
import { ZoneCard } from "./ZoneCard";

export const ZoneModal = ({ zoneModalVisible, setZoneModalVisible }) => {
  return (
    <Modal
      title={<p className={styles.titleZoneModal}>Select Zone Format</p>}
      visible={zoneModalVisible}
      onCancel={() => setZoneModalVisible(false)}
      footer={null}
      width={null}
    >
      <div className={styles.zoneModalWrapper}>
        <ZoneCard
          title="Push Rev-Share"
          paragraph="The long-term strategy"
          desc="Ability to earn money per each impression from subscribers./ In a long run, more rewarding than CPS."
          path="push-revshare/new"
          select="Select Push Rev-Share"
          img="pushrevshare"
        />
        <ZoneCard
          title="Push CPS"
          paragraph="Make money faster"
          desc="Get paid for each qualified subscriber who allowed request to receive notifications./ Control flow and summary of push notifications."
          path="push-cps/new"
          select="Select CPS"
          img="pushcps"
        />
        {/* <ZoneCard
          title="Smartlink"
          paragraph="CPC-based"
          desc="Serve your users the right pop ads with our
          AI Smartlink technology./ Ideal for website owners with excess 
          traffic."
          path="smartlink/new"
          select="Select Smartlink"
          img="smartlink"
        /> */}
        <ZoneCard
          title="Contextual Ads"
          paragraph="Seamless integration"
          desc="Automatic keyword generation lets you get the most recent ads for the best revenue./ Non-disruptive widget design which matches your page."
          path="contextual/new"
          select="Select Contextual Ads"
          img="contextualads"
        />
        <ZoneCard
          title="In-Page Push"
          paragraph="Let's earn together"
          desc="Opportunity to earn from every click your subscribers make over time./ In a long run, more profitable than CPS."
          path="inpage/new"
          select="Select In-Page Push"
          img="inpagepush"
        />
        {/* <ZoneCard
          title="Native Ads"
          paragraph="The natural approach"
          desc="Make money while still
          controlling your site’s visual identity via a variety of native ads./ Customize a large array of native ad widgets."
          path="native"
          select="Select Native Ads"
          img="nativeads"
          disabled
        /> */}
      </div>
    </Modal>
  );
};
