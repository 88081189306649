import { Button, Checkbox, Row, Col, Tooltip } from "antd";
import styles from "./renderColumns.module.css";

export const renderColumns = (
  onChange,
  checkedColumns,
  showUpdateButton,
  columnsData,
  onUpdate,
  setColumnsVisibility,
  disabledColumns = {}
) => {
  const renderRows = (perRow) => {
    let rows = [];
    for (let i = 0; i < columnsData.length; i += perRow) {
      const rowCols = [];
      for (let j = i; j < i + perRow; j++) {
        const column = columnsData[j];
        if (column) {
          const col = (
            <Col span={8} key={column.value}>
              <Tooltip
                title={
                  disabledColumns[column.value]
                    ? "To select this column switch to another column preset."
                    : ""
                }
              >
                <Checkbox
                  value={column.value}
                  checked={!!checkedColumns[column.value]}
                  onChange={({ target }) =>
                    onChange({
                      ...checkedColumns,
                      [target.value]: target.checked,
                    })
                  }
                  disabled={disabledColumns[column.value]}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    {column.longText || column.text}
                  </span>
                </Checkbox>
              </Tooltip>
            </Col>
          );
          rowCols.push(col);
        }
      }
      const row = (
        <Row style={{ marginBottom: "10px" }} key={i}>
          {rowCols}
        </Row>
      );
      rows.push(row);
    }
    return rows;
  };

  return (
    <div className={styles.columnGroupContainer}>
      {renderRows(3)}
      <br />
      {!showUpdateButton ? null : (
        <Row>
          <Button
            style={{ float: "right" }}
            onClick={() => {
              setColumnsVisibility(false);
              onUpdate();
            }}
          >
            Update
          </Button>
        </Row>
      )}
    </div>
  );
};
