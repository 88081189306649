import { BASE_URL } from "../../../config";
import { TableCellColorWrap } from "../components/TableCellColorWrap";
import styles from "../Reports.module.css";

export const columnTemplate = [
  {
    value: "date",
    text: "Date",
    longText: "Date",
    align: "center",
    tooltip: "Date frame period of observing your activity",
    fixed: "left",
  },
  {
    value: "search_channel",
    text: "Channel",
    longText: "Search Channel",
    align: "center",
    bold: true,
    tooltip:
      "Identifier of your search feed campaigns. usually created by account managers to easily distinigush campaigns",
    fixed: "left",
  },
  {
    value: "tq",
    text: "TQ",
    longText: "Traffic Quality",
    align: "center",
    tooltip: "Represents quality of traffic of the feeds",
  },
  {
    value: "country",
    text: "Country",
    longText: "Country",
    tooltip: "Country from which traffic is coming",
    render: (item) => {
      return (
        <div className={styles.countryBox}>
          {item && item !== "--" && item.toLowerCase() !== "unknown" && (
            <img
              src={`${BASE_URL}media/countries/${item.toLowerCase()}.png`}
              alt="."
            />
          )}
          {item}
        </div>
      );
    },
    align: "center",
  },
  {
    value: "filtered_traffic_percentage",
    text: "Filtered %",
    longText: "Filtered Traffic %",
    align: "center",
    tooltip:
      "Represents percentage of traffic that we are filtering and are not reaching feeds page",
    render: (value, item) => {
      const parsedValue = parseFloat(value * 100).toFixed(2);
      const displayValue = parsedValue !== "0.00" ? parsedValue + "%" : "-";
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="filtered_traffic_percentage"
          value={parsedValue}
        >
          {displayValue}
        </TableCellColorWrap>
      );
    },
  },
  {
    value: "coinis_redirects",
    text: "C. Redirects",
    longText: "Coinis Redirects",
    align: "center",
    tooltip: "Amount of searches Coinis recorded via our redirect links.",
  },
  {
    value: "filtered_traffic",
    text: "Filtered",
    longText: "Filtered Traffic",
    align: "center",
    tooltip:
      "Amount of searches that was filtered based on quality of traffic such as: latency, unsupported devices, hosting traffic, etc.",
  },
  {
    value: "total_searches",
    text: "Total Searches",
    longText: "Total Searches",
    align: "center",
    tooltip: "Amount of searches done by users",
  },
  {
    value: "monetized_searches",
    text: "Monetized Searches",
    longText: "Monetized Searches",
    align: "center",
    tooltip:
      "Monetized searches represent amount of searches that showed actual ads on search pages",
  },
  {
    value: "follow_on_searches",
    text: "FOS %",
    longText: "Follow on Searches %",
    align: "center",
    tooltip: "Additional Follow on Searches leading after first users search.",
    render: (value, item) => {
      const parsedValue = parseFloat(value * 100).toFixed(2);
      const displayValue = parsedValue !== "0.00" ? parsedValue + "%" : "-";
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="follow_on_searches"
          value={parsedValue}
        >
          {displayValue}
        </TableCellColorWrap>
      );
    },
  },
  {
    value: "clicks",
    text: "Clicks",
    longText: "Clicks",
    align: "center",
    tooltip: "Clicks on search ads",
  },
  {
    value: "ad_coverage",
    text: "Ad Coverage",
    longText: "Ad Coverage",
    align: "center",
    tooltip:
      "Ad Coverage represents ratio between your monetized and total searches. Lower coverage directly impacts your RPM and revenue per channel.      ",
    render: (value, item) => {
      const parsedValue = value && value.toFixed(2) + "%";
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="ad_coverage"
          value={value}
        >
          {parsedValue}
        </TableCellColorWrap>
      );
    },
  },
  {
    value: "revenue",
    text: "Revenue",
    longText: "Revenue",
    align: "center",
    tooltip: "Revenue from search feeds",
  },
  {
    value: "provider",
    text: "Provider",
    longText: "Provider",
    align: "center",
    tooltip: "Search feed provider that is being active on your campaigns",
  },
  {
    value: "ctr",
    text: "CTR",
    longText: "CTR",
    align: "center",
    tooltip: "Click-through rate - clicks that occurred on monetized searches",
    render: (value, item) => {
      const parsedValue = value && value.toFixed(2) + "%";
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="ctr"
          value={value}
        >
          {parsedValue}
        </TableCellColorWrap>
      );
    },
  },
  {
    value: "rpc",
    text: "RPC",
    longText: "RPC",
    align: "center",
    tooltip: "Revenue per click",
    render: (value, item) => {
      const parsedValue =
        value &&
        "$ " +
          Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="rpc"
          value={value}
        >
          {parsedValue}
        </TableCellColorWrap>
      );
    },
  },
  {
    value: "rpm",
    text: "RPM",
    longText: "RPM",
    align: "center",
    tooltip:
      "Revenue per thousand impressions or revenue per thousands searches",
    render: (value, item) => {
      const parsedValue =
        value &&
        "$ " +
          Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="rpm"
          value={value}
        >
          {parsedValue}
        </TableCellColorWrap>
      );
    },
  },
  {
    value: "monetized_rpm",
    text: "Monetized RPM",
    longText: "Monetized RPM",
    align: "center",
    tooltip: "Monetized revenue per thousand monetized searches",
  },
  {
    value: "bing_initial_searches",
    text: "Bing IS",
    longText: "Bing Initial Searches",
    align: "center",
    tooltip:
      "Represents the count of initial (first) searches conducted on the Bing feed",
  },
  {
    value: "initial_searches_difference",
    text: "ISD %",
    longText: "Initial Searches Difference %",
    align: "center",
    tooltip:
      "Initial Search Difference between feeds initial searches and actual searches reached to Bing’s feed.",
    render: (value, item) => {
      const parsedValue = parseFloat(value * 100).toFixed(2);
      const displayValue = parsedValue !== "0.00" ? parsedValue + "%" : "-";
      return (
        <TableCellColorWrap
          channel={item?.search_channel}
          color_flag={item?.color_flag}
          country={item?.country}
          type="initial_searches_difference"
          value={parsedValue}
        >
          {displayValue}
        </TableCellColorWrap>
      );
    },
  },
];

export const MERGE_TABLE_OPTIONS = [
  { label: "TQ", value: "tq" },
  { label: "Filtered Traffic", value: "filtered_traffic" },
];

export const columnTqPreset = {
  date: true,
  tq: true,
  country: true,
  search_channel: true,
  total_searches: true,
  follow_on_searches: true,
  monetized_searches: true,
  rpm: true,
  monetized_rpm: true,
  clicks: true,
  ctr: true,
  rpc: true,
  ad_coverage: true,
  revenue: true,
};

export const columnFtPreset = {
  date: true,
  search_channel: true,
  filtered_traffic_percentage: true,
  total_searches: true,
  follow_on_searches: true,
  monetized_searches: true,
  rpm: true,
  monetized_rpm: true,
  clicks: true,
  ctr: true,
  rpc: true,
  ad_coverage: true,
  revenue: true,
};

// const OLDcolumnTemplate = [
//   { value: "subid", text: "SubID" },
//   { value: "campaign_name", text: "Campaign" },
//   { value: "campaign_id", text: "Campaign ID" },
//   { value: "country", text: "Country" },
//   { value: "date", text: "Date" },
//   { value: "os_version", text: "OS Version" },
//   { value: "creatives", text: "Creatives" },
//   { value: "browser", text: "Browser" },
//   { value: "browser_version", text: "Browser Version" },
//   { value: "campaign_type", text: "Campaign Type" },

//   { value: "ad_format", text: "Ad Format" },
//   { value: "revenue", text: "Revenue" },
//   { value: "hour", text: "Hour" },
//   { value: "os", text: "OS" },
//   { value: "cpc", text: "CPC" },
//   { value: "cpm", text: "CPM" },
//   { value: "cr", text: "CR" },
//   { value: "im", text: "Impressions" },
//   { value: "cpa", text: "CPA" },
//   { value: "clicks", text: "Clicks" },
//   { value: "roi", text: "ROI" },
//   { value: "conversions", text: "Conversions" },
// ];
