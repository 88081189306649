import { Spin } from "antd";
import React, { useCallback, useEffect } from "react";
import styles from "./StatisticsLineChart.module.css";
import Highcharts from "highcharts";
import { openWarningNotification } from "../../../../utils/notifications";

const defaultOptions = {
  chart: {
    zoomType: "x",
  },
  title: {
    text: "",
  },
  xAxis: [
    {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: "Date",
      },
    },
    {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: "Date",
      },
      opposite: true,
    },
  ],
  yAxis: [],
  tooltip: {
    shared: true,
  },
  legend: {
    floating: false,
    layout: "vertical",
    navigation: {
      activeColor: "#3E576F",
      animation: true,
      arrowSize: 3,
      inactiveColor: "#CCC",
      style: {
        fontWeight: "bold",
        color: "#333",
        fontSize: "12px",
      },
    },
    margin: 0,
    labelFormatter: function () {
      let max = this.xData[this.xData.length - 1];
      let min = this.xData[0];
      return (
        Highcharts.dateFormat("%b, %e", new Date(min)) +
        " - " +
        Highcharts.dateFormat("%b, %e", new Date(max)) +
        " " +
        this.name
      );
    },
    backgroundColor:
      (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
  },
  series: [],
};

export const StatisticsLineChart = ({ loading, chartData }) => {
  const addNewYAxis = useCallback((name, data, index, xAxis = 0, options) => {
    if (index > options.yAxis.length - 1) {
      let format = "{value}";
      if (name === "spent" || name === "earned") {
        format = "$ " + format;
      }

      const colorIndex = options.series.length;
      const color = colorIndex;
      options.yAxis.push({
        labels: {
          enabled: window.innerWidth > 800 ? true : false,
          format,
          style: {
            color,
          },
        },
        title: {
          enabled: window.innerWidth > 800 ? true : false,
          text: name,
          style: {
            color,
          },
        },
        opposite: true,
      });
      if (color === 0) {
        options.series.push({
          name: "Monetized Searches",
          type: "spline",
          xAxis,
          color: "#c2d791",
          yAxis: index,
          data,
        });
      } else if (color === 1) {
        options.series.push({
          name: "Revenue",
          type: "spline",
          xAxis,
          color: "#f7ae3d",
          yAxis: index,
          data,
        });
      }
    }
  }, []);

  const parseChartData = useCallback(
    (chartData) => {
      // checking if response data is for the chart or the table
      if (chartData && chartData.values) {
        let options = { ...defaultOptions, series: [], yAxis: [] };
        let yAxisIndex = 0;
        const metrics = ["Monetized Searches", "Revenue"];
        metrics.forEach((metric, metricIndex) => {
          const values = chartData.values.map((value) => [
            value[0],
            value[metricIndex + 1],
          ]);
          addNewYAxis(metric, values, yAxisIndex, 0, options);
          yAxisIndex++;
          if (yAxisIndex > options.yAxis.length) {
            yAxisIndex = options.yAxis.length;
          }
        });
        if (options.series.length > 0) {
          Highcharts.chart("chart-wrapper", options);
        }
      } else {
        openWarningNotification({
          message: `There is no data for this filters`,
          duration: 8,
        });
      }
    },
    [addNewYAxis]
  );
  useEffect(() => {
    Highcharts.chart("chart-wrapper", defaultOptions);
    chartData !== null && parseChartData(chartData);
  }, [chartData, parseChartData]);

  return (
    <Spin spinning={loading}>
      {/* {searchPermissions.extension && searchPermissions.n2s && (
        <div className={styles.statisticsLineHeader}>
          <Radio.Group
            onChange={e => setChartType(e.target.value)}
            value={chartType}
            buttonStyle="solid"
            size="large"
          >
            <Radio.Button
              style={{ width: "160px", textAlign: "center" }}
              value={1}
            >
              Search
            </Radio.Button>
            <Radio.Button
              style={{ width: "160px", textAlign: "center" }}
              value={2}
            >
              Native To Search
            </Radio.Button>
          </Radio.Group>
        </div>
      )} */}
      <div
        id="chart-wrapper"
        style={{ height: "500px" }}
        className={styles.lineChartContainer}
      />
    </Spin>
  );
};
