import { Chatty } from "../InPagePush/adThemes/chatty";
import { Compact } from "../InPagePush/adThemes/compact";
import { OldSchool } from "../InPagePush/adThemes/oldSchool";
import { Sharp } from "../InPagePush/adThemes/sharp";

export const parsePositionStyles = (adPosition) => {
  switch (adPosition) {
    case "top-left":
      return { top: "1rem", left: "1rem" };
    case "top-right":
      return { top: "1rem", right: "1rem" };
    case "bottom-left":
      return { bottom: "1rem", left: "1rem" };
    case "bottom-right":
      return { bottom: "1rem", right: "1rem" };
    default:
      return { top: "1rem", left: "1rem" };
  }
};

export const toFormData = (jsonObj) => {
  const form_data = new FormData();
  for (let key in jsonObj) {
    if (Array.isArray(jsonObj[key])) {
      if (jsonObj[key].length > 0) {
        jsonObj[key].forEach((element) => {
          form_data.append(key, element);
        });
      }
    } else {
      form_data.append(key, jsonObj[key]);
    }
  }
  return form_data;
};

export const renderWidget = (
  adQuantity,
  widgetStyle,
  backgroundColor,
  contentColor,
  contentRadius,
  adPosition
) => {
  switch (widgetStyle) {
    case "old-school":
      return (
        <OldSchool
          adQuantity={adQuantity}
          backgroundColor={backgroundColor}
          contentColor={contentColor}
          contentRadius={contentRadius}
          adPosition={adPosition}
        />
      );
    case "sharp":
      return (
        <Sharp
          adQuantity={adQuantity}
          backgroundColor={backgroundColor}
          contentColor={contentColor}
          contentRadius={contentRadius}
          adPosition={adPosition}
        />
      );
    case "compact":
      return (
        <Compact
          adQuantity={adQuantity}
          backgroundColor={backgroundColor}
          contentColor={contentColor}
          contentRadius={contentRadius}
          adPosition={adPosition}
        />
      );
    case "chatty":
      return (
        <Chatty
          adQuantity={adQuantity}
          backgroundColor={backgroundColor}
          contentColor={contentColor}
          contentRadius={contentRadius}
          adPosition={adPosition}
        />
      );
    default:
      return null;
  }
};
