import { Tooltip } from 'antd';
import React from 'react';

import styles from '../CoinisCampaigns.module.css';

export const ZoneTypeCol = ({ zoneType }) => {

  const convertToName = (type) => {
    switch (type) {
      case 'smartlink':
        return '/images/smartlink.svg';
      case 'push':
        return '/images/push.svg';
      case 'inpage':
        return '/images/in_page_push.svg';
      case 'contextual':
        return '/images/contextual.svg';
      default:
        return '/images/push_rev_share.svg';
    }
  };

  const convertTitle = {
    smartlink: 'Smartlink',
    push: 'Push',
    inpage: 'In-Page',
    contextual: 'Contextual'
  };

  return (
    <div className={styles.zoneType}>
      <span>
        <Tooltip title={convertTitle[zoneType]}>
          <img src={convertToName(zoneType)} alt={zoneType} />
        </Tooltip>
      </span>
    </div>
  );
};
