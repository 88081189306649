import React from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import styles from './TableTitle.module.css';

export const TableTitle = ({ onSortingClick, value, order, text }) => {

  let upArrowClass = null;
  let downArrowClass = null;

  if (order === 'descend') {
    upArrowClass = null;
    downArrowClass = styles.activeArrow;
  } else if (order === 'ascend') {
    upArrowClass = styles.activeArrow;
    downArrowClass = null;
  }

  const handleAscSortingClick = (e) => {
    onSortingClick(value, 'ascend', e.shiftKey);
  };

  const handleDescSortingClick = (e) => {
    onSortingClick(value, 'descend', e.shiftKey);
  };

  return (
    <div className={styles.tableTitle}>
      <span className={styles.title}>{text}</span>
      <div className={styles.arrows}>
        <div className={upArrowClass} >
          <CaretUpOutlined onClick={handleAscSortingClick} />
        </div>
        <div className={downArrowClass} >
          <CaretDownOutlined onClick={handleDescSortingClick} />
        </div>
      </div>
    </div>
  );
};
