import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { ColumnGraph } from "./ColumnGraph";
import styles from "./HeadInfo.module.css";

export const HeadCardInfo = ({
  loading,
  title,
  color,
  chartData,
  type,
  dataInfo,
}) => {
  return (
    <div className={styles.infoCard}>
      <div className={styles.dashboardBox}>
        <p>{title}</p>
        <div className={styles.headerStatsBox}>
          <p style={{ color, whiteSpace: "nowrap" }}>
            {loading ? <LoadingOutlined spin /> : dataInfo}
          </p>
          {chartData && chartData.length > 0 ? (
            <ColumnGraph data={chartData} type={type} color={color} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
