import { callApi } from "./api";

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class CoinisPrograms {
  async getProgramsPaged({
    selectedFilters,
    currentPage,
    pageSize,
    cancelToken,
  }) {
    const { filters } = selectedFilters;
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      title: filters.title && filters.title,
      vertical: Array.isArray(filters.vertical)
        ? filters.vertical.join(",")
        : filters.vertical,
      countries: Array.isArray(filters.countries)
        ? filters.countries.join(",")
        : filters.countries,
      device_type: Array.isArray(filters.device_type)
        ? filters.device_type.join(",")
        : filters.device_type,
      os: Array.isArray(filters.os) ? filters.os.join(",") : filters.os,
      access_type: Array.isArray(filters.access_type)
        ? filters.access_type.join(",")
        : filters.access_type,
      conversion_type: Array.isArray(filters.conversion_type)
        ? filters.conversion_type.join(",")
        : filters.conversion_type,
      traffic_type: Array.isArray(filters.traffic_type)
        ? filters.traffic_type.join(",")
        : filters.traffic_type,
      has_campaigns: filters.hasCampaigns && filters.hasCampaigns,
      trending: filters.trending && filters.trending,
      pending: filters.pending && filters.pending,
    });
    const response = await callApi({
      url: `/api/offerwall/programs/${query}`,
      cancelToken,
    });
    return { results: response.results, count: response.count };
  }

  async getCampaignsPaged({
    selectedFilters,
    currentPage,
    checkedColumns,
    pageSize,
    searchValue,
    cancelToken,
    programId,
  }) {
    let colParameter = "status,id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
    });
    const response = await callApi({
      url: `/api/offerwall/programs/${programId}/campaigns/${query}`,
      cancelToken,
    });
    return { results: response.results, count: response.count };
    // return await { results: data, count: 5 };
  }

  async getProgramDetails(id) {
    return await callApi({
      url: `/api/offerwall/programs/${id}/`,
      method: "GET",
    });
  }

  async getCampaignLinks() {
    return await callApi({
      url: "/api/offerwall/programs/campaign_links/",
    });
  }

  async getOperatingSystems() {
    return await callApi({
      url: "/api/cns/os_list/",
    });
  }

  async getBrowsers() {
    return await callApi({
      url: "/api/cns/browsers/",
    });
  }

  async getCountries() {
    return await callApi({
      url: "/api/cns/countries/",
    });
  }

  async getDeviceTypes() {
    return await callApi({
      url: "/api/cns/device_types/",
    });
  }

  async getVerticals() {
    return await callApi({
      url: "/api/cns/program/verticals/",
    });
  }

  async getWelcomeVerticals() {
    return await callApi({
      url: "/api/cns/program/verticals/?trending=true",
    });
  }

  async getWelcomePrograms(id) {
    return await callApi({
      url: `/api/offerwall/programs/top_five/?vertical=${id}`,
    });
  }

  async getAccessTypes() {
    return await callApi({
      url: "/api/cns/program/access_types/",
    });
  }

  async getConversionTypes() {
    return await callApi({
      url: "/api/cns/program/conversion_types/",
    });
  }

  async getTrafficTypes() {
    return await callApi({
      url: "/api/cns/program/traffic_types/",
    });
  }

  async requestApproval(data) {
    return await callApi({
      url: "/api/offerwall/programs/request_approval/",
      method: "POST",
      data,
    });
  }

  async startCampaign(data) {
    return await callApi({
      url: "/api/offerwall/programs/start_campaign/",
      method: "POST",
      data,
    });
  }

  async getCampaignDetails(id) {
    return await callApi({
      url: `/api/offerwall/programs/campaigns/${id}/`,
    });
  }

  async editCampaign(id, data) {
    return await callApi({
      url: `/api/offerwall/programs/campaigns/${id}/edit/`,
      method: "POST",
      data,
    });
  }

  async archiveCampaign(id, data) {
    return await callApi({
      url: `/api/offerwall/programs/campaigns/${id}/delete/`,
      method: "DELETE",
    });
  }

  async uploadImage(file) {
    const formData = new FormData();
    formData.append("file", file);
    return await callApi({
      url: `/api/upload/question/image/`,
      method: "POST",
      data: formData,
    });
  }
}

export const coinisProgramsService = new CoinisPrograms();
