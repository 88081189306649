import React from "react";
import { Table as AntTable, Pagination } from "antd";
import styles from "../BillingPaymentHistory.module.css";

export const Table = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  tableColumns,
}) => {
  return (
    <AntTable
      className={styles.antTableContent}
      columns={tableColumns}
      // dataSource={campaigns}
      dataSource={campaigns}
      loading={loadingCampaigns}
      pagination={false}
      rowKey={({ id }) => id}
      footer={() => {
        return (
          <Pagination
            className="ant-table-pagination ant-table-pagination-right"
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            pageSizeOptions={["10", "20", "30", "50", "100"]}
            onChange={onPaginationChange}
            onShowSizeChange={onPaginationChange}
            showTotal={(total) => {
              if (total) return <div>Showing {(currentPage - 1) * pageSize + 1} to {(total < currentPage * pageSize) ? total : currentPage * pageSize} of {total} entries.</div>;
            }}
          />
        );
      }}
      scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
    />
  );
};
