export const n2sDataSource = [
  {
    key: "1",
    name: "key",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Authorization key, required parameter to access Native To Search Feed
          Report API. Contain only digits and letters. You may obtain your
          authorization key from your personal manager.
        </p>
        <p>
          Example: <b>UQlXSEHTd2kWbVY3n0ETLx3v</b>
        </p>
      </>
    ),
  },
  {
    key: "2",
    name: "resType",
    req_opt: "Required",
    type: "String",
    description: (
      <p>
        This parameter is used to specify the response type. Available values
        are <b>table</b> (json) and <b>csv</b>.
      </p>
    ),
  },
  {
    key: "3",
    name: "columns",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Value is a string that can take multiple values separated by a comma.
          In this field you should pass <b>labels</b> that you expect in
          response. Predefined labels that can be used are:
        </p>
        <ul>
          <li>- date,</li>
          <li>- search_channel,</li>
          <li>- country,</li>
          <li>- total_searches,</li>
          <li>- monetized_searches,</li>
          <li>- clicks,</li>
          <li>- provider,</li>
          <li>- rpc,</li>
          <li>- rpm,</li>
          <li>- ctr,</li>
          <li>- revenue,</li>
          <li>- monetized_rpm,</li>
          <li>- ad_coverage,</li>
          <li>- revenue</li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    name: "date_from",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "5",
    name: "date_to",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "6",
    name: "limit",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Limit shows you the number of rows which will be shown in the API response. By default limit is 30.",
  },
  {
    key: "7",
    name: "offset",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Offset determines how many rows will be skipped before the data is displayed. Default is 0.",
  },
  {
    key: "8",
    name: "ordering",
    req_opt: "Optional",
    type: "String",
    description: (
      <div>
        <p>
          For ordering you can use one of the labels from the columns list. For
          descending just use column name, e.g. revenue, and for ascending use -
          symbol, e.g. -revenue.
        </p>
      </div>
    ),
  },
];

export const n2sColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Req/Opt",
    dataIndex: "req_opt",
    key: "req_opt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
];

export const n2sResponse = `{
  "total": [
      {
          "date": "",
          "search_channel": "",
          "revenue": 103017.08000000002,
          "monetized_rpm": 563.7573946687245,
      }
  ],
  "table": [
      {
          "date": 20210510,
          "search_channel": "src_ES_2504",
          "revenue": 7.46,
          "monetized_rpm": 116.5625,
      },
      {
          "date": 20210510,
          "search_channel": "src_US_2605",
          "revenue": 0.71,
          "monetized_rpm": 236.66666666666666,
      },
      {
          "date": 20210510,
          "search_channel": "src_CA_2570",
          "revenue": 54.980000000000004,
          "monetized_rpm": 518.6792452830189,
      },
      {
          "date": 20210510,
          "search_channel": "src_US_2525",
          "revenue": 27.34,
          "monetized_rpm": 250.8256880733945,
      },
      {
          "date": 20210510,
          "search_channel": "src_GB_2075",
          "revenue": 38.269999999999996,
          "monetized_rpm": 911.1904761904761,
      },
      {
          "date": 20210510,
          "search_channel": "src_RU_2525",
          "revenue": 0.43,
          "monetized_rpm": 43.0,
      },
      {
          "date": 20210510,
          "search_channel": "src_CA_2599",
          "revenue": 350.95,
          "monetized_rpm": 306.50655021834064,
      },
      {
          "date": 20210510,
          "search_channel": "src_ES_2572",
          "revenue": 0.0,
          "monetized_rpm": 0.0,
      },
      {
          "date": 20210510,
          "search_channel": "src_ES_2099",
          "revenue": 627.95,
          "monetized_rpm": 243.20294345468633,
      },
      {
          "date": 20210510,
          "search_channel": "src_ES_2098",
          "revenue": 0.0,
          "monetized_rpm": 0.0,
      }
  ],
  "rows": 248,
  "missing_dates": []
}`;
