import { Select } from "antd";
const { Option } = Select;

const statuses = ["ACTIVE", "PAUSED", "ARCHIVED", "DELETED"];

export const generateStatuses = (arr) => {
  return statuses.map((status) => (
    <Option key={status} value={status}>
      {status}
    </Option>
  ));
};

export const generateOptions = (arr) => {
  return arr.map((option) => (
    <Option key={option.name} value={option.name}>
      {option.name}
    </Option>
  ));
};

export const generateChannels = (arr) => {
  return arr.map((option) => (
    <Option key={option} value={option}>
      {option}
    </Option>
  ));
};

export const generateCountries = (arr) => {
  return arr.map((country) => (
    <Option key={country.code2} value={country.code2}>
      {country.name}
    </Option>
  ));
};
