import classNames from "classnames";
import React from "react";
import styles from "./AdPreview.module.css";

export const AdPreview = ({ widgetStyle, widgetHeadline }) => {
  const colors = ["#85c5fa", "#bb8dbd", "#b8d58c", "#fad42a", "#ff9434"];

  // const getWidgetStyle = (style, n) => {
  //   let specificWidgetStyle = null;
  //   switch (style) {
  //     case "widget-style-1":
  //       specificWidgetStyle = {
  //         widgetClassName: styles.widgetStyle1,
  //         widgetStyling: { borderLeft: `3px solid ${colors[n]}88` },
  //         widgetWrapperIconStyle: {},
  //         widgetTextStyle: {},
  //         widgetIconStyle: {}
  //       };
  //       break;
  //     default:
  //       specificWidgetStyle = styles.widgetStyle1;
  //       break;
  //   }
  //   return specificWidgetStyle;
  // };

  const getModifiedWidgetStyle = (style, n) => {
    let checkedStyle = null;
    switch (style) {
      case "widget-style-1":
        checkedStyle = { borderLeft: `3px solid ${colors[n]}88` };
        break;
      case "widget-style-2":
        checkedStyle = {
          border: `3px solid ${colors[n]}88`,
          borderLeft: `15px solid ${colors[n]}88`,
        };
        break;
      case "widget-style-3":
        checkedStyle = {};
        break;
      case "widget-style-4":
        checkedStyle = {};
        break;
      case "widget-style-5":
        checkedStyle = {};
        break;
      case "widget-style-6":
        checkedStyle = {};
        break;
      case "widget-style-7":
        checkedStyle = {
          flexBasis: "47.5%",
          boxShadow: `10px 0 0 0 ${colors[n]}`,
        };
        break;
      case "widget-style-8":
        checkedStyle = { justifyContent: "space-between" };
        break;
      case "widget-style-9":
        checkedStyle = {
          overflow: "initial",
          flexBasis: "46%",
          marginRight: "4%",
        };
        break;
      case "widget-style-10":
      case "widget-style-12":
        checkedStyle = {
          overflow: "initial",
          flexBasis: "49%",
          marginRight: "1%",
        };
        break;
      case "widget-style-11":
        checkedStyle = {
          overflow: "initial",
          flexBasis: "49%",
        };
        break;
      default:
        checkedStyle = { borderLeft: `3px solid ${colors[n]}88` };
        break;
    }
    return checkedStyle;
  };

  const getModifiedWrapperIconStyle = (style, n) => {
    let checkedStyle = null;
    switch (style) {
      case "widget-style-1":
        checkedStyle = { color: `${colors[n]}` };
        break;
      case "widget-style-2":
        checkedStyle = { backgroundColor: `${colors[n]}88` };
        break;
      case "widget-style-3":
        checkedStyle = { paddingRight: "5px" };
        break;
      case "widget-style-4":
        checkedStyle = { backgroundColor: "unset" };
        break;
      case "widget-style-6":
        checkedStyle = { backgroundColor: "unset" };
        break;
      case "widget-style-9":
        checkedStyle = {};
        break;
      case "widget-style-10":
      case "widget-style-12":
        checkedStyle = {};
        break;
      case "widget-style-11":
        checkedStyle = {};
        break;
      default:
        checkedStyle = { color: `${colors[n]}` };
        break;
    }
    return checkedStyle;
  };

  const getTextStyle = (style, n) => {
    let checkedStyle = null;
    switch (style) {
      case "widget-style-3":
        checkedStyle = {
          width: "85%",
          wordBreak: "break-all",
          paddingLeft: "10px",
          borderLeft: `2px solid ${colors[n]}88`,
        };
        break;
      default:
        checkedStyle = { width: "85%", wordBreak: "break-all" };
        break;
    }
    return checkedStyle;
  };

  const getIconStyle = (style, n) => {
    let checkedStyle = null;
    switch (style) {
      case "widget-style-1":
        checkedStyle = { color: `${colors[n]}` };
        break;
      case "widget-style-2":
        // checkedStyle = {backgroundColor: `${colors[n]}88`};
        break;
      case "widget-style-3":
        checkedStyle = {
          color: `${colors[n]}88`,
          fontFamily: "system-ui",
          border: `2px solid ${colors[n]}88`,
          borderRadius: "50%",
          paddingLeft: "5px",
          paddingRight: "5px",
        };
        break;
      case "widget-style-4":
        checkedStyle = { color: "#1990FF", opacity: 0.35 };
        break;
      case "widget-style-5":
        checkedStyle = {};
        break;
      case "widget-style-6":
        checkedStyle = {};
        break;
      case "widget-style-7":
        checkedStyle = { color: `${colors[n]}88`, fontFamily: "system-ui" };
        break;
      case "widget-style-8":
        checkedStyle = { color: `${colors[n]}88`, fontFamily: "system-ui" };
        break;
      case "widget-style-9":
        checkedStyle = {};
        break;
      case "widget-style-10":
      case "widget-style-12":
        checkedStyle = {};
        break;
      case "widget-style-11":
        checkedStyle = { color: `${colors[n]}88`, fontFamily: "system-ui" };
        break;
      default:
        checkedStyle = { backgroundColor: `${colors[n]}88` };
        break;
    }
    return checkedStyle;
  };

  const exportWidget = (i) => {
    return (
      <>
        {widgetStyle === "widget-style-11" ? (
          <div
            style={{ backgroundColor: `${colors[i]}88` }}
            className={styles.styledDivLeft}
          ></div>
        ) : (
          ""
        )}
        <div
          style={getModifiedWidgetStyle(widgetStyle, i)}
          className={styles[widgetStyle]}
        >
          <span style={getTextStyle(widgetStyle, i)}>
            Ad for Keyword {i + 1}
          </span>
          <div
            style={getModifiedWrapperIconStyle(widgetStyle, i)}
            className={classNames(
              {
                [styles.widget3Icon]: ![
                  "widget-style-8",
                  "widget-style-9",
                ].includes(widgetStyle),
              },
              {
                [styles.widgetIcon12]: widgetStyle === "widget-style-12",
              },
              {
                [styles.arrowWrapper]: ["widget-style-8"].includes(widgetStyle),
              },
              {
                [styles.widget4Icon]: widgetStyle === "widget-style-9",
              }
            )}
          >
            {widgetStyle === "widget-style-8" ? (
              <div className={styles.triangleRight}></div>
            ) : (
              ""
            )}
            {["widget-style-6", "widget-style-9"].includes(widgetStyle) ? (
              <>&#10095;&#10095;</>
            ) : widgetStyle === "widget-style-12" ? (
              <>&#10095;</>
            ) : (
              <i
                style={getIconStyle(widgetStyle, i)}
                className={
                  [
                    "widget-style-3",
                    "widget-style-6",
                    "widget-style-7",
                    "widget-style-8",
                    "widget-style-11",
                  ].includes(widgetStyle)
                    ? ""
                    : styles.playW3
                }
              >
                {[
                  "widget-style-3",
                  "widget-style-7",
                  "widget-style-8",
                  "widget-style-11",
                ].includes(widgetStyle) ? (
                  <>&#8594;</>
                ) : (
                  ""
                )}
              </i>
            )}
          </div>
        </div>
        {widgetStyle === "widget-style-8" ? (
          <div className={styles.triangleRight}></div>
        ) : widgetStyle === "widget-style-11" ? (
          <div
            style={{ backgroundColor: `${colors[i]}88` }}
            className={styles.styledDivRight}
          ></div>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className={styles.adPreviewBox}>
      <div className={styles.adPreviewWindow}>
        <div className={styles.desktopPreviewContent}>
          <div className={styles.adPreviewScreen}>
            <p className={styles.headtitle}>{widgetHeadline}</p>
            <div className={styles.adPreviewContentBox}>
              {[...Array(4)].map((_, i) =>
                ["widget-style-8", "widget-style-11"].includes(widgetStyle) ? (
                  <div
                    key={i}
                    className={styles.outerDiv}
                    style={{ padding: "0px" }}
                  >
                    {exportWidget(i)}
                  </div>
                ) : (
                  // <div style={{ display: "unset", padding: 0 }} key={i}>
                  exportWidget(i)
                  // </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
