import React, { useContext } from "react";
import styles from "./ModuleModal.module.css";
import { useHistory } from "react-router-dom";
import { WalletFilled, LockFilled } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { auth } from "../../../services/auth";
import UserContext from "../../../contexts/userContext";

export const ModuleModal = ({
  moduleVisible,
  setModuleVisible,
  setCurrentModule,
}) => {
  const { userInfo } = useContext(UserContext);
  const history = useHistory();

  const handleModuleChange = (module, initialPage) => {
    setCurrentModule(module);
    setModuleVisible(false);
    history.push(initialPage);
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      visible={moduleVisible}
      width="100%"
      className={styles.drawerContainer}
    >
      <div
        className={styles.moduleContent}
        style={{ backgroundImage: "url(/images/moduleBackground.png)" }}
      >
        <div className={styles.moduleContentList}>
          <img
            className={styles.logoModule}
            src="/images/logo-blue.svg"
            alt=""
          />
          {userInfo ? (
            <div className={styles.dropdownContentFix}>
              <img
                className={styles.profileDropdownAvatar}
                src={userInfo.image ? userInfo.image : "/images/user.png"}
                alt="avatar"
              />
              <span>{userInfo.email}</span>
              <Button
                onClick={() => {
                  setCurrentModule(auth.getFirstAvailableModule());
                  setModuleVisible(false);
                }}
                className={styles.btnDropdownContent}
                size="small"
              >
                Edit your profile
              </Button>
              <h2>Select your module</h2>
            </div>
          ) : null}
          <div>
            <div
              onClick={() => {
                if (
                  auth.hasPermission("offerwall") ||
                  auth.hasPermission("affiliate_programs")
                ) {
                  handleModuleChange("offerwall", "/offerwall/dashboard");
                } else {
                  // history.push("/", {
                  //   fromModuleSelection: "offerwall",
                  // });
                  setCurrentModule(auth.getFirstAvailableModule());
                  setModuleVisible(false);
                }
              }}
              className={
                auth.hasPermission("offerwall") ||
                auth.hasPermission("affiliate_programs")
                  ? styles.moduleNameDropdown
                  : styles.moduleNameDropdownLocked
              }
            >
              <span className={styles.moduleInitials}>AP</span>
              <div className={styles.moduleDetails}>
                <div className={styles.moduleTitle}>
                  <span>
                    Affiliate Programs
                    {!(
                      auth.hasPermission("offerwall") ||
                      auth.hasPermission("affiliate_programs")
                    ) && <LockFilled style={{ marginLeft: "0.5rem" }} />}
                  </span>
                </div>
                <p className={styles.moduleDescription}>
                  Global, rewarding programs with high-quality products.
                </p>
              </div>
            </div>

            {auth.hasPermission("search_feed") && (
              <div
                onClick={() => {
                  if (auth.hasPermission("search_feed")) {
                    handleModuleChange("search_feed", "/search_feed/dashboard");
                  } else {
                    // history.push("/", {
                    //   fromModuleSelection: "publisher",
                    // });
                    setCurrentModule(auth.getFirstAvailableModule());
                    setModuleVisible(false);
                  }
                }}
                className={styles.moduleNameDropdown}
              >
                <span className={styles.moduleInitials}>S</span>
                <div className={styles.moduleDetails}>
                  <div className={styles.moduleTitle}>
                    <span>Search BI</span>
                  </div>
                  <p className={styles.moduleDescription}>
                    360 solution for search networks & premium feeds.
                  </p>
                </div>
              </div>
            )}

            {auth.hasPermission("publisher") && (
              <div
                onClick={() => {
                  if (auth.hasPermission("publisher")) {
                    handleModuleChange("publisher", "/publisher/dashboard");
                  } else {
                    // history.push("/", {
                    //   fromModuleSelection: "publisher",
                    // });
                    setCurrentModule(auth.getFirstAvailableModule());
                    setModuleVisible(false);
                  }
                }}
                className={
                  auth.hasPermission("publisher")
                    ? styles.moduleNameDropdown
                    : styles.moduleNameDropdownLocked
                }
              >
                <span className={styles.moduleInitials}>W</span>
                <div className={styles.moduleDetails}>
                  <div className={styles.moduleTitle}>
                    <span>
                      Webmasters
                      {!auth.hasPermission("publisher") && (
                        <LockFilled style={{ marginLeft: "0.5rem" }} />
                      )}
                    </span>
                  </div>
                  <p className={styles.moduleDescription}>
                    Skyrocket revenue stream for your websites.
                  </p>
                </div>
              </div>
            )}
            <div
              onClick={() => {
                if (auth.hasPermission("search")) {
                  handleModuleChange("search", "/search/dashboard");
                } else {
                  // history.push("/", {
                  //   fromModuleSelection: "search",
                  // });
                  setCurrentModule(auth.getFirstAvailableModule());
                  setModuleVisible(false);
                }
              }}
              className={
                auth.hasPermission("search")
                  ? styles.moduleNameDropdown
                  : styles.moduleNameDropdownLocked
              }
            >
              <span className={styles.moduleInitials}>SF</span>
              <div className={styles.moduleDetails}>
                <div className={styles.moduleTitle}>
                  <span>
                    Search SDK
                    {!auth.hasPermission("search") && (
                      <LockFilled style={{ marginLeft: "0.5rem" }} />
                    )}
                  </span>
                </div>
                <p className={styles.moduleDescription}>
                  360 solution for search networks & premium feeds.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                if (auth.hasPermission("social_to_search")) {
                  handleModuleChange("social_to_search", "/agency/dashboard");
                } else {
                  // history.push("/", {
                  //   fromModuleSelection: "search",
                  // });
                  setCurrentModule(auth.getFirstAvailableModule());
                  setModuleVisible(false);
                }
              }}
              className={
                auth.hasPermission("social_to_search")
                  ? styles.moduleNameDropdown
                  : styles.moduleNameDropdownLocked
              }
            >
              <span className={styles.moduleInitials}>AG</span>
              <div className={styles.moduleDetails}>
                <div className={styles.moduleTitle}>
                  <span>
                    Agency
                    {!auth.hasPermission("social_to_search") && (
                      <LockFilled style={{ marginLeft: "0.5rem" }} />
                    )}
                  </span>
                </div>
                <p className={styles.moduleDescription}>
                  Comprehensive reporting for agency-led media strategies
                </p>
              </div>
            </div>
            <div
              className={
                !auth.hasOnlySearchBiPermission()
                  ? styles.moduleNameDropdown
                  : styles.moduleNameDropdownLocked
              }
              onClick={() =>
                !auth.hasOnlySearchBiPermission() &&
                handleModuleChange("billing", "/billing/payment-methods")
              }
            >
              <span className={styles.moduleInitials}>
                <WalletFilled style={{ fontSize: "1rem" }} />
              </span>
              <div className={styles.moduleDetails}>
                <div className={styles.moduleTitle}>
                  <span>
                    Finance & Payments{" "}
                    {auth.hasOnlySearchBiPermission() && (
                      <LockFilled style={{ marginLeft: "0.5rem" }} />
                    )}
                  </span>
                </div>
                <p className={styles.moduleDescription}>
                  Platform finance moderation - all in one place.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.dropdownContentFix}>
            <Button
              className={styles.btnDropdownLogout}
              onClick={() => auth.logout()}
              size="small"
            >
              Sign Out
            </Button>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://coinis.com/publisher-policy"
            >
              Publisher Policy
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

// {
/* <Button
          size="large"
          icon={<LogoutOutlined />}
          className={styles.logoutBtn}
          onClick={() => auth.logout()}
        >
          Logout
        </Button>

        {auth.hasPermission("offerwall") && (
          <div className={styles.moduleOffer}>
            <div className={styles.lottieAnimationBox}>
              <Lottie options={getDefaultOptions(dataOfferwall)} />
            </div>
            <p
              className={styles.moduleTitle}
              onClick={() => {
                if (auth.hasPermission("offerwall")) {
                  handleModuleChange("offerwall", "/offerwall/dashboard");
                } else {
                  openWarningNotification({
                    message:
                      "Sorry, you don't have permission to access this module. Please contact customer support!",
                  });
                }
              }}
            >
              <TagsOutlined /> Offer Wall
            </p>
          </div>
        )}

        {auth.hasPermission("publisher") && (
          <div
            className={styles.modulePublisher}
            style={
              myPermissions.length < 3
                ? { flexDirection: "column-reverse" }
                : {}
            }
          >
            <p
              className={styles.moduleTitle}
              onClick={() => {
                if (auth.hasPermission("publisher")) {
                  handleModuleChange("publisher", "/publisher/dashboard");
                } else {
                  openWarningNotification({
                    message:
                      "Sorry, you don't have permission to access this module. Please contact customer support!",
                  });
                }
              }}
            >
              <NotificationOutlined /> Publisher
            </p>
            <div className={styles.lottieAnimationBox}>
              <Lottie options={getDefaultOptions(dataPublisher)} />
            </div>
          </div>
        )}

        {auth.hasPermission("search") && (
          <div className={styles.moduleSearch}>
            <div className={styles.lottieAnimationBox}>
              <Lottie options={getDefaultOptions(dataSearch)} />
            </div>
            <p
              className={styles.moduleTitle}
              onClick={() => {
                if (auth.hasPermission("search")) {
                  handleModuleChange("search", "/search/dashboard");
                } else {
                  openWarningNotification({
                    message:
                      "Sorry, you don't have permission to access this module. Please contact customer support!",
                  });
                }
              }}
            >
              <SearchOutlined /> Search
            </p>
          </div>
        )} */
// }
