import { Button, Pagination, Table } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState, useCallback, useEffect } from "react";

import { useFetch } from "../../utils/hooks/useFetch";
import { Filters } from "./components/Filters";
import { TableTitle } from "./components/TableTitle";
// import { NestedTable } from "./components/NestedTable";
import styles from "./Reports.module.css";
import moment from "moment";
import { columnTemplate } from "./utils/columnsList";
import { openWarningNotification } from "../../utils/notifications";
import { numberWithSpaces } from "../../utils/abbreviateNumber";
import { searchReportsExtensionService } from "../../services/searchReportsExtension";
import { MissingDatesModal } from "../../components/MissingDatesModal";

export const SearchReportsExtension = ({ location }) => {
  const [columnsData] = useState(columnTemplate);
  const [tableColumns, setTableColumns] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const pageSizeOptions = [10, 20, 30, 50, 100];

  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(2, "days"),
    to: moment(),
  });
  const [sorting, setSorting] = useState({
    // date: "descend",
  });
  const [mergedTable, setMergedTable] = useState("tq");

  // last import & table color flag
  const [additionalInfo, setAdditionalInfo] = useState({});

  useEffect(() => {
    const getAdditionalInfo = async () => {
      try {
        const res = await searchReportsExtensionService.getAdditionalInfo();
        const last_import = res.last_import
          ? `Last import ${moment(
              res.last_import,
              "YYYY-MM-DD hh:mm:ss"
            ).format("YYYY-MM-DD hh:mm")}  UTC+0`
          : null;
        setAdditionalInfo({ ...res, last_import });
      } catch (e) {
        console.log(e);
      }
    };
    getAdditionalInfo();
  }, []);

  const [missingDatesVisible, setMissingDatesVisible] = useState(false);

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      if (sumOfData[0]) {
        let sumOfDataRefactored = { ...sumOfData[0] };
        if (sumOfData[0].revenue) {
          sumOfDataRefactored.revenue =
            "$ " +
            Number(sumOfData[0].revenue).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        if (sumOfData[0].cr) {
          sumOfDataRefactored.cr =
            parseFloat(sumOfData[0].cr).toFixed(2) + " %";
        }
        if (sumOfData[0].initial_searches_difference) {
          sumOfData[0].initial_searches_difference = null;
        }
        // if (sumOfData[0].filtered_traffic) {
        //   sumOfData[0].filtered_traffic = null;
        // }
        // if (sumOfData[0].coinis_redirects) {
        //   sumOfData[0].coinis_redirects = null;
        // }
        if (sumOfData[0].follow_on_searches) {
          sumOfDataRefactored.follow_on_searches =
            parseFloat(sumOfData[0].follow_on_searches * 100).toFixed(2) + " %";
        }
        if (sumOfData[0].filtered_traffic_percentage) {
          sumOfDataRefactored.filtered_traffic_percentage =
            parseFloat(sumOfData[0].filtered_traffic_percentage * 100).toFixed(
              2
            ) + " %";
        }
        sumOfDataRefactored.tq = sumOfDataRefactored.tq || "-";
        if (sumOfData[0].monetized_rpm) {
          sumOfDataRefactored.monetized_rpm =
            "$ " +
            Number(sumOfData[0].monetized_rpm).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        }
        return sumOfDataRefactored;
      }
    }
  };

  const refactorTableData = (additionalInfo) => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          refactor.color_flag = additionalInfo.color_flag;
          refactor.coinis_redirects = refactor.coinis_redirects || "-";
          refactor.filtered_traffic = refactor.filtered_traffic || "-";
          refactor.bing_initial_searches =
            refactor.bing_initial_searches || "-";
          refactor.tq = refactor.tq || "-";
          refactor.revenue =
            refactor.revenue &&
            "$ " +
              Number(refactor.revenue).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.monetized_rpm =
            refactor.monetized_rpm &&
            "$ " +
              Number(refactor.monetized_rpm).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.date = moment(refactor.date, "YYYYMMDD").format(
            "YYYY-MM-DD"
          );
          refactor.monetized_searches = numberWithSpaces(
            refactor.monetized_searches
          );
          refactor.total_searches = numberWithSpaces(refactor.total_searches);
          refactor.clicks = numberWithSpaces(refactor.clicks);
          return {
            key: index,
            ...refactor,
          };
        })
      : "";
    return tableDataRefactored;
  };

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting]
  );
  const updateColumns = useCallback(
    (value) => {
      setTableColumns(() => {
        let newTableColumn = [];
        columnTemplate
          .filter((column) => value.checkedColumns[column["value"]])
          .forEach(function ({
            text,
            value,
            tooltip,
            render,
            align,
            fixed,
            bold,
          }) {
            newTableColumn.push({
              title: (
                <TableTitle
                  text={text}
                  order={sorting[value] ? sorting[value] : false}
                  value={value}
                  align={align}
                  tooltip={tooltip}
                  onSortingClick={handleSortingClick}
                  bold={bold}
                />
              ),
              dataIndex: value,
              align,
              fixed,
              render,
            });
          });
        return newTableColumn;
      });
    },
    [sorting, handleSortingClick]
  );

  const loadZoneData = (cancelToken) => {
    return searchReportsExtensionService.getStatsPaged({
      selectedFilters,
      currentPage,
      pageSize,
      sorting,
      cancelToken,
    });
  };

  const [
    {
      data: {
        total: sumOfData,
        table: tableData,
        rows: totalItems,
        missing_dates: missingDates,
      },
      isFetching,
    },
    getZoneData,
  ] = useFetch(loadZoneData);
  const loading = isFetching;

  useEffect(() => setCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getZoneData();
    }
  }, [sorting, pageSize, selectedFilters, getZoneData, currentPage]);

  // sorting by date and revenue by default
  useEffect(() => {
    const selectedColumns = tableColumns.map((item) => item.dataIndex);
    if (
      selectedColumns.includes("date") &&
      selectedColumns.includes("revenue")
    ) {
      setSorting({ date: "descend", revenue: "descend" });
    } else {
      selectedColumns.includes("date")
        ? setSorting({ date: "descend" })
        : setSorting({ revenue: "descend" });
    }
  }, [tableColumns]);

  const handleExport = () => {
    if (selectedFilters.filters) {
      searchReportsExtensionService.exportReports({
        selectedFilters,
        currentPage,
        pageSize,
        sorting,
        action: false,
      });
    } else {
      openWarningNotification({
        message: "Please update filters before trying to export table.",
      });
    }
  };
  return (
    <div>
      <div className={styles.titleContainer}>
        <h2 className={styles.pageTitle}>Statistics</h2>
        {!!missingDates?.length && (
          <Button
            className={styles.missingDatesBtn}
            icon={<ExclamationCircleOutlined />}
            onClick={() => setMissingDatesVisible(true)}
          >
            Missing Dates
          </Button>
        )}
      </div>
      <Filters
        campaign_id={location.campaign_id}
        handleExport={handleExport}
        onSubmit={(value) => {
          updateColumns(value);
          setSelectedFilters(value);
        }}
        allColumns={columnsData}
        dateRange={dateRange}
        setDateRange={setDateRange}
        additionalInfo={additionalInfo}
        mergedTable={mergedTable}
        setMergedTable={setMergedTable}
      />
      <Table
        className={styles.mainTableContainer}
        style={{ marginTop: "2%" }}
        columns={tableColumns}
        rowKey={({ key }) => key}
        dataSource={refactorTableData(additionalInfo)}
        loading={loading}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
        pagination={false}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={(value) => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
              showTotal={(total) => {
                if (total)
                  return (
                    <div>
                      Showing {(currentPage - 1) * pageSize + 1} to{" "}
                      {total < currentPage * pageSize
                        ? total
                        : currentPage * pageSize}{" "}
                      of {total} entries.
                    </div>
                  );
              }}
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor();
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {numberWithSpaces(sumOfDataRefactored[el.dataIndex])}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
      />
      <MissingDatesModal
        visible={missingDatesVisible}
        setVisible={setMissingDatesVisible}
        missingDates={missingDates}
      />
    </div>
  );
};
