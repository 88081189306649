import {
  Button,
  Radio,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Tooltip,
  Popover,
  Slider,
  Row,
  Col,
} from "antd";
import { InfoCircleTwoTone, UndoOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styles from "../InPagePushForm.module.css";
import { Link } from "react-router-dom";
import { ChromePicker } from "react-color";
import isValidDomain from "is-valid-domain";
import { AdPreview } from "./AdPreview";

const { Option } = Select;

export const InPageForm = ({
  form,
  match,
  finishZoneForm,
  isFrequency,
  setIsFrequency,
  btnLoading,
  backgroundColor,
  setBackgroundColor,
  contentColor,
  setContentColor,
  contentRadius,
  setContentRadius,
  widgetStyle,
  setWidgetStyle,
  adPosition,
  setAdPosition,
}) => {
  const [adQuantity, setAdQuantity] = useState(1);
  return (
    <>
      <h3>
        {match.params.id && match.params.id !== "new" ? "Edit" : "New"} In-Page
        Push Zone
      </h3>
      <div className={styles.createContent}>
        <Form form={form} onFinish={finishZoneForm}>
          <div className={styles.inpageContainer}>
            <div className={styles.inpageBox}>
              <p className={styles.inputLabel}>
                1. Zone Title{" "}
                <Tooltip title="Enter the title for your zone. This will help you with identifying your performance in Statistics.">
                  <InfoCircleTwoTone />
                </Tooltip>
              </p>
              <Form.Item
                name="title"
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value && value !== "" && value.length <= 250) {
                        callback();
                      } else {
                        callback("Zone title is not valid!");
                      }
                    },
                  },
                ]}
              >
                <Input
                  showCount 
                  maxLength={512}
                  disabled={!!match.params.id && match.params.id !== "new"}
                  placeholder="Zone title"
                />
              </Form.Item>
              <p className={styles.inputLabel}>
                2. Web Site where JS will be added{"  "}
                <Tooltip title="Please provide the name of the website where this .js file will be placed. You may put your entire website, or just one page of your website. Putting the .js file in just one page of your website, will only affect that page, not the entire website.">
                  <InfoCircleTwoTone />
                </Tooltip>
              </p>
              <Form.Item
                name="website"
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (
                        value &&
                        value !== "" &&
                        !value.startsWith("www.") &&
                        isValidDomain(value) &&
                        value.length <= 250
                      ) {
                        callback();
                      } else {
                        callback("Domain name is not valid!");
                      }
                    },
                  },
                ]}
              >
                <Input
                  showCount 
                  maxLength={64}
                  onChange={e => {
                    form.setFieldsValue({
                      website: e.target.value.replace(
                        /^(https:\/\/)|(http:\/\/)/,
                        ""
                      ),
                    });
                  }}
                  disabled={!!match.params.id && match.params.id !== "new"}
                  addonBefore="www."
                  placeholder="new-example.com"
                />
              </Form.Item>
              <p className={styles.inputLabel}>
                3. When would you like to show push notification to user?
              </p>
              <Form.Item
                label="Time delay"
                className={styles.firstFreq}
                style={{ marginLeft: "1.2rem" }}
                name="timer"
                initialValue={10}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      const reg = /^\d+$/;
                      if (reg.test(value) || value === "") {
                        callback();
                      } else {
                        callback("Only digits!");
                      }
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: 120 }}
                  min={0}
                  addonAfter="sec"
                  className={styles.inputNumberDelay}
                />
              </Form.Item>
              <p className={styles.inputLabel}>
                4. How many ads would you like to show?
              </p>
              <Form.Item
                // label="Time delay"
                className={styles.firstFreq}
                style={{ marginLeft: "1.2rem" }}
                name="adv_number"
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: "Please type your old password!",
                  },
                ]}
              >
                <Radio.Group
                  value={adQuantity}
                  onChange={e => setAdQuantity(e.target.value)}
                >
                  <Space direction="vertical">
                    <Radio value={1}>One (1)</Radio>
                    <Radio value={2}>Two (2)</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <p className={styles.inputLabel}>
                5. Determine the frequency at which the ad would appear on your
                page
              </p>
              <div className={styles.frequencyContent}>
                <Form.Item label="Repeat ad">
                  <Switch checked={isFrequency} onChange={setIsFrequency} />
                </Form.Item>
                <div className={styles.frequencyBox}>
                  <Form.Item
                    label="Time delay"
                    className={styles.firstFreq}
                    name="frequency_interval"
                    initialValue={1}
                  >
                    <InputNumber
                      style={{ width: 120 }}
                      min={0}
                      disabled={!isFrequency}
                    />
                  </Form.Item>
                  <Form.Item
                    className={styles.secondFreq}
                    name="frequency_unit"
                    initialValue="min"
                  >
                    <Select style={{ width: 120 }} disabled={!isFrequency}>
                      <Option value="sec">sec</Option>
                      <Option value="min">min</Option>
                      <Option value="hour">hour</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className={styles.inpageBox}>
              <p className={styles.inputLabel}>6. Select widget style</p>
              <p className={styles.infoLabel}>
                In-page push may only have one widget style selected. You can
                change this later.
              </p>
              <Select
                style={{ width: "100%", marginBottom: "1.5rem" }}
                value={widgetStyle}
                onChange={value => {
                  if (value === "sharp") {
                    setContentRadius(0);
                  }
                  setWidgetStyle(value);
                }}
              >
                <Option value="old-school">Old-school</Option>
                <Option value="sharp">Sharp</Option>
                <Option value="compact">Compact</Option>
                <Option value="chatty">Chatty</Option>
              </Select>
              <p className={styles.inputLabel}>6.1. Select ad placement</p>
              <p className={styles.infoLabel}>
                Note that on mobile, these ads will only appear on either top or
                the bottom, depending on your selection.
              </p>
              <Radio.Group
                name="radiogroup"
                value={adPosition}
                onChange={e => setAdPosition(e.target.value)}
              >
                <Space direction="vertical" className={styles.adPlacementBox}>
                  <Radio value="top-left">Top Left</Radio>
                  <Radio value="bottom-left">Bottom Left</Radio>
                </Space>
                <Space direction="vertical">
                  <Radio value="top-right">Top Right</Radio>
                  <Radio value="bottom-right">Bottom Right</Radio>
                </Space>
              </Radio.Group>
              <p className={styles.inputLabel}>6.2. Ad background color</p>
              <div className={styles.colorPreviewRow}>
                <p>Choose background color </p>
                <Popover
                  placement="right"
                  trigger="click"
                  content={
                    <ChromePicker
                      color={backgroundColor}
                      onChangeComplete={e => setBackgroundColor(e.hex)}
                    />
                  }
                >
                  <div
                    className={styles.colorBox}
                    style={{ backgroundColor }}
                  />
                </Popover>
                <UndoOutlined
                  className={styles.undoButton}
                  onClick={() => setBackgroundColor("#ffffff")}
                />
              </div>

              <p className={styles.inputLabel}>6.3. Ad content color</p>
              <div className={styles.colorPreviewRow}>
                <p>Choose content color </p>
                <Popover
                  placement="right"
                  trigger="click"
                  content={
                    <ChromePicker
                      color={contentColor}
                      onChangeComplete={e => setContentColor(e.hex)}
                    />
                  }
                >
                  <div
                    className={styles.colorBox}
                    style={{ backgroundColor: contentColor }}
                  />
                </Popover>
                <UndoOutlined
                  className={styles.undoButton}
                  onClick={() => setContentColor("#000000")}
                />
              </div>

              {widgetStyle !== "sharp" && (
                <>
                  <p className={styles.inputLabel}>6.4. Border radius</p>
                  <Row className={styles.middleCustomBox}>
                    <Col span={12}>
                      <Slider
                        min={0}
                        max={20}
                        onChange={setContentRadius}
                        value={
                          typeof contentRadius === "number" ? contentRadius : 0
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={0}
                        max={20}
                        style={{ margin: "0 16px" }}
                        value={contentRadius}
                        onChange={setContentRadius}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
            <div className={styles.inpageBox}>
              <AdPreview
                adQuantity={adQuantity}
                widgetStyle={widgetStyle}
                backgroundColor={backgroundColor}
                contentColor={contentColor}
                contentRadius={contentRadius}
                adPosition={adPosition}
              />
            </div>
          </div>
          <div className={styles.actionBox}>
            <Link to="/publisher/zones">
              <Button>Back</Button>
            </Link>
            <Button type="primary" htmlType="submit" loading={btnLoading}>
              {match.params.id && match.params.id !== "new" ? "Save" : "Create"}{" "}
              & Get Tag
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
