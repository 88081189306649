export const tableData = [
  {
    key: "1",
    name: "key",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Authorization key, required parameter to access Zone ReportAPI.
          Contain only digits and letters. You may obtain yourauthorization key
          from your personal manager.
        </p>
        <p>
          Example: <b>UQlXSEHTd2kWbVY3n0ETLx3v</b>
        </p>
      </>
    ),
  },
  {
    key: "2",
    name: "resType",
    req_opt: "Required",
    type: "String",
    description: (
      <p>
        This parameter is used to specify the response type. Available values
        are <b>table</b> (json) and <b>csv</b>.
      </p>
    ),
  },
  {
    key: "3",
    name: "columns",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Value is a string that can take multiple values separated by a comma.
          In this field you should pass <b>labels</b> that you expect in
          response. Predefined labels that can be used are:
        </p>
        <ul>
          <li>- date,</li>
          <li>- hour,</li>
          <li>- zone_name,</li>
          <li>- country,</li>
          <li>- os,</li>
          <li>- os_version,</li>
          <li>- browser,</li>
          <li>- browser_version,</li>
          <li>- impressions,</li>
          <li>- clicks,</li>
          <li>- leads,</li>
          <li>- rpl,</li>
          <li>- rpm,</li>
          <li>- cr,</li>
          <li>- fill,</li>
          <li>- fill_rate,</li>
          <li>- revenue,</li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    name: "date_from",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "5",
    name: "date_to",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "6",
    name: "limit",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Limit shows you the number of rows which will be shown in the API response. By default limit is 30.",
  },
  {
    key: "7",
    name: "offset",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Offset determines how many rows will be skipped before the data is displayed. Default is 0.",
  },
  {
    key: "8",
    name: "ordering",
    req_opt: "Optional",
    type: "String",
    description: (
      <div>
        <p>
          For ordering you can use one of the labels from columns list.For
          descending just use column name, e.g. revenue, and forascending use -
          symbol, e.g. -revenue.
        </p>
      </div>
    ),
  },
];

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Req/Opt",
    dataIndex: "req_opt",
    key: "req_opt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
];

export const response = `{
  "total": [
    {
      "date": "",
      "zone_name": "",
      "impressions": 169,
      "clicks": 26,
      "leads": 8,
      "cr": 4.733727810650888,
      "revenue": 0.24542000105429906,
      "hour": "",
      "country": "",
      "os": "",
      "browser": "",
      "fill_rate": 0.0,
      "rpl": 0.030677500131787383,
      "rpm": 1.452189355350882,
      "fill": 0
    }
  ],
  "table": [
    {
      "date": "2021-08-03",
      "zone_name": "test_zone_1",
      "impressions": 0,
      "clicks": 1,
      "leads": 0,
      "cr": 0.0,
      "revenue": 0.0,
      "hour": 21,
      "country": "US",
      "os": "UNKNOWN",
      "browser": "Googlebot",
      "fill_rate": 0.0,
      "rpl": 0.0,
      "rpm": 0.0,
      "fill": 0
    },
    {
      "date": "2021-08-03",
      "zone_name": "test_zone_2",
      "impressions": 0,
      "clicks": 1,
      "leads": 0,
      "cr": 0.0,
      "revenue": 0.0,
      "hour": 15,
      "country": "US",
      "os": "UNKNOWN",
      "browser": "Googlebot",
      "fill_rate": 0.0,
      "rpl": 0.0,
      "rpm": 0.0,
      "fill": 0
    },
    {
      "date": "2021-08-03",
      "zone_name": "test_zone_3",
      "impressions": 8,
      "clicks": 0,
      "leads": 8,
      "cr": 100.0,
      "revenue": 0.0,
      "hour": 23,
      "country": "US",
      "os": "Mac OSX",
      "browser": "Chrome",
      "fill_rate": 0.0,
      "rpl": 0.0,
      "rpm": 0.0,
      "fill": 0
    },
    {
      "date": "2021-08-02",
      "zone_name": "test_zone_2",
      "impressions": 1,
      "clicks": 1,
      "leads": 0,
      "cr": 0.0,
      "revenue": 0.03500000014901161,
      "hour": 9,
      "country": "US",
      "os": "Mac OSX",
      "browser": "Chrome",
      "fill_rate": 0.0,
      "rpl": 0.0,
      "rpm": 35.00000014901161,
      "fill": 0
    },
    {
      "date": "2021-08-02",
      "zone_name": "test_zone_1",
      "impressions": 1,
      "clicks": 1,
      "leads": 0,
      "cr": 0.0,
      "revenue": 0.0,
      "hour": 12,
      "country": "US",
      "os": "Mac OSX",
      "browser": "Chrome",
      "fill_rate": 0.0,
      "rpl": 0.0,
      "rpm": 0.0,
      "fill": 0
    }
  ],
  "rows": 37
}`;
