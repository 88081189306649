import {
  Table,
  Input,
  Col,
  Row,
  Button,
  Tooltip,
  Checkbox,
  InputNumber,
  Spin,
  Image,
  Radio,
} from "antd";

import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useEffect, useState, useContext } from "react";
import {
  InfoCircleOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import styles from "./AccessModal.module.css";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../utils/notifications";
import { coinisProgramsService } from "../../services/coinisPrograms";
import UserContext from "../../contexts/userContext";
import TextArea from "antd/lib/input/TextArea";
import { MACROS } from "../../utils/offerwallMacros";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { generateRandomString } from "./utils";

export const StartRegular = ({
  campaignEdit,
  campaignDetails,
  program_id,
  program_title,
  program_status,
  landing_pages,
  setLoadingModal,
  setIsModalVisible,
  setTriggerNewlyCreated,
  setActiveTab,
  isCampaignClone,
  welcomeProgramId,
}) => {
  const { userInfo } = useContext(UserContext);
  const [subIds, setSubIds] = useState([{ subid: "", gen_id: uuidv4() }]);
  const [defaultParams, setDefaultParams] = useState({
    cid: "{CLICK_ID}",
    sid: "{SUB_ID}",
  });

  const [isAdvancedSetup, setIsAdvancedSetup] = useState(false);

  const [formLandingPages, setFormLandingPages] = useState([]);
  const [campaignObject, setCampaignObject] = useState({});
  const [linkLoading, setLinkLoading] = useState(false);
  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);

  const [titleError, setTitleError] = useState({});

  useEffect(() => {
    if (campaignEdit && !isCampaignClone) {
      setFormLandingPages(campaignDetails.landing_pages);
      setCampaignObject({
        ...campaignDetails,
        title: campaignDetails.campaign_name,
        redirect_utm_campaign:
          campaignDetails.additional_data &&
          campaignDetails.additional_data.redirect_utm_campaign,
        redirect_utm_source:
          campaignDetails.additional_data &&
          campaignDetails.additional_data.redirect_utm_source,
      });
      if (
        campaignDetails.additional_data &&
        campaignDetails.additional_data.params
      ) {
        const tempParams = campaignDetails.additional_data.params;
        const defaultParams = {
          cid: tempParams.find((item) => item.name === "cid")?.value || "",
          sid: tempParams.find((item) => item.name === "sid")?.value || "",
        };
        setDefaultParams(defaultParams);
        delete tempParams.cid;
        delete tempParams.sid;
        setSubIds(
          tempParams
            .filter((item) => item.name.includes("qs"))
            .map((item) => ({
              subid: item.value,
              gen_id: uuidv4(),
            }))
        );
      } else {
        setDefaultParams({ cid: "", sid: "" });
      }
    } else if (isCampaignClone) {
      setFormLandingPages(campaignDetails.landing_pages);
      setCampaignObject((prevState) => ({
        ...prevState,
        title: campaignDetails.campaign_name,
        postback: campaignDetails.postback,
      }));
      if (
        campaignDetails.additional_data &&
        campaignDetails.additional_data.params
      ) {
        const tempParams = campaignDetails.additional_data.params;
        const defaultParams = {
          cid: tempParams.find((item) => item.name === "cid").value || "",
          sid: tempParams.find((item) => item.name === "sid").value || "",
        };
        setDefaultParams(defaultParams);
        setSubIds(
          tempParams
            .filter((item) => item.name.includes("qs"))
            .map((item) => ({
              subid: item.value,
              gen_id: uuidv4(),
            }))
        );
      } else {
        setDefaultParams({ cid: "", sid: "" });
      }
    } else if (landing_pages) {
      setFormLandingPages(
        parseLandingPages(
          landing_pages.map((item) => {
            return {
              ...item,
              selected: true,
              weight: 1,
            };
          })
        )
      );
    }
  }, [isCampaignClone, campaignDetails, campaignEdit, landing_pages]);

  useEffect(() => {
    const getCampaignLinks = async (isCampaignClone) => {
      setLinkLoading(true);
      try {
        const res = await coinisProgramsService.getCampaignLinks();
        const postbackDefault = isCampaignClone
          ? {}
          : { postback: userInfo.postback_url };
        const title = `${program_title.replace(
          /\s/g,
          "_"
        )}_${generateRandomString(5)}`;
        setCampaignObject((prevState) => ({
          ...prevState,
          ...res.data,
          ...postbackDefault,
          title,
        }));
      } catch (e) {
      } finally {
        setLinkLoading(false);
      }
    };
    if (!campaignEdit || isCampaignClone) {
      if (!isCampaignClone) {
        try {
          const default_params = JSON.parse(userInfo.affiliate_preset_params);
          if (default_params) {
            setDefaultParams(default_params);
          }
        } catch (e) {
          console.log(e);
        }
      }
      getCampaignLinks(isCampaignClone);
    }
    setIsAdvancedSetup(userInfo.is_affiliate_advanced_setup);
  }, [isCampaignClone, setLinkLoading, program_title, campaignEdit, userInfo]);

  const parseLandingPages = (landingPages) => {
    const sum = landingPages
      .filter((item) => item.selected)
      .reduce((sum, item) => (sum += item.weight), 0);
    const newLandingPages = landingPages
      .map((item) => {
        return {
          ...item,
          percent: item.selected ? (100 * Number(item.weight)) / sum : 0,
        };
      })
      .sort((a, b) => {
        return a.percent % 1 > b.percent % 1 ? -1 : 1;
      })
      .map((item) => {
        return { ...item, percent: Math.floor(item.percent) };
      });
    const landingSum = newLandingPages.reduce(
      (sum, item) => sum + Number(item.percent),
      0
    );
    for (let i = 0; i < 100 - landingSum; i++) {
      newLandingPages[i].percent = newLandingPages[i].percent + 1;
    }
    return newLandingPages.sort((a, b) => (a.id < b.id ? -1 : 1));
  };

  const handleCheckUpdate = (id, key, value) => {
    setFormLandingPages((prevLandingPages) => {
      const landingId = prevLandingPages.findIndex((item) => item.id === id);
      const newLandingPages = [...prevLandingPages];
      newLandingPages[landingId] = {
        ...newLandingPages[landingId],
        [key]: value,
      };
      return parseLandingPages(newLandingPages);
    });
  };

  const [previewImage, setPreviewImage] = useState(null);

  const columns = [
    {
      title: "",
      dataIndex: "selected",
      width: 30,
      render: (selected, item) => {
        return (
          <Checkbox
            checked={selected}
            disabled={
              item.selected &&
              formLandingPages.filter((item) => item.selected).length < 2
            }
            onChange={(e) =>
              handleCheckUpdate(item.id, "selected", e.target.checked)
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "preview_url",
      width: 105,
      ellipsis: true,
      render: (preview_url, item) => (
        <img
          className={styles.regularLpPreview}
          src={preview_url || "data:image/png;base64," + item.preview}
          alt="LP"
        />
      ),
    },
    {
      title: "",
      dataIndex: "preview_url",
      render: (preview_url) => {
        return (
          <div className={styles.btnGroupModal}>
            <div>
              <Tooltip title={!preview_url ? "Preview not available" : ""}>
                <Button
                  disabled={!preview_url}
                  type="link"
                  target="_blank"
                  href={preview_url}
                  icon={<ExportOutlined style={{ fontSize: "18px" }} />}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title={!preview_url ? "Preview not available" : ""}>
                <CopyToClipboard text={preview_url}>
                  <Button
                    disabled={!preview_url}
                    icon={<CopyOutlined style={{ fontSize: "18px" }} />}
                    type="link"
                    onClick={() =>
                      openSuccessNotification({ message: "Copied" })
                    }
                    // type="primary"
                  />
                </CopyToClipboard>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "title",
    },
    {
      width: 130,
      dataIndex: "weight",
      title: (
        <div className={styles.tableHead}>
          <span>Weight</span>
          <Tooltip title="Use weight to define percentage. Weight represents recurrence of individual campaign LPs in relation to one another.">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      render: (weight, item) => {
        return (
          <InputNumber
            min={1}
            max={999}
            value={weight}
            onChange={(value) =>
              value > 0 &&
              value < 1000 &&
              handleCheckUpdate(item.id, "weight", value)
            }
          />
        );
      },
    },
    {
      width: 130,
      dataIndex: "percent",
      title: (
        <div className={styles.tableHead}>
          <span>Percentage</span>
          <Tooltip title="The frequency in which your campaign LP will appear to users.">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      render: (percent) => {
        return <Input value={percent || 0 + "%"} disabled />;
      },
    },
  ];

  const handleStartRegularCampaign = async () => {
    if (!campaignObject.title) {
      setTitleError({
        status: "error",
        message: "Please fill in the link name. This is a required field.",
      });
      return;
    }
    const params = [
      ...Object.entries(defaultParams).map((item) => ({
        name: item[0],
        value: item[1],
      })),
      ...subIds.map((item, index) => ({
        name: "qs" + (index + 1),
        value: item.subid,
      })),
    ];
    const additional_data = {
      params,
      redirect_utm_campaign: campaignObject.redirect_utm_campaign,
      redirect_utm_source: campaignObject.redirect_utm_source,
    };
    const requestObject = {
      program_id,
      ...campaignObject,
      lps: formLandingPages
        .filter((item) => item.selected)
        .map(({ id, percent, weight }) => {
          return {
            id,
            percent,
            weight,
          };
        }),
      additional_data,
    };
    setLoadingModal(true);
    try {
      const res =
        !campaignEdit || isCampaignClone
          ? await coinisProgramsService.startCampaign(requestObject)
          : await coinisProgramsService.editCampaign(
              campaignEdit,
              requestObject
            );
      if (res.success) {
        openSuccessNotification({
          message: "Successfully added program link!",
        });
        setIsModalVisible(false);
        setFormLandingPages([]);
        setCampaignObject({});
        if (setTriggerNewlyCreated) {
          setTriggerNewlyCreated((flag) => !flag);
          welcomeProgramId ? setActiveTab("1") : setActiveTab("2");
        }
      } else if (res.status === 409) {
        setTitleError({
          status: "error",
          message:
            "Oops! It seems that you already have a program link with the same name.",
        });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleMacrosClick = (macroContent) => {
    setCampaignObject((prevObj) => {
      const postback = whereTheCursorWas
        ? prevObj.postback.substring(0, whereTheCursorWas) +
          macroContent +
          prevObj.postback.substring(whereTheCursorWas, prevObj.postback.length)
        : prevObj.postback + macroContent;
      if (whereTheCursorWas !== undefined) {
        setWhereTheCursorWas((prevState) => prevState + macroContent.length);
      }
      return { ...prevObj, postback };
    });
  };

  const handleSubIdChange = (id, e) => {
    const subid = e.target.value;
    setSubIds((exAds) => {
      const newAds = [...exAds];
      newAds[id].subid = subid;
      return newAds;
    });
  };
  const handleSubIdDelete = (id) => {
    setSubIds((exAds) => {
      const newAds = [...exAds];
      newAds.splice(id, 1);
      return newAds;
    });
  };

  const parseUrlParameters = (defaultParams, parameters, campaign) => {
    let urlParameters = `?utm_campaign=${campaign.redirect_utm_campaign}&utm_source=${campaign.redirect_utm_source}`;
    Object.entries(defaultParams).forEach((item) => {
      if (item[1]) {
        urlParameters += `&${item[0]}=${item[1]}`;
      }
    });
    parameters.forEach((element, id) => {
      if (element.subid) {
        urlParameters += `&qs${id + 1}=${element.subid}`;
      }
    });
    return urlParameters;
  };

  const parseShortLinkUrlParameters = (defaultParams) => {
    let urlParameters = "";
    Object.entries(defaultParams)
      .filter((item) => item[1])
      .forEach((item, id) => {
        urlParameters += `${id === 0 ? "?" : "&"}${item[0]}=${item[1]}`;
      });
    return urlParameters;
  };

  return (
    <>
      <div className={styles.modalForm}>
        <Spin spinning={linkLoading}>
          <Row gutter={[24, 24]}>
            <Col xs={24} xxl={24}>
              <div>
                <p className={styles.accessSectionTitle}>Get program link</p>

                <p className={styles.accessMinorTitle}>Link name</p>
                <div className={styles.accessSectionContainer}>
                  <div className={styles.titleSection}>
                    <Input
                      className={styles.accessSectionInputLight}
                      value={campaignObject?.title}
                      suffix={<EditOutlined />}
                      status={titleError.status}
                      onChange={(e) => {
                        setTitleError({});
                        setCampaignObject((prevObj) => {
                          return { ...prevObj, title: e.target.value };
                        });
                      }}
                    />
                    {titleError.message && (
                      <span className={styles.titleError}>
                        {titleError.message}
                      </span>
                    )}
                  </div>
                  <CopyToClipboard text={campaignObject?.title}>
                    <Button
                      icon={<CopyOutlined />}
                      className={styles.accessSectionCopyBtn}
                      onClick={() =>
                        openSuccessNotification({ message: "Copied" })
                      }
                      type="primary"
                    />
                  </CopyToClipboard>
                </div>

                <p className={styles.accessMinorTitle}>Program link</p>
                <div className={styles.accessSectionContainer}>
                  <TextArea
                    className={styles.accessSectionInput}
                    disabled
                    value={
                      campaignObject.campaign_link +
                      parseUrlParameters(defaultParams, subIds, campaignObject)
                    }
                  />
                  <CopyToClipboard
                    text={
                      campaignObject.campaign_link +
                      parseUrlParameters(defaultParams, subIds, campaignObject)
                    }
                  >
                    <Button
                      icon={<CopyOutlined />}
                      className={styles.accessSectionCopyBtn}
                      onClick={() =>
                        openSuccessNotification({ message: "Copied" })
                      }
                      type="primary"
                    />
                  </CopyToClipboard>
                </div>
                <p className={styles.accessMinorTitle}>Short link</p>
                <div className={styles.accessSectionContainer}>
                  <Input
                    className={styles.accessSectionInputLight}
                    disabled
                    value={
                      campaignObject.short_link +
                      parseShortLinkUrlParameters(defaultParams)
                    }
                  />
                  <CopyToClipboard
                    text={
                      campaignObject.short_link +
                      parseShortLinkUrlParameters(defaultParams)
                    }
                  >
                    <Button
                      icon={<CopyOutlined />}
                      className={styles.accessSectionCopyBtn}
                      onClick={() =>
                        openSuccessNotification({ message: "Copied" })
                      }
                      type="primary"
                    />
                  </CopyToClipboard>
                </div>
              </div>
              <p className={styles.accessSectionTitle}>Postback settings</p>
              <p className={styles.accessMinorTitle}>Postback link</p>
              <div className={styles.accessSectionContainer}>
                <Input
                  className={styles.accessSectionInputLight}
                  value={campaignObject.postback}
                  suffix={<EditOutlined />}
                  onChange={(e) =>
                    setCampaignObject((prevObj) => {
                      return { ...prevObj, postback: e.target.value };
                    })
                  }
                />
                <CopyToClipboard text={campaignObject.postback}>
                  <Button
                    icon={<CopyOutlined />}
                    className={styles.accessSectionCopyBtn}
                    onClick={() =>
                      openSuccessNotification({ message: "Copied" })
                    }
                    type="primary"
                  />
                </CopyToClipboard>
              </div>
              <div className={styles.postbackMacros}>
                {/* <p>
            By editing postback URL, you can set custom postback for this
            campaign.
          </p> */}
                <p className={styles.postbackMacrosTitle}>
                  Add parameters
                  {/* <span>Add some of our macros to your postback URL</span> */}
                </p>
                <div>
                  {MACROS.map((macro) => (
                    <div
                      key={macro.value}
                      className={classNames(styles.macroItem, {
                        [styles.macroItemActive]:
                          campaignObject?.postback?.includes(macro.value),
                      })}
                      onClick={() => handleMacrosClick(macro.value)}
                    >
                      {macro.value}
                    </div>
                  ))}
                </div>
                <p className={styles.postbackMacrosWarning}>
                  By default, this is your global postback. You can view your
                  current settings on{" "}
                  <Link target="_blank" to="/offerwall/global-settings">
                    Global settings
                  </Link>{" "}
                  page. Trackers can be changed per each individual program
                  link, as well as the parameters.{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://coinis.com/faq/postback-faq"
                  >
                    Postback FAQ
                  </a>
                  {/* <WarningFilled style={{ color: "#1890ff" }} />
            <span>Make sure macros are properly added.</span> If the
            implementation process is invalid, you won’t be able to view correct
            statistics in your tracker. */}
                </p>
              </div>
              <Radio.Group
                className={styles.radioGrupSetup}
                onChange={(e) => setIsAdvancedSetup(e.target.value)}
                value={isAdvancedSetup}
              >
                <Radio value={false}>Basic link set-up</Radio>
                <Radio value={true}>Advanced link set-up</Radio>
              </Radio.Group>
              <p className={styles.lpInfo}>
                <span>Important!</span> With basic link set-up, all landing
                pages are included and distributed evenly. Click “Advanced link
                set-up” to apply different settings to landing pages.
              </p>
            </Col>
            {isAdvancedSetup && (
              <>
                <Col xs={24} xxl={24}>
                  <Spin spinning={linkLoading}>
                    <div className={styles.regularStartContainer}>
                      <div>
                        <p className={styles.accessMinorTitle}>
                          Default Parameters
                        </p>
                        <div>
                          <div
                            className={classNames(
                              styles.inputVariant,
                              styles.inputVariantTop
                            )}
                          >
                            <Input
                              className={styles.inputFieldParameter}
                              disabled
                              value="cid"
                            />
                            <Input
                              className={styles.inputFieldSubid}
                              value={defaultParams.cid}
                              placeholder="+ Add Parameter"
                              onChange={(e) =>
                                setDefaultParams((prevParams) => ({
                                  ...prevParams,
                                  cid: e.target.value,
                                }))
                              }
                            />
                          </div>
                          <div
                            className={classNames(
                              styles.inputVariant,
                              styles.inputVariantTop
                            )}
                          >
                            <Input
                              className={styles.inputFieldParameter}
                              disabled
                              value="sid"
                            />
                            <Input
                              className={styles.inputFieldSubid}
                              value={defaultParams.sid}
                              placeholder="+ Add Parameter"
                              onChange={(e) =>
                                setDefaultParams((prevParams) => ({
                                  ...prevParams,
                                  sid: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className={styles.accessMinorTitle}>
                          Additional Parameters
                        </p>
                        <div>
                          {subIds.map((item, id) => (
                            <div
                              key={item.gen_id}
                              className={styles.inputVariant}
                            >
                              <Input
                                className={styles.inputFieldParameter}
                                disabled
                                value={"qs" + (id + 1)}
                              />
                              <Input
                                className={styles.inputFieldSubid}
                                value={item.subid}
                                placeholder="+ Add Parameter"
                                onChange={(e) => handleSubIdChange(id, e)}
                              />
                              <Button
                                disabled={subIds.length < 2}
                                className={styles.deleteSubidBtn}
                                onClick={() => handleSubIdDelete(id)}
                                icon={<DeleteOutlined />}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.addSubidContainer}>
                      {subIds.length < 9 && (
                        <Button
                          onClick={() =>
                            setSubIds((exAds) => [
                              ...exAds,
                              {
                                subid: "",
                                gen_id: uuidv4(),
                              },
                            ])
                          }
                          className={styles.addParamBtn}
                          type="outlined"
                        >
                          Add Parameter
                        </Button>
                      )}
                    </div>
                  </Spin>
                </Col>

                <Col xs={24} xxl={24}>
                  <p className={styles.columnHeading}>Landing pages</p>
                  {/* <p>
                  Choose which landing pages you want to include in your
                  campaign.
                </p> */}
                  <div className={styles.landingPagesTable}>
                    <Table
                      scroll={{ y: 180 }}
                      rowKey="id"
                      columns={columns}
                      dataSource={formLandingPages}
                      pagination={false}
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>
          <div className={styles.startCampaignContainer}>
            <Button
              disabled={program_status === "Paused"}
              className={styles.buttonRad}
              htmlType="submit"
              type="primary"
              onClick={handleStartRegularCampaign}
            >
              {campaignEdit && !isCampaignClone
                ? "Save Program Link"
                : "Get Program Link"}
            </Button>
          </div>
        </Spin>
      </div>
      <Image
        width={200}
        style={{ display: "none" }}
        src={previewImage}
        preview={{
          visible: previewImage,
          src: previewImage,
          onVisibleChange: () => {
            setPreviewImage(null);
          },
        }}
      />
    </>
  );
};
