import { callApi } from "./api";
const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        if (key === "hour") {
          ordering.push("-date");
        }
        ordering.push("-" + key);
      } else {
        if (key === "hour") {
          ordering.push("date");
        }
        ordering.push(key);
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class ReportsService {
  async getCountries() {
    return await callApi({
      url: "/api/cns/countries/",
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: "/api/cns/os/",
    });
  }
  async getBrowsers() {
    return await callApi({
      url: "/api/cns/browsers/",
    });
  }

  async getStatsPaged({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    cancelToken,
    action,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      os: filters.os && filters.os.join(","),
      browser: filters.browser && filters.browser.join(","),
      country: filters.countries && filters.countries.join(","),
      // channel: filters.channel,
      zone_name: filters.zone_name && filters.zone_name.join(","),
      // campaign_id: filters.campaigns && filters.campaigns.join(","),
      ordering: ordering && ordering.join(","),
      action: action ? true : "",
    });
    return await callApi({
      url: `/api/publishers/stats/${query}`,
      cancelToken,
    });
  }

  async exportReports({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    action,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      os: filters.os && filters.os.join(","),
      browser: filters.browsers && filters.browsers.join(","),
      country: filters.countries && filters.countries.join(","),
      // channel: filters.channel,
      zone_id: filters.zone_id && filters.zone_id.join(","),
      // campaign_id: filters.campaigns && filters.campaigns.join(","),
      ordering: ordering && ordering.join(","),
      action: action ? true : "",
    });
    window.location = `/api/publishers/stats/?resType=csv&${query}`;
  }

  async changeTargetingStatus(data) {
    return await callApi({
      url: "/api/campaigns/targeting/",
      method: "POST",
      data,
    });
  }

  async getZonesAutocomplete(searchTerm) {
    return await callApi({
      url: `/api/publishers/zone_app/autocomplete/?search=${searchTerm}`,
    });
  }
  async getAppsAutocomplete(searchTerm) {
    return await callApi({
      url: `/api/publishers/apps/autocomplete/?search=${searchTerm}`,
    });
  }
}

export const publisherReportsService = new ReportsService();
