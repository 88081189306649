import React from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import styles from "./TableTitle.module.css";
import { Tooltip } from "antd";
export const TableTitle = ({ onSortingClick, value, order, tooltip, text }) => {
  let upArrowClass = null;
  let downArrowClass = null;
  // if (order === "descend") {
  //   upArrowClass = null;
  //   downArrowClass = "active-arrow";
  // } else if (order === "ascend") {
  //   upArrowClass = "active-arrow";
  //   downArrowClass = null;
  // }
  const handleAscSortingClick = e => {
    onSortingClick(value, "ascend", e.shiftKey);
  };
  const handleDescSortingClick = e => {
    onSortingClick(value, "descend", e.shiftKey);
  };
  return (
    <div className={styles.TableTitle}>
      <Tooltip title={tooltip}>
        <span className="title">{text}</span>
      </Tooltip>
      <div className="arrows">
        <div className={upArrowClass}>
          <CaretUpOutlined onClick={handleAscSortingClick} />
        </div>
        <div className={downArrowClass}>
          <CaretDownOutlined onClick={handleDescSortingClick} />
        </div>
      </div>
    </div>
  );
};
