import React, { useState } from "react";
import styles from "./AccessModal.module.css";
import { Input, Button, Upload, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { coinisProgramsService } from "../../services/coinisPrograms";
import { openErrorNotification } from "../../utils/notifications";

const { Dragger } = Upload;

export const TermsOfUseImages = ({
  imagesObject,
  setImagesObject,
  question,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const removeImage = (i) => {
    setImagesObject((prevState) => {
      const splicedImages = [...prevState.images];
      [...prevState.images].length !== 1
        ? splicedImages.splice(i, 1)
        : splicedImages.splice(0, 1);
      return { ...prevState, question, images: splicedImages };
    });
  };

  const addImage = (inputValue) => {
    setImagesObject((prevState) => {
      return {
        ...prevState,
        question,
        images: [...prevState.images, inputValue],
      };
    });
  };

  const handleImageDrop = async (image) => {
    setUploadLoading(true);
    try {
      const res = await coinisProgramsService.uploadImage(image);
      if (res.success) {
        setImagesObject((prevState) => {
          return {
            ...prevState,
            question,
            images: [...prevState.images, res.data.url],
          };
        });
      } else {
        openErrorNotification({ message: res.message });
      }
      return false;
    } catch (e) {
      openErrorNotification({
        message: "Error occurred while trying to add new image!",
      });
    } finally {
      setUploadLoading(false);
    }
    return false;
  };

  return (
    <>
      <p>Examples of creatives to be used</p>
      {imagesObject.images.length > 0 && (
        <div className={styles.carouselWrapper}>
          {imagesObject.images.map((image, index) => {
            return (
              <div className={styles.imageWrapper} key={index}>
                <img
                  src={image}
                  className={styles.imagePreview}
                  alt="carousel_image"
                />
                <div
                  className={styles.imageDelete}
                  onClick={(e) => {
                    e.preventDefault();
                    removeImage(index);
                  }}
                >
                  <span className={styles.deleteIcon}>
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Input.Group
        style={{
          marginTop: "0.5rem",
        }}
        compact
      >
        <Input
          style={{
            width: "80%",
          }}
          value={inputValue}
          placeholder="Insert url here"
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={(e) => {
            e.preventDefault();
            addImage(inputValue);
            setInputValue("");
          }}
        />
        <Button
          style={{
            width: "20%",
          }}
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            addImage(inputValue);
            setInputValue("");
          }}
        >
          Add URL
        </Button>
      </Input.Group>
      <p
        style={{
          textAlign: "center",
          paddingTop: "0.4rem",
          paddingBottom: "0.4rem",
        }}
      >
        OR
      </p>
      <Spin spinning={uploadLoading}>
        <Dragger
          name="file"
          multiple={true}
          beforeUpload={(image) => {
            handleImageDrop(image);
          }}
          fileList={[]}
        >
          <div className={styles.buttonUpload}>
            <UploadOutlined style={{ fontSize: "1.4rem" }} />{" "}
            <span>Upload or drag image</span>
          </div>
        </Dragger>
      </Spin>
    </>
  );
};
