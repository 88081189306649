import { callApi } from "./api";

class FeedbackService {
  async sendFeedback(data) {
    return await callApi({
      url: "/api/feedback/",
      method: "POST",
      data,
    });
  }
}

export const feedbackService = new FeedbackService();
