import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styles from "../InPagePushForm.module.css";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { openSuccessNotification } from "../../../../utils/notifications";
import { ExampleTag } from "../../components/ExampleTag";

import { TutorialVideo } from "../../components/TutorialVideo";

export const GetTag = ({ currentZoneInfo, location, setStep, match }) => {
  const [showExample, setShowExample] = useState(true);
  const [showIPPVideo, setShowIPPVideo] = useState(false);

  const history = useHistory();

  return (
    <>
      <h3>Get Tag for Sports News IPP (In-Page Push) zone</h3>
      <div>
        <p className={styles.parHints}>
          Paste this code at the end of your body tag.{" "}
          {/* <Tooltip title="If you are using Wordpress, you may insert this file via a Plugin. If not make sure this code is copied to all the website pages you wish to show ads.">
            <InfoCircleTwoTone />
          </Tooltip> */}
        </p>
        <div className={styles.scriptBox}>
          <CopyToClipboard
            text={
              currentZoneInfo && currentZoneInfo.script
                ? currentZoneInfo.script
                : ""
            }
          >
            <Button
              icon={<CopyOutlined />}
              type="primary"
              className={styles.copyLinkBtn}
              onClick={() => openSuccessNotification({ message: "Copied!" })}
            >
              Copy
            </Button>
          </CopyToClipboard>
          {currentZoneInfo && currentZoneInfo.script
            ? currentZoneInfo.script
            : ""}
        </div>
        <div className={styles.tutorialContainer}>
          <img onClick={() => setShowIPPVideo(true)} alt="preview" className={styles.videoPreview} src="/images/video-preview.png" />
          <div className={styles.videoInstructions}>
            <p>Take a look at this video for step-by-step tutorial which will show you how to correctly add the code to your website. Make sure you properly input the code, or the ads won’t appear on your page at all. Click the preview or <span onClick={() => setShowIPPVideo(true)}>this link</span> to view it.</p>
            <p>You can see correct example of usage below.</p>
          </div>
        </div>
        <TutorialVideo source={"/media/video/inpage_push.mp4"} showVideo={showIPPVideo} setShowVideo={setShowIPPVideo} />
        <p className={styles.parRule}>
          Make sure you properly input the code, <b>or the ads won’t appear </b>
          on your page at all. You can see correct example of usage below.{" "}
          <span
            className={styles.showHideExamples}
            onClick={() => setShowExample(!showExample)}
          >
            {showExample ? "Hide examples." : "See examples."}{" "}
          </span>
        </p>
        {showExample && <ExampleTag type={'ipp'} />}
      </div>
      <div className={styles.actionBox}>
        <Button
          className={styles.backBtnInPage}
          onClick={() =>
            !location.preview && match.params.id !== "new"
              ? setStep(0)
              : history.push("/publisher/zones")
          }
        >
          {match.params.id && match.params.id !== "new" ? "Back" : "Done"}
        </Button>
        {match.params.id && match.params.id !== "new" && !location.preview ?
          <Button type="primary" onClick={() => history.push("/publisher/zones")}>
            Done
          </Button> : ""}
      </div>
    </>
  );
};
