import moment from "moment";
import styles from "../Reports.module.css";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { numberWithSpaces } from "../../../utils/abbreviateNumber";
import { BASE_URL } from "../../../config";
import { Button } from "antd";

export const columnTemplate = [
  {
    value: "expand_row",
    text: "",
    longText: "",
    align: "center",
    fixed: "left",
    render: (item, record) => (
      <div className={styles.expandRow}>
        {item && !record.is_inserted_row ? (
          <Button
            type="link"
            icon={
              record.is_expanded_row ? (
                <MinusSquareOutlined />
              ) : (
                <PlusSquareOutlined />
              )
            }
          />
        ) : // ) : record.is_inserted_row ? (
        //   <LineOutlined rotate={90} />
        null}
      </div>
    ),
  },
  {
    value: "date",
    text: "Date",
    longText: "Date",
    align: "center",
    tooltip:
      "Specific time period during which the advertising data was collected, allowing you to track performance over time.",
    fixed: "left",
  },
  {
    value: "status",
    text: "Status",
    longText: "Status",
    align: "center",
    bold: true,
    tooltip:
      "Current state of your advertising campaigns, indicating whether they are active, paused, or have concluded.",
    fixed: "left",
  },
  {
    value: "search_channel",
    text: "Channel",
    longText: "Search Channel",
    align: "left",
    bold: true,
    tooltip:
      "The unique identifier for your search feed campaigns, typically set up by account managers to differentiate between various campaign efforts.",
    fixed: "left",
    render: (item) => <div className={styles.channelNameTitle}>{item}</div>,
  },
  {
    value: "country",
    text: "Country",
    longText: "Country",
    tooltip: "Country from which traffic is coming",
    render: (item) => {
      return (
        <div className={styles.countryBox}>
          {item && item !== "--" && item.toLowerCase() !== "unknown" && (
            <img
              src={`${BASE_URL}media/countries/${item.toLowerCase()}.png`}
              alt="."
            />
          )}
          {item}
        </div>
      );
    },
    align: "center",
  },
  {
    value: "spent",
    text: "Spent",
    longText: "Spent",
    align: "center",
    tooltip:
      "Represents the total expenditure on a network for advertising during the reporting period, reflecting your advertising investment.",
  },
  {
    value: "revenue",
    text: "Revenue",
    longText: "Revenue",
    align: "center",
    tooltip:
      "The total income generated from the campaigns through search feeds, showing the direct financial outcome of your ad spend.",
  },
  {
    value: "gross_profit",
    text: "Gross Profit",
    longText: "Gross Profit",
    align: "center",
    tooltip:
      "The total profit from the campaigns without any deductions, representing the total earnings before rev-share.",
  },
  {
    value: "profit",
    text: "Profit",
    longText: "Profit",
    align: "center",
    tooltip:
      "The net income after deducting our share of the profits, reflecting the your actual earnings from the campaigns.",
  },
  {
    value: "impressions",
    text: "Impressions",
    longText: "Impressions",
    align: "center",
    tooltip:
      "The number of times your ads were displayed on the screen for your target audience, indicating the reach of your campaign.",
  },
  {
    value: "clicks",
    text: "Ad Clicks",
    longText: "Ad Clicks",
    align: "center",
    tooltip:
      "Counts the instances where a user actively clicked on your ad, which is a key metric for gauging audience engagement.",
  },
  {
    value: "clicks_feed",
    text: "Feed Clicks",
    longText: "Feed Clicks",
    align: "center",
    tooltip:
      "Refers to the data stream that records each click on your ads. It’s used to analyze user interaction and engagement with your campaign.",
  },
  {
    value: "search_cr",
    text: "Search CR",
    longText: "Search Conversion Rate",
    align: "center",
    tooltip:
      "The percentage of users who have made a conversion (leads, sales, purchases, registrations).",
    render: (item) => <div className={styles.greenText}>{item}</div>,
  },
  {
    value: "roi",
    text: "ROI",
    longText: "Return of Investment",
    align: "center",
    tooltip:
      "A performance measure used to evaluate the efficiency of an investment. In the context of advertising, it compares the profit generated by ads to the cost of those ads.",
  },
  {
    value: "cpc",
    text: "CPC",
    longText: "Cost per Click",
    align: "center",
    tooltip:
      "The amount you pay each time a user clicks on your ad. This metric helps you understand the cost-effectiveness of your campaigns.",
  },
  {
    value: "cpm",
    text: "CPM",
    longText: "Cost per Mile",
    align: "center",
    tooltip:
      "Also known as cost per thousand impressions, this measures how much it costs for your ad to be displayed 1,000 times, not accounting for clicks or conversions.",
  },
  {
    value: "rpm",
    text: "RPM",
    longText: "Revenue per Mile",
    align: "center",
    tooltip:
      "The estimated revenue generated for every 1,000 impressions. This metric helps you understand the value each impression brings to your business.",
  },
  {
    value: "ctr",
    text: "CTR",
    longText: "Click Through Rate",
    align: "center",
    tooltip:
      "The percentage of impressions that resulted in a click. This is a key metric to gauge how well your ads are capturing your target audience’s attention.",
  },
  {
    value: "rpc",
    text: "RPC",
    longText: "Revenue per Click",
    align: "center",
    tooltip:
      "The average revenue earned for each click on an ad. This is important for understanding the value each click brings to your business.",
  },
];

export const columnPreset = {
  expand_row: true,
  date: true,
  status: true,
  search_channel: true,
  country: true,
  spent: true,
  revenue: true,
  gross_profit: true,
  profit: true,
  impressions: true,
  clicks: true,
  clicks_feed: true,
  roi: true,
  rpm: true,
  cpc: true,
  cpm: true,
  ctr: true,
  rpc: true,
  search_cr: true,
};

// columns formating

export const sumOfDataRefactor = (sumOfData) => {
  if (sumOfData) {
    if (sumOfData[0]) {
      let sumOfDataRefactored = { ...sumOfData[0] };
      if (sumOfData[0].expand_row) {
        sumOfDataRefactored.expand_row = "";
      }

      if (sumOfData[0].revenue) {
        sumOfDataRefactored.revenue =
          "$ " +
          Number(sumOfData[0].revenue).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].spent) {
        sumOfDataRefactored.spent =
          "$ " +
          Number(sumOfData[0].spent).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].profit) {
        sumOfDataRefactored.profit =
          "$ " +
          Number(sumOfData[0].profit).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].gross_profit) {
        sumOfDataRefactored.gross_profit =
          "$ " +
          Number(sumOfData[0].gross_profit).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].rpm) {
        sumOfDataRefactored.rpm =
          "$ " +
          Number(sumOfData[0].rpm).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].cpc) {
        sumOfDataRefactored.cpc =
          "$ " +
          Number(sumOfData[0].cpc).toLocaleString(undefined, {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          });
      }
      if (sumOfData[0].cpm) {
        sumOfDataRefactored.cpm =
          "$ " +
          Number(sumOfData[0].cpm).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].rpc) {
        sumOfDataRefactored.rpc =
          "$ " +
          Number(sumOfData[0].rpc).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      if (sumOfData[0].search_cr) {
        sumOfDataRefactored.search_cr =
          parseFloat(sumOfData[0].search_cr * 100).toFixed(2) + " %";
      }
      if (sumOfData[0].roi) {
        sumOfDataRefactored.roi =
          parseFloat(sumOfData[0].roi * 100).toFixed(2) + " %";
      }
      if (sumOfData[0].ctr) {
        sumOfDataRefactored.ctr =
          parseFloat(sumOfData[0].ctr * 100).toFixed(2) + " %";
      }
      if (sumOfData[0].status) {
        sumOfDataRefactored.status = "";
      }
      if (sumOfData[0].monetized_rpm) {
        sumOfDataRefactored.monetized_rpm =
          "$ " +
          Number(sumOfData[0].monetized_rpm).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      }
      return sumOfDataRefactored;
    }
  }
};

export const refactorTableData = (tableData) => {
  const tableDataRefactored = tableData
    ? tableData.map((el, index) => {
        const refactor = { ...el };

        // if is_inserted_row than remove grouping columns
        if (refactor.is_inserted_row) {
          delete refactor.date;
          delete refactor.status;
          delete refactor.search_channel;
        }

        refactor.revenue =
          refactor.revenue &&
          "$ " +
            Number(refactor.revenue).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.spent =
          refactor.spent &&
          "$ " +
            Number(refactor.spent).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.profit =
          refactor.profit &&
          "$ " +
            Number(refactor.profit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.rpm =
          refactor.rpm &&
          "$ " +
            Number(refactor.rpm).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.cpc =
          refactor.cpc &&
          "$ " +
            Number(refactor.cpc).toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            });
        refactor.cpm =
          refactor.cpm &&
          "$ " +
            Number(refactor.cpm).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.gross_profit =
          refactor.gross_profit &&
          "$ " +
            Number(refactor.gross_profit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.monetized_rpm =
          refactor.monetized_rpm &&
          "$ " +
            Number(refactor.monetized_rpm).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.rpc =
          refactor.rpc &&
          "$ " +
            Number(refactor.rpc).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
        refactor.date =
          refactor.date &&
          moment(refactor.date, "YYYYMMDD").format("YYYY-MM-DD");
        refactor.monetized_searches = numberWithSpaces(
          refactor.monetized_searches
        );
        refactor.roi =
          refactor.roi && parseFloat(refactor.roi * 100).toFixed(2) + "%";
        refactor.ctr =
          refactor.ctr && parseFloat(refactor.ctr * 100).toFixed(2) + "%";
        refactor.search_cr =
          refactor.search_cr &&
          parseFloat(refactor.search_cr * 100).toFixed(2) + "%";
        refactor.total_searches = numberWithSpaces(refactor.total_searches);
        refactor.clicks = numberWithSpaces(refactor.clicks);
        Object.keys(refactor).forEach((key) => {
          if (
            !["expand_row", "og_index"].includes(key) &&
            (refactor[key] === 0 || refactor[key] === null)
          ) {
            refactor[key] = "-";
          }
        });
        return {
          key: index,
          ...refactor,
        };
      })
    : "";
  return tableDataRefactored;
};
