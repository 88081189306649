import React from "react";

import styles from "../CoinisCampaigns.module.css";

export const StatusTableCol = ({ status }) => {
  return (
    <div
      className={`${styles.status} ${styles["status" + status.toLowerCase()]}`}
    >
      {status}
    </div>
  );
};
