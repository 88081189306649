import { Select } from "antd";
const { Option } = Select;

export const generateCountries = (arr) => {
  return arr.map((country) => (
    <Option key={country.code2} value={country.id}>
      {country.name}
    </Option>
  ));
};
