import React, { useCallback, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { getAllowedRoutes } from "../../routes";
import { Logo } from "./Logo";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { ModuleName } from "./ModuleName";
import styles from "../Layout.module.css";

const generateSidebarMenu = (routes, parentPath = "", currentModule) => {
  return routes.map((route) => {
    if (route.skipRender || !route.permission_name.includes(currentModule)) {
      return null;
    }
    const path = route.linkPath || route.path;

    if (route.children) {
      return route.children.length > 0 ? (
        <Menu.SubMenu
          key={path}
          title={
            <span className={styles.submenuTitleRestyle}>
              {route.icon}
              <span>{route.title}</span>
            </span>
          }
        >
          {generateSidebarMenu(route.children, path, currentModule)}
        </Menu.SubMenu>
      ) : null;
    }

    const fullPath = parentPath + path;
    return (
      <Menu.Item key={fullPath}>
        <div className={styles.menuItemRestyle}>
          <Link to={fullPath}>
            {!parentPath && route.icon}
            <span>{route.title}</span>
          </Link>
        </div>
      </Menu.Item>
    );
  });
};

export const Sidebar = ({
  selectedKey,
  currentModule,
  isSmallScreen,
  collapsed,
  setCollapsed,
}) => {
  const onCollapsed = useCallback(
    (collapsed) => {
      setCollapsed(collapsed);
      localStorage.setItem("collapse", collapsed);
    },
    [setCollapsed]
  );

  useEffect(() => {
    isSmallScreen ? onCollapsed(true) : onCollapsed(false);
  }, [isSmallScreen, onCollapsed]);

  const makeTrigger = (collapsed, isSmallScreen) => {
    if (isSmallScreen) {
      return collapsed ? (
        <MenuOutlined style={{ fontSize: "1.3rem" }} />
      ) : (
        <CloseOutlined style={{ fontSize: "1.3rem" }} />
      );
    }
  };

  return (
    <Layout.Sider
      style={{
        position: isSmallScreen && "fixed",
        zIndex: 200,
        minHeight: "100vh",
      }}
      collapsedWidth={isSmallScreen ? 0 : 80}
      // collapsible
      collapsed={collapsed}
      className={styles.sidebarContent}
      // onClick={onCollapse}
      trigger={makeTrigger(collapsed, isSmallScreen)}
    >
      <div>
        <Logo collapsed={collapsed} currentModule={currentModule} />
        <Menu
          inlineIndent={13}
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
        >
          {generateSidebarMenu(getAllowedRoutes(), "", currentModule)}
        </Menu>
      </div>
      {/* {!isSmallScreen && (
        <> */}
      <ModuleName
        currentModule={currentModule}
        collapsed={collapsed}
        onCollapsed={onCollapsed}
      />
      {/* </>
      )} */}
    </Layout.Sider>
  );
};
