import { callApi } from './api';

class UserFiltersService {

  async saveFilter(data) {
    return await callApi({
      url: '/api/user-filters/',
      method: "POST",
      data
    });
  }

  async deleteFilter(id) {
    return await callApi({
      url: `/api/user-filters/?id=${id}`,
      method: "DELETE"
    });
  }

  async getFilters() {
    return await callApi({
      url: '/api/user-filters/',
      method: "GET",
    });
  }
}

export const userFiltersService = new UserFiltersService();
