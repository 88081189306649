import { Button, Form, Input, Select, Tooltip } from "antd";
import { InfoCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import styles from "../SearchWidgetBuilder.module.css";
import { Link } from "react-router-dom";
import isValidDomain from "is-valid-domain";
import { AdPreview } from "./AdPreview";
import { WidgetPreviewWrapper } from "./WidgetPreviewWrapper";
import { NewWidgetForm } from "./NewWidgetForm";
import classNames from "classnames";
import { Questionnaire } from "../../components/Questionnaire";
import { openWarningNotification } from "../../../../utils/notifications";

export const WidgetForm = ({
  form,
  id,
  finishZoneForm,
  btnLoading,
  ctxCategories,
  createdWidgets,
  setCreatedWidgets,
  isShaking,
  setIsShaking,
  match,
  questionnaireLoading,
  setQuestionnaireLoading,
}) => {
  const [widgetFormVisible, setWidgetFormVisible] = useState(false);
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);

  const handleDelete = (id) => {
    setCreatedWidgets((createdWidgets) => {
      const tempWidgets = [...createdWidgets];
      tempWidgets.splice(id, 1);
      return tempWidgets;
    });
  };

  const handleOpenEdit = (widget, id) => {
    setWidgetFormVisible({ widget, id });
  };

  return (
    <>
      <h3 className={styles.titleWidget}>
        {id && id !== "new" ? "Edit" : "New"} Contextual Ad Widget
      </h3>
      <div className={styles.createContent}>
        {/* <Radio.Group
          value={formStep}
          size="large"
          buttonStyle="solid"
          onChange={e => setFormStep(e.target.value)}
        >
          <Radio.Button style={{ width: 180, textAlign: "center" }} value={0}>
            <SettingOutlined /> General Settings
          </Radio.Button>
          <Radio.Button style={{ width: 180, textAlign: "center" }} value={1}>
            <FormatPainterOutlined /> Design
          </Radio.Button>
        </Radio.Group> */}
        <Form form={form}>
          <div className={styles.inpageContainer}>
            <div className={styles.widgetBox}>
              <p className={styles.formTitleMain}>1. General Set-up</p>
              <p className={styles.inputLabel}>Widget title</p>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please Enter widget title!",
                  },
                ]}
              >
                <Input
                  showCount
                  maxLength={512}
                  disabled={!!(id && id !== "new")}
                  placeholder="Enter widget title"
                />
              </Form.Item>
              <p className={styles.inputLabel}>
                Website where you want to display widget{" "}
                <Tooltip title="Please provide the name of the website where this .js file will be placed. You may put your entire website, or just one page of your website. Putting the .js file in just one page of your website, will only affect that page, not the entire website.">
                  <InfoCircleTwoTone />
                </Tooltip>
              </p>
              <Form.Item
                name="website"
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (
                        value &&
                        value !== "" &&
                        !value.startsWith("www.") &&
                        isValidDomain(value)
                      ) {
                        callback();
                      } else {
                        callback("Domain name is not valid!");
                      }
                    },
                  },
                ]}
              >
                <Input
                  showCount
                  maxLength={64}
                  disabled={!!(id && id !== "new")}
                  addonBefore="www."
                  placeholder="new-example.com"
                />
              </Form.Item>

              <p className={styles.inputLabel}>Ad vertical</p>
              <Form.Item
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please Enter widget name!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Ad Vertical"
                  options={ctxCategories}
                />
              </Form.Item>
              {/* <p className={styles.inputLabel}>Widget Headline</p>
              <Select
                placeholder="Select Widget Headline"
                style={{ width: "100%", marginBottom: "20px" }}
                value={widgetHeadline}
                onChange={value => setWidgetHeadline(value)}
              >
                <Option value="Related Topics (Ads)">
                  Related Topics (Ads)
                </Option>
                <Option value="Promoted Content">Promoted Content</Option>
                <Option value="More Like This (Ads)">
                  More Like This (Ads)
                </Option>
              </Select> */}
              <p className={styles.inputLabel}>Country</p>
              <p className={styles.inputLabelDesc}>
                You can only display your widgets to users from one of these 4
                countries: <b>US</b>, <b>UK</b>, <b>AU</b> and <b>NZ</b>.
              </p>
              <img src="/images/ctx-targeting.svg" alt="targeting-map" />
            </div>
            <div className={styles.widgetsContainer}>
              <p className={styles.inputLabel}>Widget theme</p>
              <p>
                Your widget can display up to 4 text ads, and you can have no
                more than 6 widgets per zone.
              </p>
              <div className={styles.widgetsGrid}>
                {createdWidgets.map((widget, i) => (
                  <WidgetPreviewWrapper
                    key={i}
                    widget={widget}
                    id={i}
                    handleOpenEdit={handleOpenEdit}
                    handleDelete={handleDelete}
                  >
                    <AdPreview
                      widgetStyle={widget.widgetStyle}
                      widgetHeadline={widget.widgetHeadline}
                    />{" "}
                  </WidgetPreviewWrapper>
                ))}
                {createdWidgets.length < 6 && (
                  <div
                    className={classNames(styles.widgetWrapper, {
                      [styles.widgetWrapperShake]: isShaking,
                    })}
                    onClick={() => setWidgetFormVisible(true)}
                  >
                    <div>
                      <span>
                        <PlusOutlined />
                      </span>
                      <span>Add new widget</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <NewWidgetForm
            visible={widgetFormVisible}
            setVisible={setWidgetFormVisible}
            setCreatedWidgets={setCreatedWidgets}
          />

          <div className={styles.actionBox}>
            <Link to="/publisher/zones">
              <Button>Back</Button>
            </Link>
            <Button
              type="primary"
              onClick={() => {
                if (match.params.id && match.params.id !== "new") {
                  const values = form.getFieldsValue(true);
                  finishZoneForm(values);
                } else {
                  form
                    .validateFields()
                    .then(() => {
                      if (!createdWidgets.length) {
                        openWarningNotification({
                          message:
                            "Please create at least one widget to proceed.",
                        });
                        setIsShaking(true);
                        setTimeout(() => {
                          setIsShaking(false);
                        }, 2000);
                        return;
                      }
                      setQuestionnaireOpen(true);
                    })
                    .catch(() => {
                      openWarningNotification({
                        message: "Please complete form input.",
                      });
                    });
                }
              }}
              loading={btnLoading}
            >
              {id && id !== "new" ? "Save" : "Create"} & Get Tag
            </Button>
          </div>
        </Form>
      </div>
      <Questionnaire
        isOpen={questionnaireOpen}
        setIsOpen={setQuestionnaireOpen}
        loading={questionnaireLoading}
        setLoading={setQuestionnaireLoading}
        isNewZone={!id}
        handleConfirm={(questionnaireValues) => {
          const values = form.getFieldsValue(true);
          finishZoneForm({ ...values, ...questionnaireValues });
        }}
      />
    </>
  );
};
