export const tableData = [
  {
    key: "1",
    name: "key",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Authorization key, required parameter to access ChannelReport API.
          Contain only digits and letters. You may obtainyour authorization key
          from your personal manager.
        </p>
        <p>
          Example: <b>UQlXSEHTd2kWbVY3n0ETLx3v</b>
        </p>
      </>
    ),
  },
  {
    key: "2",
    name: "resType",
    req_opt: "Required",
    type: "String",
    description: (
      <p>
        This parameter is used to specify the response type. Available values
        are <b>table</b> (json) and <b>csv</b>.
      </p>
    ),
  },
  {
    key: "3",
    name: "columns",
    req_opt: "Required",
    type: "String",
    description: (
      <>
        <p>
          Value is a string that can take multiple values separated by a comma.
          In this field you should pass <b>labels</b> that you expect in
          response. Predefined labels that can be used are:
        </p>
        <ul>
          <li>- date,</li>
          <li>- hour,</li>
          <li>- channel,</li>
          <li>- campaign_id,</li>
          <li>- country,</li>
          <li>- os,</li>
          <li>- os_version,</li>
          <li>- browser,</li>
          <li>- browser_version,</li>
          <li>- source,</li>
          <li>- im,</li>
          <li>- leads,</li>
          <li>- rpl,</li>
          <li>- rpm,</li>
          <li>- cr,</li>
          <li>- on_hold_revenue,</li>
          <li>- confirmed_revenue,</li>
          <li>- declined_revenue,</li>
          <li>- revenue,</li>
          <li>- qs1,</li>
          <li>- qs2,</li>
          <li>- qs3,</li>
          <li>- qs4,</li>
          <li>- qs5,</li>
          <li>- qs6,</li>
          <li>- qs7,</li>
          <li>- qs8,</li>
          <li>- qs9,</li>
        </ul>
      </>
    ),
  },
  {
    key: "4",
    name: "date_from",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "5",
    name: "date_to",
    req_opt: "Required",
    type: "String",
    description: "yyyy-mm-dd",
  },
  {
    key: "6",
    name: "limit",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Limit shows you the number of rows which will be shown in the API response. By default limit is 30.",
  },
  {
    key: "7",
    name: "offset",
    req_opt: "Optional",
    type: "Integer",
    description:
      "Offset determines how many rows will be skipped before the data is displayed. Default is 0.",
  },
  {
    key: "8",
    name: "ordering",
    req_opt: "Optional",
    type: "String",
    description: (
      <div>
        <p>
          For ordering you can use one of the labels from columns list.For
          descending just use column name, e.g. revenue, and forascending use -
          symbol, e.g. -revenue.
        </p>
      </div>
    ),
  },
];

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Req/Opt",
    dataIndex: "req_opt",
    key: "req_opt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "60%",
  },
];

export const response = `{
  "total": [
    {
      "date": "",
      "cr": 0.9227448979260179,
      "im": 307669,
      "channel": "",
      "leads": 2839,
      "revenue": 1206.0519975684583,
      "hour": "",
      "campaign_id": "",
      "country": "",
      "os": "",
      "rpm": 3.9199659295166502,
      "rpl": 0.42481577934781906,
      "source": "",
      "os_version": "",
      "browser": "",
      "browser_version": ""
    }
  ],
  "table": [
    {
      "date": "2021-09-20",
      "cr": 0.0,
      "im": 2,
      "channel": "channel_1",
      "leads": 0,
      "revenue": 0.0,
      "hour": 0,
      "campaign_id": 34570,
      "country": "CA",
      "os": "Mac OSX",
      "rpm": 0.0,
      "rpl": 0.0,
      "source": "4512664",
      "os_version": "10.15.7",
      "browser": "Safari",
      "browser_version": "14.1.2"
    },
    {
      "date": "2021-09-20",
      "cr": 0.0,
      "im": 1,
      "channel": "channel_2",
      "leads": 0,
      "revenue": 0.0,
      "hour": 4,
      "campaign_id": 35438,
      "country": "US",
      "os": "Android",
      "rpm": 0.0,
      "rpl": 0.0,
      "source": "16513424",
      "os_version": "10",
      "browser": "Chrome",
      "browser_version": "93.0.4577.82"
    },
    {
      "date": "2021-09-20",
      "cr": 100.0,
      "im": 1,
      "channel": "channel_3",
      "leads": 1,
      "revenue": 0.800000011920929,
      "hour": 4,
      "campaign_id": 38286,
      "country": "US",
      "os": "Windows 10",
      "rpm": 800.000011920929,
      "rpl": 0.800000011920929,
      "source": "14860886",
      "os_version": "NT 10.0",
      "browser": "Edge",
      "browser_version": "91.0.864.70"
    },
    {
      "date": "2021-09-20",
      "cr": 0.0,
      "im": 1,
      "channel": "channel_4",
      "leads": 0,
      "revenue": 0.0,
      "hour": 4,
      "campaign_id": 38288,
      "country": "US",
      "os": "Windows 10",
      "rpm": 0.0,
      "rpl": 0.0,
      "source": "15888358",
      "os_version": "NT 10.0",
      "browser": "Edge",
      "browser_version": "93.0.961.52"
    },
    {
      "date": "2021-09-20",
      "cr": 0.0,
      "im": 4,
      "channel": "channel_5",
      "leads": 0,
      "revenue": 0.0,
      "hour": 4,
      "campaign_id": 38295,
      "country": "US",
      "os": "Windows 10",
      "rpm": 0.0,
      "rpl": 0.0,
      "source": "16140696",
      "os_version": "NT 10.0",
      "browser": "Chrome",
      "browser_version": "93.0.4577.82"
    }
  ],
  "rows": 116425
}`;
