import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { auth } from "../services/auth";
const UserContext = React.createContext({});
export default UserContext;

export const UserContextProvider = ({ currentModule, ...props }) => {
  const [userInfo, setUserInfo] = useState({});
  const [allAccManagers, setAllAccManagers] = useState({});

  const [loading, setLoading] = useState(false);
  const [accManagerLoading, setAccManagerLoading] = useState(false);

  const getUser = async () => {
    setLoading(true);
    try {
      const userObj = await auth.loadPermissions();
      setUserInfo(userObj[0]);
      const referenceDate = new Date("2022-05-20");
      const joinedDate = new Date(userObj[0].date_joined);
      if (joinedDate.getTime() >= referenceDate.getTime()) {
        window.stonlyTrack("track", "new-user");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const getAccManagerInfo = async () => {
    setAccManagerLoading(true);
    try {
      const managerInfo = await auth.getAccManagerInfo();
      setAllAccManagers(managerInfo);
    } catch (e) {
      console.log(e);
    } finally {
      setAccManagerLoading(false);
    }
  };

  const currentAccManager = useMemo(() => {
    if (!allAccManagers) return null;
    return allAccManagers[currentModule];
  }, [allAccManagers, currentModule]);

  useEffect(() => {
    getUser();
    getAccManagerInfo();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        loading,
        accManagerInfo: currentAccManager,
        accManagerLoading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
