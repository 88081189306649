import React from "react";
import styles from "./FormWidgetWrapper.module.css";
import classNames from "classnames";

export const FormWidgetWrapper = ({
  selectedWidgetStyle,
  setSelectedWidgetStyle,
  children,
  id,
}) => {
  return (
    <div
      className={styles.widgetWrapper}
      onClick={() => setSelectedWidgetStyle(`widget-style-${id + 1}`)}
    >
      <div>Widget {id + 1}</div>
      <div
        className={classNames(styles.widgetContentWrapper, {
          [styles.widgetSelected]:
            selectedWidgetStyle === `widget-style-${id + 1}`,
        })}
      >
        <div className={styles.widgetContentZoom}>{children}</div>
      </div>
    </div>
  );
};
