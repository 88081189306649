import styles from "../CoinisCampaigns.module.css";
import moment from "moment";
import { StatusTableCol } from "../components/StatusTableCol";

export const initialSelectedColumns = {
  channel: true,
  status: true,
  name: true,
  type: true,
  date_created: true,
  program: false,
};

export const allColumns = [
  {
    text: "Channel",
    value: "channel",
  },
  {
    text: "Title",
    value: "name",
  },
  {
    text: "Created",
    value: "date_created",
    render: date_created =>
      date_created && moment(date_created).format("DD MMM YYYY hh:mm:ss"),
  },
  {
    text: "Type",
    value: "type",
  },
  {
    text: "Status",
    value: "status",
    align: "center",
    className: styles.statusColumn,
    render: status => {
      return <StatusTableCol status={status} />;
    },
  },
  // {
  //   text: "Program",
  //   value: "program",
  //   render: (_, { program_name }, index) => {
  //     let programName = (program_name) ? program_name : '-';
  //     return <>{programName}</>;
  //   }
  // }
];
