import { Table, Row, Col } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { openSuccessNotification } from "../../utils/notifications";
import { columns, tableData, response } from "./utils/pageContent";
import styles from "./PublisherAPIIntegration.module.css";
import { useContext, useState } from "react";
import UserContext from "../../contexts/userContext";
import { TutorialVideo } from "../../components/TutorialVideo";
import moment from "moment";

export const PublisherAPIIntegration = () => {
  const { userInfo } = useContext(UserContext);
  const [videoPreview, setVideoPreview] = useState(false);
  const api_key = userInfo.api_key;

  return (
    <div className={styles.mainContainer}>
      <h4>API Integration Guide</h4>
      <Row>
        <Col flex="80px" className={styles.firstColumn}>
          <img alt="" src="/images/settings.png" />
        </Col>
        <Col flex="auto" className={styles.secondColumn}>
          <h2>API supports following query parameters:</h2>
          <div className={styles.tableContainer}>
            <Table
              scroll={{ x: "800px", scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <div className={styles.paginationPreview}>
          <p>Pagination & Ordering</p>
          <div>
            <div>
              <p>Offset & Limit</p>
              <div className={styles.videoPreviewContainer}>
                <img
                  onClick={() =>
                    setVideoPreview("/media/video/offset_and_limit.mp4")
                  }
                  alt="preview"
                  className={styles.videoPreview}
                  src="/images/offset_limit.png"
                />
              </div>
            </div>
            <div>
              <p>Ordering</p>
              <div className={styles.videoPreviewContainer}>
                <img
                  onClick={() =>
                    setVideoPreview("/media/video/ordering_final.mp4")
                  }
                  alt="preview"
                  className={styles.videoPreview}
                  src="/images/ordering.png"
                />
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col flex="80px" className={styles.firstColumn}>
          <img alt="" src="/images/key.png" />
        </Col>
        <Col flex="auto" className={styles.secondColumn}>
          <h2>1. Get Your Account API Key</h2>
          {api_key == null ? (
            <p>Contact your account manager to retrieve API key.</p>
          ) : (
            <p>
              Your API Key is:{" "}
              <span className={styles.apiKeyStyle}>{api_key}</span>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col flex="80px" className={styles.firstColumn}>
          <img alt="" src="/images/email.png" />
        </Col>
        <Col flex="auto" className={styles.secondColumn}>
          <h2>2. Send GET Request</h2>
          <p>Send GET request to the endpoint below.</p>
          <div className={styles.requestContainer}>
            <p>
              <span>
                https://publisher.coinis.com/api/publishers/reports/?
                <span className={styles.apiKeyBold}>limit=30&offset=0</span>
                &columns=date,zone_name,impressions,clicks,leads,cr,revenue,hour,country,os,browser,fill_rate,rpl,rpm,fill&date_from=
                {moment().subtract(1, "day").format("YYYY-MM-DD")}&date_to=
                {moment().format("YYYY-MM-DD")}&ordering=date&key=
                <span className={styles.apiKeyBold}>
                  {api_key == null ? "{YourAPIkey}" : api_key}
                </span>
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col flex="80px" className={styles.firstColumn}>
          <img alt="" src="/images/tick-inside-circle.png" />
        </Col>
        <Col flex="auto" className={styles.secondColumn}>
          <h2>3. Make Sure Everything Is OK</h2>
          <p>First of all, validate that you have received a valid response.</p>
          <p>Make sure that you received a response body as shown below.</p>
          <div className={styles.responseContainer}>
            <CopyToClipboard text={response}>
              <button
                className={styles.copyButton}
                onClick={() => openSuccessNotification({ message: "Copied" })}
              >
                <CopyOutlined />
                COPY
              </button>
            </CopyToClipboard>
            <div className={styles.responseContent}>
              <pre>{response}</pre>
            </div>
          </div>
        </Col>
      </Row>
      <TutorialVideo
        source={videoPreview}
        showVideo={videoPreview}
        setShowVideo={setVideoPreview}
      />
    </div>
  );
};
