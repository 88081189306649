import { useCallback, useContext, useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./Dashboard.module.css";
import { HeadInfo } from "./components/HeadInfo";
// import { Insights } from "./components/Insights";
import { StatisticsLineChart } from "./components/StatisticsLineChart";
import { TopChannelsTable } from "./components/TopChannelsTable";
import { EarningsMap } from "./components/EarningsMap";
import { searchDashboardService } from "../../services/searchDashboard";
import { Popover, Select, Spin } from "antd";
import { CustomLoader } from "./components/CustomLoader";
import { publisherReportsService } from "../../services/publisherReports";
import { auth } from "../../services/auth";
import { RequestFeed } from "./components/RequestFeed";
import { FeedForm } from "./components/FeedForm";
import UserContext from "../../contexts/userContext";

const { Option } = Select;

const mapColors = [
  "#A3A1FB",
  "#50D8BB",
  "#FFA167",
  "#FF7CC3",
  "#81C9FF",
  "#FF8474",
];

export const SearchDashboard = () => {
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [mapData, setMapData] = useState({});
  const [loading, setLoading] = useState(false);
  const [missingDates, setMissingDates] = useState([]);
  const [searchPermissions, setSearchPermissions] = useState(false);
  const [chartType, setChartType] = useState(false);

  const [requestFeed, setRequestFeed] = useState(false);
  const [feedStatus, setFeedStatus] = useState({});

  const [feedFormVisible, setFeedFormVisible] = useState(false);

  const [stonlyTrackSent, setStonlyTrackSent] = useState(false);
  const { userInfo } = useContext(UserContext);

  const parseTopCountries = useCallback((topCountries) => {
    if (topCountries) {
      const formatedCountries = topCountries.map((country, index) => {
        return { [country.country]: mapColors[index] };
      });
      const mapData = Object.assign({}, ...formatedCountries);
      setMapData(mapData);
      setTopCountries(topCountries);
    }
  }, []);

  useEffect(() => {
    const fetchStatsChart = async () => {
      setLoading(true);
      try {
        const [chart, topChannels, countriesData, data] = await Promise.all([
          searchDashboardService.getStatisticData(chartType, [
            "monetized_searches",
            "revenue",
          ]),
          searchDashboardService.getTopChannelsData(chartType),
          publisherReportsService.getCountries(),
          searchDashboardService.getEarningsMapData(chartType),
        ]);
        setRequestFeed(!chart);
        setAllCountries(countriesData.data);
        parseTopCountries(data ? data.table : []);
        if (chart.values) {
          const chartParse = {
            values: chart.values.map((item) => {
              item[2] = parseFloat(item[2].toFixed(2));
              return item;
            }),
          };
          setChartData(chartParse);
        } else {
          setChartData(chart);
        }
        if (topChannels && topChannels.missing_dates.length > 0) {
          setMissingDates(topChannels.missing_dates);
        } else {
          setMissingDates([]);
        }
        setTableData(topChannels.table);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (chartType) {
      fetchStatsChart();
    }
  }, [chartType, parseTopCountries]);

  useEffect(() => {
    const loadPermissions = async () => {
      const permissions = auth.getSearchPermissions();
      if (permissions.extension) {
        setChartType(1);
      } else {
        setChartType(2);
      }
      setSearchPermissions(permissions);
    };
    loadPermissions();
  }, []);

  useEffect(() => {
    if (userInfo.id && !stonlyTrackSent) {
      const referenceDate = new Date("2022-06-01");
      const joinedDate = new Date(userInfo.date_joined);
      if (joinedDate.getTime() >= referenceDate.getTime()) {
        window.stonlyTrack("track", "new-user");
        window.stonlyTrack("identify", String(userInfo.id), {
          "user-age-key": "new-user",
        });
      } else {
        window.stonlyTrack("identify", String(userInfo.id), {
          "user-age-key": "old-user",
        });
      }
      setStonlyTrackSent(true);
    }
  }, [userInfo, stonlyTrackSent]);

  const getStatus = async () => {
    try {
      const res = await searchDashboardService.getFeedStatus();
      if (res.success) {
        setFeedStatus(res.data);
      }
    } catch {
      // TODO: handle error?
    } finally {
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <h4 className={styles.titleDashboard}>
        <span>Dashboard</span>
        <div>
          {missingDates.length > 0 && (
            <Popover
              placement="bottomLeft"
              title={<p style={{ color: "#555", margin: 0 }}>Missing dates</p>}
              content={
                <p style={{ maxWidth: 300, color: "#999" }}>
                  {missingDates.join(", ")}
                </p>
              }
              trigger="hover"
            >
              <ExclamationCircleOutlined style={{ color: "#f7ad3d" }} />
            </Popover>
          )}
          {!requestFeed &&
          (feedStatus.extension === "allow" || feedStatus.n2s === "allow") ? (
            <Select
              disabled={loading}
              value={"Request Feed"}
              className={styles.dropdownReqFeed}
              onChange={(value) => {
                setFeedFormVisible(value);
              }}
            >
              {searchPermissions.extension && (
                <Option
                  disabled={!(feedStatus.extension === "allow")}
                  value="extension"
                >
                  Search Type-In
                </Option>
              )}
              {searchPermissions.n2s && (
                <Option disabled={!(feedStatus.n2s === "allow")} value="n2s">
                  Native to Search
                </Option>
              )}
            </Select>
          ) : null}
        </div>
      </h4>
      <p style={{ paddingTop: "0.5rem" }} className={styles.statsTitle}>
        Last 10 days
      </p>
      <HeadInfo
        missingDates={missingDates}
        missingDatesLoading={loading}
        searchPermissions={searchPermissions}
      />
      <div className={styles.statsDivider} />
      <Spin spinning={loading} indicator={<CustomLoader />}>
        <h4 className={styles.statsTitle}>Statistics</h4>
        {requestFeed ? (
          <>
            <p className={styles.statsInfo}>
              You don't have any statistics to display yet. Request a feed below
              to gain access to your stats.
            </p>
            <RequestFeed
              setFeedFormVisible={setFeedFormVisible}
              searchPermissions={searchPermissions}
              feedStatus={feedStatus}
            />
          </>
        ) : (
          <StatisticsLineChart
            loading={false}
            chartData={chartData}
            chartType={chartType}
            setChartType={setChartType}
            searchPermissions={searchPermissions}
          />
        )}
        <div className={styles.dashBottomContent}>
          <TopChannelsTable
            chartType={chartType}
            tableData={tableData}
            loading={loading}
          />
          {/* <Insights /> */}
          <EarningsMap
            mapData={mapData}
            loading={false}
            topCountries={topCountries}
            allCountries={allCountries}
          />
        </div>
      </Spin>
      <FeedForm
        feedFormVisible={feedFormVisible}
        setFeedFormVisible={setFeedFormVisible}
        onRequest={getStatus}
      />
    </div>
  );
};
