import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import styles from "../PushForm.module.css";
import { openWarningNotification } from "../../../../utils/notifications";

export const PushSmartlinkModal = ({
  smartlinkVisible,
  setSmartlinkVisible,
  smartlinkURL,
  setSmartlinkURL,
}) => {
  const [form] = Form.useForm();
  const handleAddLink = values => {
    if (values.postback) {
      setSmartlinkURL(values.postback);
      setSmartlinkVisible(false);
    } else {
      openWarningNotification({ message: "Postback URL is empty!" });
    }
  };
  useEffect(() => {
    form.setFieldsValue({ postback: smartlinkURL });
  }, [smartlinkVisible, form, smartlinkURL]);

  return (
    <Modal
      title="Smartlink URL"
      className={styles.modalContainer}
      visible={smartlinkVisible}
      style={{ top: 40 }}
      width={700}
      onCancel={() => setSmartlinkVisible(false)}
      footer={null}
    >
      <p className={styles.postTipTitle}>Smartlink URL:</p>
      <Form form={form} onFinish={handleAddLink} layout="inline">
        <Form.Item name="postback" style={{ width: "78%", margin: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item style={{ width: "22%", margin: 0 }}>
          <Button
            type="primary"
            className={styles.postbackBtn}
            htmlType="submit"
          >
            Add URL
          </Button>
        </Form.Item>
      </Form>
      <p className={styles.postTip}>
        {"Example: http://example.com/smartlink"}
      </p>
    </Modal>
  );
};
