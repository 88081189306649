import React from "react";
import styles from "./TableCellColorWrap.module.css";

export const TableCellColorWrap = ({
  channel,
  color_flag,
  country,
  type,
  value,
  children,
}) => {
  const getClassName = (channel, color_flag, type, value) => {
    if (!channel || !color_flag || parseFloat(value) === 0) {
      return null;
    }
    if (country && country !== "US") {
      switch (type) {
        case "ctr":
          if (value !== "" && (value < 6 || value > 10.5)) {
            return styles.redColorFlag;
          } else if (value !== "" && value < 7.5) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value < 10.5) {
            return styles.greenColorFlag;
          }
          break;
        case "ad_coverage":
          if (value !== "" && value < 77) {
            return styles.redColorFlag;
          } else if (value !== "" && value < 85) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value > 85) {
            return styles.greenColorFlag;
          }
          break;
        case "filtered_traffic_percentage":
          if (value !== "" && value > 15) {
            return styles.redColorFlag;
          } else if (value !== "" && value > 4) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value > 0) {
            return styles.greenColorFlag;
          }
          break;
        case "follow_on_searches":
          if (value !== "" && (value < 7 || (value > 26 && value <= 100))) {
            return styles.redColorFlag;
          } else if (value !== "" && value >= 7 && value < 15) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value >= 15 && value <= 26) {
            return styles.greenColorFlag;
          }
          break;
        case "initial_searches_difference":
          if (
            value !== "" &&
            ((value < -20 && value >= -100) || (value > 20 && value <= 100))
          ) {
            return styles.redColorFlag;
          } else if (
            value !== "" &&
            ((value < -5 && value >= -20) || (value > 5 && value <= 20))
          ) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value >= -5 && value <= 5) {
            return styles.greenColorFlag;
          }
          break;
        default:
          return null;
      }
    } else {
      switch (type) {
        case "rpc":
          if (value !== "" && (value < 0.4 || value > 1.05)) {
            return styles.redColorFlag;
          } else if (value !== "" && value < 0.6) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value < 1.05) {
            return styles.greenColorFlag;
          }
          break;
        case "rpm":
          if (value !== "" && (value < 35 || value > 100)) {
            return styles.redColorFlag;
          } else if (value !== "" && value < 45) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value < 100) {
            return styles.greenColorFlag;
          }
          break;
        case "ctr":
          if (value !== "" && (value < 7.5 || value > 10.5)) {
            return styles.redColorFlag;
          } else if (value !== "" && value < 9) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value < 10.5) {
            return styles.greenColorFlag;
          }
          break;
        case "ad_coverage":
          if (value !== "" && value < 77) {
            return styles.redColorFlag;
          } else if (value !== "" && value < 85) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value > 85) {
            return styles.greenColorFlag;
          }
          break;
        case "filtered_traffic_percentage":
          if (value !== "" && value > 15) {
            return styles.redColorFlag;
          } else if (value !== "" && value > 4) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value > 0) {
            return styles.greenColorFlag;
          }
          break;
        case "follow_on_searches":
          if (value !== "" && (value < 7 || (value > 26 && value <= 100))) {
            return styles.redColorFlag;
          } else if (value !== "" && value >= 7 && value < 15) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value >= 15 && value <= 26) {
            return styles.greenColorFlag;
          }
          break;
        case "initial_searches_difference":
          if (
            value !== "" &&
            ((value < -20 && value >= -100) || (value > 20 && value <= 100))
          ) {
            return styles.redColorFlag;
          } else if (
            value !== "" &&
            ((value < -5 && value >= -20) || (value > 5 && value <= 20))
          ) {
            return styles.yellowColorFlag;
          } else if (value !== "" && value >= -5 && value <= 5) {
            return styles.greenColorFlag;
          }
          break;
        default:
          return null;
      }
    }
  };
  return (
    <div className={getClassName(channel, color_flag, type, value)}>
      {children}
    </div>
  );
};
