import { Button, Radio, DatePicker, Row, Col, Select, Checkbox, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import styles from "./Filters.module.css";
import { ExportOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnsGroup } from "./ColumnsGroup";
import { reportsService } from "../../../services/reports";
import {
  generateChannels,
  generateCountries,
  generateOptions,
  // generatePrograms,
} from "../utils/filterOptions";
import moment from "moment";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { columnPreset } from "../utils/columnsList";
import { ColumnInfo } from "../../../components/ColumnInfo";
import { FilterPresetModal } from "../../../components/FilterPresetModal";
// import UserContext from "../../../contexts/userContext";
import UserFiltersContext from "../../../contexts/userFiltersContext";
import { showApiErrors } from "../../../utils/showApiErrors";
import { openSuccessNotification } from "../../../utils/notifications";
import { userFiltersService } from "../../../services/userFilters";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { confirm } = Modal;

export const Filters = ({
  onSubmit,
  allColumns,
  isOfferwall,
  isAffiliate,
  dateRange,
  setDateRange,
  columnsData,
  // chartStats,
  handleExport,
  campaign_id,
  channel,
  isArchiveIncluded,
  setIsArchiveIncluded
}) => {
  // const { userInfo } = useContext(UserContext);
  const [checkedColumns, setCheckedColumns] = useState(columnPreset);
  const [countries, setCountries] = useState([]);
  const [os, setOS] = useState([]);
  const [browsers, setBrowsers] = useState([]);
  const [autocompleteChannelLoading, setAutocompleteChannelLodaing] =
    useState(false);
  // const [autocompleteProgramLoading, setAutocompleteProgramLodaing] =
  //   useState(false);
  //title debounce search
  const [searchChannelTerm, setSearchChannelTerm] = useState("");
  // const [searchProgramTerm, setSearchProgramTerm] = useState("");
  const debounceChannel = useDebounce(searchChannelTerm, 300);
  // const debounceProgram = useDebounce(searchProgramTerm, 300);
  const [channels, setChannels] = useState([]);
  // const [programs, setPrograms] = useState([]);
  const [filters, setFilters] = useState({});

  const [columnInfoVisible, setColumnInfoVisible] = useState(false);

  const {
    userFilters,
    setUserFilters,
    userSavedFilter,
    setUserSavedFilter,
    userSavedFilterLoading,
    module
  } = useContext(UserFiltersContext);

  // const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const getChannels = async searchChannelTerm => {
      setAutocompleteChannelLodaing(true);
      try {
        const res = await reportsService.getChannelsAutocomplete(
          searchChannelTerm
        );
        // when linked from channels
        let newChannels = [...res.data];
        if (
          campaign_id &&
          newChannels.every(item => item.campaign_id !== campaign_id)
        ) {
          newChannels.push({ campaign_id, channel });
        }
        setChannels(newChannels);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompleteChannelLodaing(false);
      }
    };
    getChannels(debounceChannel);
  }, [debounceChannel, campaign_id, channel]);

  // useEffect(() => {
  //   const getPrograms = async searchProgramTerm => {
  //     setAutocompleteProgramLodaing(true);
  //     try {
  //       const res = await reportsService.getProgramsAutocomplete(
  //         searchProgramTerm
  //       );
  //       // when linked from channels
  //       let newPrograms = [...res.data];
  //       setPrograms(newPrograms);
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setAutocompleteProgramLodaing(false);
  //     }
  //   };
  //   getPrograms(debounceProgram);
  // }, [debounceProgram]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await reportsService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getOS = async () => {
      try {
        const res = await reportsService.getOperatingSystems();
        if (res.data) {
          setOS(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getBrowsers = async () => {
      try {
        const res = await reportsService.getBrowsers();
        if (res.data) {
          setBrowsers(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    getCountries();
    getOS();
    getBrowsers();
  }, []);

  const handleUpdate = () => {
    onSubmit({
      filters,
      dateRange,
      checkedColumns: checkedColumns,
    }, columnsData);
  };

  // fix warnings
  useEffect(() => {
    if (campaign_id) {
      onSubmit({
        filters: { ...filters, campaign_id: [campaign_id] },
        dateRange,
        checkedColumns: checkedColumns,
      }, columnsData);
      setFilters(prevState => {
        return { ...prevState, campaign_id: [campaign_id] };
      });
    } else {
      handleUpdate();
    }
    // eslint-disable-next-line
  }, [columnsData]);
  //fix warnings ^^

  // useEffect(() => {
  //   if (!userInfo.ow_tmp) {
  //     checkedColumns.program = false;
  //   }
  // });

  const setSavedFilter = (name) => {
    let selectedSavedFilter = userFilters.find((el) => el.name === name);
    setFilters(JSON.parse(selectedSavedFilter.filter));
  };

  const handleDeleteFilter = async (id, name) => {
    const newFilters = userFilters.filter((filter) => filter.name.toLowerCase() !== name);
    try {
      await userFiltersService.deleteFilter(id);
      openSuccessNotification({ message: "Filter preset successfully deleted!" })
      if (userSavedFilter === name) {
        setUserSavedFilter(null);
        setFilters({});
      }
      setUserFilters(newFilters);
    } catch (e) {
      showApiErrors(e);
    }
  };

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: `Are you sure that you want to delete ${name} filter?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteFilter(id, name);
      }
    });
  };

  return (
    <>
      {/* <div className={styles.topActionBtns}>
        <Button
          className={styles.topActionButton}
          onClick={() => setDisplayAdvanced(!displayAdvanced)}
        >
          <FilterFilled /> Filters
        </Button>
      </div> */}
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="today"
          size="large"
        >
          <Radio.Button
            value="today"
            onClick={() => setDateRange({ from: moment(), to: moment() })}
          >
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "days"),
                to: moment().subtract(1, "days"),
              })
            }
          >
            Yesterday
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({ from: moment().subtract(6, "days"), to: moment() })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div className={styles.filtersContainerBottom}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col md={24} lg={12} xxl={8}>
              <span className={styles.spanMarginBottom}>Date range</span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={false}
                // className={styles.rangeDatePicker}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Filter presets</span>
              <Select
                className={styles.filtersSelect}
                allowClear
                value={userSavedFilter}
                onChange={(value) => {
                  setUserSavedFilter(value);
                  if (value) {
                    setSavedFilter(value);
                  }
                }}
                style={{ width: "100%" }}
                loading={userSavedFilterLoading}
                placeholder="Select filter preset"
              >
                {userFilters.map(({ id, name }) =>
                  <Option className={styles.optionFilter} key={id} value={name}>
                    {name}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirm(id, name);
                      }}
                      className={styles.deleteBtn}
                    >
                      <DeleteOutlined style={{
                        float: 'right',
                        color: '#ff5600',
                        cursor: 'pointer',
                        marginTop: '3px'
                      }} />
                    </span>
                  </Option>
                )}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className={styles.limitTooltip}>
              <FilterPresetModal
                filters={filters}
                userFilters={userFilters}
                setUserSavedFilter={setUserSavedFilter}
                currentModule={module}
                onUpdate={setUserFilters}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: "1%" }} className={styles.advancedFilters}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Country</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, countries: value })}
                style={{ width: "100%" }}
                placeholder="Select country"
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                value={filters.countries}
                allowClear
              >
                {generateCountries(countries || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Operating System</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, os: value })}
                style={{ width: "100%" }}
                placeholder="Select OS"
                value={filters.os}
                allowClear
              >
                {generateOptions(os || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Browser</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, browsers: value })}
                style={{ width: "100%" }}
                placeholder="Select browsers"
                value={filters.browsers}
                allowClear
              >
                {generateOptions(browsers || [])}
              </Select>
            </Col>
            {isOfferwall && <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Channel</span>
              <Select
                mode="multiple"
                onChange={value =>
                  setFilters({ ...filters, campaign_id: value })
                }
                loading={autocompleteChannelLoading}
                filterOption={false}
                style={{ width: "100%" }}
                placeholder="Select channels"
                onSearch={setSearchChannelTerm}
                value={filters.campaign_id}
                allowClear
              >
                {generateChannels(channels || [])}
              </Select>
            </Col>}
            {/* {userInfo.ow_tmp && (
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Program</span>
                <Select
                  mode="multiple"
                  onChange={value => setFilters({ ...filters, program: value })}
                  loading={autocompleteProgramLoading}
                  filterOption={false}
                  style={{ width: "100%" }}
                  placeholder="Select programs"
                  onSearch={setSearchProgramTerm}
                  value={filters.program}
                  allowClear
                >
                  {generatePrograms(programs || [])}
                </Select>
              </Col>
            )} */}
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className={styles.isArchivedCheckbox}>
              <Checkbox checked={isArchiveIncluded}
                onChange={e =>
                  setIsArchiveIncluded(e.target.checked)
                }>
                Include archived
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <Button
                style={{ marginTop: "22px", height: "32px", width: "100%" }}
                className={styles.applyFiltersBtn}
                type="primary"
                size="medium"
                onClick={handleUpdate}
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.bottomActions}>
        <div className={styles.rightActionBtns}>
          <div>
            <ColumnsGroup
              columnsData={allColumns}
              onChange={value => {
                setCheckedColumns(value);
              }}
              showUpdateButton={true}
              onUpdate={handleUpdate}
              checkedColumns={checkedColumns}
            />
            <Button
              className={styles.columnsBtn}
              onClick={() => setColumnInfoVisible(true)}
            >
              Column Info
            </Button>
          </div>
          <Button
            onClick={handleExport}
            className={styles.columnsBtn}
            icon={<ExportOutlined />}
          >
            Export to CSV
          </Button>
          {/* <Button
            icon={<ReloadOutlined />}
            className={styles.reloadBtn}
            onClick={handleUpdate}
          >
            Reload
          </Button> */}
        </div>
      </div>
      <ColumnInfo
        visible={columnInfoVisible}
        setVisible={setColumnInfoVisible}
        allColumns={allColumns}
      />
    </>
  );
};
