import { Button, Modal } from "antd";
import React from "react";
import styles from "./MissingDatesModal.module.css";
import moment from "moment";

export const MissingDatesModal = ({ visible, setVisible, missingDates }) => {
  const parsedDates = missingDates
    ? missingDates.map(date => ({
        month: moment(date).format("MMMM"),
        date,
      }))
    : [];

  const groupedDates = parsedDates.reduce((sum, item) => {
    const prevMonth = sum[item.month] ? sum[item.month] : [];
    sum[item.month] = [...prevMonth, item.date];
    return sum;
  }, {});

  const renderDates = Object.entries(groupedDates);
  return (
    <Modal
      title="Missing Dates"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={
        <Button
          onClick={() => setVisible(false)}
          className={styles.backBtn}
          type="primary"
        >
          Back
        </Button>
      }
    >
      {renderDates.map(item => (
        <div key={item[0]} className={styles.missingDateContent}>
          <p className={styles.missingDateTitle}>{item[0]}</p>
          {item[1].join(", ")}
        </div>
      ))}
    </Modal>
  );
};
