import { Select } from "antd";
const { Option } = Select;

export const generateCountries = arr => {
  return arr.map(country => (
    <Option key={country.code2} value={country.code2}>
      {country.name}
    </Option>
  ));
};

export const generateOptions = arr => {
  return arr.map(option => (
    <Option key={option.name} value={option.name}>
      {option.name}
    </Option>
  ));
};

export const generateChannels = arr => {
  return arr.map(option => (
    <Option key={option} value={option}>
      {option}
    </Option>
  ));
};
