import React from "react";
import { Button, Select, Row, Col, DatePicker } from "antd";
import styles from "../CoinisCampaigns.module.css";

const { Option } = Select;

export const CampaignFilters = ({ filters, setFilters, submit }) => {
  return (
    <>
      <div className={styles.filtersContainer}>
        <div style={{ marginTop: "1%", paddingBottom: "0.5rem" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Status</span>
              <Select
                size="medium"
                className={styles.selectStatuses}
                placeholder="All Statuses"
                allowClear
                onChange={value => {
                  setFilters({ ...filters, status: value });
                }}
              >
                <Option value="active">Active</Option>
                <Option value="paused">Paused</Option>
                <Option value="pending">Pending</Option>
                <Option value="rejected">Rejected</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Type</span>
              <Select
                size="medium"
                className={styles.selectFormats}
                placeholder="All Formats"
                allowClear
                onChange={value => {
                  setFilters({ ...filters, format: value });
                }}
              >
                <Option value="push">Push</Option>
                {/* <Option value="smartlink">Smartlink</Option> */}
                <Option value="contextual">Contextual</Option>
                <Option value="inpage">In-Page</Option>
              </Select>
            </Col>{" "}
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Date Created</span>
              <DatePicker
                style={{ width: "100%" }}
                onChange={value => {
                  setFilters({ ...filters, dateCreated: value });
                }}
              />
            </Col>{" "}
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <Button
                className={styles.applyFiltersBtn}
                style={{ marginTop: 22 }}
                onClick={() => submit(filters)}
                type="primary"
                size="medium"
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      {/* <div className={styles.extActionGroup}>
        <Button
          className={styles.reloadBtn}
          onClick={() => {
            submit(filters);
          }}
          icon={<ReloadOutlined />}
        >
          Reload
        </Button>
      </div> */}
    </>
  );
};
