import { Button, Input, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styles from "../BillingPaymentMethods.module.css";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";
import { billingPaymentMethodsService } from "../../../services/billingPaymentMethods";

export const PayPalForm = ({
  methodsCount,
  setPaypalForm,
  getPaymentMethods,
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onPaypalAdd = async () => {
    if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      openWarningNotification({ message: "Email is not valid!" });
      return;
    }
    setLoading(true);
    try {
      const res = await billingPaymentMethodsService.submitPaymentMethod({
        method_type: "paypal",
        is_default: !methodsCount,
        email,
      });
      if (res.success) {
        openSuccessNotification({
          message: "Successfully created payment method!",
        });
        setEmail("");
        setPaypalForm(false);
        getPaymentMethods();
      } else {
        openErrorNotification({
          message: res.message,
        });
      }
    } catch (e) {
      openErrorNotification({
        message:
          "Error while trying to add payment method. Please, contact support.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading} wrapperClassName={styles.paymentMethodContainer}>
      <div className={styles.paymentMethodContent}>
        <div>
          <span className={styles.statusPending}>PENDING</span>
        </div>
        <div className={styles.methodContentBoxForm}>
          <span className={styles.methodHighlight}>Paypal</span>
          <div style={{ marginTop: "0.4rem" }}>
            <Input
              placeholder="Email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              style={{ marginLeft: "1rem" }}
              type="primary"
              onClick={onPaypalAdd}
            >
              Confirm e-mail address
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.paymentMethodActions}>
        <CloseOutlined
          className={styles.closeBtn}
          onClick={() => setPaypalForm(false)}
        />
      </div>
    </Spin>
  );
};
