import React, { useContext } from "react";
import Icon from "@ant-design/icons";
import { Alert } from "antd";
import Marquee from "react-fast-marquee";
import ReactMarkdown from "react-markdown";
import NotificationContext from "../../../contexts/notificationContext";
import styles from "./Broadcast.module.css";
import { BASE_URL } from "../../../config";

export const BroadcastNotification = () => {
  const { moduleChanged, broadcastNotifications } =
    useContext(NotificationContext);

  const LinkRender = props => {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  };

  return !moduleChanged &&
    broadcastNotifications &&
    broadcastNotifications.length > 0 ? (
    <div style={{ width: "100%" }}>
      <Alert
        message={
          broadcastNotifications.length > 1 ? (
            <Marquee pauseOnHover gradient={false}>
              {broadcastNotifications &&
                broadcastNotifications.map((broadcastNotification, key) => {
                  return (
                    <div key={key} style={{ marginLeft: "1.5rem" }}>
                      {broadcastNotification.icon !== "" ? (
                        <Icon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "0.4rem",
                          }}
                          component={() => (
                            <img
                              src={BASE_URL + broadcastNotification.icon}
                              alt=""
                            />
                          )}
                        />
                      ) : (
                        ""
                      )}
                      <ReactMarkdown
                        className={styles.broadcastNotification}
                        components={{ a: LinkRender }}
                      >
                        {broadcastNotification.description}
                      </ReactMarkdown>
                      {key < broadcastNotifications.length - 1 ? (
                        <span style={{ marginLeft: "1.5rem" }}>|</span>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </Marquee>
          ) : (
            <div
              style={{
                marginLeft: "1.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {broadcastNotifications[0].icon !== "" ? (
                <img
                  style={{ verticalAlign: "middle", marginRight: "0.4rem" }}
                  src={BASE_URL + broadcastNotifications[0].icon}
                  alt=""
                />
              ) : (
                ""
              )}
              <ReactMarkdown
                className={styles.broadcastNotification}
                components={{ a: LinkRender }}
              >
                {broadcastNotifications[0].description}
              </ReactMarkdown>
            </div>
          )
        }
      />
    </div>
  ) : null;
};
