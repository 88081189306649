import React from "react";
import styles from "./Examples.module.css";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

export const ExampleTagContextual = () => {
  return (
    <>
      <div className={styles.examplesContainer}>
        <div>
          <div className={styles.alignCenter}>
            <CheckCircleFilled style={{ fontSize: "30px", color: "#58A54F" }} />
            <p className={styles.correct}>
              Correct examples of implementation:
            </p>
          </div>
          <img src="/images/CorrectContextual.png" alt=""></img>
        </div>
        <div>
          <div className={styles.alignCenter}>
            <CloseCircleFilled style={{ fontSize: "30px", color: "#D85C5C" }} />
            <p className={styles.incorrect}>
              Incorrect examples of implementation:
            </p>
          </div>
          <img src="/images/IncorrectContextual.png" alt=""></img>
        </div>
      </div>
      <div className={styles.examplesContainer}>
        <div>
          <div className={styles.alignCenter}>
            <p className={styles.contextualExampleText}>
              How to implement HTML tag:
            </p>
          </div>
          <img src="/images/ContextualAdTag.png" alt=""></img>
        </div>
        <div>
          <div className={styles.alignCenter}>
            <p className={styles.contextualExampleText}>
              Preview of correctly implemented HTML tag:
            </p>
          </div>
          <img src="/images/ContextualAdPreview.png" alt=""></img>
        </div>
      </div>
    </>
  );
};
