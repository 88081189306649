import React from "react";
import styles from "../BillingPaymentMethods.module.css";

import { Button, Drawer, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

export const TipaltiForm = ({
  tipaltiLink,
  tipaltiVisible,
  setTipaltiVisible,
  loading,
  setTipaltiReload,
}) => {
  return (
    <Drawer
      title={
        <div>
          <span>Tipalti Form</span>{" "}
          <Button
            className={styles.reloadFormBtn}
            onClick={() => setTipaltiReload((prevValue) => !prevValue)}
            icon={<ReloadOutlined />}
          >
            Reload Form
          </Button>
        </div>
      }
      placement="right"
      width={window.innerWidth < 700 ? "100%" : 700}
      className={styles.drawerPaymentMethod}
      onClose={() => {
        setTipaltiVisible(false);
      }}
      visible={!!tipaltiVisible}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin spinning={loading} />
        </div>
      ) : (
        tipaltiLink && (
          <iframe
            title="Tipalti Form"
            src={tipaltiLink}
            width="100%"
            frameBorder="0"
            height="100%"
          ></iframe>
        )
      )}
    </Drawer>
  );
};
