import React from "react";
import { parsePositionStyles } from "../../utils";
import styles from "./AdThemes.module.css";

export const Chatty = ({
  adQuantity,
  backgroundColor,
  contentColor,
  contentRadius,
  adPosition,
}) => {
  return (
    <div
      className={styles.mainWrapper} style={{
        ...parsePositionStyles(adPosition)
      }}>
      <div
        className={styles.chatty}
      >
        <div className={styles.chattyBody}>
          <div
            className={styles.chattyContent}
            style={{
              backgroundColor,
              color: contentColor,
              borderRadius: contentRadius,
            }}
          >
            <p>Visit Melbourne this summer</p>
            <p>Here's why you should consider visiting...</p>
          </div>
          <div className={styles.chattyCube} style={{ backgroundColor }}></div>
        </div>
        <img src="/images/coinis-icon.jpg" alt="coinis" />
        <span className={styles.closeBtnChatty}>&#10005;</span>
      </div>
      {adQuantity === 2 ?
        <div
          className={styles.chatty}
        >
          <div className={styles.chattyBody}>
            <div
              className={styles.chattyContent}
              style={{
                backgroundColor,
                color: contentColor,
                borderRadius: contentRadius,
              }}
            >
              <p>Visit Melbourne this summer</p>
              <p>Here's why you should consider visiting...</p>
            </div>
            <div className={styles.chattyCube} style={{ backgroundColor }}></div>
          </div>
          <img src="/images/coinis-icon.jpg" alt="coinis" />
          <span className={styles.closeBtnChatty}>&#10005;</span>
        </div> : ""}
    </div>
  );
};
