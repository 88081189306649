import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Table } from "antd";
import styles from "../PushForm.module.css";
import { openWarningNotification } from "../../../../utils/notifications";
import { MACROS } from "../../../../utils/offerwallMacros";

export const PostbackModal = ({
  postbackVisible,
  setPostbackVisible,
  postbackURL,
  setPostbackURL,
}) => {
  const [form] = Form.useForm();
  const [campaignObject, setCampaignObject] = useState({});
  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);

  const columns = [
    {
      title: "Macro name",
      dataIndex: "macro",
      key: "macro",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const tableData = [
    {
      macro: "{CONVERSION_ID}",
      description: "Carries information about unique transfer ID (Click ID).",
    },
    {
      macro: "{SOURCE}",
      description: "Identifies traffic source.",
    },
    {
      macro: "{COUNTRY_CODE}",
      description: "Indicates in which country the conversation took place.",
    },
    {
      macro: "{LEAD_PRICE}",
      description: "Denotes conversation payout. Price is expressed in USD.",
    },
  ];

  const handleSubmitLink = values => {
    if (values.postback_url) {
      setPostbackURL(values.postback_url);
      setPostbackVisible(false);
    } else {
      openWarningNotification({ message: "Postback URL is empty!" });
    }
  };

  const handleMacrosClick = macroContent => {
    setCampaignObject(prevObj => {
      const postback = whereTheCursorWas
        ? prevObj.postback.substring(0, whereTheCursorWas) +
          macroContent +
          prevObj.postback.substring(whereTheCursorWas, prevObj.postback.length)
        : (prevObj.postback ? prevObj.postback : "") + macroContent;
      if (whereTheCursorWas !== undefined) {
        setWhereTheCursorWas(prevState => prevState + macroContent.length);
      }
      return { ...prevObj, postback };
    });
  };

  useEffect(() => {
    setCampaignObject(prevState => ({
      ...prevState,
      postback: postbackURL,
    }));
    form.setFieldsValue({ postback: postbackURL });
  }, [postbackVisible, form, postbackURL, setCampaignObject]);

  useEffect(() => {
    form.setFieldsValue({
      postback_url: campaignObject.postback,
    });
  }, [campaignObject, form]);

  return (
    <Modal
      title="Postback S2S"
      className={styles.modalContainer}
      visible={postbackVisible}
      style={{ top: 40 }}
      width={700}
      onCancel={() => setPostbackVisible(false)}
      footer={null}
    >
      <p className={styles.postTipTitlePostback}>Postback URL:</p>
      <Form form={form} onFinish={handleSubmitLink} layout="inline">
        <Form.Item name="postback_url" style={{ width: "100%", margin: 0 }}>
          <Input
            value={campaignObject.postback}
            onChange={e =>
              setCampaignObject(prevObj => {
                return { ...prevObj, postback: e.target.value };
              })
            }
            placeholder="https://example-postback.com?click_id={CONVERSION_ID}"
          />
        </Form.Item>
        <div className={styles.macrosContainer}>
          {MACROS.map(macro => (
            <div
              key={macro.value}
              className={styles.macroItem}
              onClick={() => handleMacrosClick(macro.value)}
            >
              {macro.value}
            </div>
          ))}
        </div>
        <div>
          <p style={{ marginTop: "2px", marginBottom: "20px" }}>
          Click on the macro to add it to your URL above. For your CONVERSION_ID and SOURCE parameters to be properly forwarded, you must also add <b>cid=YourClickID&sid=YourSubID</b> to your URL. For example: <span style={{ backgroundColor: "#1990FF1A", color: "#1990FF", padding: "2px" }}>https://yourwebsite.com?cid=YourClickID&sid=YourSubID</span>
          </p>
        </div>
        <div className={styles.tablePostbackBox}>
          <p className={styles.postTipTitlePostback}>
            Available URL parameters:
          </p>
          <p className={styles.postTipPostback}>
            By inserting a Postback URL we can send a server to server pixel on
            your conversions. With this pixel we are sending some additional
            information using parameters/macros.
          </p>
          <div className={styles.tablePostback}>
            <Table
              rowKey="macro"
              dataSource={tableData}
              columns={columns}
              pagination={false}
              bordered
            />
          </div>
          <p style={{ marginTop: "0.625rem" }}>
            <i>
              Make sure to save your zone settings in order to apply postback
              URL.
            </i>
          </p>
        </div>
        <Form.Item style={{ width: "100%", margin: 0 }}>
          <Button
            type="primary"
            className={styles.postbackModalBtn}
            htmlType="submit"
          >
            Add URL
          </Button>
          <Button
            onClick={() => setPostbackVisible(false)}
            className={styles.postbackModalBtn}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
