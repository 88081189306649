import React from "react";
import { Spin, Row, Col, Pagination } from "antd";
import { Card } from "./Card";

import styles from "../AdSamples.module.css";

export const Grid = ({
    currentPage,
    pageSize,
    onPaginationChange,
    samples,
    totalRows,
    isFetching
}) => {
    return (
        <>
            <div className={styles.samplesWrapper}>
                <Spin spinning={isFetching}>
                    <Row gutter={[24, 24]}>
                        {samples &&
                            samples.map((item, index) => {
                                return (
                                    <Col xs={24} sm={24} md={12} lg={6} xxl={6} key={item.id}>
                                        <Card
                                            key={index}
                                            details={item}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </Spin>
            </div>
            <Pagination
                className="ant-table-pagination ant-table-pagination-right"
                total={totalRows}
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                pageSizeOptions={["12", "16", "20"]}
                onChange={onPaginationChange}
                onShowSizeChange={onPaginationChange}
            />
        </>
    );
};
