import React, { useEffect, useState } from "react";
import styles from "./Questionnaire.module.css";

import { Button, Form, Input, Modal, Upload, Image, Tooltip } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { openWarningNotification } from "../../../utils/notifications";

const { TextArea } = Input;
const { Dragger } = Upload;

export const Questionnaire = ({
  isOpen,
  setIsOpen,
  handleConfirm,
  loading,
  setLoading,
  isNewZone,
}) => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [form] = Form.useForm();

  const handleSendRequest = (values) => {
    if (!values.url && !file) {
      openWarningNotification({ message: "Place URL or upload screenshot." });
      return;
    }

    setLoading(true);
    handleConfirm({ ...values, file });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getSrcFromFile = async (file) => {
    const base64 = await getBase64(file);
    setImagePreview(base64);
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setFile(null);
      setImagePreview(null);
      setIsOpen(false);
      setLoading(false);
    }
  }, [isOpen, form, setIsOpen, setLoading]);

  return (
    <Modal
      title={false}
      open={isOpen}
      onCancel={() => {
        if (!loading) setIsOpen(false);
      }}
      footer={false}
    >
      <h4 className={styles.modalTitle}>
        {isNewZone ? "New Zone" : "Request Approval"}
      </h4>
      {isNewZone && (
        <p>
          It looks like you’re adding new CPS zone. Before we begin, please
          answer a few questions which will help our compliance team review your
          request faster.
        </p>
      )}
      <Form form={form} onFinish={handleSendRequest} layout="vertical">
        <p>
          <span>*</span> Tell us more about your traffic volume, excluding
          Google Analytics.
        </p>
        <Form.Item
          name="traffic_volume"
          rules={[
            {
              required: true,
              message: "This is required field.",
            },
          ]}
        >
          <TextArea rows={4} placeholder="Enter answer here" />
        </Form.Item>
        <div>
          <p>
            <span>*</span> Help us verify your traffic source below.
          </p>
          <div className={styles.analyticsDiv}>
            <div>
              <p className={styles.labelDescription}>
                Paste URL with access to the Google Analytics of your website
                below.
              </p>
              <Form.Item
                name="url"
                rules={[
                  {
                    type: "url",
                    message: "This field must be a valid url.",
                  },
                ]}
              >
                <Input placeholder="Enter URL here"></Input>
              </Form.Item>
            </div>
            <p>OR</p>
            <div>
              <p className={styles.labelDescription}>
                Upload a screenshot of your website analytics within the last
                two months.
              </p>
              {imagePreview ? (
                <div>
                  <Image
                    className={styles.imagePreview}
                    src={imagePreview}
                    alt="preview_image"
                  />
                  <Tooltip title="Remove image">
                    <Button
                      style={{ marginLeft: "5px" }}
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setFile(null);
                        setImagePreview(null);
                      }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <Dragger
                  name="file"
                  multiple={false}
                  beforeUpload={(image) => {
                    setFile(image);
                    getSrcFromFile(image);
                    return false;
                  }}
                  fileList={[]}
                >
                  <div className={styles.buttonUpload}>
                    <UploadOutlined style={{ fontSize: "1.4rem" }} />{" "}
                    <span>Upload or drag image</span>
                  </div>
                </Dragger>
              )}
            </div>
          </div>
        </div>
        <div className={styles.actionsBtns}>
          <Button disabled={loading} onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Send request
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
