import React from "react";
import styles from "./ExtensionPolicy.module.css";
import { Row, Col } from "antd";
import { Badge } from "antd";
import { Blogs } from "./components/Blogs";

export const ExtensionPolicy = () => {
  const bingGeos = [
    [
      "Albania",
      "Andorra",
      "Argentina",
      "Australia",
      "Austria",
      "Belgium",
      "Bosnia",
      "Brazil",
      "Bulgaria",
      "Canada",
      "Chile",
      "Colombia",
      "Croatia",
    ],
    [
      "Cyprus",
      "Czech Republic/Czechia",
      "Denmark",
      "Finland",
      "France",
      "Germany",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Ireland",
    ],
    [
      "Italy",
      "Japan",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxemburg",
      "Malaysia",
      "Malta",
      "Mexico",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "New Zealand",
    ],
    [
      "North Macedonia",
      "Norway",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Romania",
      "San Marino",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "South Africa",
      "Singapore",
    ],
    [
      "Spain",
      "Sweden",
      "Switzerland",
      "Taiwan",
      "Thailand",
      "Turkey",
      "United Kingdom",
      "United States",
      "Vatican City",
      "Venezuela",
      "Vietnam",
    ],
  ];

  const yahooGeos = [
    [
      "Argentina",
      "Australia",
      "Austria",
      "Brazil",
      "Canada - French",
      "Chile",
      "Columbia",
    ],
    [
      "Denmark",
      "Finland",
      "France",
      "Germany",
      "Hong Kong",
      "India",
      "Indonesia",
    ],
    [
      "Ireland",
      "Italy",
      "Malaysia",
      "Mexico",
      "Netherlands",
      "New Zealand",
      "Norway",
    ],
    [
      "Peru",
      "Philippines",
      "Singapore",
      "Spain",
      "Sweden",
      "Switzerland",
      "Taiwan",
    ],
    ["Thailand", "United Kingdom", "United States", "Venezuela", "Vietnam"],
  ];

  const productExamples = [
    {
      title: "Edge Add-On",
      image: "/images/EcosiaEdgeAddOn.png",
      link: "https://microsoftedge.microsoft.com/addons/detail/ecosia-the-search-engin/fhfidmlnclkepgapcephbaciajegheco",
      arrow: "/images/arrow-up-right.png",
    },
    {
      title: "Chrome Extension",
      image: "/images/EcosiaChromeExtension.png",
      link: "https://chrome.google.com/webstore/detail/ecosia-the-search-engine/eedlgdlajadkbbjoobobefphmfkcchfk?hl=en",
      arrow: "/images/arrow-up-right.png",
    },
    {
      title: "",
      image: "",
      link: "",
      arrow: "",
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <h4 className={styles.titleDashboard}>
        <span>Extension / Add-On Policy</span>
      </h4>
      <p className={styles.statsTitle}>
        Search Type-In Monetization Guide Diagram
      </p>
      <p className={styles.statsDescription}>
        Search feed solution allows our partners to implement Coinis Publisher’s
        direct Bing and/or Yahoo’s feeds in their products. Here’s a diagram
        which illustrates how this happens:
      </p>
      <img
        style={{ width: "100%", height: "auto" }}
        src="/images/extension-policy-explanation-image.png"
        alt=""
      />
      <p style={{ marginTop: "30px" }} className={styles.statsTitle}>
        Bing’s monetized GEOs — <span>BHS</span>
      </p>
      <p className={styles.statsDescription}>
        Revenue can be collected only if the user’s geographic location is one
        of Bing’s monetized markets. For optimal results and user experience, we
        will return Bing’s search results for any search request.
        <br />
        <br />
        Here’s a list of Bing’s monetized geos:
      </p>
      <Row className={styles.countriesList} justify="space-between">
        {bingGeos.map((bingGeosColumn) => {
          return (
            <Col span={4}>
              {bingGeosColumn.map((bingGeo, index) => {
                return (
                  <div key={index}>
                    <Badge
                      className={styles.countryName}
                      color="#F9AE3B"
                      text={bingGeo}
                    />
                  </div>
                );
              })}
            </Col>
          );
        })}
      </Row>
      <p style={{ marginTop: "30px" }} className={styles.statsTitle}>
        Yahoo’s monetized GEOs — <span>YHS</span>
      </p>
      <p className={styles.statsDescription}>
        Revenue can be collected only if the user’s geographic location is one
        of Bing’s monetized markets. For optimal results and user experience, we
        will return Yahoo’s search results for any search request.
        <br />
        <br />
        Here’s a list of Yahoo monetized geos:
      </p>
      <Row className={styles.countriesList} justify="space-between">
        {yahooGeos.map((yahooGeosColumn) => {
          return (
            <Col span={4}>
              {yahooGeosColumn.map((yahooGeo, index) => {
                return (
                  <div key={index}>
                    <Badge
                      className={styles.countryName}
                      color="#F9AE3B"
                      text={yahooGeo}
                    />
                  </div>
                );
              })}
            </Col>
          );
        })}
      </Row>
      <p style={{ marginTop: "30px" }} className={styles.statsTitle}>
        Product Examples
      </p>
      <p className={styles.statsDescription}>
        Here are some examples of products you can use for this sort of ads.
        These products match the compliance rules.
      </p>
      <Row gutter={30}>
        {productExamples.map((productExample) => {
          return (
            <Col span={8}>
              {productExample.title ? (
                <a
                  href={productExample.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={styles.productExampleTitle}>
                    <div className={styles.productExampleTitleLeft}>
                      <b>Ecosia</b> - {productExample.title}
                    </div>
                    <div className={styles.productExampleTitleRight}>
                      <span>VISIT PAGE</span>{" "}
                      <div>
                        <img src={productExample.arrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles.exampleImage}
                    style={{ boxShadow: "0px 2px 6px #0000000A" }}
                    src={productExample.image}
                    alt=""
                  />
                </a>
              ) : null}
            </Col>
          );
        })}
      </Row>
      <p style={{ marginTop: "30px" }} className={styles.statsTitle}>
        Further Reading
      </p>
      <p className={styles.statsDescription}>
        We have vast experience and we offer premium service. Learn more about
        search feed monetization from our selection of blows below.
      </p>
      <Blogs />
    </div>
  );
};
