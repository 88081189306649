import { Button, notification, Popconfirm, Space, Tooltip } from "antd";
import {
  LineChartOutlined,
  EditOutlined,
  CodeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React from "react";
import styles from "../CoinisCampaigns.module.css";
import { Link, useHistory } from "react-router-dom";
import { publisherFormsService } from "../../../services/publisherFormsService";
import { isDisabledZone } from "../../../utils/validations";

export const ActionsTableCol = ({
  cmp_id,
  id,
  title,
  type,
  allow_reapprove,
  dispatchSelectedFilters,
  filters,
  status,
  pricing_model,
  setQuestionnaireOpen,
}) => {
  const history = useHistory();
  const pathEdit = type === "push" ? `${type}-${pricing_model}` : type;

  const updateZoneStatus = async () => {
    notification.open({
      message: "Updating zone...",
      key: "update",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });
    try {
      if (type === "push") {
        const res = await publisherFormsService.editApp(
          { status: status === "active" ? "paused" : "active" },
          id
        );
        if (res.success) {
          notification["success"]({
            message: "Successfully updated zone!",
            key: "update",
          });
          dispatchSelectedFilters({ type: "", payload: { filters } });
        } else {
          throw new Error();
        }
      } else if (type === "contextual") {
        const res = await publisherFormsService.editWidget(
          { status: status === "active" ? "paused" : "active" },
          id
        );
        if (res.success) {
          notification["success"]({
            message: "Successfully updated zone!",
            key: "update",
          });
          dispatchSelectedFilters({ type: "", payload: { filters } });
        } else {
          throw new Error();
        }
      } else {
        const res = await publisherFormsService.editZone(
          { status: status === "active" ? "paused" : "active" },
          id
        );
        if (res.success) {
          notification["success"]({
            message: "Successfully updated zone!",
            key: "update",
          });
          dispatchSelectedFilters({ type: "", payload: { filters } });
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to update zone!",
        key: "update",
      });
    }
  };

  const deleteZone = async () => {
    notification.open({
      message: "Deleting zone...",
      key: "delete",
      icon: <LoadingOutlined style={{ color: "#eb4034" }} />,
      duration: 0,
    });
    try {
      if (type === "push") {
        await publisherFormsService.deleteApp(id);
      } else if (type === "contextual") {
        await publisherFormsService.deleteWidget(id);
      } else {
        await publisherFormsService.deleteZone(id);
      }
      notification["success"]({
        message: "Successfully deleted zone!",
        key: "delete",
      });
      dispatchSelectedFilters({ type: "", payload: { filters } });
    } catch (e) {
      notification["error"]({
        message: "Error, failed to delete zone!",
        key: "delete",
      });
    }
  };

  return (
    <Space>
      {status === "rejected" ? (
        <Button
          style={{ width: 192 }}
          icon={<ReloadOutlined />}
          onClick={() => setQuestionnaireOpen(cmp_id)}
          disabled={!allow_reapprove}
        >
          Request Approval
        </Button>
      ) : (
        <>
          <Link
            to={{
              pathname: `/publisher/zones/${pathEdit}/${id}?type=preview`,
            }}
            target="_blank"
            onClick={(e) => isDisabledZone(status) && e.preventDefault()}
          >
            <Tooltip title="Get Tag">
              <Button
                disabled={isDisabledZone(status)}
                style={{ color: "#737373" }}
                icon={<CodeOutlined />}
              />
            </Tooltip>
          </Link>
          <Link
            to={{ pathname: `/publisher/zones/${pathEdit}/${id}` }}
            target="_blank"
            onClick={(e) => status === "suspended" && e.preventDefault()}
          >
            <Tooltip title="Edit Zone">
              <Button
                disabled={status === "suspended"}
                style={{ color: "#737373" }}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Link>
          <Tooltip title="Zone Statistics">
            <Button
              disabled={isDisabledZone(status)}
              style={{ color: "#737373" }}
              onClick={() =>
                history.push(
                  `/publisher/statistics?id=${cmp_id}&title=${title}`
                )
              }
              icon={<LineChartOutlined />}
            />
          </Tooltip>
          <Popconfirm
            disabled={isDisabledZone(status)}
            title="Are you sure you want to change status of this zone?"
            onConfirm={updateZoneStatus}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip
              title={
                isDisabledZone(status)
                  ? ""
                  : status === "active"
                  ? "Pause zone"
                  : "Activate zone"
              }
            >
              <Button
                disabled={isDisabledZone(status)}
                style={{ color: "#737373" }}
                icon={
                  status === "active" ? (
                    <PauseCircleOutlined />
                  ) : (
                    <PlayCircleOutlined />
                  )
                }
              />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to delete this zone?"
            onConfirm={deleteZone}
            okText="Yes"
            cancelText="No"
            disabled={status === "suspended"}
          >
            <Tooltip title="Delete Zone">
              <Button
                className={styles.deleteZoneBtn}
                icon={<DeleteOutlined />}
                disabled={status === "suspended"}
              />
            </Tooltip>
          </Popconfirm>
        </>
      )}
    </Space>
  );
};
