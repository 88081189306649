import { callApi } from "./api";


const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class AdSamplesService {
  async getCountries() {
    return await callApi({
      url: "/api/cns/countries/",
    });
  }

  async getSamplesPaged({
    selectedFilters,
    currentPage,
    pageSize,
    cancelToken,
  }) {
    const { filters } = selectedFilters;

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      verticals__in: filters.verticals && filters.verticals.join(","),
      device_types__in: filters.device_types && filters.device_types.join(","),
      traffic_types__in: filters.traffic_types && filters.traffic_types.join(","),
      geos__in: filters.countries && filters.countries.join(","),
    });
    return await callApi({
      url: `/api/ad_sample/${query}`,
      cancelToken,
    });
  }
}

export const adSamplesService = new AdSamplesService();
