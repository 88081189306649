import { callApi } from "./api";
import moment from "moment";

const createPaymentMethodDetails = repsonse => {
  let paymentMethod = repsonse.data.payment_method
    ? repsonse.data.payment_method.charAt(0).toUpperCase() +
    repsonse.data.payment_method.slice(1) +
    ` transfer\n`
    : "";
  let bankNumber =
    repsonse.data.payment_method.toLowerCase() === "wire" &&
      repsonse.data.payment_method_details.bankAccount.number
      ? `Account number: ` +
      repsonse.data.payment_method_details.bankAccount.number.replace(
        /\d(?=\d{4})/g,
        "*"
      ) +
      "\n"
      : "";
  let paypalId =
    repsonse.data.payment_method_details.paypal &&
      repsonse.data.payment_method_details.paypal.paypalId &&
      repsonse.data.payment_method_details.paypal.type === "EMAIL"
      ? `Email address: ${repsonse.data.payment_method_details.paypal.paypalId}\n`
      : "";
  let minimumPayout = repsonse.data.payment_method_details.minimumPayout
    ? `Minimum payout: ` +
    (repsonse.data.payment_method_details.minimumPayout.search(".000") !== -1
      ? repsonse.data.payment_method_details.minimumPayout.replace(".000", "")
      : repsonse.data.payment_method_details.minimumPayout) +
    "\n"
    : "";
  return paymentMethod + bankNumber + paypalId + minimumPayout;
};

class BillingUploadInvoice {
  async getPaymentMethods() {
    return await callApi({
      url: "/api/billing/payment_methods",
    });
  }

  //   const responseData = {
  //     success: true,
  //     message: "Invoice upload allowed.",
  //     data: {
  //       amount: 1395.0,
  //       payment_method: "wire",
  //       payment_method_details: {
  //         id: 57,
  //         paypal: null,
  //         payoneer: null,
  //         bankAccount: {
  //           id: 25,
  //           bankAddress: {
  //             id: 162,
  //             country: "ME",
  //             city: "Podgorica",
  //             zip: "",
  //             address: "Podgorica"
  //           },
  //           beneficiary: "Henrietta  Lovelace Publisher",
  //           bankName: "test",
  //           label: "test",
  //           number: "123456",
  //           swift: "fkjwnaheuir",
  //           iban: "kfjwnjquh43i",
  //           intermediaryBank: "",
  //           intermediarySwift: "",
  //           currency: [
  //             "EUR"
  //           ],
  //           batchPaymentType: "",
  //           type: "wire"
  //         },
  //         isVerified: true,
  //         minimumPayout: "1000.000",
  //         isDefault: true,
  //         partner: 109
  //       },
  //       currency: "EUR"
  //     }
  //   };

  async checkUserInvoice(data) {
    const response = await callApi({
      url: `/api/billing/invoice/check/?module=${data.module}&month=${data.month}&year=${data.year}`,
    });

    let descriptionModuleForm = "";
    switch (data.module.toLowerCase()) {
      case "offerwall":
        descriptionModuleForm = "Affiliate programs";
        break;
      case "publisher":
        descriptionModuleForm = "Publisher";
        break;
      case "search":
        descriptionModuleForm = "Search Feed";
        break;
      case "n2s":
        descriptionModuleForm = "Search Feed - Native to Search";
        break;
      default:
        descriptionModuleForm = "";
    }
    const descriptionForm = `${descriptionModuleForm} activity for - ${moment(
      `${data.year}-${data.month}`,
      "YYYY-MM"
    ).format("MMM YYYY")}`;

    if (response.data) {
      let paymentMetod = "";
      if (response.data.payment_method === "paypal") {
        paymentMetod = "PayPal";
      } else if (response.data.payment_method === "payoneer") {
        paymentMetod = "Payoneer";
      } else if (response.data.payment_method === "wire") {
        paymentMetod = "Wire";
      } else {
        paymentMetod = response.data.payment_method;
      }

      const returnData = {
        success: response.success,
        message: response.message,
        module: data.module.toLowerCase(),
        invoiceDate: moment().format("YYYY-MM-DD").toString(),
        description: descriptionForm,
        paymentMethod: paymentMetod,
        amount: response.data.amount,
        paymentMethodDetails: createPaymentMethodDetails(response),
        currency: response.data.currency,
      };
      return returnData;
    } else {
      return { success: response.success, message: response.message };
    }
    // const responseData = {
    //   success: true,
    //   message: "Invoice upload allowed.",
    //   data: {
    //     amount: 1395.0,
    //     payment_method: "wire",
    //     payment_method_details: {
    //       id: 57,
    //       paypal: null,
    //       payoneer: null,
    //       bankAccount: {
    //         id: 25,
    //         bankAddress: {
    //           id: 162,
    //           country: "ME",
    //           city: "Podgorica",
    //           zip: "",
    //           address: "Podgorica"
    //         },
    //         beneficiary: "Henrietta  Lovelace Publisher",
    //         bankName: "test",
    //         label: "test",
    //         number: "123456",
    //         swift: "fkjwnaheuir",
    //         iban: "kfjwnjquh43i",
    //         intermediaryBank: "",
    //         intermediarySwift: "",
    //         currency: [
    //           "EUR"
    //         ],
    //         batchPaymentType: "",
    //         type: "wire"
    //       },
    //       isVerified: true,
    //       minimumPayout: "1000.000",
    //       isDefault: true,
    //       partner: 109
    //     },
    //     currency: "EUR"
    //   }
    // };
    // return responseData;
  }

  async submitPaymentMethod(data) {
    return await callApi({
      url: "/api/billing/payment_methods/add/",
      method: "POST",
      data,
    });
  }

  async deletePaymentMethod(id) {
    return await callApi({
      url: `/api/billing/payment_methods/${id}/delete/`,
      method: "DELETE",
    });
  }

  async uploadInvoiceForm(data) {
    return await callApi({
      url: `/api/billing/invoice/upload/`,
      method: "POST",
      data,
    });
  }

  async initializePayoneer() {
    return await callApi({
      url: "/api/billing/payoneer_init/",
      method: "POST",
    });
  }
}

export const billingUploadInvoiceService = new BillingUploadInvoice();
