import React from "react";
import Lottie from "react-lottie";
import SpinnerImg from "../components/spinnerimages/spinner.json";

const spinner = {
  loop: true,
  autoplay: true,
  animationData: SpinnerImg,
};

export const CustomLoadingSpinner = ({ pausedAnim }) => (
  <Lottie options={spinner} isPaused={false} />
);
