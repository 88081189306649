import { Table } from "antd";
import React from "react";
import { numberWithSpaces } from "../../../utils/abbreviateNumber";
import styles from "../Dashboard.module.css";

const columnsSearch = [
  {
    title: "SEARCH CHANNEL",
    dataIndex: "search_channel",
  },
  {
    title: "MONETIZED SEARCHES",
    dataIndex: "monetized_searches",
    align: "center",
  },
  {
    title: "RPM",
    dataIndex: "rpm",
    align: "center",
  },
  {
    title: "AD COVERAGE",
    dataIndex: "ad_coverage",
    align: "center",
  },
  {
    title: "REVENUE",
    dataIndex: "revenue",
    align: "center",
  },
];
const columnsN2S = [
  {
    title: "SEARCH CHANNEL",
    dataIndex: "search_channel",
  },
  {
    title: "MONETIZED SEARCHES",
    dataIndex: "monetized_searches",
    align: "center",
  },
  {
    title: "MONETIZED RPM",
    dataIndex: "monetized_rpm",
    align: "center",
  },
  {
    title: "REVENUE",
    dataIndex: "revenue",
    align: "center",
  },
];
const refactorTableData = (tableData) => {
  const tableDataRefactored = tableData
    ? tableData.map((el, index) => {
      const refactor = { ...el };
      refactor.revenue =
        refactor.revenue &&
        "$ " +
        Number(refactor.revenue).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      refactor.monetized_rpm =
        refactor.monetized_rpm &&
        "$ " +
        Number(refactor.monetized_rpm).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      refactor.ad_coverage =
        refactor.ad_coverage && refactor.ad_coverage.toFixed(2) + "%";

      refactor.rpm =
        refactor.rpm &&
        "$ " +
        Number(refactor.rpm).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      refactor.monetized_searches =
        refactor.monetized_searches &&
        numberWithSpaces(refactor.monetized_searches);
      return {
        key: index,
        ...refactor,
      };
    })
    : "";
  return tableDataRefactored;
};

export const TopChannelsTable = ({ chartType, loading, tableData }) => {
  return (
    <div className={styles.channelsTableContainer}>
      <h4>Top 10 Search Channels</h4>
      <Table
        loading={loading}
        columns={chartType === 1 ? columnsSearch : columnsN2S}
        dataSource={refactorTableData(tableData)}
        pagination={false}
        rowKey={({ search_channel }) => search_channel}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </div>
  );
};
