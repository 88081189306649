import React from "react";
import { Tooltip } from "antd";
import moment from "moment";
import styles from "./HeadInfo.module.css";

export const ColumnGraph = ({ data, type, color, name }) => {
  let sum = 0;
  let lineValue = 0;
  for (let i in data) {
    sum += Math.abs(data[i][name]);
    if (lineValue > data[i][name]) {
      lineValue = data[i][name];
    }
  }
  sum = sum !== 0 ? sum : 1;

  const height =
    300 * (Math.abs(lineValue) / sum) + 4 > 76
      ? 76
      : 300 * (Math.abs(lineValue) / sum) + 4;

  const negativeStylesDiv =
    lineValue < 0 ? { transform: `translateY(-${height}px)` } : {};

  return (
    <div className={styles.mainDiv} style={negativeStylesDiv}>
      {data.map((item, index) => {
        const dateString = moment(item.date).format("DD-MM-YYYY");
        const columnValue =
          type === "2"
            ? "$ " +
              Number(item[name]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : item[name];
        const height =
          300 * (Math.abs(item[name]) / sum) + 4 > 76
            ? 76
            : 300 * (Math.abs(item[name]) / sum) + 4;

        const negativeStyles =
          item[name] < 0
            ? {
                transform: `translateY(${height}px)`,
              }
            : {};

        return (
          <Tooltip
            placement="top"
            title={dateString + " : " + columnValue}
            key={index}
          >
            <div
              style={{
                height: height + "px",
                background: color,
                ...negativeStyles,
              }}
              className={`${styles["col-graph" + index]} ${
                styles["col-graph"]
              }`}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};
