import React from "react";
import { parsePositionStyles } from "../../utils";
import styles from "./AdThemes.module.css";

export const Sharp = ({
  adQuantity,
  backgroundColor,
  contentColor,
  contentRadius,
  adPosition,
}) => {
  return (
    <div
      className={styles.mainWrapper} style={{
        ...parsePositionStyles(adPosition)
      }}>
      <div
        className={styles.sharp}
        style={{
          backgroundColor,
          color: contentColor,
          borderRadius: contentRadius,
        }}
      >
        <div className={styles.sharpTop}>
          <img src="/images/coinis-icon.jpg" alt="coinis" />
          <div>
            <p>Visit Melbourne this summer</p>
            <p>Here's why you should consider visiting Melbourne</p>
          </div>
        </div>
        <div className={styles.sharpBottom}>
          <p>Remove ads</p>
          <p>Close</p>
        </div>
      </div>
      {adQuantity === 2 ?
        <div
          className={styles.sharp}
          style={{
            backgroundColor,
            color: contentColor,
            borderRadius: contentRadius,
          }}
        >
          <div className={styles.sharpTop}>
            <img src="/images/coinis-icon.jpg" alt="coinis" />
            <div>
              <p>Visit Melbourne this summer</p>
              <p>Here's why you should consider visiting Melbourne</p>
            </div>
          </div>
          <div className={styles.sharpBottom}>
            <p>Remove ads</p>
            <p>Close</p>
          </div>
        </div> : ""}
    </div>

  );
};
