import React, { useEffect, useState } from "react";
import { abbrNum } from "../../../../utils/abbreviateNumber";
import { HeadCardInfo } from "./HeadCardInfo";
import styles from "./HeadInfo.module.css";
import { agencyService } from "../../../../services/agencyService";

export const HeadInfo = () => {
  const [loading, setLoading] = useState(false);
  const [dataInfo, setDataInfo] = useState([]);

  useEffect(() => {
    const fetchCardInfo = async () => {
      setLoading(true);
      try {
        const data = await agencyService.getTopCardInfo();
        setDataInfo(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchCardInfo();
  }, []);

  return (
    <div className={styles.headInfoContainer}>
      <HeadCardInfo
        dataTotal={dataInfo.total && abbrNum(dataInfo.total[0].spent, 2)}
        dataDate={dataInfo.table}
        loading={loading}
        name="spent"
        title="Spent"
        type="2"
        color="#C6D699"
      />
      <HeadCardInfo
        dataTotal={
          dataInfo.total && "$ " + abbrNum(dataInfo.total[0].revenue, 2)
        }
        dataDate={dataInfo.table}
        loading={loading}
        type="2"
        name="revenue"
        title="Revenue"
        color="#F9AE3B"
      />
      <HeadCardInfo
        dataTotal={
          dataInfo.total && "$ " + abbrNum(dataInfo.total[0].gross_profit, 2)
        }
        dataDate={dataInfo.table}
        loading={loading}
        type="2"
        name="gross_profit"
        title="Gross Profit"
        color="#E07655"
      />
      <HeadCardInfo
        dataTotal={
          dataInfo.total && "$ " + abbrNum(dataInfo.total[0].profit, 2)
        }
        dataDate={dataInfo.table}
        loading={loading}
        type="2"
        name="profit"
        title="Profit"
        color="#C6D699"
      />
      <HeadCardInfo
        dataTotal={dataInfo.total && "$ " + abbrNum(dataInfo.total[0].rpc, 2)}
        dataDate={dataInfo.table}
        loading={loading}
        type="2"
        name="rpc"
        title="RPC"
        color="#F9AE3B"
      />
      <HeadCardInfo
        dataTotal={dataInfo.total && "$ " + abbrNum(dataInfo.total[0].cpc, 2)}
        dataDate={dataInfo.table}
        loading={loading}
        type="2"
        name="cpc"
        title="CPC"
        color="#E07655"
      />
    </div>
  );
};
