import React, { useState } from "react";

import { SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip, notification } from "antd";

import styles from "../CoinisCampaigns.module.css";
import { publisherFormsService } from "../../../services/publisherFormsService";

const convertPricingModel = (type) => {
  switch (type) {
    case "cps":
      return "CPS";
    case "CPC":
      return "CPC";
    case "revshare":
      return "RevShare";
    default:
      return "";
  }
};

export const PricingModelColumn = ({ type: tableType, item }) => {
  const [type, setType] = useState(tableType);
  const [loading, setLoading] = useState(false);

  const handlePricingModelSwitch = async (id, type) => {
    setLoading(true);
    notification.open({
      message: "Loading...",
      key: "update",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });
    try {
      const { success } = await publisherFormsService.editApp(
        {
          pricing_model: type === "revshare" ? "cps" : "revshare",
        },
        id
      );
      if (success) {
        notification["success"]({
          message: "Pricing model switched successfully",
          key: "update",
        });
        return true;
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to send request.",
        key: "update",
      });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <span className={styles.pricingModel}>
      {convertPricingModel(type)}
      {item.format === "push" && (
        <Tooltip
          title={`Switch to ${type === "revshare" ? "CPS" : "RevShare"}`}
        >
          <Button
            disabled={loading}
            type="text"
            style={{ color: "#999" }}
            onClick={async () => {
              const status = await handlePricingModelSwitch(item.id, item.type);
              setType(
                status ? (type === "revshare" ? "cps" : "revshare") : type
              );
            }}
            icon={<SyncOutlined />}
          />
        </Tooltip>
      )}
    </span>
  );
};
