import { callApi } from "./api";
const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        if (key === "hour") {
          ordering.push("-date");
        }
        ordering.push("-" + key);
      } else {
        if (key === "hour") {
          ordering.push("date");
        }
        ordering.push(key);
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class ReportsService {
  async getCountries() {
    return await callApi({
      url: "/api/cns/countries/",
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: "/api/cns/os/",
    });
  }
  async getBrowsers() {
    return await callApi({
      url: "/api/cns/browsers/",
    });
  }

  async getStatsPaged({
    selectedFilters,
    isArchiveIncluded,
    currentPage,
    pageSize,
    sorting,
    cancelToken,
    action,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      os: filters.os && filters.os.join(","),
      browser: filters.browsers && filters.browsers.join(","),
      country: filters.countries && filters.countries.join(","),
      // channel: filters.channel,
      campaign_id: filters.campaign_id && filters.campaign_id.join(","),
      // campaign_id: filters.campaigns && filters.campaigns.join(","),
      // program: filters.program && filters.program.join(","),
      ordering: ordering && ordering.join(","),
      action: action ? true : "",
      show_archived: isArchiveIncluded ? 1 : 0,
    });
    return await callApi({
      url: `/api/offerwall/stats/${query}`,
      cancelToken,
    });
  }

  async exportReports({
    selectedFilters,
    isArchiveIncluded,
    currentPage,
    pageSize,
    sorting,
    action,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      resType: "csv",
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      os: filters.os && filters.os.join(","),
      browser: filters.browsers && filters.browsers.join(","),
      country: filters.countries && filters.countries.join(","),
      // channel: filters.channel,
      campaign_id: filters.campaign_id && filters.campaign_id.join(","),
      // campaign_id: filters.campaigns && filters.campaigns.join(","),
      ordering: ordering && ordering.join(","),
      action: action ? true : "",
      show_archived: isArchiveIncluded ? 1 : 0,
    });

    window.location = `/api/offerwall/stats/${query}`;
  }

  async changeTargetingStatus(data) {
    return await callApi({
      url: "/api/campaigns/targeting/",
      method: "POST",
      data,
    });
  }

  async getChannelsAutocomplete(searchChannelTerm) {
    return await callApi({
      url: `/api/offerwall/campaigns/autocomplete/?search=${searchChannelTerm}`,
    });
  }

  async getProgramsAutocomplete(searchProgramTerm) {
    return await callApi({
      url: `/api/offerwall/programs/autocomplete/?search=${searchProgramTerm}`,
    });
  }
}

export const reportsService = new ReportsService();
