import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { userNotificationsService } from "../services/userNotifications";
import UserContext from "./userContext";
import moment from "moment";
import { auth } from "../services/auth";

const NotificationContext = React.createContext({});
export default NotificationContext;

export const NotificationContextProvider = ({ children, currentModule }) => {
  const { userInfo } = useContext(UserContext);

  const [modules, setModules] = useState();
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [moduleChanged, setModuleChanged] = useState(false);

  const [userNotifications, setUserNotifications] = useState([]);
  const [userNotificationsCount, setUserNotificationsCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [broadcastNotifications, setBroadcastNotifications] = useState([]);
  const [interstitialNotification, setInterstitialNotification] =
    useState(null);

  const [userNotificationPage, setUserNotificationPage] = useState(1);
  const [userNotificationPageSize, setUserNotificationPageSize] = useState(5);

  const [showUnread, setShowUnread] = useState(null);

  useEffect(() => {
    setModuleChanged(true);
    if (currentModule === "search") {
      setModules(["extension", "n2s"]);
    } else if (currentModule === "offerwall") {
      auth.hasPermission("affiliate_programs") ? setModules(["affiliate_programs"]) : setModules(["offerwall"]);
    } else {
      setModules([currentModule]);
    }
  }, [currentModule]);

  const getUploadInvoiceMessage = (
    currentModule,
    userUploadInvoiceNotification
  ) => {
    const currentMonth = moment().format("MMMM");
    if (moment().date() <= 10 && currentModule !== "billing") {
      if (
        currentModule !== "search" &&
        userUploadInvoiceNotification[currentModule]
      ) {
        return `Please submit your invoice no later than 10th ${currentMonth}.`;
      } else {
        return userUploadInvoiceNotification[currentModule]
          ? `Please submit your invoice no later than 10th ${currentMonth}.`
          : false;
      }
    }
    return false;
  };

  useEffect(() => {
    if (userInfo.upload_invoice_notification) {
      const description = getUploadInvoiceMessage(
        currentModule,
        userInfo.upload_invoice_notification
      );
      if (description) {
        setBroadcastNotifications(prevState => [
          ...prevState,
          {
            icon: "/resources/notification_icons/metro-dollar2.png",
            description,
          },
        ]);
      }
    }
  }, [currentModule, userInfo.upload_invoice_notification]);

  useEffect(() => {
    const getNotifications = async () => {
      setLoadingNotifications(true);
      try {
        const res = await userNotificationsService.getUserNotifications(
          modules,
          userNotificationPage,
          userNotificationPageSize,
          showUnread
        );
        if (res.success) {
          setBroadcastNotifications(res.data.broadcast_notifications);
          setUserNotifications(res.data.notification_bar_notifications);
          setInterstitialNotification(res.data.interstitial_notification);
          setUserNotificationsCount(
            res.data.notification_bar_notifications_count
          );
          setUnreadCount(res.data.notification_bar_notifications_unread_count);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingNotifications(false);
        setModuleChanged(false);
      }
    };
    getNotifications();
  }, [modules, showUnread, userNotificationPageSize, userNotificationPage]);

  return (
    <NotificationContext.Provider
      value={{
        loadingNotifications,
        broadcastNotifications,
        interstitialNotification,
        userNotifications,
        setUserNotifications,
        userNotificationsCount,
        unreadCount,
        moduleChanged,
        setUnreadCount,
        userNotificationPage,
        setUserNotificationPage,
        userNotificationPageSize,
        setUserNotificationPageSize,
        showUnread,
        setShowUnread,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
