import { Form, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./InPagePushForm.module.css";
import { publisherFormsService } from "../../../services/publisherFormsService";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../../utils/notifications";
import { InPageForm } from "./components/InPageForm";
import { GetTag } from "./components/GetTag";
import { useHistory } from "react-router";
import QueryString from "qs";
import { isDisabledZone } from "../../../utils/validations";

export const InPagePushForm = ({ location, match }) => {
  const [zoneLoading, setZoneLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const isPreview =
    QueryString.parse(location.search, { ignoreQueryPrefix: true }).type ===
    "preview";

  // first || second step
  const [step, setStep] = useState(0);

  const [isFrequency, setIsFrequency] = useState(true);
  const [currentZoneId, setCurrentZoneId] = useState(
    match.params.id && match.params.id !== "new" ? match.params.id : null
  );
  const [currentZoneInfo, setCurrentZoneInfo] = useState({});

  const history = useHistory();

  //form items
  const [widgetStyle, setWidgetStyle] = useState("old-school");
  const [adPosition, setAdPosition] = useState("top-left");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [contentColor, setContentColor] = useState("#000000");
  const [contentRadius, setContentRadius] = useState(4);

  const [form] = Form.useForm();

  const getZoneInfo = useCallback(
    async (id) => {
      if (isPreview) {
        setStep(1);
      }
      setZoneLoading(true);
      try {
        const res = await publisherFormsService.getZone(id);

        if (isDisabledZone(res.data?.status)) {
          setStep(0);
        }

        if (match.params.id && match.params.id !== "new") {
          form.setFieldsValue(res.data);
          setWidgetStyle(res.data.widget_style);
          setAdPosition(res.data.ad_position);
          setBackgroundColor(res.data.background_color);
          setContentColor(res.data.content_color);
          setContentRadius(res.data.content_radius);
          if (res.data.frequency_interval) {
            setIsFrequency(true);
          }
        }
        setCurrentZoneInfo(res.data);
      } catch (e) {
        openErrorNotification({
          message: "Error while trying to get details about zone!",
        });
      } finally {
        setZoneLoading(false);
      }
    },
    [form, isPreview, match.params.id]
  );

  useEffect(() => {
    if (currentZoneId) {
      getZoneInfo(currentZoneId);
    }
  }, [currentZoneId, getZoneInfo]);

  const finishZoneForm = async (data) => {
    setBtnLoading(true);
    try {
      if (!data.frequency_interval || !isFrequency) {
        data.frequency_interval = 0;
      }
      const res = !(match.params.id && match.params.id !== "new")
        ? await publisherFormsService.createZone({
            ...data,
            widget_style: widgetStyle,
            ad_position: adPosition,
            background_color: backgroundColor,
            content_color: contentColor,
            content_radius: contentRadius,
            type: "inpage",
          })
        : await publisherFormsService.editZone(
            {
              ...data,
              widget_style: widgetStyle,
              ad_position: adPosition,
              background_color: backgroundColor,
              content_color: contentColor,
              content_radius: contentRadius,
              type: "inpage",
            },
            match.params.id
          );
      if (res.success) {
        openSuccessNotification({ message: res.message });
        if (match.params.id && match.params.id !== "new") {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            history.push("/publisher/zones");
          } else {
            getZoneInfo(match.params.id);
            setStep(1);
          }
        } else {
          if (res.data.status === "pending" || res.data.status === "rejected") {
            openWarningNotification({
              message:
                "Looks like you have a new website! Allow us 24-48h to check your traffic quality. You will get an email once your zone is approved.",
              duration: 15,
            });
            history.push("/publisher/zones");
          } else {
            setCurrentZoneId(res.data.id);
            setStep(1);
          }
        }
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: "Error occurred while trying to create new zone!",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Spin spinning={zoneLoading}>
      <div className={styles.formContainer}>
        {step === 0 ? (
          <InPageForm
            form={form}
            match={match}
            finishZoneForm={finishZoneForm}
            isFrequency={isFrequency}
            setIsFrequency={setIsFrequency}
            btnLoading={btnLoading}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            contentColor={contentColor}
            setContentColor={setContentColor}
            contentRadius={contentRadius}
            setContentRadius={setContentRadius}
            widgetStyle={widgetStyle}
            setWidgetStyle={setWidgetStyle}
            adPosition={adPosition}
            setAdPosition={setAdPosition}
          />
        ) : (
          <GetTag
            currentZoneInfo={currentZoneInfo}
            location={location}
            match={match}
            setStep={setStep}
          />
        )}
      </div>
    </Spin>
  );
};
