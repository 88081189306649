import React, { useState } from "react";
import { Layout } from "antd";
import Media from "react-media";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { SwitchRoutes } from "../routes/SwitchRoutes";
import { getAllowedRoutes } from "../routes";
import { useLocation } from "react-router-dom";
import { isHeaderAndSidebarHidden } from "./utils/hiddenSidebar";
import { UserContextProvider } from "../contexts/userContext";
import { ModuleModal } from "./components/ModuleModal";
import { getInitialModule, isModuleVisible } from "./utils";
import { BroadcastNotification } from "./components/BroadcastNotification";
import { NotificationContextProvider } from "../contexts/notificationContext";
import { AgreementModal } from "./components/AgreementModal";
import { InterstitialNotification } from "./components/InterstitialNotification";
import { FeedbackModal } from "./components/FeedbackModal";
import { UserFiltersContextProvider } from "../contexts/userFiltersContext";

export const AppLayout = () => {
  const location = useLocation();
  const isSidebarVisible = !isHeaderAndSidebarHidden(location);
  const [moduleVisible, setModuleVisible] = useState(
    isModuleVisible(location.pathname)
  );
  const [currentModule, setCurrentModule] = useState(
    getInitialModule(location.pathname)
  );
  const [agreementModalVisible, setAgreementModalVisible] = useState(false);
  const wasCollapsed = localStorage.getItem("collapse");
  const [collapsed, setCollapsed] = useState(
    wasCollapsed ? wasCollapsed === "true" : window.innerWidth < 1400
  );

  return (
    <>
      <Layout style={{ minHeight: "100vh", maxHeight: "100vh", zIndex: "2" }}>
        {isSidebarVisible && (
          <Media queries={{ small: { minWidth: 599 } }}>
            {(matches) => (
              <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                isSmallScreen={!matches.small}
                selectedKey={location.pathname}
                currentModule={currentModule}
              />
            )}
          </Media>
        )}
        <Layout>
          <UserContextProvider currentModule={currentModule}>
            <NotificationContextProvider currentModule={currentModule}>
              <UserFiltersContextProvider
                currentModule={currentModule}
                location={location}
              >
                {isSidebarVisible && (
                  <Media queries={{ small: { minWidth: 599 } }}>
                    {(matches) => (
                      <Header
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        isSmallScreen={!matches.small}
                        setModuleVisible={setModuleVisible}
                        setCurrentModule={setCurrentModule}
                        currentModule={currentModule}
                        setAgreementModalVisible={setAgreementModalVisible}
                      />
                    )}
                  </Media>
                )}
                <BroadcastNotification />
                <Layout.Content
                  style={{
                    margin: isSidebarVisible ? "20px 20px 20px 20px" : 0,
                  }}
                >
                  <FeedbackModal currentModule={currentModule} />
                  <div
                    style={{
                      padding: isSidebarVisible ? 24 : 0,
                      background: "#fff",
                      minHeight: 360,
                    }}
                  >
                    <SwitchRoutes routes={getAllowedRoutes()} />
                  </div>
                </Layout.Content>
                <ModuleModal
                  moduleVisible={moduleVisible}
                  setModuleVisible={setModuleVisible}
                  setCurrentModule={setCurrentModule}
                />
                <AgreementModal
                  agreementModalVisible={agreementModalVisible}
                  setAgreementModalVisible={setAgreementModalVisible}
                  currentModule={currentModule}
                />
                <InterstitialNotification />
              </UserFiltersContextProvider>
            </NotificationContextProvider>
          </UserContextProvider>
        </Layout>
      </Layout>
    </>
  );
};
