import { Button, Radio, DatePicker, Row, Col, Select, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import styles from "./Filters.module.css";
import { ExportOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnsGroup } from "./ColumnsGroup";
import {
  generateZones,
  generateCountries,
  generateOptions,
} from "../utils/filterOptions";
import moment from "moment";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { columnPreset } from "../utils/columnsList";
import { publisherReportsService } from "../../../services/publisherReports";
import { ColumnInfo } from "../../../components/ColumnInfo";
import { FilterPresetModal } from "../../../components/FilterPresetModal";
import UserFiltersContext from "../../../contexts/userFiltersContext";
import { showApiErrors } from "../../../utils/showApiErrors";
import { openSuccessNotification } from "../../../utils/notifications";
import { userFiltersService } from "../../../services/userFilters";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { confirm } = Modal;

export const Filters = ({
  onSubmit,
  allColumns,
  dateRange,
  setDateRange,
  chartStats,
  handleExport,
  zone_id,
  zone_title,
  setCurrentPage,
}) => {
  const [checkedColumns, setCheckedColumns] = useState(columnPreset);
  const [countries, setCountries] = useState([]);
  const [os, setOS] = useState([]);
  const [browsers, setBrowsers] = useState([]);
  const [autocompleteLoading, setAutocompleteLodaing] = useState(false);
  //title debounce search
  const [searchTerm, setSearchTerm] = useState("");
  const debounceZone = useDebounce(searchTerm, 300);
  const [zones, setZones] = useState([]);
  const [filters, setFilters] = useState({});

  const [columnInfoVisible, setColumnInfoVisible] = useState(false);

  const {
    userFilters,
    setUserFilters,
    userSavedFilter,
    setUserSavedFilter,
    userSavedFilterLoading,
    module
  } = useContext(UserFiltersContext);

  useEffect(() => {
    const getZones = async searchTerm => {
      setAutocompleteLodaing(true);
      try {
        const res = await publisherReportsService.getZonesAutocomplete(
          searchTerm
        );
        // when linked from zones
        let newZones = [...res.data];
        if (zone_id && newZones.every(item => item.cmp_id !== zone_id)) {
          newZones.push({ title: zone_title, cmp_id: zone_id });
        }
        setZones(newZones);
      } catch (e) {
        console.log(e);
      } finally {
        setAutocompleteLodaing(false);
      }
    };
    getZones(debounceZone);
  }, [debounceZone, zone_id, zone_title]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await publisherReportsService.getCountries();
        if (res.data) {
          setCountries(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getOS = async () => {
      try {
        const res = await publisherReportsService.getOperatingSystems();
        if (res.data) {
          setOS(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getBrowsers = async () => {
      try {
        const res = await publisherReportsService.getBrowsers();
        if (res.data) {
          setBrowsers(res.data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    getCountries();
    getOS();
    getBrowsers();
  }, []);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      // filters: urlFilters || filters,
      filters,
      dateRange,
      checkedColumns: urlCheckedColumns || checkedColumns,
    });
    setCurrentPage(1);
  };

  // fix warnings
  useEffect(() => {
    if (zone_id) {
      onSubmit({
        filters: { ...filters, zone_name: [zone_id] },
        dateRange,
        checkedColumns: checkedColumns,
      });
      setFilters(prevState => {
        return { ...prevState, zone_name: [zone_id] };
      });
    } else {
      handleUpdate();
    }
    // eslint-disable-next-line
  }, []);
  //fix warnings ^^

  const setSavedFilter = (name) => {
    let selectedSavedFilter = userFilters.find((el) => el.name === name);
    setFilters(JSON.parse(selectedSavedFilter.filter));
  };

  const handleDeleteFilter = async (id, name) => {
    const newFilters = userFilters.filter((filter) => filter.name.toLowerCase() !== name);
    try {
      await userFiltersService.deleteFilter(id);
      openSuccessNotification({ message: "Filter preset successfully deleted!" })
      if (userSavedFilter === name) {
        setUserSavedFilter(null);
        setFilters({});
      }
      setUserFilters(newFilters);
    } catch (e) {
      showApiErrors(e);
    }
  };

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: `Are you sure that you want to delete ${name} filter?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteFilter(id, name);
      }
    });
  };

  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="today"
          size="large"
        >
          <Radio.Button
            value="today"
            onClick={() => setDateRange({ from: moment(), to: moment() })}
          >
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "days"),
                to: moment().subtract(1, "days"),
              })
            }
          >
            Yesterday
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({ from: moment().subtract(6, "days"), to: moment() })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div className={styles.filtersContainerBottom}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col md={24} lg={12} xxl={8}>
              <span className={styles.spanMarginBottom}>Date range</span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={false}
                // className={styles.rangeDatePicker}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Filter presets</span>
              <Select
                className={styles.filtersSelect}
                allowClear
                value={userSavedFilter}
                onChange={(value) => {
                  setUserSavedFilter(value);
                  if (value) {
                    setSavedFilter(value);
                  }
                }}
                style={{ width: "100%" }}
                loading={userSavedFilterLoading}
                placeholder="Select filter preset"
              >
                {userFilters.map(({ id, name }) =>
                  <Option className={styles.optionFilter} key={id} value={name}>
                    {name}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirm(id, name);
                      }}
                      className={styles.deleteBtn}
                    >
                      <DeleteOutlined style={{
                        float: 'right',
                        color: '#ff5600',
                        cursor: 'pointer',
                        marginTop: '3px'
                      }} />
                    </span>
                  </Option>
                )}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xxl={4} className={styles.limitTooltip}>
              <FilterPresetModal
                filters={filters}
                userFilters={userFilters}
                setUserSavedFilter={setUserSavedFilter}
                currentModule={module}
                onUpdate={setUserFilters}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: "1%" }} className={styles.advancedFilters}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Country</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, countries: value })}
                style={{ width: "100%" }}
                placeholder="Select country"
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                value={filters.countries}
                allowClear
              >
                {generateCountries(countries || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Operating System</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, os: value })}
                style={{ width: "100%" }}
                placeholder="Select OS"
                value={filters.os}
                allowClear
              >
                {generateOptions(os || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Browser</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, browsers: value })}
                style={{ width: "100%" }}
                placeholder="Select browsers"
                value={filters.browsers}
                allowClear
              >
                {generateOptions(browsers || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Zone</span>
              <Select
                mode="multiple"
                onChange={value => setFilters({ ...filters, zone_name: value })}
                loading={autocompleteLoading}
                filterOption={false}
                style={{ width: "100%" }}
                placeholder="Select zones"
                onSearch={setSearchTerm}
                value={filters.zone_name}
                allowClear
              >
                {generateZones(zones || [])}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xxl={4}>
              <Button
                style={{ marginTop: "22px", height: "32px", width: "100%" }}
                className={styles.applyFiltersBtn}
                type="primary"
                size="medium"
                onClick={handleUpdate}
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.bottomActions}>
        <div className={styles.rightActionBtns}>
          <div>
            <ColumnsGroup
              columnsData={allColumns}
              onChange={value => {
                setCheckedColumns(value);
              }}
              showUpdateButton={true}
              onUpdate={handleUpdate}
              checkedColumns={checkedColumns}
            />
            <Button
              className={styles.columnsBtn}
              onClick={() => setColumnInfoVisible(true)}
            >
              Column Info
            </Button>
          </div>
          <Button
            onClick={handleExport}
            className={styles.columnsBtn}
            icon={<ExportOutlined />}
          >
            Export to CSV
          </Button>
          {/* <Button
            icon={<ReloadOutlined />}
            className={styles.reloadBtn}
            onClick={handleUpdate}
          >
            Reload
          </Button> */}
        </div>
      </div>
      <ColumnInfo
        visible={columnInfoVisible}
        setVisible={setColumnInfoVisible}
        allColumns={allColumns}
      />
    </>
  );
};
