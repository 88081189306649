import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./SearchGraphView.module.css";
import { StatisticsLineChart } from "./components/StatisticsLineChart";
import { Spin } from "antd";
import { CustomLoader } from "./components/CustomLoader";
// import { auth } from "../../services/auth";
import UserContext from "../../contexts/userContext";
import { Filters } from "./components/Filters";
import moment from "moment";
import { searchGraphService } from "../../services/searchGraphReports";

// const { Option } = Select;

// const mapColors = [
//   "#A3A1FB",
//   "#50D8BB",
//   "#FFA167",
//   "#FF7CC3",
//   "#81C9FF",
//   "#FF8474",
// ];

export const SearchGraphView = () => {
  const { userInfo } = useContext(UserContext);

  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: moment(),
  });
  const [compareDateRange, setCompareDateRange] = useState(null);

  const [chartData, setChartData] = useState({});
  const [compareChartData, setCompareChartData] = useState({});

  const [loading, setLoading] = useState(false);
  const [currentFeedType, setCurrentFeedType] = useState(null);
  const [selectedMetrics, setSelectedMetrics] = useState([
    "monetized_searches",
    "revenue",
    "total_searches",
    "clicks",
  ]);

  const bothPermissions = useMemo(() => {
    return (
      userInfo.permissions?.search.extension && userInfo.permissions?.search.n2s
    );
  }, [userInfo]);

  const handleGraphRender = async () => {
    if (!currentFeedType) return false;

    setLoading(true);
    try {
      const [resData, compareResData] = await Promise.all(
        currentFeedType === "n2s"
          ? [
              searchGraphService.getN2sStats(dateRange),
              searchGraphService.getN2sStats(compareDateRange),
            ]
          : [
              searchGraphService.getSearchStats(dateRange),
              searchGraphService.getSearchStats(compareDateRange),
            ]
      );
      setChartData(resData);
      setCompareChartData(compareResData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCompareChartData({});
    setChartData({});
    setSelectedMetrics([
      "monetized_searches",
      "revenue",
      "total_searches",
      "clicks",
    ]);
    setDateRange({
      from: moment().subtract(2, "days"),
      to: moment(),
    });
    setCompareDateRange(null);
  }, [currentFeedType]);

  useEffect(() => {
    setCurrentFeedType(
      userInfo.permissions?.search.extension
        ? "extension"
        : userInfo.permissions?.search.n2s
        ? "n2s"
        : null
    );
  }, [userInfo]);

  return (
    <div className={styles.mainContainer}>
      <h4 className={styles.titleDashboard}>Graph View</h4>
      <p>Start by selecting the activity you want to create graph for.</p>
      <div>
        {bothPermissions ? (
          <div className={styles.requestFeedRadioGroup}>
            <div
              className={`${styles.requestFeedRadioBox} ${
                currentFeedType === "extension" &&
                styles.requestFeedRadioBoxSelected
              }`}
              onClick={() => setCurrentFeedType("extension")}
            >
              <p>Search</p>
            </div>
            <div
              className={`${styles.requestFeedRadioBox} ${
                currentFeedType === "n2s" && styles.requestFeedRadioBoxSelected
              }`}
              onClick={() => setCurrentFeedType("n2s")}
            >
              <p>N2S</p>
            </div>
          </div>
        ) : null}
      </div>
      <Filters
        selectedMetrics={selectedMetrics}
        setSelectedMetrics={setSelectedMetrics}
        dateRange={dateRange}
        setDateRange={setDateRange}
        compareDateRange={compareDateRange}
        setCompareDateRange={setCompareDateRange}
        handleGraphRender={handleGraphRender}
      />
      <Spin spinning={loading} indicator={<CustomLoader />}>
        <StatisticsLineChart
          loading={false}
          selectedMetrics={selectedMetrics}
          chartData={chartData}
          compareChartData={compareChartData}
        />
      </Spin>
    </div>
  );
};
