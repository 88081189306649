import React from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import styles from "./TableTitle.module.css";
import { Tooltip } from "antd";
export const TableTitle = ({
  onSortingClick,
  value,
  tooltip,
  order,
  text,
  align,
  bold,
}) => {
  let upArrowClass = null;
  let downArrowClass = null;
  // if (order === "descend") {
  //   upArrowClass = null;
  //   downArrowClass = "active-arrow";
  // } else if (order === "ascend") {
  //   upArrowClass = "active-arrow";
  //   downArrowClass = null;
  // }
  const handleAscSortingClick = (e) => {
    onSortingClick(value, "ascend", e.shiftKey);
  };
  const handleDescSortingClick = (e) => {
    onSortingClick(value, "descend", e.shiftKey);
  };
  return (
    <div className={styles.TableTitle} style={{ justifyContent: align }}>
      <Tooltip title={tooltip} style={bold ? { fontWeight: 600 } : {}}>
        <span className={styles.title}>{text}</span>
      </Tooltip>
      <div className={styles.arrows}>
        <div className={upArrowClass}>
          <CaretUpOutlined onClick={handleAscSortingClick} />
        </div>
        <div className={downArrowClass}>
          <CaretDownOutlined onClick={handleDescSortingClick} />
        </div>
      </div>
    </div>
  );
};
