import { Table } from "antd";
import React from "react";
import { numberWithSpaces } from "../../../utils/abbreviateNumber";
import styles from "../Dashboard.module.css";
import moment from "moment";

const columns = [
  {
    dataIndex: "search_channel",
    title: "Channel",
    align: "left",
    render: (item) => <div className={styles.channelNameTitle}>{item}</div>,
  },
  {
    dataIndex: "revenue",
    title: "Revenue",
    align: "center",
  },
  {
    dataIndex: "impressions",
    title: "Impressions",
    align: "center",
  },
  {
    dataIndex: "clicks",
    title: "Clicks",
    align: "center",
  },
  {
    dataIndex: "cpc",
    title: "CPC",
    align: "center",
  },
  {
    dataIndex: "ctr",
    title: "CTR",
    align: "center",
  },
  {
    dataIndex: "rpc",
    title: "RPC",
    align: "center",
  },
  {
    dataIndex: "search_cr",
    title: "Search CR",
    align: "center",
  },
];

export const TopChannelsTable = ({ loading, tableData }) => {
  const refactorTableData = (tableData) => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          refactor.revenue =
            refactor.revenue &&
            "$ " +
              Number(refactor.revenue).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.spent =
            refactor.spent &&
            "$ " +
              Number(refactor.spent).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.profit =
            refactor.profit &&
            "$ " +
              Number(refactor.profit).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.cpc =
            refactor.cpc &&
            "$ " +
              Number(refactor.cpc).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.cpm =
            refactor.cpm &&
            "$ " +
              Number(refactor.cpm).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.gross_profit =
            refactor.gross_profit &&
            "$ " +
              Number(refactor.gross_profit).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.monetized_rpm =
            refactor.monetized_rpm &&
            "$ " +
              Number(refactor.monetized_rpm).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.rpc =
            refactor.rpc &&
            "$ " +
              Number(refactor.rpc).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
          refactor.date = moment(refactor.date, "YYYYMMDD").format(
            "YYYY-MM-DD"
          );
          refactor.monetized_searches = numberWithSpaces(
            refactor.monetized_searches
          );
          refactor.roi =
            refactor.roi && parseFloat(refactor.roi * 100).toFixed(2) + "%";
          refactor.ctr =
            refactor.ctr && parseFloat(refactor.ctr * 100).toFixed(2) + "%";
          refactor.search_cr =
            refactor.search_cr &&
            parseFloat(refactor.search_cr * 100).toFixed(2) + "%";
          refactor.total_searches = numberWithSpaces(refactor.total_searches);
          refactor.clicks = numberWithSpaces(refactor.clicks);
          return {
            key: index,
            ...refactor,
          };
        })
      : [];
    return tableDataRefactored;
  };

  return (
    <div className={styles.channelsTableContainer}>
      <h4>Top 10 Search Channels</h4>
      <Table
        loading={loading}
        columns={columns}
        dataSource={refactorTableData(tableData)}
        pagination={false}
        rowKey={({ search_channel }) => search_channel}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </div>
  );
};
