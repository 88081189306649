import React from "react";
import { parsePositionStyles } from "../../utils";
import styles from "./AdThemes.module.css";

export const Compact = ({
  adQuantity,
  backgroundColor,
  contentColor,
  contentRadius,
  adPosition,
}) => {
  return (
    <div
      className={styles.mainWrapper} style={{
        ...parsePositionStyles(adPosition)
      }}>
      <div
        className={styles.compact}
        style={{
          backgroundColor,
          color: contentColor,
          borderRadius: contentRadius,
        }}
      >
        <div className={styles.compactTop}>
          <img src="/images/coinis-icon.jpg" alt="coinis" />
          <div>
            <p>Visit Melbourne this summer</p>
            <p>Here's why you should consider visiting Melbourne</p>
          </div>
        </div>
        <p className={styles.compactBottom}>CLOSE</p>
      </div>
      {adQuantity === 2 ?
        <div
          className={styles.compact}
          style={{
            backgroundColor,
            color: contentColor,
            borderRadius: contentRadius,
          }}
        >
          <div className={styles.compactTop}>
            <img src="/images/coinis-icon.jpg" alt="coinis" />
            <div>
              <p>Visit Melbourne this summer</p>
              <p>Here's why you should consider visiting Melbourne</p>
            </div>
          </div>
          <p className={styles.compactBottom}>CLOSE</p>
        </div> : ""}
    </div>
  );
};
