import { callApi } from "./api";
import moment from "moment";

const handleOrdering = (sorting) => {
  let ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        ordering.push("-" + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter(key => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map(key => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class ZonesService {
  async getCountries() {
    return await callApi({
      url: "/api/countries/",
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: "/api/os/",
    });
  }
  async getBrowsers() {
    return await callApi({
      url: "/api/browsers/",
    });
  }
  async getCampaignsPaged({
    selectedFilters,
    currentPage,
    checkedColumns,
    pageSize,
    searchValue,
    cancelToken,
    sorting
  }) {
    const { filters } = selectedFilters;
    let colParameter = "status,id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      zone_name: filters.name,
      columns: colParameter,
      date_created:
        filters.dateCreated && moment(filters.dateCreated).format("YYYY-MM-DD"),
      // cmp_ids: filters.campaigns && filters.campaigns.join(","),
      status: filters.status && filters.status,
      ad_format: filters.format && filters.format,
      ordering: ordering && ordering.join(",")
    });
    const response = await callApi({
      url: `/api/publishers/zone_app/${query}`,
      cancelToken,
    });
    return { results: response.results, count: response.count };
    // return await { results: data, count: 5 };
  }
}

export const publisherZonesService = new ZonesService();
