import React, { useState } from "react";
import { useEffect } from "react";
import { userFiltersService } from "../services/userFilters";
import { auth } from "../services/auth";

const UserFiltersContext = React.createContext({});
export default UserFiltersContext;

export const UserFiltersContextProvider = ({ children, currentModule, location }) => {

    const [userFilters, setUserFilters] = useState([]);

    const [userSavedFilter, setUserSavedFilter] = useState(null);
    const [userSavedFilterLoading, setUserSavedFilterLoading] = useState(false);

    const module = location.pathname.includes('n2s') ? 'n2s' : currentModule === "offerwall" && auth.hasPermission("affiliate_programs") ? "affiliate_programs" : currentModule;

    useEffect(() => {
        const getUserFilters = async () => {
            try {
                setUserSavedFilterLoading(true);
                let userFiltersModule = await userFiltersService.getFilters();
                if (userFiltersModule.hasOwnProperty(module)) {
                    setUserFilters(userFiltersModule[module]);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setUserSavedFilterLoading(false);
            }
        };
        getUserFilters();
    }, [module]);

    return (
        <UserFiltersContext.Provider
            value={{
                userFilters,
                setUserFilters,
                userSavedFilter,
                setUserSavedFilter,
                userSavedFilterLoading,
                module
            }}
        >
            {children}
        </UserFiltersContext.Provider>
    );
};
