import React from "react";
import { renderWidget } from "../../utils";
import styles from "../InPagePushForm.module.css";

export const AdPreview = ({
  adQuantity,
  widgetStyle,
  backgroundColor,
  contentColor,
  contentRadius,
  adPosition,
}) => {
  return (
    <div className={styles.adPreviewBox}>
      <div className={styles.adPreviewTitle}>
        <p>Ad preview</p>
      </div>
      <div className={styles.adPreviewWindow}>
        <div>
          {renderWidget(
            adQuantity,
            widgetStyle,
            backgroundColor,
            contentColor,
            contentRadius,
            adPosition
          )}
        </div>
      </div>
    </div>
  );
};
