import React, { useContext } from "react";
import { Tabs } from "antd";
import { PersonalDetails } from "./components/PersonalDetails";
import { ChangePassword } from "./components/ChangePassword";
import styles from "./Profile.module.css";
import UserContext from "../../contexts/userContext";
import { MyModules } from "./components/MyModules";

const { TabPane } = Tabs;
export const MyProfile = ({ location }) => {
  const { userInfo } = useContext(UserContext);

  return (
    <div className={styles.profileContainer}>
      <h2 className={styles.titleProfile}>My Profile</h2>
      <Tabs
        defaultActiveKey={
          "3"
          // location.state && location.state.fromModuleSelection ? "3" : "2"
        }
        className={styles.tabsSection}
      >
        <TabPane tab="Personal Details" key="1">
          <PersonalDetails userDetails={userInfo} />
        </TabPane>
        <TabPane tab="Security" key="2">
          <ChangePassword userDetails={userInfo} />
        </TabPane>
        <TabPane tab="My Modules" key="3">
          <MyModules
            userDetails={userInfo}
            requestedModule={
              location.state && location.state.fromModuleSelection
            }
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
