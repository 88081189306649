import { Popover } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import styles from "../CoinisProgramDetails.module.css";

export const renderStatusIcon = status => {
  switch (status.toLowerCase()) {
    case "allowed":
      return (
        <CheckCircleOutlined
          style={{ color: "#58a54f", marginLeft: "0.3rem", fontSize: "1rem" }}
        />
      );
    case "needs approval":
      return (
        <ExclamationCircleOutlined
          style={{ color: "#ffaa2d", marginLeft: "0.3rem", fontSize: "1rem" }}
        />
      );
    case "forbidden":
    case "not allowed":
      return (
        <CloseCircleOutlined
          style={{ color: "#f84949", marginLeft: "0.3rem", fontSize: "1rem" }}
        />
      );
    default:
      return null;
  }
};

export const formatedCountries = (data) => {
  let numOfWords = 0;
  let visibleElements = [];
  let other = 0;

  for (let i = 0; i < data.length; i++) {
    numOfWords = numOfWords + data[i].split(' ').length;
    if (!(numOfWords > 15)) {
      other = data.length - (i + 1);
      visibleElements = data.slice(0, i + 1).join(", ");
    }
  }

  return (
    <>
      <div>{visibleElements}</div>
      <Popover overlayInnerStyle={{width: '400px', height: '300px', overflow: 'scroll'}} placement="bottom" title={null} content={data.join(", ")} trigger="click">
        <span className={styles.otherData}>{other !== 0 ? "+ " + other + " more" : ""}</span>
      </Popover>
    </>
  );
}
