import React, { useState, useEffect, useMemo, useReducer, useCallback } from "react";

import { Table } from "./components/Table";
import styles from "./CoinisCampaigns.module.css";
import { useFetch } from "../../utils/hooks/useFetch";
import { Button, Input, notification } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { CampaignFilters } from "./components/CampaignsFilters";
import {
  allColumns,
  initialSelectedColumns,
  staticColumns,
} from "./utils/columns";
import { ActionsTableCol } from "./components/ActionsTableCol";
import { useDebounce } from "../../utils/hooks/useDebounce";
import { filtersReducer } from "./utils/reducers";
import { publisherZonesService } from "../../services/publisherZones";
import { ZoneModal } from "./components/ZoneModal.js";
import { Questionnaire } from "../PublisherForms/components/Questionnaire";
import { publisherFormsService } from "../../services/publisherFormsService";
import { toFormData } from "../PublisherForms/utils";

export const PublisherZones = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(
    filtersReducer,
    {}
  );
  // const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [filters, setFilters] = useState({});
  const [checkedColumns, setCheckedColumns] = useState(initialSelectedColumns);

  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const [questionnaireLoading, setQuestionnaireLoading] = useState(false);

  //title debounce search
  const [searchTerm, setSearchTerm] = useState("");
  const debounceChannel = useDebounce(searchTerm, 300);
  //create zone modal
  const [zoneModalVisible, setZoneModalVisible] = useState(false);

  const [sorting, setSorting] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const loadMediaCampaigns = (cancelToken) =>
    publisherZonesService.getCampaignsPaged({
      selectedFilters,
      checkedColumns,
      currentPage,
      pageSize,
      cancelToken,
      sorting
    });

  useEffect(() => {
    // if (debounceChannel) {
    dispatchSelectedFilters({ type: "debounce", payload: debounceChannel });
    // }
  }, [debounceChannel]);

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({
        cmp_id,
        id,
        title,
        format,
        status,
        type,
        allow_reapprove,
      }) => (
        <ActionsTableCol
          cmp_id={cmp_id}
          allow_reapprove={allow_reapprove}
          id={id}
          title={title}
          type={format}
          status={status}
          pricing_model={type}
          dispatchSelectedFilters={dispatchSelectedFilters}
          filters={filters}
          setQuestionnaireOpen={setQuestionnaireOpen}
        />
      ),
    };
  }, [filters]);

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    const filteredColumns = allColumns(sorting, handleSortingClick).filter((item) => {
      return Object.keys(checkedColumns).some((key) => {
        return checkedColumns[key] && key === item.dataIndex;
      });
    });
    setSelectedColumns([
      ...staticColumns,
      ...filteredColumns,
      actionTableColumn,
    ]);
  }, [selectedFilters, actionTableColumn, checkedColumns, sorting, handleSortingClick]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters, sorting]);

  const handleRequestApproval = async (values) => {
    notification.open({
      message: "Requesting approval...",
      key: "reapprove",
      icon: <LoadingOutlined style={{ color: "#00cc66" }} />,
      duration: 0,
    });
    try {
      const resObj = toFormData({ ...values, cmp_id: questionnaireOpen });

      const res = await publisherFormsService.requestReapproval(resObj);
      if (res.success) {
        notification["success"]({
          message: "Successfully requested approval!",
          key: "reapprove",
        });
        setQuestionnaireOpen(false);
        dispatchSelectedFilters({ type: "", payload: { filters } });
      } else {
        throw new Error();
      }
    } catch (e) {
      notification["error"]({
        message: "Error, failed to send request!",
        key: "reapprove",
      });
    } finally {
      setQuestionnaireLoading(false);
    }
  };

  return (
    <div className={styles.campaignsTable}>
      <div className={styles.pageTitle}>
        <h2>Zones</h2>
        <Input
          style={{ maxWidth: 200, margin: "0 1rem" }}
          placeholder="Enter channel"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          icon={<PlusOutlined />}
          type="primary"
          className={styles.newCampaignBtn}
          onClick={() => setZoneModalVisible(true)}
        >
          Add Zone
        </Button>
      </div>
      <CampaignFilters
        submit={(filters) => {
          dispatchSelectedFilters({ type: "", payload: { filters } });
        }}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        searchValue={searchValue}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        onSearch={(search) => setSearchValue(search)}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        selectedFilters={selectedFilters}
      />
      <ZoneModal
        zoneModalVisible={zoneModalVisible}
        setZoneModalVisible={setZoneModalVisible}
      />
      <Questionnaire
        isOpen={questionnaireOpen}
        setIsOpen={setQuestionnaireOpen}
        loading={questionnaireLoading}
        setLoading={setQuestionnaireLoading}
        isNewZone={false}
        handleConfirm={(questionnaireValues) => {
          handleRequestApproval(questionnaireValues);
        }}
      />
    </div>
  );
};
