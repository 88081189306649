import { BASE_URL } from "../../../config";
import styles from "../Reports.module.css";

export const baseColumnTemplate = [
  {
    value: "date",
    text: "Date",
    tooltip: "Date frame period of observing your activity",
  },
  {
    value: "hour",
    text: "Hour",
    tooltip: "Checking performance of your activity on hourly basis",
  },
  {
    value: "country",
    text: "Country",
    tooltip: "Country from which traffic is coming",
    render: item => {
      return (
        <div className={styles.countryBox}>
          {item && item !== "XX" && (
            <img
              src={`${BASE_URL}media/countries/${item.toLowerCase()}.png`}
              alt="test"
            />
          )}
          {item}
        </div>
      );
    },
  },
  {
    value: "im",
    text: "Visits/Impressions",
    tooltip:
      "Visits represents total traffic that came to our links after filtering",
  },
  {
    value: "leads",
    text: "Leads",
    tooltip:
      "Amount of leads - it can be installs, trials, sales or other custom event",
  },

  {
    value: "os",
    text: "OS",
    tooltip: "Operating systems from which traffic is coming",
  },
  {
    value: "os_version",
    text: "OS Version",
    tooltip: "Operations systems versions and sub-versions",
  },
  {
    value: "browser",
    text: "Browser",
    tooltip: "Browser from which traffic is coming",
  },
  {
    value: "browser_version",
    text: "Browser Version",
    tooltip: "Browser versions and sub-versions from which traffic is coming",
  },
  {
    value: "source",
    text: "Source",
    tooltip:
      "Source or subid is often related as actual website/publisher from which traffic is coming within a channel",
  },

  { value: "cr", text: "CR", tooltip: "Conversion rate of your campaigns" },
  { value: "rpm", text: "RPM", tooltip: "Revenue per thousand impressions" },
  { value: "rpl", text: "RPL", tooltip: "Revenue per lead" },
  { value: "on_hold_revenue", text: "On hold revenue", tooltip: "Revenue that has been generated from sales that have been made, but are still awaiting confirmation" },
  { value: "confirmed_revenue", text: "Confirmed revenue", tooltip: "Revenue that has been generated from sales that have been confirmed by the advertiser" },
  { value: "declined_revenue", text: "Declined revenue", tooltip: "Revenue that has been generated from sales that have been rejected by the advertiser" },
  { value: "revenue", text: "Revenue", tooltip: "Revenue or money earned" },
  // { value: "program", text: "Program", tooltip: "Program for your campaign" },
  { value: "qs1", text: "QS1", tooltip: "QS1" },
  { value: "qs2", text: "QS2", tooltip: "QS2" },
  { value: "qs3", text: "QS3", tooltip: "QS3" },
  { value: "qs4", text: "QS4", tooltip: "QS4" },
  { value: "qs5", text: "QS5", tooltip: "QS5" },
  { value: "qs6", text: "QS6", tooltip: "QS6" },
  { value: "qs7", text: "QS7", tooltip: "QS7" },
  { value: "qs8", text: "QS8", tooltip: "QS8" },
  { value: "qs9", text: "QS9", tooltip: "QS9" },
];

export const offerwallAdditionalColumns = [
  {
    value: "channel",
    text: "Channel",
    tooltip:
      "Identifier of your campaigns. usually created by account managers to easily distinigush campaigns",
  },
  {
    value: "campaign_id",
    text: "Campaign ID",
    tooltip: "Unique id of your campaigns that matches with actual channels",
  }
];

export const columnPreset = {
  date: true,
  cr: true,
  im: true,
  ecpa: true,
  channel: true,
  cpm: true,
  leads: true,
  on_hold_revenue: true,
  confirmed_revenue: true,
  declined_revenue: true,
  revenue: true,
  rpm: true,
  rpl: true,
};

// export const columnPresetAffiliate = {
//   date: true,
//   program: true,
//   im: true,
//   leads: true,
//   cr: true,
//   on_hold_revenue: true,
//   confirmed_revenue: true,
//   declined_revenue: true,
//   revenue: true
// };

export const zoneColumnPreset = {
  revenue: true,
  zone: true,
  cpm: true,
  impressions: true,
  clicks: true,
};

export const dateColumnPreset = {
  revenue: true,
  date: true,
  cpm: true,
  impressions: true,
  clicks: true,
};

export const countryColumnPreset = {
  revenue: true,
  country: true,
  cpm: true,
  impressions: true,
  clicks: true,
};

// const OLDcolumnTemplate = [
//   { value: "subid", text: "SubID" },
//   { value: "campaign_name", text: "Campaign" },
//   { value: "campaign_id", text: "Campaign ID" },
//   { value: "country", text: "Country" },
//   { value: "date", text: "Date" },
//   { value: "os_version", text: "OS Version" },
//   { value: "creatives", text: "Creatives" },
//   { value: "browser", text: "Browser" },
//   { value: "browser_version", text: "Browser Version" },
//   { value: "campaign_type", text: "Campaign Type" },

//   { value: "ad_format", text: "Ad Format" },
//   { value: "revenue", text: "Revenue" },
//   { value: "hour", text: "Hour" },
//   { value: "os", text: "OS" },
//   { value: "cpc", text: "CPC" },
//   { value: "cpm", text: "CPM" },
//   { value: "cr", text: "CR" },
//   { value: "im", text: "Impressions" },
//   { value: "cpa", text: "CPA" },
//   { value: "clicks", text: "Clicks" },
//   { value: "roi", text: "ROI" },
//   { value: "conversions", text: "Conversions" },
// ];
