import React from "react";
import { Button, Row, Col, DatePicker } from "antd";
import moment from "moment";
import styles from "../SearchGraphView.module.css";
import classNames from "classnames";

// const { Option } = Select;
const { RangePicker } = DatePicker;

export const Filters = ({
  selectedMetrics,
  setSelectedMetrics,
  dateRange,
  setDateRange,
  compareDateRange,
  setCompareDateRange,
  handleGraphRender,
}) => {
  const handleMetricSelect = (metric, id) => {
    setSelectedMetrics(prevMetrics => {
      const currentMetrics = [...prevMetrics];
      currentMetrics[id] = prevMetrics.includes(metric) ? null : metric;
      return currentMetrics;
    });
  };

  const rangeData = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(30, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
  };

  return (
    <>
      <div className={styles.filtersContainer}>
        <div style={{ marginTop: "1%", paddingBottom: "0.5rem" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <span className={styles.spanMarginBottom}>Date range</span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={false}
                ranges={rangeData}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <span className={styles.spanMarginBottom}>
                Compare to date range
              </span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={true}
                ranges={rangeData}
                onChange={(momentDates, dates) => {
                  setCompareDateRange(
                    momentDates
                      ? {
                          from: momentDates[0],
                          to: momentDates[1],
                        }
                      : null
                  );
                }}
                value={
                  compareDateRange && [
                    moment(compareDateRange.from),
                    moment(compareDateRange.to),
                  ]
                }
              />
            </Col>
            <Col xs={24} sm={12} md={12}>
              <p className={styles.filterMetricLabel}>
                Click on metric to include or exclude it from the graph.
              </p>
              <div className={styles.graphCheckboxMetrics}>
                <span
                  onClick={() => handleMetricSelect("total_searches", 2)}
                  className={classNames(styles.graphSingleMetric, {
                    [styles.graphSelectedMetric]:
                      selectedMetrics.includes("total_searches"),
                  })}
                >
                  Total searches
                </span>
                <span
                  onClick={() => handleMetricSelect("monetized_searches", 0)}
                  className={classNames(styles.graphSingleMetric, {
                    [styles.graphSelectedMetric]:
                      selectedMetrics.includes("monetized_searches"),
                  })}
                >
                  Monetized searches
                </span>
                <span
                  onClick={() => handleMetricSelect("clicks", 3)}
                  className={classNames(styles.graphSingleMetric, {
                    [styles.graphSelectedMetric]:
                      selectedMetrics.includes("clicks"),
                  })}
                >
                  Clicks
                </span>
                <span
                  onClick={() => handleMetricSelect("revenue", 1)}
                  className={classNames(styles.graphSingleMetric, {
                    [styles.graphSelectedMetric]:
                      selectedMetrics.includes("revenue"),
                  })}
                >
                  Revenue
                </span>
              </div>
            </Col>
            <Col xs={24} xxl={24}>
              <Button
                className={styles.applyFiltersBtn}
                style={{ marginTop: 22 }}
                type="primary"
                size="medium"
                onClick={handleGraphRender}
              >
                Make Graph
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
