import React, { useState } from "react";
import styles from "../BillingPaymentMethods.module.css";
import {
  CloseOutlined,
  InfoCircleTwoTone,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Tooltip, InputNumber, Space, Popconfirm, Button } from "antd";
import { billingPaymentMethodsService } from "../../../services/billingPaymentMethods";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../utils/notifications";
import classNames from "classnames";

export const PaymentMethod = ({
  hasTipalti,
  methodDetails,
  setLoading,
  getPaymentMethods,
  setTipaltiVisible,
}) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [newMinimumPayout, setNewMinimumPayout] = useState(
    methodDetails.minimumPayout
  );
  const [payoneerUrl, setPayoneerUrl] = useState(null);
  const [payoneerLoading, setPayoneerLoading] = useState(false);

  const getPayoneerUrl = async () => {
    setPayoneerLoading(true);
    try {
      if (methodDetails.payoneer && !methodDetails.isVerified) {
        const res = await billingPaymentMethodsService.initializePayoneer();
        if (res.success) {
          setPayoneerUrl(res.data.registrationLink);
          window.open(res.data.registrationLink, "_blank");
        } else {
          openErrorNotification({ message: res.message });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setPayoneerLoading(false);
    }
  };

  const renderMethodInfo = (methodDetails) => {
    switch (methodDetails.type) {
      case "payoneer":
        return `Payee ID: ${methodDetails.payoneer && methodDetails.payoneer.payoneerId
          }`;
      case "paypal":
        return `Email address: ${methodDetails.paypal && methodDetails.paypal.paypalId
          }`;
      case "wire":
        if (methodDetails.tipalti) {
        }
        return `Account number: ${methodDetails.bankAccount && methodDetails.bankAccount.number
          }`;
      default:
        return null;
    }
  };

  const handleDelete = async () => {
    // if (methodDetails.isDefault) {
    //   openWarningNotification({ message: "You can't delete default method." });
    //   return;
    // }
    setLoading(true);
    try {
      const res = await billingPaymentMethodsService.deletePaymentMethod(
        methodDetails.id
      );
      if (res.success) {
        openSuccessNotification({
          message: res.message,
        });
        getPaymentMethods();
      } else {
        openErrorNotification({
          message: res.message,
        });
      }
    } catch (e) {
      openErrorNotification({
        message: e.message,
      });
    }
  };

  const handleUpdate = async (price) => {
    setLoading(true);
    let editObj = isNaN(price) ? {} : { minimum_payout: price };
    try {
      const res = await billingPaymentMethodsService.updatePaymentMethod(
        methodDetails.id,
        editObj
      );
      if (res.success) {
        setVisibleEdit(false);
        openSuccessNotification({
          message: res.message,
        });
        getPaymentMethods();
      } else {
        openErrorNotification({
          message: res.message,
        });
        setLoading(false);
      }
    } catch (e) {
      openErrorNotification({
        message: e.message,
      });
      setLoading(false);
    }
  };

  return (
    <div
      className={classNames(styles.paymentMethodContainer, {
        [styles.methodCursor]: methodDetails.tipalti,
      })}
      onClick={() => {
        if (methodDetails.tipalti) {
          setTipaltiVisible(true);
        }
      }}
    >
      <div className={styles.paymentMethodContent}>
        <div>
          {methodDetails.isDefault && !hasTipalti && (
            <span className={styles.statusDefault}>DEFAULT</span>
          )}
          {methodDetails.isVerified ? (
            <span className={styles.statusVerified}>VERIFIED</span>
          ) : (
            <span className={styles.statusPending}>PENDING</span>
          )}
        </div>
        {methodDetails.tipalti ? (
          <div className={styles.methodContentBox}>
            <span className={styles.methodHighlight}>Tipalti</span>
            <span>Successfully integrated</span>
          </div>
        ) : (
          <div className={styles.methodContentBox}>
            <span className={styles.methodHighlight}>{methodDetails.type === "tetherErc20" ? "USDT ERC-20" : methodDetails.type}</span>
            <span>{renderMethodInfo(methodDetails)}</span>
          </div>
        )}

        {methodDetails.isVerified && methodDetails.minimumPayout ? (
          <div
            className={styles.methodContentBox}
            onClick={(e) => e.stopPropagation()}
          >
            <span className={styles.methodHighlight}>
              Minimum payout{" "}
              <Tooltip
                overlayClassName={styles.tooltipOverlay}
                title={`Minimum payout for ${methodDetails.type === "tetherErc20" ? "USDT ERC-20" : methodDetails.type} is ${methodDetails.minimumPayout}${methodDetails.type === "tetherErc20" ? " USD₮" : " USD"}`}
              >
                <InfoCircleTwoTone />
              </Tooltip>
            </span>

            <span>
              {visibleEdit ? (
                <InputNumber
                  size="small"
                  min={methodDetails.type === "wire" ? 1000 : methodDetails.type === "tetherErc20" ? 500 : 100}
                  value={newMinimumPayout}
                  onChange={(value) => setNewMinimumPayout(value)}
                />
              ) : (
                methodDetails.minimumPayout
              )}{" "}
              {methodDetails.type === "tetherErc20" ? "USD₮" : "USD"}{" "}
              {visibleEdit ? (
                <Space>
                  <CheckOutlined
                    style={{
                      cursor: "pointer",
                      color: "#73b566",
                    }}
                    onClick={() => handleUpdate(newMinimumPayout)}
                  />
                  <CloseOutlined
                    style={{ cursor: "pointer", color: "#8c1616" }}
                    onClick={() => setVisibleEdit(false)}
                  />
                </Space>
              ) : (
                <EditOutlined
                  style={{ color: "#717171" }}
                  onClick={() => setVisibleEdit(!visibleEdit)}
                />
              )}
            </span>
          </div>
        ) : null}
        {!methodDetails.isVerified && (
          <div className={styles.payoneerContainerReg}>
            {payoneerUrl ? (
              <Button
                className={styles.payoneerRegistrationBtn}
                href={payoneerUrl}
                type="primary"
                target="_blank"
                rel="noreferrer"
              >
                Link With Payoneer
              </Button>
            ) : (
              <Button
                className={styles.payoneerLinkBtn}
                loading={payoneerLoading}
                onClick={getPayoneerUrl}
                type="primary"
              >
                Complete Registration
              </Button>
            )}
            <span className={styles.payoneerInfoMessage}>
              If you completed registration, please wait for the verification.
            </span>
          </div>
        )}
      </div>
      <div className={styles.paymentMethodActions}>
        {!methodDetails.tipalti ? (
          <Popconfirm
            title="Are you sure you want to delete this payment method?"
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <CloseOutlined className={styles.closeBtn} />
          </Popconfirm>
        ) : (
          <span></span>
        )}
        {/* <Popconfirm
          disabled={methodDetails.isDefault}
          title="Are you sure you want to make this payment method default?"
          onConfirm={handleUpdate}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete"> */}
        {methodDetails.isVerified && !hasTipalti && (
          <span
            className={
              methodDetails.isDefault
                ? styles.defaultDisabled
                : styles.defaultEnabled
            }
            onClick={(e) => {
              e.stopPropagation();
              handleUpdate();
            }}
          >
            Make default
          </span>
        )}
        {/* </Tooltip>
        </Popconfirm> */}
      </div>
    </div>
  );
};
